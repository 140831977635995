<template>
  <div class="home" v-if="pageData">
    <div class="warp-01">
      <div>
        <van-icon name="checked" color="#4D88D2" class="the-icon" />
        <span class="tips">{{ $t("bind.success") }}</span>
      </div>
      <div class="sub-tips">{{ $t("bind.success_tips") }}</div>
      <div class="block-01">
        <img :src="pageData.parent.avatar" alt="" class="img-01" />
        <div class="text-01">{{ pageData.parent.name }}</div>
        <div class="text-02">
          <span><van-icon name="phone-o" /> {{ pageData.parent.phoneNum }}</span>
          <span style="white-space: nowrap;"><van-icon name="envelop-o" /> {{ pageData.parent.email }}</span>
        </div>
      </div>
      <div class="block-02">
        <!-- <van-button type="default" round class="btn-01">申请修改信息</van-button> -->
        <!-- <van-button type="default" round class="btn-01" @click="cancelBind">{{ $t("bind.logout") }}</van-button> -->
      </div>
    </div>
    <div class="warp-02" v-if="pageData.children.length > 0">
      <div class="block-03">{{ $t("bind.my_children") }}</div>
      <div class="block-04">
        <div class="item-01" v-for="(item, index) in pageData.children" :key="index">
          <div class="item-01-01">
            <van-image
              round
              :src="item.avatar"
              class="img-02"
            />
          </div>
          <div class="item-01-02">
            <div class="item-01-02-01">{{ item.name }}</div>
            <div class="item-01-02-02">{{ item.birthday }} <span v-if="item.class">{{ item.class }}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="warp-03">
      <van-button type="default" round class="btn-01" @click="cancelBind">{{ $t("bind.logout") }}</van-button>
      <van-button type="info" round class="btn-01" @click="onClose">{{ $t("global.close") }}</van-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Icon, Dialog } from 'vant';
import { subscribe, home } from '@/api/login'
import store from '../store'
import wx from 'weixin-js-sdk'
import { ACCESS_TOKEN } from '@/store/mutation-types'

Vue.use(Icon);

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      pageData: null
    }
  },
  created () {
    subscribe({
      state: store.getters.wxState || 'ds'
    }).then(res => {
      if (res.code == 1) {
        this.$router.push({ path: '/subscribe' })
      }
    })
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData() {
      home().then(res => {
        this.pageData = res.data
      })
    },
    cancelBind() {
      Dialog.confirm({
        title: this.$t('bind.logout'),
        message: this.$t('bind.are_you_sure_logout'),
      })
        .then(() => {
          // on confirm
          store.dispatch('Logout')
          sessionStorage.removeItem(ACCESS_TOKEN)
          this.$router.push({ path: '/login' })
        })
        .catch(() => {
          // on cancel
        });
    },
    onClose() {
      wx.closeWindow()
    }
  }
}
</script>

<style scoped lang="less">
.home {
  .warp-01 {
    background-color: #fff;
    padding: 18px 16px;
    .tips {
      font-size: 17px;
      font-weight: 500;
      color: #333333;
    }
    .sub-tips {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      margin-left: 28px;
      margin-top: 4px;
      margin-bottom: 24px;
    }
    .the-icon {
      font-size: 20px;
      margin-right: 8px;
    }
    .the-icon:before {
      vertical-align: middle;
    }
    .block-01 {
      text-align: center;
      margin-bottom: 16px;
      .img-01 {
        width: 88px;
        height: 88px;
        border-radius: 8px;
        margin-bottom: 12px;
      }
      .text-01 {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        margin-bottom: 8px;
      }
      .text-02 {
        height: 18px;
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        span:first-child {
          margin-right: 24px;
        }
        span i {
          font-size: 16px;
          &::before {
            vertical-align: middle;
          }
        }
      }
    }
    .block-02 {
      text-align: center;
      .btn-01 {
        width: 160px;
        &:nth-child(2) {
          margin-left: 23px;
        }
      }
    }
  }
  .warp-02 {
    margin-top: 10px;
    background-color: #fff;
    padding: 18px 16px;
    .block-03 {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      margin-bottom: 8px;
    }
    .block-04 {
      .item-01 {
        padding: 8px 0;
        display: flex;
        .item-01-01 {
          margin-right: 12px;
          .img-02 {
            width: 52px;
            height: 52px;
          }
        }
        .item-01-02 {
          margin-top: 4px;
          .item-01-02-01 {
            font-size: 15px;
            font-weight: 500;
            color: #323233;
            margin-bottom: 6px;
          }
          .item-01-02-02 {
            font-size: 12px;
            font-weight: 400;
            color: #646566;
            span:before {
              display: inline-block;
              content: '';
              width: 1px;
              height: 10px;
              background-color: #DCDEE0;
              margin: 0 8px;
            }
          }
        }
      }
    }
  }
  .warp-03 {
    display: flex;
    justify-content: space-between;
    padding: 24px 16px;
    .btn-01 {
      width: 160px;
      height: 40px;
    }
  }
}
</style>
