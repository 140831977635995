<template>
  <div>
    <van-row class="bus mt-20" :class="stepStatus == 4 && dataDetails.isCommon==1 ? 'clickNone' : ''">
      <div class="color3 font15">
        {{$t('coll.apply_bus')}}
      </div>
      <van-radio-group v-model="busData.need_bus" class="mt-20">
        <van-radio name="2">
          <span class="color6 font14">{{ $t("coll.no_bus") }}</span>
        </van-radio>
        <van-radio name="1" class="mt-15"
          ><span class="color6 font14">{{ $t("coll.yes_bus") }}</span>
        </van-radio>
      </van-radio-group>
    </van-row>

    <div v-if="busData.need_bus == 1">
      <div :class="stepStatus == 4 && dataDetails.isCommon==1 ? 'clickNone' : ''">
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">{{ $t("coll.Method") }}</div>
          </div>
        </div>
        <van-row gutter="20" class="mt-20">
          <van-col span="12">
            <span
              class="ride font14 color3"
              :class="busData.journey == 1 ? 'rideActive' : ''"
              @click="toRide(1)"
              >{{ $t("coll.one_way_school") }}</span
            >
          </van-col>
          <van-col span="12">
            <span
              class="ride font14 color3"
              :class="busData.journey == 2 ? 'rideActive' : ''"
              @click="toRide(2)"
              >{{ $t("coll.two_way_home") }}</span
            >
          </van-col>
          <van-col span="12" class="pr-10 mt-15">
            <span
              class="ride font14 color3"
              :class="busData.journey == 3 ? 'rideActive' : ''"
              @click="toRide(3)"
              >{{ $t("coll.two_way") }}</span
            >
          </van-col>
        </van-row>
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">{{ $t("coll.pick_up") }}</div>
          </div>
        </div>
        <van-row class="mt-10">
          <span class="site color6 font14 mr-20 mt-10" @click="mapShow()"
            ><van-icon name="search"  class="pr-5 font16" />{{
              $t("coll.existing_Pick_up")
            }}</span
          >
          <span class="site color6 font14 mt-10" @click="addSite()"
            ><van-icon name="plus"  class="pr-5 font16" />{{
              $t("coll.apply_pick_up")
            }}</span
          >
        </van-row>
        <div class="flex mt-20" v-if="busData.parking != ''">
          <div class="flexLat">
            <van-icon name="location" class="font13" color="#C8C9CC" />
          </div>
          <div class="flex1">
            <div class="color6 font13 ">{{ $t("coll.my_Pick_up") }}</div>
            <div class="color3 font14 mt-10">{{ busData.parking }}</div>
          </div>
        </div>
        <div v-if="dataDetails.protocol.length != 0">
          <protocol-sign
            @click="baseImg"
            :busProtocol="dataDetails.protocol"
            :base64="base64"
          ></protocol-sign>
        </div>
      </div>
    </div>
    <div class="mt-20">
      <van-button
        v-if="stepStatus == 4 && dataDetails.isCommon==1"
        disabled
        type="info"
        block
        round
        color="#4d88d2"
        class="saveSub"
        >{{ $t("coll.step_reviewed") }}</van-button
      >
      <a
        href="javascript:;"
        v-else
        @click="submitData()"
        class="saveBtnStep"
        :class="btnDisabled ? 'disabledSubmit' : ''"
        >{{ $t("global.submit") }}</a
      >
    </div>
    <van-action-sheet v-model="baidumapShow">
      <div class="flex p10">
        <div class="flexWdith font13" @click="siteCheckCancel()">
          {{ $t("global.cancel") }}
        </div>
        <div class="flex1 text-center">
          <strong class="font15">{{ $t("coll.point_Pick_up") }}</strong>
        </div>
        <div class="flexWdith font13" @click="siteCheck()">
          {{ $t("global.ok") }}
        </div>
      </div>
      <van-search
        v-model="location"
        show-action
        placeholder="请输入搜索地址"
        class='borderInput'
      >
        <template #action>
          <div @click="initSearch" class="pr-10">{{ $t("coll.search") }}</div>
        </template>
      </van-search>
      <div id="firstPage">
        <div class="map">
          <el-amap :center="center" :zoom="zoom" id="mapExample" :events="events" :plugin="plugin">
           <el-amap-marker
              v-for="(marker,index) in markers"
              :key="index"
              :events="marker.events"
              :position="marker.position"
            />
            <el-amap-info-window
              v-if="window"
              :position="window.position"
              :visible="window.visible"
              :content="window.content"
              :offset="window.offset"
              :close-when-click-map="true"
              :is-custom="true"
            >
              <div id="info-window">
                <div class="mb-5">{{ $t("coll.CompoundName") }}{{ window.address }}</div>
                <div class="mb-10">{{ $t("coll.Pick-upPoint") }}{{ window.point }}</div>
                <button class="van-button van-button--info van-button--small van-button--round" @click="checkDetail">{{ $t("coll.SelectPoint") }}</button>
                <div class="amap-info-sharp"></div>
              </div>
            </el-amap-info-window>
          </el-amap>
        </div>
      </div>
      <div style="max-height: 250px; overflow-y: auto" ref="scroll">
        <van-radio-group v-model="mapchecked">
          <van-cell-group>
            <van-cell
              :title="marker.name_cn + marker.park_address_cn"
              clickable
              @click="showMapLoca(marker, index)"
              v-for="(marker, index) in  dataDetails.data.station_list.list"
              :key="index"
            >
              <template #right-icon>
                <van-radio :name="marker.id" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="addMap" :title="$t('coll.apply_pick_up')">
      <van-notice-bar color="#333333" background="#E8EFFA" wrapable>
        {{ $t("coll.new_pick_up") }}
      </van-notice-bar>
      <div class="bg">
        <p class="font14">
          <span class="color3">{{ $t("coll.format_example") }} </span>
        </p>
        <van-field
          v-model="newSite"
          rows="3"
          autosize
          class="bgGray"
          type="textarea"
          :placeholder="$t('coll.please_enter')"
        />
        <van-button
          type="info"
          round
          color="#4d88d2"
          block
          class="saveSub mt-20"
          @click="savaSite()"
          >{{ $t("global.ok") }}</van-button
        >
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import ProtocolSign from "@/components/steps/ProtocolSign";
import { Toast } from "vant";
import Vue from "vue";
Vue.use(Toast);
export default {
  components: {
    "protocol-sign": ProtocolSign,
  },
  props: {
    dataDetails: {
      type: Object,
    },
    status: {
      type: Number,
    },
    disabled: {
      type: Number,
    },
  },
  name: "homeAddress",
  
  data() {
    const self = this;
    return {
      busData: "",
      stepStatus: "",
      need_bus: "",
      mySite: "",
      journey: "",
      // center: { lng: 0, lat: 0 },
      // zoom: 12,
      baidumapShow: false,
      addMap: false,
      newSite: "",
      mapchecked: "",
      BMap: {},
      customize: "",
      parking: "",
      markers: [],
      signQiniuToken: "",
      base64: "",
      newSign: false,
      btnDisabled: false,
      center: [116.397428, 39.90923],
      zoom: 15,
      label: {
        content: "",
      },
      pointIndex:null,
      windows: [],
      window: "",
      events: {
        click(e) {
          const { lng, lat } = e.lnglat;
          self.lng = lng;
          self.lat = lat;
          self.center = [self.lng, self.lat];
        },
      },
      lng: 0,
      lat: 0,
      plugin: [
        {
          // 定位
          pName: "Geolocation",
          events: {
            init(o) {
              // o是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  // 设置经度
                  self.lng = result.position.lng;
                  // 设置维度
                  self.lat = result.position.lat;
                  // 设置坐标
                  self.center = [self.lng, self.lat];
                  // load
                  self.loaded = true;
                  // 页面渲染好后
                  self.$nextTick();
                }
              });
            },
          },
        },
      ],
      location:'',
      sign_url:'',
      sign:''
    };
  },
  created() {
    this.stepStatus = this.status;
    this.busData = this.dataDetails.data;
    this.busData.need_bus = this.busData.need_bus + "";
    if (this.dataDetails.sign_url != "") {
      this.base64 = this.dataDetails.sign_url;
      this.sign_url = this.dataDetails.sign_url;
      this.sign = this.dataDetails.sign;
    }
    this.signQiniuToken = this.dataDetails.signQiniuToken;
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
    pointIndex(){
      var scroll = this.$refs.scroll;
      scroll.scrollTop = this.pointIndex * 44;
      this.mapchecked=this.dataDetails.data.station_list.list[this.pointIndex].id
      this.showMapLoca(this.dataDetails.data.station_list.list[this.pointIndex])
    }
  },
  methods: {
    initSearch(){
       let that=this
       var geocoder = new AMap.Geocoder({
          radius: 1000,
          extensions: "all",
          city: "北京"
        });
        geocoder.getLocation(that.location, (status, result) => {
          if (status === "complete" && result.geocodes.length) {
            let lnglat = result.geocodes[0].location
            that.lng = lnglat.lng;
            that.lat = lnglat.lat;
            that.center = [that.lng, that.lat];
          }
        });
      },
    point(){
      const windows = []
      const that = this;
      this.dataDetails.data.station_list.list.forEach((item, index) => {
        that.markers.push({
          position: [item.longitude,item.latitude],
          address: item.name_cn + item.park_address_cn,
          id:item.id,
          events: {
            click() {
              that.pointIndex=index
              that.windows.forEach(window => {
                window.visible = false // 关闭窗体
              })
              that.window = that.windows[index]
              that.$nextTick(() => {
                that.window.visible = true
              })
            },
          },
        });
        windows.push({
          id:item.id,
          position: [item.longitude,item.latitude],
          isCustom: true,
          offset: [0, -35], // 窗体偏移
          showShadow: false,
          visible: false, // 初始是否显示
          address: item.name_cn,
          point: item.park_address_cn
        })
      });
      this.windows = windows
    },
    checkDetail() {
      this.mapchecked=this.window.id
      this.siteCheck()
    },
    toRide(id) {
      this.busData.journey = id;
    },
    mapShow() {
      this.baidumapShow = true;
      if (this.busData.customize == 1) {
        this.mapchecked = "";
      }
      this.point()
    },
    showMapLoca(map) {
      this.center=[map.longitude,map.latitude]
    },
    siteCheck() {
      let index = this.showCheck();
      if(index!=undefined){
        this.busData.parking =this.markers[index].address
        this.busData.customize = 0;
        this.baidumapShow = false;
      }
    
    },
    siteCheckCancel() {
      this.baidumapShow = false;
      if (this.busData.customize == 1) {
        this.mapchecked = "";
      }
    },
    showCheck() {
      for (var i = 0; i < this.markers.length; i++) {
        if (this.markers[i].id == this.mapchecked) {
          return i;
        }
      }
    },
    addSite() {
      this.addMap = true;
      if (this.busData.customize == 1) {
        this.newSite = this.busData.parking;
      } else {
        this.newSite = "";
      }
    },
    savaSite() {
      this.addMap = false;
      this.busData.customize = 1;
      this.busData.parking = this.newSite;
    },
    baseImg(data) {
      if (data != "cancel") {
        this.base64 = data;
        this.newSign = true;
      }
    },
    submitData() {
      const that = this;
      if (this.btnDisabled) {
        return;
      }
      if (this.busData.need_bus == 0 || this.busData.need_bus == '0' ) {
          Toast(that.$t("coll.choose_last_school_year_bus"));

          return;
      }
      if (this.busData.need_bus == "1") {
        if (this.busData.journey == 0 || this.busData.journey == "") {
          Toast(that.$t("coll.choose_bus_needs"));
          return;
        }
        if (this.busData.parking == "") {
          Toast(that.$t("coll.choose_bus_change_stop"));
          return;
        }
        if (that.dataDetails.protocol.length != 0 && this.base64 == "") {
          Toast(that.$t("coll.please_sign"));
          return;
        }
      }
      this.$nextTick(function () {
        this.btnDisabled = true
      })
      if (that.dataDetails.protocol.length == 0) {
        that.saveData();
        return;
      }
      if (!that.newSign) {
        that.saveData();
        return;
      }
      var pic = this.base64.substring(this.base64.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // toast.clear()
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that, "sign_url", resJson.data.url);
            Vue.set(that, "sign", resJson.data.key);
            that.saveData();
          } else {
            Toast(resJson.msg);
            that.btnDisabled = false;
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.signQiniuToken);
      xhr.send(pic);
    },
    saveData() {
      var data = {
        customize: this.busData.customize,
        journey: this.busData.journey,
        need_bus: parseInt(this.busData.need_bus),
        parking: this.busData.parking,
        sign: this.sign,
        sign_url: this.sign_url,
      };
      this.$emit("change", data);
    },
  },
};
</script>

<style lang="less" scoped>
 #mapExample {
    width: 100%;
    height:300px;
  }
  #info-window{
    background: #fff;
    padding:5px 10px;
    .amap-info-sharp:after {
        position: absolute;
        content: "";
        margin-left: -8px;
        margin-top: -7px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid rgba(0,0,0,0.3);
        filter: blur(2px);
        z-index: -1;
    }
  }
.p10 {
  padding: 10px 16px;
}
.bg {
  background: #fff;
  padding: 16px;
}
.site {
  padding: 8px 16px;
  border-radius: 100px;
  border: 1px solid #dcdee0;
  display: inline-block;
}
.colorBlue {
  color: #4d88d2;
}
.flex {
  display: flex;
  .flexWidth {
    width: 16px;
    padding-top: 3px;
    .icon {
      display: inline-block;
      width: 6px;
      height: 14px;
      background: #4d88d2;
      border-radius: 3px;
    }
  }
  .flexLat {
    width: 16px;
    line-height:19px
  }
  .flex1 {
    flex: 1;
    .text {
      font-size: 12px;
      font-weight: 400;
      color: #646566;
      line-height: 20px;
    }
    .title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 26px;
    }
  }
}
</style>