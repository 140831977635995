<template>
  <div>
    <van-action-sheet v-model="show" :title="$t('uniform.select_specifications')" style="height: 80%" @close="showAction">
      <div class="content" v-if="itemList.img">
        <div class="flex">
          <img :src="imgUrl" alt="" class="img72" />
          <div class="flex1 ml-10">
            <div class="color3 mt-5 font16 fontWeight">{{ itemList.title }}</div>
            <div class="flex1 font18 mt-15"><span class="colorRed fontWeight">¥ {{ currentPrice }}</span><span class="van-card__origin-price" v-if="scribing_price!=''">¥ {{ scribing_price }}</span></div>
          </div>
        </div>
        <div class="categoryTitle">{{ $t("uniform.Color") }}</div>
        <van-row gutter="20">
          <span v-for="(list, key, index) in colorItem" :key="index">
            <span class="tagList" :class="key == colorNum ? 'currentTag' : ''" @click="colorClick(key)"
              v-if="!list.disabled">{{ list.title }}</span>
            <span class="tagList disabledTag" v-else>{{ list.title }}</span>
          </span>
        </van-row>
        <div class="categoryTitle">{{ $t("uniform.Size") }}</div>
        <van-row gutter="20">
          <span v-for="(list, key, index) in sizeItem" :key="index"><span class="tagList"
              :class="key == sizeNum ? 'currentTag' : ''" @click="sizeClick(key)" v-if="!list.disabled">{{ list.title }}
              <span class="NoStock" v-if="currentStock == 0 && key == sizeNum">{{$t('uniform.Out_stock')}}</span> </span>
            <span class="tagList disabledTag" v-else>{{ list.title }}</span>
          </span>
        </van-row>
        <div class="van-sku-stepper-stock categoryTitle">
          <div class="van-sku__stepper-title font14">
            {{ $t("uniform.Quantity") }} <span class="font12 color9 ml-10" v-if="sizeNum != '' && colorNum != ''">{{ $t("uniform.Stock_Quantity")
            }}{{ currentStock }}</span>
          </div>
          <div class="van-stepper van-sku__stepper" v-if="currentStock != 0"><button type="button"
              class="van-stepper__minus" @click="reduceNum"
              :class="quantityNum == 1 ? 'van-stepper__minus--disabled' : ''"></button><input type="tel"
              v-model="quantityNum" class="van-stepper__input" /><button type="button" class="van-stepper__plus"
              @click="addNum" :class="quantityNum >= currentStock ? 'van-stepper__minus--disabled' : ''"></button></div>
          <div class="van-stepper van-sku__stepper" v-if="currentStock == 0"><button type="button"
              class="van-stepper__minus van-stepper__minus--disabled"></button><input type="tel" v-model="quantityNum"
              class="van-stepper__input" /><button type="button"
              class="van-stepper__plus van-stepper__minus--disabled"></button></div>
        </div>
        <van-goods-action v-if="currentStock == 0" class="p10">
          <van-goods-action-button color="#4D88D2" type="warning" :text="$t('uniform.Out_stock')" :loading="registerLoading" disabled />
        </van-goods-action>
        <van-goods-action v-else class="p10">
          <van-goods-action-button color="#4D88D2" type="warning" @click="addShop" :text="$t('uniform.Add_To_Cart')" />
          <van-goods-action-button color="#F5A429" type="danger" :text="$t('uniform.buy_Now')" @click="buyNow" :loading="showLoading"
            :disabled="showLoading" />
        </van-goods-action>
      </div>
    </van-action-sheet>
    <div class="short-box" ref="shortList" @click="router">
      <div class="short-btn customer-service shopping" @click="showMask = !showMask" ref="customerService"
        @touchstart="!showMask && touchstartHandle('customerService', $event)"
        @touchmove="!showMask && touchmoveHandle('customerService', $event)"
        @touchend="!showMask && end('customerService')">
        <van-icon name="shopping-cart" color="#fff" size="24" />
        <span class="num" v-if="shopNum != 0">{{ shopNum }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Col, Row, Image as VanImage, ActionSheet, Toast } from "vant";
import storage from "store";
import { UNIFORM_SHOPPING, CURRENT_CHILD } from "@/store/mutation-types";
import { recordWant, creatOrder, JSAPIPa, cancelPay } from "@/api/uniform";
import i18n from "../i18n";
import wx from "weixin-js-sdk";
Vue.use(Col);
Vue.use(Row);
Vue.use(VanImage);
Vue.use(ActionSheet);
Vue.use(Toast);

export default {
  name: "Children",
  props: {
    actionShow: Boolean,
    shoppingData: Object,
  },
  data() {
    return {
      show: false,
      quantityNum: 1,
      itemList: {},
      colorNum: "",
      sizeNum: "",
      currentStock: null,
      infoList: {},
      colorItem: {},
      sizeItem: {},
      imgUrl: "",
      shopNum: 0,
      child_id: "",
      showLoading: false,
      touch: {
        initialPosition: {
          x: 0,
          y: 500,
        },
        movePostion: {},
      },
      element: {
        initialPosition: {},
        movePostion: {},
      },
      nowLeft: -1,
      classFlag: false,
      showMask: false,
      currentPrice: 0,
      scribing_price:'',
      childData: {},
      registerLoading: false,
    };
  },
  watch: {
    actionShow(val) {
      if (val) {
        this.initAction();
      }
    },
  },
  created() {
    this.show = this.actionShow;
    this.childData = storage.get(CURRENT_CHILD + i18n.locale);
    this.child_id = this.childData.id;
    let shop = storage.get(UNIFORM_SHOPPING + '_' + this.childData.family_id) || { shopping: {}, order: [] };
    this.infoList = shop.shopping;
    this.getShopNum();
  },
  methods: {
    getShopNum() {
      var result = []
      for (let item in this.infoList) {
        for (let list in this.infoList[item]) {
          result.push(this.infoList[item][list])
        }
      }
      this.shopNum = result.reduce((a, b) => {
        return a + b;
      }, 0);
    },
    initAction() {
      this.colorNum = "";
      this.currentStock = null;
      this.sizeNum = "";
      if (this.shoppingData.attr) {
        this.itemList = this.shoppingData;
        this.currentPrice = this.shoppingData.unit_price;
        this.scribing_price= this.shoppingData.scribing_price;
        this.colorItem = {};
        for (let key in this.itemList.attr.color.item) {
          this.colorItem[key] = {
            disabled: false,
            title: this.itemList.attr.color.item[key],
          };
        }
        this.sizeItem = {};
        for (let key in this.itemList.attr.size.item) {
          this.sizeItem[key] = {
            disabled: false,
            title: this.itemList.attr.size.item[key],
          };
        }
      }

      this.imgUrl = this.itemList.img.all ? this.itemList.img.all[0] : "https://m2.files.ivykids.cn/cloud01-file-8025768Frlg5G8ESlkiwyVrQ50DbxZebVHT.png";
      this.quantityNum = 1;
      this.show = this.actionShow;
      let shop = storage.get(UNIFORM_SHOPPING + '_' + this.childData.family_id) || { shopping: {}, order: [] };
      this.infoList = shop.shopping;
    },
    colorClick(item) {
      if (item == this.colorNum) {
        this.colorNum = "";
        this.quantityNum = 1;
        this.currentPrice = this.shoppingData.unit_price;
        this.scribing_price= this.shoppingData.scribing_price;
        this.currentStock = null;
        for (let key in this.itemList.attr.size.item) {
          this.sizeItem[key].disabled = false;
        }
        return;
      }
      this.colorNum = item;
      for (let key in this.itemList.attr.size.item) {
        if (this.itemList.sku[item][key]) {
          this.sizeItem[key].disabled = false;
        } else {
          this.sizeItem[key].disabled = true;
        }
      }
      let colorImg = this.itemList.img[this.itemList.attr.color.item_cn[this.colorNum]];
      this.imgUrl = colorImg ? colorImg : this.itemList.img.all ? this.itemList.img.all[0] : "https://m2.files.ivykids.cn/cloud01-file-8025768Frlg5G8ESlkiwyVrQ50DbxZebVHT.png";
      if (this.sizeNum != "" && this.colorNum != "") {
        this.getStock();
      }
    },
    sizeClick(item) {
      if (item == this.sizeNum) {
        this.sizeNum = "";
        this.quantityNum = 1;
        this.currentPrice = this.shoppingData.unit_price;
        this.scribing_price= this.shoppingData.scribing_price;
        this.currentStock = null;
        for (let key in this.itemList.sku) {
          this.colorItem[key].disabled = false;
        }
        return;
      }
      this.sizeNum = item;
      for (let key in this.itemList.sku) {
        if (this.itemList.sku[key][item]) {
          this.colorItem[key].disabled = false;
        } else {
          this.colorItem[key].disabled = true;
        }
      }
      if (this.sizeNum != "" && this.colorNum != "") {
        this.getStock();
      }
    },
    getStock() {
      this.quantityNum = 1;
      this.currentPrice = this.itemList.sku[this.colorNum][this.sizeNum].price;
      this.scribing_price= this.itemList.sku[this.colorNum][this.sizeNum].scribing_price;
      this.currentStock = this.itemList.sku[this.colorNum][this.sizeNum].stock;
    },
    reduceNum() {
      if (this.currentStock != 0 && this.quantityNum <= 1) {
        // Toast(this.$t("最少购买一件"));
        return;
      } else {
        this.quantityNum = this.quantityNum - 1;
      }
    },
    addNum() {
      if (this.currentStock != 0 && this.quantityNum >= this.currentStock) {
        // Toast(this.$t("uniform.out_of_stock"));
        return;
      }
      this.quantityNum = this.quantityNum + 1;
    },
    addShop() {
      if (this.colorNum == "") {
        Toast(this.$t("uniform.select_specifications"));
        return;
      }
      if (this.sizeNum == "") {
        Toast(this.$t("uniform.select_specifications"));
        return;
      }
      let paid = this.itemList.sku[this.colorNum][this.sizeNum].paid;
      if (this.infoList[this.child_id]) {
        if (this.infoList[this.child_id][paid]) {
          this.infoList[this.child_id][paid] = this.infoList[this.child_id][paid] + this.quantityNum;
        } else {
          this.infoList[this.child_id][paid] = this.quantityNum;
        }
      } else {
        this.infoList[this.child_id] = {};
        this.infoList[this.child_id][paid] = this.quantityNum;
      }

      let shop = {
        shopping: this.infoList,
        order: {},
      };
      storage.set(UNIFORM_SHOPPING + '_' + this.childData.family_id, shop);
      this.quantityNum = 1;
      this.getShopNum();
      Toast.success(this.$t("uniform.added_Cart"));
      this.show = false;
    },
    showAction() {
      this.show = false;
      this.$emit("click", false);
    },
    register() {
      if (this.colorNum == "") {
        Toast(this.$t("uniform.select_specifications"));
        return;
      }
      if (this.sizeNum == "") {
        Toast(this.$t("uniform.select_specifications"));
        return;
      }
      this.registerLoading = true;
      recordWant({ aid: this.itemList.sku[this.colorNum][this.sizeNum].paid, child_id: this.child_id, num: this.quantityNum })
        .then((res) => {
          if (res.code == 0) {
            Toast.success(res.msg);
          }
          this.registerLoading = false;
        })
        .catch(() => {
          this.registerLoading = false;
        });
    },
    buyNow() {
      if (this.colorNum == "") {
        Toast(this.$t("uniform.select_specifications"));
        return;
      }
      if (this.sizeNum == "") {
        Toast(this.$t("uniform.select_specifications"));
        return;
      }
      let id = this.itemList.sku[this.colorNum][this.sizeNum].paid;
      let product = {};
      product[this.child_id] = [];
      product[this.child_id].push({
        paid: id,
        num: this.quantityNum,
        pid: this.itemList.pid,
      });
      this.showLoading = true;
      console.log(product)
      creatOrder({ product })
        .then((order) => {
          if (order.code == 0) {
            JSAPIPa({ order_id: order.data.order_id, invoice_id: order.data.invoice_id, child_id: this.child_id })
              .then((res) => {
                let that = this;
                wx.config({
                  // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                  appId: res.data.jsapiParams.appId,
                  jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表
                });
                wx.ready(function () {
                  wx.chooseWXPay({
                    appId: res.data.jsapiParams.appId, //公众号ID，由商户传入
                    timestamp: res.data.jsapiParams.timeStamp, //时间戳，自1970年以来的秒数
                    nonceStr: res.data.jsapiParams.nonceStr, //随机串
                    package: res.data.jsapiParams.package,
                    signType: res.data.jsapiParams.signType, //微信签名方式：
                    paySign: res.data.jsapiParams.paySign, //微信签名
                    success() {
                      that.showLoading = false;
                      Toast.success(that.$t("uniform.Payment_successful"));
                      that.$router.push({
                        name: 'UnifromOrder',
                      });
                      // 支付成功回调
                    },
                    cancel() {
                      Toast.fail( that.$t("uniform.Cancel_Payment"));
                      that.showLoading = false;
                      that.getCancelPay(order.data.invoice_id);
                    },
                    fail() {
                      that.showLoading = false;
                      Toast.fail(that.$t("uniform.Payment_Failed"));
                      that.getCancelPay(order.data.invoice_id);
                      // 支付失败回调
                    },
                  });
                });
              })
              .catch(() => {
                this.showLoading = false;
              });
          } else {
            this.showLoading = false;
            this.currentStock = order.data[0].num;
          }
        })
        .catch(() => {
          this.showLoading = false;
        });
    },
    getCancelPay(invoice_id) {
      cancelPay({ invoice_ids: invoice_id }).then((invoice) => {
        console.log(invoice);
      });
    },
    router() {
      this.$router.push({
        name: "UniformShop",
      });
    },
    touchstartHandle(refName, e) {
      // 触摸开始
      let touchTarget = e.targetTouches[0];
      // 记录触摸点的坐标（初始位置）
      this.touch.initialPosition = {
        x: touchTarget.clientX,
        y: touchTarget.clientY,
      };
      // 记录需要移动的元素坐标（初始位置）
      this.element.initialPosition = {
        x: this.$refs[refName].offsetLeft,
        y: this.$refs[refName].offsetTop,
      };
    },
    touchmoveHandle(refName, e) {
      e.preventDefault();
      let touchTarget = e.targetTouches[0];
      let X = this.element.initialPosition.x + (touchTarget.clientX - this.touch.initialPosition.x);
      let Y = this.element.initialPosition.y + (touchTarget.clientY - this.touch.initialPosition.y);
      let maxWidth = innerWidth - this.$refs[refName].offsetWidth;
      let maxHeight = innerHeight - this.$refs[refName].offsetHeight;
      X = X <= 0 ? 0 : X >= maxWidth ? maxWidth : X;
      Y = Y <= 0 ? 0 : Y >= maxHeight ? maxHeight : Y;
      this.nowLeft = X;
      this.classFlag = Y < 300;
      // 移动元素
      this.$refs[refName].style.left = X + "px";
      this.$refs[refName].style.top = Y + "px";
    },
    end(refName) {
      if (this.nowLeft < 0) {
        return;
      }
      // 吸边处理
      let halfMaxWidth = (innerWidth - this.$refs[refName].offsetWidth) / 2;
      const dragDiv = this.$refs[refName];
      if (this.nowLeft > halfMaxWidth) {
        // 右吸边
        dragDiv.style.left = document.documentElement.clientWidth - parseInt(dragDiv.clientWidth) - 16 + "px";
      } else {
        // 左吸边
        dragDiv.style.left = "16px";
      }
      dragDiv.style.top = dragDiv.offsetTop + "px";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.content {
  padding: 16px;
  margin-bottom: 50px;

  .img72 {
    width: 72px;
    height: 72px;
    border-radius: 8px;
    object-fit: contain;
  }

  .categoryTitle {
    color: #323233;
    font-size: 14px;
    font-weight: 500;
    margin: 20px 0 12px 0;
  }

  .tagList {
    color: #323233;
    font-size: 13px;
    padding: 7px 16px;
    background: #f7f8fa;
    border-radius: 4px;
    margin-bottom: 8px;
    margin-right: 12px;
    display: inline-block;
    position: relative;

    .NoStock {
      right:-10px;
      bottom: 25px;
      background: #ee0a24;
      display: inline-block;
      line-height: 4vw;
      position: absolute;
      border-radius: 50px;
      color: #fff;
      font-size: 3.2vw;
      display: inline-block;
      padding: 2px 4px;
      z-index:99;
    }
  }
}

.van-sku-stepper-stock {
  padding: 0;
}

.currentTag {
  background: rgba(77, 136, 210, 0.1) !important;
  color: #4d88d2 !important;
  font-weight: 500;
}

.disabledTag {
  // background:#f2f3f5 !important;
  color: #999 !important;
}

.shopping {
  width: 50px;
  height: 50px;
  // background: #4d88d2;
  background: rgba(0,0,0,0.8);
  border-radius: 50%;
  position: fixed;
  right: 16px;
  bottom: 110px;
  line-height: 55px;
  text-align: center;
  z-index: 99;

  .num {
    width: 16px;
    height: 16px;
    background: #ee0a24;
    display: inline-block;
    line-height: 16px;
    position: absolute;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    top: 0px;
    right: 0px;
    border: 0.8px solid #fff;
  }
}

.colorRed {
  color: #ee0a24;
}

.p10 {
  padding: 10px;
}

.van-stepper__input {
  pointer-events: none;
}
</style>
