import Vue from 'vue';

export function semester (params) {
  return Vue.axios({
    url: '/api/ptc/semester',
    method: 'get',
    params: params
  })
}

export function childInfo (params) {
  return Vue.axios({
    url: '/api/ptc/childInfo',
    method: 'get',
    params: params
  })
}

export function reserve (params) {
  return Vue.axios({
    url: '/api/ptc/showReserve',
    method: 'get',
    params: params
  })
}

export function agree (data) {
  return Vue.axios({
    url: '/api/ptc/agree',
    method: 'post',
    data: data
  })
}

export function booked (data) {
  return Vue.axios({
    url: '/api/ptc/reserve',
    method: 'post',
    data: data
  })
}

export function translation (params) {
  return Vue.axios({
    url: '/api/ptc/getTranslation',
    method: 'get',
    params: params
  })
}

export function saveTranslation (data) {
  return Vue.axios({
    url: '/api/ptc/translation',
    method: 'post',
    data: data
  })
}

export function setOnlineChild (data) {
  return Vue.axios({
    url: '/api/ptc/setOnlineChild',
    method: 'post',
    data: data
  })
}

export function getChildPlan (data) {
  return Vue.axios({
    url: '/api/ptc/getChildPlan',
    method: 'post',
    data: data
  })
}