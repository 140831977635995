<template>
  <div class="uniform">
    <div class="itemList">
      <van-swipe :height="300" ref="mySwipe" :initial-swipe="index" @change="onChange">
        <van-swipe-item v-for="(image, index) in images" :key="index" @click="onImgPreView">
          <img :src="image" style="max-width: 100%" />
        </van-swipe-item>
      </van-swipe>
      <div class="content">
        <div class="flex1 font18 mt-15"><span class="colorRed fontWeight">¥ {{ currentPrice }}</span><span class="van-card__origin-price" v-if="scribing_price!=''">¥ {{ scribing_price }}</span></div>
        <div class="color3 mt-5 font16 mt-12 fontWeight">{{ dataDetail.title }}</div>
        <div class="color6 font13 mt-5">{{ dataDetail.intro }}</div>
        <van-divider />
        <div class="categoryTitle">{{ $t("uniform.Color") }}</div>
        <van-row gutter="20">
          <span v-for="(list, key, index) in colorItem" :key="index">
            <span class="tagList" :class="key == colorNum ? 'currentTag' : ''" @click="colorClick(key)"
              v-if="!list.disabled">{{ list.title }}</span>
            <span class="tagList disabledTag" v-else>{{ list.title }}</span>
          </span>
        </van-row>
        <div class="categoryTitle">{{ $t("uniform.Size") }}</div>
        <van-row gutter="20">
          <span v-for="(list, key, index) in sizeItem" :key="index">
            <span class="tagList" :class="key == sizeNum ? 'currentTag' : ''" @click="sizeClick(key)"
              v-if="!list.disabled">{{ list.title }} <span class="NoStock"
                v-if="currentStock == 0 && key == sizeNum">{{$t('uniform.Out_stock')}}</span></span>
            <span class="tagList disabledTag" v-else>{{ list.title }}</span>
          </span>
        </van-row>
        <div class="categoryTitle">
          <div class="van-sku__stepper-title">
            {{$t('uniform.Quantity')}} <span class="font12 color9 ml-10" v-if="sizeNum != '' && colorNum != ''">{{ $t("uniform.Stock_Quantity")
            }}{{ currentStock }}</span>
          </div>
          <div class="van-stepper van-sku__stepper" v-if="currentStock != 0"><button type="button"
              class="van-stepper__minus" @click="reduceNum"
              :class="quantityNum == 1 ? 'van-stepper__minus--disabled' : ''"></button><input type="tel"
              v-model="quantityNum" class="van-stepper__input" /><button type="button" class="van-stepper__plus"
              @click="addNum" :class="quantityNum >= currentStock ? 'van-stepper__minus--disabled' : ''"></button></div>
          <div class="van-stepper van-sku__stepper" v-if="currentStock == 0"><button type="button"
              class="van-stepper__minus van-stepper__minus--disabled"></button><input type="tel" v-model="quantityNum"
              class="van-stepper__input" /><button type="button"
              class="van-stepper__plus van-stepper__minus--disabled"></button></div>
        </div>
        <van-goods-action v-if="currentStock == 0" class="p10">
          <van-goods-action-button color="#4D88D2" type="warning" :text="$t('uniform.Out_stock')" :loading="registerLoading" disabled />
        </van-goods-action>
        <van-goods-action v-else class="p10">
          <van-goods-action-button color="#4D88D2" type="warning" @click="addShop" :text="$t('uniform.Add_To_Cart')" />
          <van-goods-action-button color="#F5A429" type="danger" :text="$t('uniform.buy_Now')" :loading="showLoading"
            :disabled="showLoading" @click="buyNow" />
        </van-goods-action>
      </div>
    </div>
    <shopping :actionShow="actionShow" :shoppingData="shoppingData" @click="hideAction" :key="datekey" />
  </div>
</template>

<script>
import storage from "store";
import Vue from "vue";
import { CURRENT_CHILD, UNIFORM_SHOPPING } from "@/store/mutation-types";
import i18n from "../i18n";
import { productDetail, recordWant, creatOrder, JSAPIPa, cancelPay } from "@/api/uniform";
import { NoticeBar, ActionSheet, Empty, Toast, Icon, GoodsAction, GoodsActionIcon, GoodsActionButton, Sku, Divider, Swipe, SwipeItem, ImagePreview, Card } from "vant";
import Shopping from "@/components/shopping";
import wx from "weixin-js-sdk";

Vue.use(ActionSheet);
Vue.use(NoticeBar);
Vue.use(Empty);
Vue.use(Icon);
Vue.use(Toast);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Divider);
Vue.use(Sku);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(SwipeItem);
Vue.use(Card);
export default {
  name: "lunch",
  components: {
    shopping: Shopping,
  },
  data() {
    return {
      show: false,
      quantityNum: 1,
      images: [],
      dataDetail: {},
      pid: "",
      actionShow: false,
      shoppingData: {},
      colorItem: {},
      sizeItem: {},
      colorNum: "",
      sizeNum: "",
      currentStock: null,
      infoList: {},
      child_id: "",
      showLoading: false,
      datekey: Date.now(),
      index: 0,
      currentPrice: 0,
      scribing_price:'',
      childData: {},
      registerLoading: false,
    };
  },
  beforeCreate() {
    // document.querySelector("body").setAttribute("style", "background-color:#fff;");
  },
  mounted() { },
  created() {
    this.childData = storage.get(CURRENT_CHILD + i18n.locale);
    this.child_id = this.childData.id;
    let shop = storage.get(UNIFORM_SHOPPING + '_' + this.childData.family_id) || { shopping: {}, order: [] };
    this.infoList = shop.shopping;
    this.pid = this.$route.params.id;
    this.getList();
  },
  methods: {
    getList() {
      productDetail({ pid: this.$route.params.id, child_id: this.child_id }).then((res) => {
        this.dataDetail = res.data;
        this.images = res.data.img;
        this.currentPrice = res.data.unit_price;
        this.scribing_price = res.data.scribing_price;
        
        this.colorItem = {};
        for (let key in this.dataDetail.attr.attr.color.item) {
          this.colorItem[key] = {
            disabled: false,
            title: this.dataDetail.attr.attr.color.item[key],
          };
        }
        this.sizeItem = {};
        for (let key in this.dataDetail.attr.attr.size.item) {
          this.sizeItem[key] = {
            disabled: false,
            title: this.dataDetail.attr.attr.size.item[key],
          };
        }
      });
    },
    onImgPreView() {
      // 点击轮播图时,通过数据拿到当前索引,根据当前索引创建图片预览,设置默认图片
      const that = this;
      ImagePreview({
        images: this.images, //点击后的图片。
        startPosition: this.index, //index默认为0，提供的起始位置
        onChange(i) {
          // 当图片预览切换时,根据切换后的索引,设置轮播图的位置
          that.$refs.mySwipe.swipeTo(i);
        },
      });
    },
    onChange(index) {
      this.index = index;
    },
    hideAction() { },
    colorClick(item) {
      if (item == this.colorNum) {
        this.colorNum = "";
        this.quantityNum = 1;
        this.currentPrice = this.dataDetail.unit_price;
        this.scribing_price = this.dataDetail.scribing_price;
        this.currentStock = null;
        for (let key in this.dataDetail.attr.attr.size.item) {
          this.sizeItem[key].disabled = false;
        }
        return;
      }
      this.colorNum = item;
      for (let key in this.dataDetail.attr.attr.size.item) {
        if (this.dataDetail.attr.sku[item][key]) {
          this.sizeItem[key].disabled = false;
        } else {
          this.sizeItem[key].disabled = true;
        }
      }
      if (this.sizeNum != "" && this.colorNum != "") {
        this.getStock();
      }
    },
    sizeClick(item) {
      if (item == this.sizeNum) {
        this.sizeNum = "";
        this.quantityNum = 1;
        this.currentPrice = this.dataDetail.unit_price;
        this.scribing_price = this.dataDetail.scribing_price;
        this.currentStock = null;
        for (let key in this.dataDetail.attr.sku) {
          this.colorItem[key].disabled = false;
        }
        return;
      }
      this.sizeNum = item;
      for (let key in this.dataDetail.attr.sku) {
        if (this.dataDetail.attr.sku[key][item]) {
          this.colorItem[key].disabled = false;
        } else {
          this.colorItem[key].disabled = true;
        }
      }
      if (this.sizeNum != "" && this.colorNum != "") {
        this.getStock();
      }
    },
    getStock() {
      this.quantityNum = 1;
      this.currentPrice = this.dataDetail.attr.sku[this.colorNum][this.sizeNum].price;
      this.scribing_price =this.dataDetail.attr.sku[this.colorNum][this.sizeNum].scribing_price;
      this.currentStock = this.dataDetail.attr.sku[this.colorNum][this.sizeNum].stock;
    },
    reduceNum() {
      if (this.currentStock != 0 && this.quantityNum <= 1) {
        // Toast(this.$t("最少购买一件"));
        return;
      } else {
        this.quantityNum = this.quantityNum - 1;
      }
    },
    addNum() {
      if (this.currentStock != 0 && this.quantityNum >= this.currentStock) {
        // Toast(this.$t("uniform.out_of_stock"));
        return;
      }
      this.quantityNum = this.quantityNum + 1;
    },
    addShop() {
      if (this.colorNum == "") {
        Toast(this.$t("uniform.select_specifications"));
        return;
      }
      if (this.sizeNum == "") {
        Toast(this.$t("uniform.select_specifications"));
        return;
      }
      let paid = this.dataDetail.attr.sku[this.colorNum][this.sizeNum].paid;
      if (this.infoList[this.child_id]) {
        if (this.infoList[this.child_id][paid]) {
          this.infoList[this.child_id][paid] = this.infoList[this.child_id][paid] + this.quantityNum;
        } else {
          this.infoList[this.child_id][paid] = this.quantityNum;
        }
      } else {
        this.infoList[this.child_id] = {};
        this.infoList[this.child_id][paid] = this.quantityNum;
      }
      let shop = {
        shopping: this.infoList,
        order: {},
      };
      storage.set(UNIFORM_SHOPPING + '_' + this.childData.family_id, shop);
      this.quantityNum = 1;
      this.datekey = Date.now();
      Toast.success(this.$t("uniform.added_Cart"));
      this.show = false;
    },
    register() {
      if (this.colorNum == "") {
        Toast(this.$t("uniform.select_specifications"));
        return;
      }
      if (this.sizeNum == "") {
        Toast(this.$t("uniform.select_specifications"));
        return;
      }
      this.registerLoading = true;
      recordWant({ aid: this.dataDetail.attr.sku[this.colorNum][this.sizeNum].paid, child_id: this.child_id, num: this.quantityNum })
        .then((res) => {
          if (res.code == 0) {
            Toast.success(res.msg);
          }
          this.registerLoading = false;
        })
        .catch(() => {
          this.registerLoading = false;
        });
    },
    buyNow() {
      if (this.colorNum == "") {
        Toast(this.$t("uniform.select_specifications"));
        return;
      }
      if (this.sizeNum == "") {
        Toast(this.$t("uniform.select_specifications"));
        return;
      }
      let id = this.dataDetail.attr.sku[this.colorNum][this.sizeNum].paid;
      let product = {};
      product[this.child_id] = [];
      product[this.child_id].push({
        num: this.quantityNum,
        pid: this.pid,
        paid: id,
      });
      this.showLoading = true;
      creatOrder({ product })
        .then((order) => {
          if (order.code == 0) {
            JSAPIPa({ order_id: order.data.order_id, invoice_id: order.data.invoice_id, child_id: this.child_id })
              .then((res) => {
                let that = this;
                wx.config({
                  // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                  appId: res.data.jsapiParams.appId,
                  jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表
                });
                wx.ready(function () {
                  wx.chooseWXPay({
                    appId: res.data.jsapiParams.appId, //公众号ID，由商户传入
                    timestamp: res.data.jsapiParams.timeStamp, //时间戳，自1970年以来的秒数
                    nonceStr: res.data.jsapiParams.nonceStr, //随机串
                    package: res.data.jsapiParams.package,
                    signType: res.data.jsapiParams.signType, //微信签名方式：
                    paySign: res.data.jsapiParams.paySign, //微信签名
                    success() {
                      that.showLoading = false;
                      Toast.success(that.$t("uniform.Payment_successful"));
                      that.$router.push({
                        name: "UnifromOrder",
                      });
                      // 支付成功回调
                    },
                    cancel() {
                      Toast.fail(that.$t("uniform.Cancel_Payment"));
                      that.showLoading = false;
                      that.getCancelPay(order.data.invoice_id);
                    },
                    fail() {
                      that.showLoading = false;
                      Toast.fail(that.$t("uniform.Payment_Failed"));
                      that.getCancelPay(order.data.invoice_id);
                      // 支付失败回调
                    },
                  });
                });
              })
              .catch(() => {
                this.showLoading = false;
              });
          } else {
            this.showLoading = false;
            this.currentStock = order.data[0].num;
          }
        })
        .catch(() => {
          this.showLoading = false;
        });
    },
    getCancelPay(invoice_id) {
      cancelPay({ invoice_ids: invoice_id }).then((invoice) => {
        console.log(invoice);
      });
    },
  },
};
</script>

<style scoped lang="less">
.blue {
  color: #4d88d2;
}

.classify {
  padding: 16px;
  background: #fff;

  .flex1 {
    width: 0;
  }
}

.img88 {
  width: 88px;
  height: 88px;
  border-radius: 8px;
  object-fit: cover;
}

.img72 {
  width: 72px;
  height: 72px;
  border-radius: 8px;
  object-fit: cover;
}

.uniformList {
  background: #fff;
  padding: 12px;
  border-radius: 8px;
  margin: 12px 16px;
}

.align-items {
  align-items: center;
}

.colorRed {
  color: #ee0a24;
}

.fontWeight {
  font-weight: 500;
}

.word-break {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shopping {
  width: 40px;
  height: 40px;
  background: #4d88d2;
  border-radius: 50%;
  position: fixed;
  right: 16px;
  bottom: 100px;
  line-height: 44px;
  text-align: center;

  .num {
    width: 16px;
    height: 16px;
    background: #ee0a24;
    display: inline-block;
    line-height: 15px;
    position: absolute;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    top: -3px;
  }
}

.word-break2 {
  overflow: hidden; //超出文本隐藏
  text-overflow: ellipsis; ///超出部分省略号显示
  display: -webkit-box; //弹性盒模型
  -webkit-box-orient: vertical; //上下垂直
  -webkit-line-clamp: 2; //自定义行数
}

.content {
  padding: 16px;
  padding-bottom: 140px;

  background: #ffffff;

  .categoryTitle {
    color: #323233;
    font-size: 14px;
    font-weight: 500;
    margin: 20px 0 12px 0;
  }

  .tagList {
    color: #323233;
    font-size: 13px;
    padding: 7px 16px;
    background: #f7f8fa;
    border-radius: 4px;
    margin-bottom: 8px;
    margin-right: 10px;
    display: inline-block;
    position: relative;

    .NoStock {
      right:-10px;
      bottom: 25px;
      background: #ee0a24;
      display: inline-block;
      line-height: 4vw;
      position: absolute;
      border-radius: 50px;
      color: #fff;
      font-size: 3.2vw;
      display: inline-block;
      padding: 2px 4px;
      z-index:99;
    }
  }
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}

.van-swipe-item img {
  width: 100% !important;
}

.currentTag {
  background: rgba(77, 136, 210, 0.1) !important;
  color: #4d88d2 !important;
  font-weight: 500;
}

.disabledTag {
  // background:#f2f3f5 !important;
  color: #999 !important;
}

.p10 {
  padding: 10px;
}

.van-stepper__input {
  pointer-events: none;
}

.uniform {
  overflow-y: scroll;
  height: 100vh;
}

.uniform::-webkit-scrollbar {
  display: none;
}
</style>
