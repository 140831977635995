<template>
  <div  :class="stepStatus == 4 && dataDetails.isCommon==1?'clickNone':''">
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{$t('coll.address_info')}}</div>
        <!-- <p class="color6">{{$t('homeAddress.myAddress')}}</p> -->
      </div>
    </div>
     <div class="">
      <div class="color6 mt-20 mb-10 font14">{{$t('coll.resident_address')}} </div>
      <van-field
        v-model="fieldValue"
        is-link
        clearable
        readonly
        class="bgGray"
        active-color="#4d88d2"
        :placeholder="$t('coll.select_district')"
        @click="cascaderShow = true"
      />
      <van-popup v-model="cascaderShow" round position="bottom">
        <van-cascader
          v-model="address1.street"
          :title="$t('coll.select_district')"
          :options="options"
          active-color="#4d88d2"
          @close="cascaderShow = false"
          @finish="onFinish"
        />
      </van-popup>
      <van-field
        v-model="address1.detail"
        :placeholder="this.$t('coll.please_detailed_address')"
        class="bgGray mt-10"
      />
      <div class="text-right color6 font12 mt-10" v-if='(address1.area && address1.area!="") || (address1.detail && address1.detail!="")'>
        <span @click='clearAddress(1)' class="flex"> 
          <van-icon name="clear" color="#cccccc" class='mr-5 flexWidth font14' />
          <span class="flex1">{{$t('coll.revoke_information')}}</span>  
        </span> 
      </div>
      <div class="clearfix"></div>

      <div class="color6 mt-20 mb-10  font14">{{$t('coll.other_address')}} </div>
      <van-field
        v-model="fieldValue2"
        is-link
        readonly
        class="bgGray"
        active-color="#4d88d2"
        :placeholder="$t('coll.select_district')"
        @click="cascader2Show = true"
      />
      <van-popup v-model="cascader2Show" round position="bottom">
        <van-cascader
          v-model="address2.street"
          :title="$t('coll.select_district')"
          :options="options"
          active-color="#4d88d2"
          @close="cascader2Show = false"
          @finish="onFinish2"
        />
      </van-popup>
      <van-field
        v-model="address2.detail"
        :placeholder="this.$t('coll.please_detailed_address')"
        class="bgGray mt-10"
      />
      <div class="text-right color6 font12 mt-10" v-if='(address2.area && address2.area!="") || (address2.detail && address2.detail!="")'>
        <span @click='clearAddress(2)' class="flex"> 
          <van-icon name="clear" color="#cccccc" class='mr-5 flexWidth font14' />
          <span class="flex1">{{$t('coll.revoke_information')}}</span>  
        </span> 
      </div>
      <div class="clearfix"></div>
    </div>
   
    <div v-if="dataDetails.protocol.length != 0">
      <protocol-sign
        @click="baseImg"
        :busProtocol="dataDetails.protocol"
        :base64="base64"
      ></protocol-sign>
    </div>
    <div class="mt-20">
      <van-button
        v-if="stepStatus == 4 && dataDetails.isCommon==1"
        disabled
        type="info"
        block
        round
        color="#4d88d2"
        class="saveSub"
        >{{ $t("coll.step_reviewed") }}</van-button
      >
      <a
        href="javascript:;"
        v-else
        @click="submitData()"
        class="saveBtnStep"
        :class="btnDisabled ? 'disabledSubmit' : ''"
        >{{ $t("global.submit") }}</a
      >
    </div>
  </div>
</template>

<script>
import ProtocolSign from "@/components/steps/ProtocolSign";
import Vue from "vue";
import { Toast } from "vant";
export default {
   components: {
    "protocol-sign": ProtocolSign,
  },
  props: {
    dataDetails: {
      type: Object,
    },
    status: {
      type: Number,
    },
    disabled: {
      type: Number,
    },
  },
  name: "homeAddress",
  data() {
    return {
      addressData: "",
      stepStatus: "",
      btnDisabled:false,
      base64: "",
      newSign: false,
      fieldValue: "",
      cascaderValue: "",
      fieldValue2: "",
      cascaderValue2: "",
      cascaderShow: false,
      cascader2Show: false,
      options: {},
      address1Detail: "",
      address2Detail: "",
      area1: "",
      area2: "",
      address1: {},
      address2: {},
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
  },
  created() {
    this.stepStatus = this.status;
    this.addressData = this.dataDetails;
    this.options = this.dataDetails.areaList.areaData;
    if (this.dataDetails.address1.street) {
      this.address1 = this.dataDetails.address1;
      this.fieldValue = this.filterAdress("address1");
    }
    if (this.dataDetails.address2.street) {
      this.address2 = this.dataDetails.address2;
      this.fieldValue2 = this.filterAdress("address2");
    }
    if (this.dataDetails.sign_url != "") {
      this.base64 = this.dataDetails.sign_url;
    }
  },
  methods: {
    filterAdress(type) {
      var text = "";
      this.options.forEach((item) => {
        if (item.value == this.dataDetails[type].area) {
          item.children.forEach((street) => {
            if (street.value == this.dataDetails[type].street) {
              text = item.text + "/" + street.text;
            }
          });
        }
      });
      return text;
    },
    onFinish({ selectedOptions }) {
      console.log(selectedOptions)
      this.cascaderShow = false;
      Vue.set(this.address1, "area", selectedOptions[0].value);
      this.fieldValue = selectedOptions.map((option) => option.text).join("/");
    },
    onFinish2({ selectedOptions }) {
      this.cascader2Show = false;
      Vue.set(this.address2, "area", selectedOptions[0].value);
      this.fieldValue2 = selectedOptions.map((option) => option.text).join("/");
    },
    clearAddress(type){
      if(type==1){
        this.address1.street=''
        this.address1.area=''
        this.fieldValue=''
        this.address1.detail=''
      }else{
         this.address2.street=''
        this.address2.area=''
        this.fieldValue2=''
        this.address2.detail=''
      }
    },
     baseImg(data) {
      if (data != "cancel") {
        this.base64 = data;
        this.newSign = true;
      }
    },
    submitData() {
      let that = this;
      if (this.btnDisabled) {
        return;
      }
       
      if(Object.keys(this.address1).length==0 && Object.keys(this.address2).length==0){
        Toast(this.$t('coll.fields_blank'));
        return;
      }
      if (this.address1.street || this.address1.detail) {
        if (!this.address1.street || this.address1.street == "") {
          Toast(this.$t('coll.select_district'));
          return;
        }
        if (!this.address1.detail || this.address1.detail == "") {
          Toast(this.$t('coll.please_detailed_address'));
          return;
        }
        this.address1.city = this.addressData.areaList.cityCode;
      }
      if (this.address2.street || this.address2.detail) {
        if (!this.address2.street || this.address2.street == "") {
          Toast(this.$t('coll.select_district'));
          return;
        }
        if (!this.address2.detail || this.address2.detail == "") {
          Toast(this.$t('coll.please_detailed_address'));
          return;
        }
        this.address1.city = this.addressData.areaList.cityCode;
      }
      if (that.dataDetails.protocol.length != 0 && this.base64 == "") {
        Toast(that.$t("coll.please_sign"));
        return;
      }
      this.$nextTick(function () {
        this.btnDisabled = true
      })
      if (that.dataDetails.protocol.length == 0) {
        that.saveData();
        return;
      }
      if (!that.newSign) {
        that.saveData();
        return;
      }
      var pic = this.base64.substring(this.base64.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // toast.clear()
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that.addressData, "sign_url", resJson.data.url);
            Vue.set(that.addressData, "sign", resJson.data.key);
            that.saveData();
          } else {
            Toast(resJson.msg);
            that.btnDisabled = false;
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.dataDetails.signQiniuToken);
      xhr.send(pic);
    },
    saveData() {
      this.addressData.address1= this.address1;
      this.addressData.address2= this.address2;
      this.$emit("change", this.addressData);
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  .flexWidth {
    width: 16px;
    padding-top: 3px;
    .icon {
      display: inline-block;
      width: 6px;
      height: 14px;
      background: #4d88d2;
      border-radius: 3px;
    }
  }
  .flex1 {
    flex: 1;
    .text {
      font-size: 12px;
      font-weight: 400;
      color: #646566;
      line-height: 20px;
    }
    .title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 26px;
    }
  }
}
.text-right{
  text-align: right;
  float: right;
  .flexWidth{
    padding-top: 2px;
  }
}
</style>