<template>
  <div  :class="stepStatus == 4 && dataDetails.isCommon==1?'clickNone':''">
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{$t('coll.livePeople')}}</div>
      </div>
    </div>
    <van-field
        v-model="step_data.live_people"
        :placeholder="$t('global.please_input')"
        class="bgGray mt-10"
      />
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{$t('coll.48hours')}}</div>
        <div class="color6 font14 pt-5">{{$t('coll.multiple_pictures')}}</div>
      </div>
    </div>
    <van-row class="mt-20">
      <van-uploader
        v-model="step_data.files1_url"
        @delete="deleteimg"
        :after-read="afterRead"
        :before-read="beforeRead"
        name="files1"
      />
    </van-row>
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{$t('coll.itineraryCode')}}</div>
        <div class="color6 font14 pt-5">{{$t('coll.multiple_pictures')}}</div>
      </div>
    </div>
    <van-row class="mt-20">
      <van-uploader
        v-model="step_data.files2_url"
        @delete="deleteimg"
        :after-read="afterRead"
        :before-read="beforeRead"
        name="files2"
      />
    </van-row>
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{$t('coll.monitoringForm')}}</div>
        <div class="color6 font14 pt-5">{{$t('coll.multiple_pictures')}}</div>
      </div>
    </div>
    <van-row class="mt-20">
      <van-uploader
        v-model="step_data.files3_url"
        @delete="deleteimg"
        :after-read="afterRead"
        :before-read="beforeRead"
        name="files3"
      />
    </van-row>
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{$t('coll.otherFile')}}</div>
        <div class="color6 font14 pt-5">{{$t('coll.optional')}}</div>
      </div>
    </div>
    <van-row class="mt-20">
      <van-uploader
        v-model="step_data.files4_url"
        @delete="deleteimg"
        :after-read="afterRead"
        :before-read="beforeRead"
        name="files4"
      />
    </van-row>
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{$t('coll.Comment')}}</div>
      </div>
    </div>
    <van-field
        rows="3"
        autosize
        class="bgGray mt-10"
        v-model="step_data.comment"
        type="textarea"
        :placeholder="$t('global.please_input')"
      />
    <div v-if="dataDetails.protocol.length != 0">
      <protocol-sign
        @click="baseImg"
        :busProtocol="dataDetails.protocol"
        :base64="base64"
      ></protocol-sign>
    </div>
    <div class="mt-20">
      <van-button
        v-if="stepStatus == 4 && dataDetails.isCommon==1"
        disabled
        type="info"
        block
        round
        color="#4d88d2"
        class="saveSub"
        >{{ $t("coll.step_reviewed") }}</van-button
      >
      <a
        href="javascript:;"
        v-else
        @click="submitData()"
        class="saveBtnStep"
        :class="btnDisabled ? 'disabledSubmit' : ''"
        >{{ $t("global.submit") }}</a
      >
    </div>
  </div>
</template>

<script>
import ProtocolSign from "@/components/steps/ProtocolSign";
import Vue from "vue";
import { Toast } from "vant";
import Compressor from 'compressorjs';

export default {
   components: {
    "protocol-sign": ProtocolSign,
  },
  props: {
    dataDetails: {
      type: Object,
    },
    status: {
      type: Number,
    },
    disabled: {
      type: Number,
    },
  },
  name: "homeAddress",
  data() {
    return {
      insurance: [],
      stuPhoto: [],
      monPhoto: [],
      vaccination: [],
      stepStatus: "",
      qiniuToken: "",
      type: "",
      base64:'',
      btnDisabled:false,
      sign: "",
      sign_url: "",
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
  },
  created() {
    this.stepStatus = this.status;
    this.step_data = this.dataDetails.data;
    this.step_data.files1_url=this.tojson(this.dataDetails.data.files1_url)
    this.step_data.files2_url=this.tojson(this.dataDetails.data.files2_url)
    this.step_data.files3_url=this.tojson(this.dataDetails.data.files3_url)
    this.step_data.files4_url=this.tojson(this.dataDetails.data.files4_url)
    this.qiniuToken = this.dataDetails.qiniuToken;
    if (this.dataDetails.sign_url != "") {
      this.base64 = this.dataDetails.sign_url;
    }
  },
  methods: {
    tojson(rows){
        var ids=[]
          for(var i=0,len=rows.length; i<len; i++){
              var signAgainReq=new Object();
              signAgainReq.url=rows[i];
              ids.push(signAgainReq);
          }
          return ids
    },
    beforeRead(file) {
      return new Promise((resolve) => {
        new Compressor(file, {
          maxWidth:1200,
          quality:0.6,
          success: resolve,
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
    afterRead(file, detail) {
      this.type = detail.name;
      if (file.length > 1) {
        for (let i in file) {
          this.upimg(file[i].content);
        }
      } else {
        this.upimg(file.content);
      }
    },
    upimg(data) {
      const that = this;
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: that.$t("pickupcard.uploading"),
      });
      var pic = data.substring(data.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            that.step_data[that.type].push(resJson.data.key);
            Toast(that.$t("pickupcard.Uploaded"));
          } else {
            Toast(resJson.msg);
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.qiniuToken);
      xhr.send(pic);
    },
    //删除图片
    deleteimg(file, detail) {
      this.step_data[detail.name].splice(detail.index - 1, 1);
    },
    baseImg(data) {
      if (data != "cancel") {
        this.base64 = data;
        this.newSign = true;
      }
    },
    submitData() {
      let that = this;
      if (this.btnDisabled) {
        return;
      }
      if (that.step_data.live_people==null || that.step_data.live_people=='') {
        Toast(that.$t("coll.please_household"));
        return;
      }
      if (that.step_data.files1.length==0) {
        Toast(that.$t("coll.please_48hours"));
        return;
      }
      if (that.step_data.files2.length==0) {
        Toast(that.$t("coll.please_kit"));
        return;
      }
      if (that.step_data.files3.length==0) {
        Toast(that.$t("coll.please_form"));
        return;
      }
      if (that.dataDetails.protocol.length != 0 && this.base64 == "") {
        Toast(that.$t("coll.please_sign"));
        return;
      }
      this.$nextTick(function () {
        this.btnDisabled = true
      })
      if (that.dataDetails.protocol.length == 0) {
        that.saveData();
        return;
      }
      if (!that.newSign) {
        that.saveData();
        return;
      }
      var pic = this.base64.substring(this.base64.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // toast.clear()
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that, "sign_url", resJson.data.url);
            Vue.set(that, "sign", resJson.data.key);
            that.saveData();
          } else {
            Toast(resJson.msg);
            that.btnDisabled = false;
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.dataDetails.signQiniuToken);
      xhr.send(pic);
    },
    saveData() {
      this.btnDisabled = true
      let data = {
        files4: this.step_data.files4,
        files2: this.step_data.files2,
        files1: this.step_data.files1,
        files3: this.step_data.files3,
        live_people: this.step_data.live_people,
        comment:this.step_data.comment,
        sign: this.sign,
        sign_url: this.sign_url,
      };
      this.$emit("change", data);
    },
  },
};
</script>

<style lang="less" scoped>

</style>