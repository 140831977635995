<template>
  <div class="uniform">
    <children @change="childChange" :children="childrenList" v-if="childrenList.length > 0" />
    <van-notice-bar color="#323233" background="#E8EFFA" wrapable :scrollable="false" class="font12 line18"> {{
      $t("uniform.Purchasing_Notice") }} </van-notice-bar>
    <div class="itemList mb-10">
      <div v-if="uniformList.featuredList && Object.keys(uniformList.featuredList).length > 0">
        <div class="title">{{ $t("uniform.Hot_Sale") }}</div>
        <div>
          <div class="flex uniformList" v-for="(item, index) in uniformList.featuredList" :key="index">
            <img v-if="item.img.all" :src="item.img.all[0]" alt="" class="img72" @click="routerDetail(item)" />
            <img v-else src="https://m2.files.ivykids.cn/cloud01-file-8025768Frlg5G8ESlkiwyVrQ50DbxZebVHT.png" alt=""
              class="img72" @click="routerDetail(item)" />
            <div class="flex1 ml-10 width0" @click="routerDetail(item)">
              <div class="color3 font14 fontWeight word-break2 mt-2">{{ item.title }}</div>
              <div class="font12 color6 mt-5 word-break height16">{{ item.intro }}</div>
              <div class="flex align-items mt-5">
                <div class="flex1 font14"><span class=" colorRed fontWeight">¥ {{ item.unit_price }} </span> <span
                    class="van-card__origin-price" v-if="item.scribing_price != ''">¥ {{ item.scribing_price }}</span>
                </div>
                <van-button round type="info" size="mini" @click.stop="buyUniform(item)" class="btnWidth">{{
                  $t("uniform.Purchase") }}</van-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="" v-if="uniformList.cats">
        <div class="title">{{ $t("uniform.Departments") }}</div>
        <div class="listUniform">
          <div class="item-1" v-for="(item, index) in uniformList.cats" :key="index">
            <router-link :to="`/uniformList/${item.cid}`">
              <div class="listCard">
                <img :src="item.img" alt="" class="imgCard" />
                <div class="cardText">
                  <div class="color3 font14 fontWeight">{{ item.title }}</div>
                  <div class="font12 color6 mt-5 " v-if="item.desc != ''">{{ item.desc }}</div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <shopping :actionShow="actionShow" :shoppingData="shoppingData" @click="hideAction" :key="datekey" />
    <div class="myOrder" @click="router">
      <van-icon name="column" color="#4D88D2" size="20" class="icon" /> <span>{{ $t("uniform.Myorders") }}</span>
    </div>
  </div>
</template>

<script>
import Children from "@/components/Children";
import Shopping from "@/components/shopping";
import storage from "store";
import { CURRENT_CHILD } from "@/store/mutation-types";
import { childList } from "@/api/child";
import { uniformHome } from "@/api/uniform";
import Vue from "vue";

import { NoticeBar, ActionSheet, Empty, Toast, Icon, Card } from "vant";

Vue.use(ActionSheet);
Vue.use(NoticeBar);
Vue.use(Empty);
Vue.use(Icon);
Vue.use(Toast);
Vue.use(Card);
export default {
  name: "lunch",
  components: {
    children: Children,
    shopping: Shopping,
  },
  data() {
    return {
      childrenList: [],
      childrenStatus: {},
      childid: "",
      uniformList: {},
      actionShow: false,
      shoppingData: {},
      datekey: Date.now(),
    };
  },
  beforeCreate() {
    // document.querySelector("body").setAttribute("style", "background-color:#fff;");
  },
  mounted() { },
  created() {
    childList().then((res) => {
      this.childrenList = res.data;
      let cIds = [];
      this.childrenList.map((item) => {
        this.childrenStatus[item.id] = true;
        cIds.push(item.id);
      });
      let cid = storage.get(CURRENT_CHILD) ? storage.get(CURRENT_CHILD).id : "";
      this.childid = cIds.indexOf(cid) > -1 ? cid : cIds[0];
    });
  },
  methods: {
    childChange(val) {
      this.childid = val.id;
      this.datekey = Date.now();
      this.getUniform();
    },
    getUniform() {
      uniformHome({
        child_id: this.childid,
      }).then((res) => {
        this.uniformList = res.data;
      });
    },
    buyUniform(item) {
      this.shoppingData = item;
      this.actionShow = true;
    },
    hideAction() {
      this.actionShow = false;
    },
    routerDetail(item) {
      this.$router.push({
        path: "/uniformDetail/" + item.pid,
      });
    },
    router() {
      this.$router.push({
        path: "UnifromOrder",
      });
    },
  },
};
</script>

<style scoped lang="less">
.uniform {
  overflow-y: scroll;
  height: 100vh;
}

.uniform::-webkit-scrollbar {
  display: none;
}

.btnWidth {
  padding: 4px 10px;
}

.blue {
  color: #4d88d2;
}

.title {
  padding: 16px 0;
  color: #323233;
  font-weight: 500;
  font-size: 16px;
}

.itemList {
  padding: 0 16px;
  margin-bottom: 50px;
}

.img72 {
  width: 72px;
  height: 72px;
  border-radius: 8px;
  object-fit: contain;
}

.uniformList {
  background: #fff;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.align-items {
  align-items: center;
}

.colorRed {
  color: #ee0a24;
}

.fontWeight {
  font-weight: 500;
}

.listUniform {
  display: flex;
  flex-wrap: wrap;

  .item-1 {
    width: calc(50% - 0.5rem);
    margin: 0.25rem;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 8px;

    .listCard {

      background: #fff;
      border-radius: 8px;
      margin-bottom: 12px;

      .imgCard {
        width: 100%;
        height: 102px;
        object-fit: cover;
        border-radius: 8px 8px 0px 0px;
      }

      .cardText {
        padding: 10px;
      }
    }
  }
}

.myOrder {
  width: 100%;
  height: 50px;
  background: #ffffff;
  text-align: center;
  line-height: 50px;
  color: #323233;
  font-size: 14px;
  position: fixed;
  bottom: 0;
  // display: flex;
  align-items: center;
  text-align: center;

  span {
    margin-left: 25px;
  }

  .icon {
    position: absolute;
    left: 50%;
    top: 15px;
    margin-left: -40px;
  }
}

.word-break {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.word-break2 {
  overflow: hidden; //超出文本隐藏
  text-overflow: ellipsis; ///超出部分省略号显示
  display: -webkit-box; //弹性盒模型
  -webkit-box-orient: vertical; //上下垂直
  -webkit-line-clamp: 2; //自定义行数
}

.width0 {
  width: 0;
}

.height16 {
  height: 16px;
}

.mt-2 {
  margin-top: 2px;
}

.line18 {
  line-height: 18px;
}
</style>
