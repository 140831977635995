import Vue from 'vue'
import Vuex from 'vuex'
import storage from 'store'
import { login, getInfo, logout } from '@/api/login'
import { APP_LANGUAGE, ACCESS_TOKEN } from '@/store/mutation-types'

import getters from './getters'

import { Locale } from 'vant';

import zhCN from 'vant/es/locale/lang/zh-CN';
import enUS from 'vant/es/locale/lang/en-US';

Vue.use(Vuex)

// var expirePlugin = require('store/plugins/expire')
// storage.addPlugin(expirePlugin)

export default new Vuex.Store({
  state: {
    lang: '',
    wxState: '',
    token: '',
    userInfo: '',
    landingPage:''
  },
  mutations: {
    [APP_LANGUAGE]: (state, lang) => {
      state.lang = lang
      storage.set(APP_LANGUAGE, lang)
      if (lang == 'zh') {
        Locale.use('zh-CN', zhCN);
      }
      else {
        Locale.use('en-US', enUS);
      }
    },
    SET_WXSTATE: (state, wxState) => {
      state.wxState = wxState
    },
    SET_TOKEN: (state, token) => {
      state.token = token
      // storage.set(ACCESS_TOKEN, token, new Date().getTime() + 5000*60)
      sessionStorage.setItem(ACCESS_TOKEN, token)
    },
    SET_INFO: (state, info) => {
      state.userInfo = info
    },
    SET_LANDINGPAGE: (state, landingPage) => {
      state.landingPage = landingPage
    }
  },
  actions: {
    setLang({ commit }, lang) {
      commit(APP_LANGUAGE, lang)
    },
    setWxState({ commit }, wxState) {
      commit('SET_WXSTATE', wxState)
    },
    setLandingPage({ commit }, landingPage) {
      commit('SET_LANDINGPAGE', landingPage)
    },
    getWxState({ state }) {
      return state.wxState
    },
    Login({ commit }, _userInfo) {
      return new Promise((resolve, reject) => {
        login(_userInfo).then(response => {
          commit('SET_TOKEN', '')
          commit('SET_INFO', {})
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetInfo({ commit }, params) {
      return new Promise((resolve, reject) => {
        getInfo(params).then(response => {
          if (response.code == 0 && response.data) {
            const result = response.data;
            commit('SET_TOKEN', result.token)
            if (result.userInfo) {
              commit('SET_INFO', result.userInfo)
            }
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    Logout({ state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          // commit('SET_TOKEN', '')
          // sessionStorage.removeItem(ACCESS_TOKEN)
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
        })
      })
    }
  },
  modules: {
  },
  getters
})
