import Vue from 'vue';

export function deptList (params) {
  return Vue.axios({
    url: '/api/parent/directMessage/deptList',
    method: 'get',
    params: params
  })
}

export function teacherList (params) {
  return Vue.axios({
    url: '/api/parent/directMessage/teacherList',
    method: 'get',
    params: params
  })
}

export function directMessage (id, type,  params) {
    return Vue.axios({
        url: '/api/parent/directMessage/commentList/' + type + '/' + id,
        method: 'get',
        params: params
    })
}

export function contact (id, type, data) {
    return Vue.axios({
        url: '/api/parent/directMessage/contact/' + type + '/' + id,
        method: 'post',
        data: data
    })
}

export function getQiniuToken (data) {
  return Vue.axios({
      url: '/api/parent/directMessage/getQiniuToken',
      method: 'post',
      data: data
  })
}

export function deleteFile (id) {
  return Vue.axios({
      url: '/api/parent/directMessage/deleteFile/'+id,
      method: 'post',
  })
}

export function updateFileTitle (data) {
  return Vue.axios({
      url: '/api/parent/directMessage/updateFileTitle',
      method: 'post',
      data: data

  })
}