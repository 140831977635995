<template>
    <div :class="stepStatus == 4 && dataDetails.isCommon == 1 ? 'clickNone' : ''">
        <div class="color6 font14">{{ $t("coll.child_PE") }}</div>
        <van-field v-model="step_data.medical_reasons" clearable :placeholder="$t('global.please_input')"
            class="bgGray mt-10" />
        <div class="color6 font14 mt-20">{{ $t("coll.from_PE") }}</div>
        <div class="flex">
            <div class="flex1">
                <div class="color9 font12 mt-10">{{ $t("coll.From") }}</div>
                <van-field v-model="step_data.application_dates[0]" is-link readonly class="bgGray mt-10"
                    :placeholder="$t('coll.choose')" @click="showDateEvent(0)" />
            </div>
            <div class="flex1 ml-10">
                <div class="color9 font12 mt-10">{{ $t("coll.To") }}</div>
                <van-field v-model="step_data.application_dates[1]" is-link readonly class="bgGray mt-10"
                    :placeholder="$t('coll.choose')" @click="showDateEvent(1)" />
            </div>
        </div>
        <div class="color6 font14 mt-20">{{ $t("coll.other_PE") }}</div>
        <van-field v-model="step_data.other" clearable :placeholder="$t('global.please_input')" class="bgGray mt-10" />
        <div class="color6 font14 mt-20">{{ $t("coll.upload_PE") }}</div>
        <van-uploader class="mt-10" :before-read="beforeRead" v-model="fileList" @delete="deleteimg"
            :after-read="afterRead" />

        <div v-if="dataDetails.protocol.length != 0">
            <protocol-sign @click="baseImg" :busProtocol="dataDetails.protocol" :base64="base64"></protocol-sign>
        </div>
        <div class="mt-20">
            <van-button v-if="stepStatus == 4 && dataDetails.isCommon == 1" disabled type="info" block round
                color="#4d88d2" class="saveSub">{{ $t("coll.step_reviewed") }}</van-button>
            <a href="javascript:;"  v-else-if="stepStatus ==1 && dataDetails.isCommon == 2" @click="submitData()" class="saveBtnStep"
                :class="btnDisabled ? 'disabledSubmit' : ''">{{$t("coll.Resubmit")}}</a>
            <a href="javascript:;" v-else @click="submitData()" class="saveBtnStep"
                :class="btnDisabled ? 'disabledSubmit' : ''">{{ $t("global.submit") }}</a>
        </div>
        <van-popup v-model="showDate" round position="bottom">
            <van-datetime-picker v-model="currentDate" type="date" @confirm="dateConfirm" :min-date="minDate" />
        </van-popup>
    </div>
</template>

<script>
import ProtocolSign from "@/components/steps/ProtocolSign";
import Vue from "vue";
import { Toast } from "vant";
import Compressor from 'compressorjs';

export default {
    props: {
        dataDetails: {
            type: Object,
        },
        status: {
            type: Number,
        },
        disabled: {
            type: Number,
        },
    },
    components: {
        "protocol-sign": ProtocolSign,
    },
    name: "homeAddress",
    data() {
        return {
            qiniuToken: "",
            step_data: "",
            stepStatus: "",
            showDate: false,
            currentDate: '',
            minDate: new Date(2013, 11, 1),
            btnDisabled: false,
            fileList: [],
            base64:'',
        };
    },
    watch: {
        disabled() {
            this.btnDisabled = false;
        },
    },
    created() {
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1;  // 注意月份从0开始，需要加1
        let day = currentDate.getDate();
        
        this.currentDate=year + "-" + month + "-" + day;
        this.qiniuToken = this.dataDetails.qiniuToken;
        this.stepStatus = this.status;
        this.step_data = this.dataDetails.step_data;
        if (this.step_data.medical_certificate.length != 0) {
            this.fileList = this.tojson(this.step_data.medical_certificate_url);
        }
        if (this.step_data.application_dates.length == 0) {
            this.step_data.application_dates = [this.currentDate, this.currentDate]
        }
        if (this.dataDetails.sign_url != "") {
            this.base64 = this.dataDetails.sign_url;
        }
    },
    methods: {
        showDateEvent(type) {
            this.listType = type
            this.showDate = true;
            let date = this.step_data.application_dates[type].split('-')
            this.currentDate = new Date(date[0], date[1] - 1, date[2])
            if(type==1){
                let minDate = this.step_data.application_dates[0].split('-')
                this.minDate = new Date(minDate[0], minDate[1] - 1, minDate[2])
            }else{
                this.minDate=new Date(2013, 11, 1)
            }
        },
        dateConfirm(value) {
            this.step_data.application_dates[this.listType] = this.timestampToTime(value)
            this.showDate = false
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
            return Y + M + D
        },
        tojson(rows) {
            var ids = []
            for (var i = 0, len = rows.length; i < len; i++) {
                var signAgainReq = new Object();
                signAgainReq.url = rows[i];
                ids.push(signAgainReq);
            }
            return ids
        },
        beforeRead(file) {
            return new Promise((resolve) => {
                new Compressor(file, {
                    maxWidth: 1200,
                    quality: 0.6,
                    success: resolve,
                    error(err) {
                        console.log(err.message);
                    },
                });
            });
        },
        afterRead(file, detail) {
            this.type = detail.name;
            if (file.length > 1) {
                for (let i in file) {
                    this.upimg(file[i].content);
                }
            } else {
                this.upimg(file.content);
            }
        },
        upimg(data) {
            const that = this;
            Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: that.$t("pickupcard.uploading"),
            });
            var pic = data.substring(data.indexOf(",") + 1);
            var url = "https://upload-z1.qiniup.com/putb64/-1";
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    var resJson = JSON.parse(xhr.responseText);
                    if (resJson.code == 0) {
                        that.step_data.medical_certificate.push(resJson.data.key);
                        Toast(that.$t("pickupcard.Uploaded"));
                    } else {
                        Toast(resJson.msg);
                    }
                }
            };
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/octet-stream");
            xhr.setRequestHeader("Authorization", "UpToken " + that.qiniuToken);
            xhr.send(pic);
        },
        //删除图片
        deleteimg(file, detail) {
            this.step_data.medical_certificate.splice(detail.index, 1);
        },
        baseImg(data) {
            if (data != "cancel") {
                this.base64 = data;
                this.newSign = true;
            }
        },
        checkDateDifference(dateStr1, dateStr2) {
            const date1 = new Date(dateStr1);
            const date2 = new Date(dateStr2);
            const diffMilliseconds = Math.abs(date2.getTime() - date1.getTime());
            const diffDays = diffMilliseconds / (1000 * 60 * 60 * 24);
            if (diffDays >= 3) {
                return true;
            } else {
                return false;
            }
        },

        submitData() {
            let that = this;
            if (this.btnDisabled) {
                return;
            }
            if (this.step_data.medical_reasons.trim() == '') {
                Toast(this.$t("global.please_input") + ' ' + this.$t("coll.child_PE"));
                return
            }
            if (this.step_data.application_dates[0] == '' || this.step_data.application_dates[1] == '') {
                Toast(this.$t("global.please_choose") + ' ' + this.$t("coll.from_PE"));
                return
            }
            if (this.checkDateDifference(this.step_data.application_dates[0], this.step_data.application_dates[1]) && this.step_data.medical_certificate.length == 0) {
                Toast(this.$t("coll.upload_PE"));
                return
            }
            this.$nextTick(function () {
                this.btnDisabled = true
            })
            if (that.dataDetails.protocol.length == 0) {
                that.saveData();
                return;
            }
            if (!that.newSign) {
                that.saveData();
                return;
            }
            var pic = this.base64.substring(this.base64.indexOf(",") + 1);
            var url = "https://upload-z1.qiniup.com/putb64/-1";
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                // toast.clear()
                if (xhr.readyState == 4) {
                    var resJson = JSON.parse(xhr.responseText);
                    if (resJson.code == 0) {
                        Vue.set(that.step_data, "sign_url", resJson.data.url);
                        Vue.set(that.step_data, "sign", resJson.data.key);
                        that.saveData();
                    } else {
                        Toast(resJson.msg);
                        that.btnDisabled = false;
                    }
                }
            };
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/octet-stream");
            xhr.setRequestHeader("Authorization", "UpToken " + that.dataDetails.signQiniuToken);
            xhr.send(pic);
        },
        saveData() {
            this.$emit("change", this.step_data);
        },
    },
};
</script>

<style lang="less" scoped>
.radioList {
    margin-top: 10px;
}

.blue {
    color: #4D88D2;
    margin-top: 16px;
}

.medicationList {
    border-radius: 4px;
    border: 1px solid #EBEDF0;
    padding: 12px 16px;
    margin-top: 12px;
    position: relative;

    .close {
        position: absolute;
        right: 0;
        top: 0;
        width: 18px;
        height: 16px;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 0 0 0 12px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        line-height: 16px;

    }
}

.max90 {
    max-height: 90px;
    overflow-y: auto;
}
</style>