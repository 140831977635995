<template>
  <div :class="stepStatus == 4 && dataDetails.isCommon==1? 'clickNone' : ''">
    <van-row class="bus">
      <div class="color3 font15">
        {{ $t("coll.school_lunch2") }}
        <div>
          <small>{{ $t("coll.school_lunch2_notice") }}</small>
        </div>

      </div>
      <van-radio-group v-model="eat_lunch" class="mt-20">
        <van-radio name="2">
          <span class="color6 font14">{{ $t("coll.no_lunch2") }}</span>
        </van-radio>
        <van-radio name="1" class="mt-15"
          ><span class="color6 font14">{{ $t("coll.yes_lunch2") }}</span>
        </van-radio>
      </van-radio-group>
    </van-row>
    <div v-if="eat_lunch == 1">
      <div class="flex mt-20">
        <div class="flexWidth"><span class="icon"></span></div>
        <div class="flex1">
          <div class="title">{{ $t("coll.remarks") }}</div>
        </div>
      </div>
      <van-radio-group v-model="foodData.special" class="mt-20">
        <van-cell-group>
          <van-cell
            :title="marker.title"
            clickable
            v-for="(marker, index) in specialList"
            :key="index"
            @click="foodData.special=marker.id"
          >
            <template #right-icon>
              <van-radio :name="marker.id" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div v-if="dataDetails.protocol.length != 0">
      <protocol-sign
        @click="baseImg"
        :busProtocol="dataDetails.protocol"
        :base64="base64"
      ></protocol-sign>
    </div>
    <div class="mt-20">
      <van-button
        v-if="stepStatus == 4 && dataDetails.isCommon==1"
        disabled
        type="info"
        block
        round
        color="#4d88d2"
        class="saveSub"
        >{{ $t("coll.step_reviewed") }}</van-button
      >
      <a
        href="javascript:;"
        v-else
        @click="submitData()"
        class="saveBtnStep"
        :class="btnDisabled ? 'disabledSubmit' : ''"
        >{{ $t("global.submit") }}</a
      >
    </div>
  </div>
</template>

<script>
import ProtocolSign from "@/components/steps/ProtocolSign";
import {Dialog, Toast} from "vant";
import Vue from "vue";

export default {
  components: {
    "protocol-sign": ProtocolSign,
  },
  props: {
    dataDetails: {
      type: Object,
    },
    status: {
      type: Number,
    },
    disabled: {
      type: Number,
    },
  },
  name: "homeAddress",
  data() {
    return {
      foodData: "",
      stepStatus: "",
      eat_lunch:"",
      show_no_lunch:false,
      specialList: [
        { title: this.$t("coll.normal_lunch2"), id: 1 },
        { title: this.$t("coll.pork_lunch2"), id: 2 },
        { title: this.$t("coll.vegan_lunch2"), id: 3 },
      ],
      btnDisabled: false,
      base64: "",
      newSign: false,
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
    eat_lunch(new_value,old_val){
      if(new_value == 2 && old_val!=new_value && old_val!=''){
        console.log(old_val)
        console.log(new_value)
        Dialog.alert({
          message: this.$t('coll.no_lunch2_notice'),
        }).then(() => {
          // on close
        });
      }
    }
  },
  created() {
    this.stepStatus = this.status;
    this.foodData = this.dataDetails;
    this.eat_lunch = this.dataDetails.eat_lunch + "";
    // this.foodData.special = this.dataDetails.special + "";
    if (this.dataDetails.sign_url != "") {
      this.base64 = this.dataDetails.sign_url;
    }
  },
  methods: {
    baseImg(data) {
      if (data != "cancel") {
        this.base64 = data;
        this.newSign = true;
      }
    },
    submitData() {
      let that = this;
      if (this.btnDisabled) {
        return;
      }
      if (that.eat_lunch == 0 || this.eat_lunch == "") {
        Toast(this.$t('coll.choose')+this.$t('coll.school_lunch2'));
        return;
      }
      if (that.eat_lunch == 1 && (this.foodData.special == 0 || this.foodData.special == '')) {
        Toast(this.$t('coll.choose')+this.$t('coll.remarks'));
        return;
      }
      if (that.dataDetails.protocol.length != 0 && this.base64 == "") {
        Toast(that.$t("coll.please_sign"));
        return;
      }
      this.$nextTick(function () {
        this.btnDisabled = true
      })
      if (that.dataDetails.protocol.length == 0) {
        that.saveData();
        return;
      }
      if (!that.newSign) {
        that.saveData();
        return;
      }
      var pic = this.base64.substring(this.base64.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // toast.clear()
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that.foodData, "sign_url", resJson.data.url);
            Vue.set(that.foodData, "sign", resJson.data.key);
            that.saveData();
          } else {
            Toast(resJson.msg);
            that.btnDisabled = false;
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader(
        "Authorization",
        "UpToken " + that.dataDetails.signQiniuToken
      );
      xhr.send(pic);
    },
    saveData() {
      this.foodData.eat_lunch = parseInt(this.eat_lunch);
      this.$emit("change", this.foodData);
    }
  },
};
</script>

<style lang="less" scoped>
</style>