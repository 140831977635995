<template>
  <van-empty
    class="custom-image"
    :image="image"
    :description="description"
    />
</template>

<script>
import { Empty } from 'vant';

export default {
  name: 'CustomEmpty',
  components: {
    'van-empty': Empty
  },
  props: {
    description: String,
    image: {
      type: String,
      default: 'http://m2.files.ivykids.cn/cloud01-file-5Fi4lBSM_gOAmKdxHXmJo7lo6IlGS.png'
    }
  }
}
</script>
