<template>

  <div :class="stepStatus == 4 && dataDetails.isCommon==1? 'clickNone' : ''">
    <!--  国籍-->
    <div class="color6 font14 mt-20">{{ $t("coll.nationality") }}</div>
    <van-field
        v-model="country"
        is-link
        readonly
        class="bgGray mt-10"
        :placeholder="$t('coll.choose')"
        @click="showPicker()"
    />

    <van-popup v-model="showNationality" round position="bottom">
      <van-picker
          :title="$t('coll.choose')"
          :columns="columns"
          @cancel="showNationality = false"
          @confirm="onConfirm"
          value-key="title"
          ref="picker1"
          id="picker1"
          show-toolbar
      />
    </van-popup>

    <!--  证件号码-->
    <div class="color6 font14 mt-20">{{ $t("coll.ID/Passport Number") }}</div>
    <van-field
        v-model="step_data.license_number"
        :placeholder="$t('global.please_input')"
        class="bgGray mt-10"
    />
    <!--    上传照片-->
    <div class="color6 font14 mt-20">{{ $t("coll.changedUpload") }}</div>
    <van-row class="mt-20">
      <van-uploader
          :before-read="beforeRead"
          v-model="step_data.files1_url"
          @delete="deleteimg"
          :after-read="afterRead"
          name="medical"
      />
    </van-row>
    <!--    协议-->
    <div v-if="dataDetails.protocol.length != 0">
      <protocol-sign
          @click="baseImg"
          :busProtocol="dataDetails.protocol"
          :base64="base64"
      ></protocol-sign>
    </div>
    <!--    提交按钮-->
    <div class="mt-20">
      <van-button
          v-if="stepStatus == 4 && dataDetails.isCommon==1"
          disabled
          type="info"
          block
          round
          color="#4d88d2"
          class="saveSub"
      >{{ $t("coll.step_reviewed") }}
      </van-button
      >
      <a
          href="javascript:;"
          v-else
          @click="submitData()"
          class="saveBtnStep"
          :class="btnDisabled ? 'disabledSubmit' : ''"
      >{{ $t("global.submit") }}</a
      >
    </div>
  </div>
</template>

<script>
import ProtocolSign from "@/components/steps/ProtocolSign";
import {Toast} from "vant";
import Vue from "vue";
import Compressor from 'compressorjs';
export default {
  components: {
    "protocol-sign": ProtocolSign,
  },
  props: {
    dataDetails: {
      type: Object,
    },
    status: {
      type: Number,
    },
    disabled: {
      type: Number,
    },
  },
  name: "homeAddress",
  data() {
    return {
      // Nationality:"",//国家id
      country: "",//国家名字
      showNationality: false,
      columns: [],
      btnDisabled: false,
      fileList: [],
      step_data: "",
      qiniuToken: '',
      type: "",
      base64: "",
      sign: "",
      signShow: false,
      sign_url: "",
      newSign: false,
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
  },
  created() {
    this.stepStatus = this.status;
    this.step_data = this.dataDetails.data;
    this.step_data.license_number = this.step_data.license_number ? this.step_data.license_number + "" : "";
    this.step_data.files1_url = this.tojson(this.step_data.files1_url)
    this.qiniuToken = this.dataDetails.qiniuToken;
    this.country = this.dataDetails.data.country_list[this.dataDetails.data.country]
    for (var key in this.dataDetails.data.country_list) {
      this.columns.push({
        id: key,
        title: this.dataDetails.data.country_list[key],
      });
    }
    if (this.dataDetails.sign_url != "") {
      this.base64 = this.dataDetails.sign_url;
    }
  },
  methods: {
    showPicker() {
      this.showNationality = true;
      let that = this;
      this.$nextTick(() => {
        that.index = this.lanIndex(that.step_data.country);
        that.$refs.picker1.setIndexes([that.index]);
      });
    },
    lanIndex(id) {
      for (var i = 0; i < this.columns.length; i++) {
        if (this.columns[i].id == id) {
          return i;
        }
      }
    },
    onConfirm(value) {
      this.step_data.country = value.id;
      this.country = value.title;
      this.showNationality = false;
    },
    tojson(rows) {
      var ids = []
      for (var i = 0, len = rows.length; i < len; i++) {
        var signAgainReq = new Object();
        signAgainReq.url = rows[i];
        ids.push(signAgainReq);
      }
      return ids
    },
    beforeRead(file) {
      return new Promise((resolve) => {
        new Compressor(file, {
          maxWidth:1200,
          quality:0.6,
          success: resolve,
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
    afterRead(file, detail) {
      this.type = detail.name;
      if (file.length > 1) {
        for (let i in file) {
          this.upimg(file[i].content);
        }
      } else {
        this.upimg(file.content);
      }
    },
    upimg(data) {
      const that = this;
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: that.$t("pickupcard.uploading"),
      });
      var pic = data.substring(data.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          console.log(xhr)
          console.log(resJson)
          if (resJson.code == 0) {
            Toast(that.$t("pickupcard.Uploaded"));
            that.step_data.files1.push(resJson.data.key);
          } else {
            Toast(resJson.msg);
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.qiniuToken);
      xhr.send(pic);
    },
    //删除图片
    deleteimg(file, detail) {
      console.log(file, detail)
      this.step_data.files1_url.splice(detail.index, 1);
      this.step_data.files1.splice(detail.index, 1);
    },

    baseImg(data) {
      if (data != "cancel") {
        this.base64 = data;
        this.newSign = true;
      }
      this.signShow = false;
    },
    submitData() {
      let that = this;
      if (this.btnDisabled) {
        return;
      }
      if (that.step_data.country == null || that.step_data.country == '') {
        Toast(this.$t('global.please_choose')+this.$t("coll.nationality"));
        return;
      }
      if (that.step_data.license_number == null || that.step_data.license_number == '') {
        Toast(this.$t('global.please_input')+this.$t("coll.ID/Passport Number"));
        return;
      }

      if (this.dataDetails.protocol.length != 0 && this.base64 == "") {
        Toast(that.$t("coll.please_sign"));
        return;
      }
      this.$nextTick(function () {
        this.btnDisabled = true
      })

      if (that.dataDetails.protocol.length == 0) {
        that.saveData();
        return;
      }
      if (!that.newSign) {
        that.saveData();
        return;
      }

      var pic = this.base64.substring(this.base64.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // toast.clear()
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that.step_data, "sign_url", resJson.data.url);
            Vue.set(that.step_data, "sign", resJson.data.key);
            that.saveData();
          } else {
            Toast(resJson.msg);
            that.btnDisabled = false;
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.dataDetails.signQiniuToken);
      xhr.send(pic);
    },
    saveData() {
      this.btnDisabled = true
      this.$emit("change", this.step_data);
    },
  },
};
</script>

<style lang="less" scoped>
</style>