<template>
  <div>
    <div v-if="attachments.length>0" class="attachment mt-20">
      <div class="attachment-title mb-15">{{ attachments.length }}个附件</div>
      <div class="attachment-content">
        <a class="content-item mb-10" v-for="(item, i) in attachments" :key="i" :href="item.file_key">
          <van-row type="flex" justify="space-between">
            <van-col :span="22">
              <div class="attachment-icon">
                <img :src="`http://mega.ivymik.cn/file-icon/${md5(item.mime_type)}.png`" />
                {{ item.title }}
              </div>
            </van-col>
            <van-col>
              <van-icon name="arrow" color="#4D88D2" style="vertical-align: middle;" />
            </van-col>
          </van-row>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Field } from 'vant';
import md5 from 'js-md5';

Vue.use(Field);

export default {
  name: 'Comment',
  props: {
    attachments: Array
  },
  data() {
    return {
      
    }
  },
  created () {
  },
  methods: {
    md5(str) {
      return md5(str);
    }
  }
}
</script>

<style lang="less" scoped>
.attachment {
  .attachment-title {
    font-size: 12px;
    font-weight: 400;
    color: #646566;
    line-height: 17px;
  }
  .content-item {
    display: block;
    background: #EFF6FF;
    border-radius: 6px;
    border: 1px solid #4D88D2;
    padding: 8px 12px 8px 16px;
    font-size: 14px;
    font-weight: 400;
    color: #323233;
    line-height: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .attachment-icon {
      line-height: 19px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      & img {
        vertical-align:middle;
      }
    }
  }
}
</style>
