<template>
  <div  :class="stepStatus == 4 && dataDetails.isCommon==1 ?'clickNone':''">
    <div v-if="dataDetails.protocol.length != 0">
      <protocol-sign
        @click="baseImg"
        :busProtocol="dataDetails.protocol"
        :base64="base64"
      ></protocol-sign>
    </div>
    <div class="mt-20">
      <van-button
        v-if="stepStatus == 4 && dataDetails.isCommon==1"
        disabled
        type="info"
        block
        round
        color="#4d88d2"
        class="saveSub"
        @click="submitData()"
        >{{$t('coll.step_reviewed')}}</van-button
      >
      <a href="javascript:;" v-else @click="submitData()" class="saveBtnStep" :class="btnDisabled ? 'disabledSubmit' : ''">{{
        $t("global.submit")
      }}</a>
    </div>
  </div>
</template>

<script>
import ProtocolSign from "@/components/steps/ProtocolSign";

import { Toast } from "vant";
import Vue from "vue";
Vue.use(Toast);
export default {
  components: {
   "protocol-sign": ProtocolSign,
  },
  props: {
    dataDetails: {
      type: Object,
    },
    status: {
      type: Number,
    },
    protocol: {
      type: Array,
    },
    disabled: {
      type: Number,
    },
  },
  name: "homeAddress",
  data() {
    return {
      stepStatus: "",
      signQiniuToken: "",
      base64: "",
      sign: "",
      sign_url: "",
      signShow: false,
      btnDisabled:false,
      newSign:false
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
  },
  created() {
    this.stepStatus = this.status;
    this.sign = this.dataDetails.sign;
    this.sign_url = this.dataDetails.sign_url;
    if (this.dataDetails.sign_url != "") {
      this.base64 = this.dataDetails.sign_url;
    }
    this.signQiniuToken = this.dataDetails.signQiniuToken;
  },
  methods: {
    baseImg(data) {
      if (data != "cancel") {
        this.base64 = data;
        this.newSign = true;
      }
    },

    submitData() {
      const that = this;
      if(that.btnDisabled){
        return
      }
      if (that.dataDetails.protocol.length != 0 && this.base64 == "") {
        Toast(that.$t("coll.please_sign"));
        return;
      }
      this.$nextTick(function () {
        this.btnDisabled = true
      })
      if (!that.newSign) {
        that.saveData();
        return;
      }
      var pic = that.base64.substring(that.base64.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that, "sign", resJson.data.key);
            Vue.set(that, "sign_url", resJson.data.url);
            that.saveData();
          } else {
            Toast(resJson.msg);
            Vue.set(that, "btnDisabled", false);
          }
        }else{
            Vue.set(that, "btnDisabled", false);
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.signQiniuToken);
      xhr.send(pic);
    },
    saveData() {
      var data = {
        sign: this.sign,
        sign_url: this.sign_url,
      };
      this.$emit("change", data);
    },
  },
};
</script>

<style lang="less" scoped>
</style>