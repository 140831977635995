<template>
  <div>
    <custom-empty :description="$t('ptc.no_active_ptc')" v-if="option1 && option1.length == 0" />
    <div v-else-if="option1">
      <van-dropdown-menu>
        <van-dropdown-item v-model="value1" :options="option1" />
      </van-dropdown-menu>
      <van-notice-bar left-icon="volume-o" wrapable :scrollable="false" :text="$t('ptc.conflict_tips')"
        v-if="data && data.status" />
      <div class="action-warp" v-if="data && data.list.length > 0">
        <div class="mb-15" style="text-align: right;">
          <van-button plain type="info" size="small" @click="openDialog">{{ $t('ptc.ptc_translation_service_survey')
          }}</van-button>
        </div>
        <div class="box-1" v-for="(item, key) in data.list" :key="key">
          <div class="ele">
            <div class="ele-1">
              <img :src="item.avatar" :alt="item.name" class="avatar" referrerpolicy="no-referrer">
              <div class="ele-2">
                <div class="ele-2-name">{{ item.name }}</div>
                <div class="ele-2-class">{{ item.class }}</div>
              </div>
            </div>
            <div class="ele-3" v-if="item.status == 10 && !item.is_online">
              <van-button round type="info" size="small" v-if="item.reserve_btn == 1"
                :to="{ name: 'PTCBooked', params: { id: item.id, year: value1 } }">{{ $t('ptc.schedule_now')
                }}</van-button>
              <div v-else class="font-15">{{ $t('ptc.not_opened') }}</div>
            </div>
          </div>
          <div class="cancelOnline" v-if='item.status == 10 && item.is_online'>
            <van-divider />
            <div class="onlineText">{{ $t("ptc.apply_online_ptc") }}</div>
            <van-divider />
            <van-row justify="end" type="flex" class="mb16">
              <van-col><van-button v-if="item.reserve_btn == 1" type="info" size="small" round
                  @click="onlinePTC(item.id, value1)">{{ $t("ptc.cancel_ptc") }}</van-button></van-col>
            </van-row>
          </div>
          <div v-if="item.dept == 2">
            <div v-if="item.status == 20 && item.schedule.length > 0">
              <van-divider />
              <div v-for="(_item, _key) in item.schedule" :key="_key" :class='_key!=0?"mt-20":""'>
                <div class="bg-1">
                  <div class="main2">
                    <div class="main2-1">{{ _item.month }}</div>
                    <div class="main2-2">{{ _item.day }}</div>
                  </div>
                  <div class="tips-1">{{ $t('ptc.ptc_meetings', { n: _item.times.length }) }}</div>
                  <div class="tips-2">{{ _item.date }} {{ _item.weekday }}</div>
                </div>
                <div class="bg-2" v-for="(__item, __key) in _item.times" :key="__key">
                  <div class="block-1">
                    <div class="block-1-1">
                      <van-icon name="clock" color="#4D88D2" />
                      <span class="time">{{ __item.time }}</span>
                      <van-tag color="#FFFBE8" text-color="#ED6A0C" class='onlineMeet' v-if='item.is_online'>{{
                        $t("ptc.Online_Meet") }}</van-tag>
                    </div>
                  </div>
                  <div class="block-2" :class="{ 'op-70': __item.parents_choose == -1 }">
                    <div class="info-1">
                      <div class="info-1-1">
                        <div class="avatar-2" :style="{ 'background-image': 'url(' + __item.avatar + ')' }"></div>
                        <div class="info-1-1-1">
                          <div class="text-1">{{ __item.teacher }}</div>
                          <div class="text-2">{{ __item.title }}</div>
                        </div>
                      </div>
                      <div class="info-1-2" v-if="__item.address">
                        <van-icon name="location" color="#C8C9CC" />
                        <span>{{ __item.address }}</span>
                      </div>
                      <div class="info-1-3" v-if="item.dept == 2">
                        <van-divider />
                        <van-row v-if="__item.parents_choose == 0 && !agreeLoading" type="flex" justify="space-between">
                          <van-col>
                            <van-button round type="default" size="small" @click="agree({
                              item: item,
                              _item: _item,
                              __item: __item,
                              choose_type: -1
                            })">{{ $t('ptc.cannot_attend') }}</van-button>
                          </van-col>
                          <van-col>
                            <van-button round type="info" size="small" @click="agree({
                              item: item,
                              _item: _item,
                              __item: __item,
                              choose_type: 1
                            })">{{ $t('ptc.confirm_to_attend') }}</van-button>
                          </van-col>
                        </van-row>
                        <div v-else-if="__item.parents_choose == 1" class="info-1-3-1">
                          <van-icon name="checked" color="#45A367" />
                          <span>{{ $t('ptc.Confirmed_to_attend') }}</span>
                        </div>
                        <div v-else-if="__item.parents_choose == -1" class="info-1-3-1">
                          <van-icon name="clear" color="#C8C9CC" />
                          <span style="color: #666;">{{ $t('ptc.cannot_attend') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.dept == 1 && !item.is_online">
                <van-divider />
                <div style="text-align: right;">
                  <van-button round type="info" size="small" v-if="item.reserve_btn == 1"
                    @click="reschedule(item.id, value1)">{{ $t('ptc.reschedule') }}</van-button>
                </div>
              </div>
            </div>
            <div v-if="item.status == 30 && item.schedule.length > 0">
              <van-divider />
              <div v-for="(_item, _key) in item.schedule" :key="_key" :class="_key>0?'mt-15':''">
                <van-row type="flex" justify="space-between">
                  <van-col class="bg-3-head-1">{{ $t('ptc.meeting_completed') }}</van-col>
                  <van-col class="bg-3-head-2">{{ _item.date }} {{ _item.weekday }}</van-col>
                </van-row>
                <div class="bg-3">
                  <div v-for="(__item, __key) in _item.times" :key="__key" class="item">
                    <van-row type="flex" justify="space-between">
                      <van-col class="info-1">
                        <div class="avatar-3" :style="{ 'background-image': 'url(' + __item.avatar + ')' }"></div>
                        <div class="info-1-2">
                          <div class="text-1">{{ __item.teacher }}</div>
                          <div class="text-2">{{ __item.title }}</div>
                        </div>
                      </van-col>
                      <van-col class="info-2">
                        <span class="bg-3-head-2">{{ __item.time }}</span>
                      </van-col>
                    </van-row>
                    <div class="block-11" v-for="(___item, ___key) in __item.test" :key="___key">
                      <div class="text-1 mb-10">{{ ___item.title }}: </div>
                      <van-row type="flex" justify="center" align="center" class="head-1">
                        <van-col :span="12" class="line-right-1">
                          <div class="font-13 text-center" v-if="___item.title == 'Math' || ___item.title == 'CLA'">{{
                            $t('ptc.number_of_areas_for_growth') }}</div>
                          <div class="font-13 text-center" v-else>{{ $t('ptc.score') }}</div>
                        </van-col>
                        <van-col :span="12">
                          <div class="font-13 text-center">{{ $t('ptc.target') }}</div>
                        </van-col>
                      </van-row>
                      <div v-if="___item.subdomain">
                        <van-row type="flex" justify="center" align="center" class="mt-10"
                          v-for="(____item, ____key) in ___item.subdomain" :key="____key">
                          <van-col :span="12" style="position: relative;">
                            <div class="font-14">{{ ____item.title }}</div>
                            <div class="font-12 text-center" :style="{ color: ____item.score == '-' ? '#C8C9CC' : '' }">
                              {{ ____item.score }}</div>
                          </van-col>
                          <van-col :span="12">
                            <div class="font-12 text-center" :style="{ color: ____item.target == '-' ? '#C8C9CC' : '' }">
                              {{ ____item.target }}</div>
                          </van-col>
                        </van-row>
                      </div>
                      <div v-else>
                        <van-row type="flex" justify="center" align="center" class="mt-10">
                          <van-col :span="12">
                            <div class="font-12 text-center">{{ ___item.score }}</div>
                          </van-col>
                          <van-col :span="12">
                            <div class="font-12 text-center">{{ ___item.target }}</div>
                          </van-col>
                        </van-row>
                      </div>
                    </div>
                    <div class="block-12 mt-20 text-center">
                      <van-button plain round type="info" size="small" @click="upPlan(__item, item.id)">{{
                        $t('ptc.view_improvement_plan') }}</van-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="(item.status == 20 || item.status == 30 || item.status == 40) && item.schedule.length > 0 && item.dept == 1">
            <van-divider />
            <div v-for="(_item, _key) in item.schedule" :key="_key" :class='_key!=0?"mt-20":""'>
              <div class="bg-1" v-if="item.status != 40">
                <div class="main2">
                  <div class="main2-1">{{ _item.month }}</div>
                  <div class="main2-2">{{ _item.day }}</div>
                </div>
                <div class="tips-1">{{ $t('ptc.ptc_meetings', { n: _item.times.length }) }}</div>
                <div class="tips-2">{{ _item.date }} {{ _item.weekday }}</div>
              </div>
              <div class="bg-2" v-for="(__item, __key) in _item.times" :key="__key">
                <div class="block-1">
                  <div class="block-1-1">
                    <van-icon name="clock" color="#4D88D2" />
                    <span class="time" v-if="item.status == 20 || item.status == 30 ">{{ __item.time }}</span>
                    <span class="time" v-if="item.status == 40">{{ $t("ptc.not_meeting_completed") }}</span>
                    <van-tag round type="primary" v-if="item.status == 30 || item.status == 40" color="#EBF5ED" text-color="#45A367"
                      class='onlineMeet'>{{ $t("ptc.meeting_completed") }}</van-tag>
                  </div>
                </div>

                <div class="block-2" :class="{ 'op-70': __item.parents_choose == -1 }">
                  <div class="tag-online" v-if='item.is_online'><van-tag round color="#FFFBE8" text-color="#ED6A0C"
                      class='onlineMeet'>{{
                        $t("ptc.Online_Meet") }}</van-tag></div>
                  <div class="info-1">
                    <div class="info-1-1">
                      <div class="avatar-2" :style="{ 'background-image': 'url(' + __item.avatar + ')' }"></div>
                      <div class="info-1-1-1">
                        <div class="text-1">{{ __item.teacher }}</div>
                        <div class="text-2">{{ __item.title }}</div>
                      </div>
                    </div>
                    <div class="info-1-2" v-if="__item.address">
                      <van-icon name="location" color="#C8C9CC" />
                      <span>{{ __item.address }}</span>
                    </div>
                    <div class="info-1-3" v-if="item.dept == 2">
                      <van-divider v-if="__item.parents_choose" />
                      <van-row v-if="__item.parents_choose == 0 && !agreeLoading" type="flex" justify="space-between"
                        class="mt-20">
                        <van-col>
                          <van-button round type="default" size="small" @click="agree({
                            item: item,
                            _item: _item,
                            __item: __item,
                            choose_type: -1
                          })">{{ $t('ptc.cannot_attend') }}</van-button>
                        </van-col>
                        <van-col>
                          <van-button round type="info" size="small" @click="agree({
                            item: item,
                            _item: _item,
                            __item: __item,
                            choose_type: 1
                          })">{{ $t('ptc.confirm_to_attend') }}</van-button>
                        </van-col>
                      </van-row>
                      <div v-else-if="__item.parents_choose == 1" class="info-1-3-1">
                        <van-icon name="checked" color="#45A367" />
                        <span>{{ $t('ptc.Confirmed_to_attend') }}</span>
                      </div>
                      <div v-else-if="__item.parents_choose == -1" class="info-1-3-1">
                        <van-icon name="clear" color="#C8C9CC" />
                        <span style="color: #666;">{{ $t('ptc.cannot_attend') }}</span>
                      </div>
                    </div>
                    <div v-if="__item.show_comments && item.dept == 1">
                      <div v-if="__item.test.length > 0">
                        <van-divider />
                        <div class="block-11" v-for="(___item, ___key) in __item.test" :key="___key">
                          <div class="text-1 mb-10">{{ ___item.title }}: </div>
                          <van-row type="flex" justify="center" align="center" class="head-1">
                            <van-col :span="12" class="line-right-1">
                              <div class="font-13 text-center" v-if="___item.title == 'Math' || ___item.title == 'CLA'">{{
                                $t('ptc.number_of_areas_for_growth') }}</div>
                              <div class="font-13 text-center" v-else>{{ $t('ptc.score') }}</div>
                            </van-col>
                            <van-col :span="12">
                              <div class="font-13 text-center">{{ $t('ptc.target') }}</div>
                            </van-col>
                          </van-row>
                          <div v-if="___item.subdomain">
                            <van-row type="flex" justify="center" align="center" class="mt-10"
                              v-for="(____item, ____key) in ___item.subdomain" :key="____key">
                              <van-col :span="12" style="position: relative;">
                                <div class="font-14">{{ ____item.title }}</div>
                                <div class="font-12 text-center"
                                  :style="{ color: ____item.score == '-' ? '#C8C9CC' : '' }">
                                  {{ ____item.score }}</div>
                              </van-col>
                              <van-col :span="12">
                                <div class="font-12 text-center"
                                  :style="{ color: ____item.target == '-' ? '#C8C9CC' : '' }">
                                  {{ ____item.target }}</div>
                              </van-col>
                            </van-row>
                          </div>
                          <div v-else>
                            <van-row type="flex" justify="center" align="center" class="mt-10">
                              <van-col :span="12">
                                <div class="font-12 text-center">{{ ___item.score }}</div>
                              </van-col>
                              <van-col :span="12">
                                <div class="font-12 text-center">{{ ___item.target }}</div>
                              </van-col>
                            </van-row>
                          </div>
                        </div>
                      </div>
                      <van-divider />
                      <div class="block-12 text-center">
                        <van-button plain round type="info" size="small" @click="upPlan(__item, item.id)">{{
                          $t('ptc.view_improvement_plan') }}</van-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item.status == 20 && item.dept == 1 && !item.is_online && item.reserve_btn == 1">
              <van-divider />
              <div style="text-align: right;">
                <van-button round type="info" size="small" v-if="item.reserve_btn == 1"
                  @click="reschedule(item.id, value1)">{{ $t('ptc.reschedule') }}</van-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="noPtc" v-else-if="data">
        <van-empty>
          <div class="color3 font14 titleList">{{ $t('ptc.not_PTC') }}</div>
          <div class="color6 font13 not_PTC_Text">{{ $t('ptc.not_PTC_Text') }}</div>
        </van-empty>
      </div>
      <van-action-sheet v-model="show" :title="$t('ptc.improvement_plan')">
        <div class="box-4" v-if="plan">
          <div class="noData" v-if='plan.content == "" && plan.pdf == ""'>
            {{ $t('calendar.no_data') }}
          </div>
          <div v-else>
            <div class="text-3" v-html="plan.content"></div>
            <div class="mt-20" v-if="plan.pdf">
              <div class="font-12 mb-5">{{ $t('ptc.tli_suggestions') }}</div>
              <div class="text-4 mb-10">{{ $t('ptc.based_on_map_reading_score') }}</div>
              <van-button plain round block type="info" @click="viewPDF">{{ $t('ptc.view_pdf') }}</van-button>
            </div>
          </div>
        </div>
      </van-action-sheet>
      <van-dialog v-model="showDialog" :title="$t('ptc.ptc_translation_service_survey')" show-cancel-button
        :before-close="beforeClose">
        <div class="dialog-content">
          <van-divider />
          <div v-for="(item, key) in option2" :key="key" class="dialog-item"
            :class="{ 'dialog-item-active': item.id == value2 }" @click="checkedItem(item.id)">{{ item.title }}
            <van-divider />
          </div>
          <div class="dialog-tips">
            <van-checkbox v-model="checked">{{ $t('ptc.i_understand_the_survey_') }}</van-checkbox>
          </div>
        </div>
      </van-dialog>
      <van-dialog v-model="showDialog1" show-cancel-button :cancel-button-text="$t('global.close')"
        :show-confirm-button=false :title="$t('ptc.ptc_translation_service_survey')">
        <div class="dialog-content">
          <van-divider />
          <div v-for="(item, key) in option2" :key="key" class="dialog-item"
            :class="{ 'dialog-item-active': item.id == value2 }" style="color:#969799;">{{ item.title }}
            <van-divider />
          </div>
          <div class="dialog-tips text-center">
            <van-icon name="info-o" /> {{ $t('ptc.the_survey_') }}
          </div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast, Dialog, Tag } from 'vant';
import { DropdownMenu, DropdownItem, Empty } from 'vant';
import CustomEmpty from '@/components/CustomEmpty';
import { semester, childInfo, agree, translation, saveTranslation, setOnlineChild, getChildPlan } from '@/api/ptc'
import logger from '@/plugins/logger'

Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Tag);
export default {
  name: 'PTC',
  components: {
    'custom-empty': CustomEmpty,
    'van-dropdown-menu': DropdownMenu,
    'van-dropdown-item': DropdownItem,
    'van-empty': Empty,
  },
  data() {
    return {
      checked: null,
      agreeLoading: false,
      show: false,
      showDialog: false,
      showDialog1: false,
      plan: null,
      value1: '',
      option1: null,
      data: null,
      value2: null,
      option2: [],
      timer: null
    }
  },
  beforeCreate() {
    // document.querySelector('body').setAttribute('style', 'background-color:#fff;')
  },
  created() {
    this.fetchData()
  },
  watch: {
    value1(val) {
      this.yearChange(val)
    },
    show(val) {
      if (val === false) {
        clearTimeout(this.timer)
      }
    }
  },
  methods: {
    fetchData() {
      semester().then(res => {
        this.option1 = res.data
        if (this.option1.length > 0) {
          this.value1 = this.option1[0].value
        }
      })
    },
    yearChange(val) {
      childInfo({
        year_semester: val
      }).then(res => {
        this.data = res.data
        this.agreeLoading = false
      })
    },
    upPlan(data, childId) {
      getChildPlan({
        child_id: childId,
        ptc_item_id: data.id,
      }).then((res) => {
        if (res.code == 0) {
          this.plan = res.data;
          this.show = true
        }
      });
      this.timer = setTimeout(() => {
        logger.send({
          category: 'improvement_plan',
          childid: childId,
          targetid: data.id,
          teacher: data.teacher
        })
      }, 2000)
    },
    viewPDF() {
      location.href = this.plan.pdf
    },
    agree(obj) {
      var msg = obj.choose_type == 1 ? this.$t('ptc.confirm_toattend_this_meeting') : this.$t('ptc.confirm_not_to_attend_this_meeting')
      Dialog.confirm({
        title: msg,
        message: obj._item.date + ' ' + obj._item.weekday + '\n' + obj.__item.time + '\n' + obj.__item.teacher,
      })
        .then(() => {
          this.agreeLoading = true
          agree({
            id: obj.__item.id,
            child_id: obj.item.id,
            choose_type: obj.choose_type,
          }).then(() => {
            Toast.success(this.$t('ptc.successful'))
            this.yearChange(this.value1)
          })
        })
        .catch(() => { })
    },
    reschedule(id, year) {
      Dialog.confirm({
        title: this.$t('ptc.reschedule_confirm'),
        message: this.$t('ptc.reschedule_confirm_tips')
      }).then(() => {
        this.$router.push({
          name: 'PTCBooked',
          params: { id: id, year: year }
        })
      })
    },
    openDialog() {
      translation().then(res => {
        this.value2 = res.data.selected
        this.option2 = res.data.list
        if (res.data.selected) {
          this.showDialog1 = true
        }
        else {
          this.showDialog = true
        }
      })
    },
    beforeClose(action, done) {
      if (action == 'cancel') {
        done()
      }
      else if (action == 'confirm') {
        if (this.checked && this.value2) {
          saveTranslation({
            translation: this.value2
          }).then(() => {
            done()
          })
        }
        else {
          Toast(this.$t('ptc.please_make_a_selection'))
          done(false)
        }
      }
    },
    checkedItem(id) {
      this.value2 = id
    },
    onlinePTC(childId, year) {
      Dialog.confirm({
        message: this.$t("ptc.confirm_cancel_ptc"),
      })
        .then(() => {
          setOnlineChild({
            child_id: childId,
            year_semester: year,
            status: 0
          }).then((res) => {
            if (res.code == 0) {
              Toast.success(this.$t('ptc.successful'))
              this.yearChange(this.value1)
            } else {
              // this.fetchData();
            }
          });
        })
        .catch(() => { });
    }
  }
}
</script>

<style scoped lang="less">
.noPtc {
  padding: 16px;
  text-align: center;

  .titleList {
    font-weight: 600;
  }

  .not_PTC_Text {
    line-height: 20px;
    padding: 20px;
  }
}

.action-warp {
  padding: 16px;

  .box-1 {
    padding: 16px;
    background-color: #fff;
    margin-bottom: 16px;
    border-radius: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .text-1 {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      margin-bottom: 4px;
    }

    .text-2 {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
    }

    .ele {
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      align-items: center;

      .ele-1 {
        display: flex;
        display: -webkit-flex;
        align-items: center;

        .avatar {
          width: 52px;
          height: 52px;
          border-radius: 8px;
        }
      }

      .ele-2 {
        margin-left: 10px;

        .ele-2-name {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
          margin-bottom: 8px;
        }

        .ele-2-class {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 12px;
        }
      }

      .ele-3 {
        float: right;
      }
    }

    .bg-1 {
      height: 54px;
      background-color: #FFF7F0;
      border-radius: 27px;
      text-align: center;
      margin-bottom: 21px;
      position: relative;

      .main2 {
        width: 40px;
        height: 40px;
        text-align: center;
        margin-right: 12px;
        position: absolute;
        left: 20px;
        top: 6px;

        .main2-1 {
          height: 18px;
          background-color: #F19043;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
          border-radius: 8px 8px 0px 0px;
          font-size: 14px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 18px;
        }

        .main2-2 {
          height: 22px;
          background-color: #FFFFFF;
          border-radius: 0px 0px 8px 8px;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
          font-size: 16px;
          font-weight: 500;
          color: #F19043;
          line-height: 22px;
        }
      }
    }

    .tips-1 {
      padding-top: 9px;
      font-size: 13px;
      font-weight: 500;
      color: #333333;
      line-height: 18px;
      margin-bottom: 2px;
    }

    .tips-2 {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
    }

    .onlineMeet {
      font-size: 12px !important;
      font-weight: 400 !important;
      padding: 0 6px !important
    }

    .bg-2 {
      .block-1 {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: space-between;

        .block-1-1 {
          display: flex;
          display: -webkit-flex;
          align-items: center;

          i {
            font-size: 18px;
          }

          span {
            margin-left: 10px;
            font-size: 15px;
            font-weight: 500;
            color: #333333;
          }
        }
      }

      .tag-online {
        margin-bottom: 12px;
      }

      .block-2 {
        margin-left: 8px;
        border-left: 1px solid #D7DAE2;
        border-top: 12px;
        border-bottom: 12px;
        padding: 4px 0 16px 19px;

        .info-1 {
          margin-top: 8px;
          padding: 16px;
          background-color: #FAFAFA;
          border-radius: 10px;

          .info-1-1 {
            display: flex;

            .avatar-2 {
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              width: 36px;
              height: 36px;
              margin-right: 10px;
              border-radius: 18px;
            }

            .info-1-1-1 {
              width: 206px;
            }
          }

          .info-1-2 {
            line-height: 20px;
            display: flex;
            display: -webkit-flex;
            align-items: flex-start;
            margin-top: 16px;

            i {
              line-height: 20px;
              font-size: 14px;
              margin-right: 8px;
            }

            span {
              line-height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #333333;
            }
          }

          .info-1-3 {
            .info-1-3-1 {
              display: flex;
              display: -webkit-flex;
              align-items: center;
              justify-content: flex-end;

              i {
                font-size: 14px;
              }

              span {
                margin-left: 4px;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
              }
            }
          }

          .block-11 {
            margin-top: 16px;

            .line {
              width: 1px;
              height: 100%;
              background-color: #DCDEE0;
              margin: 0 auto;
            }

            .head-1 {
              background-color: #F2F3F5;
              border-radius: 14px;
              height: 28px;
              line-height: 28px;
              margin-top: 12px;

              .line-right-1 {
                border-right: 1px solid #fff;
              }
            }
          }
        }
      }

      &:last-child .block-2 {
        border-left: 0;
        padding-bottom: 0;
      }

      .op-70 {

        .text-1,
        .text-2,
        .avatar-2,
        .info-1-2 {
          opacity: .7;
        }
      }
    }

    .bg-3-head-1 {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }

    .bg-3-head-2 {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
    }

    .bg-3 {
      margin-top: 16px;

      .item {
        padding: 16px;
        background-color: #FBFBFC;
        margin-bottom: 16px;
        border-radius: 10px;
        align-items: center;
        .block-11 {
            margin-top: 16px;

            .line {
              width: 1px;
              height: 100%;
              background-color: #DCDEE0;
              margin: 0 auto;
            }

            .head-1 {
              background-color: #F2F3F5;
              border-radius: 14px;
              height: 28px;
              line-height: 28px;
              margin-top: 12px;

              .line-right-1 {
                border-right: 1px solid #fff;
              }
            }
          }
        &:last-child {
          margin-bottom: 0;
        }

        .info-1 {
          display: flex;
          display: -webkit-flex;
          width: 207px;

          .avatar-3 {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            margin-right: 10px;
          }

          .info-1-2 {
            width: 156px;
          }
        }
      }


    }

    .van-button {
      padding: 0 10px;
    }

    .cancelOnline {
      .onlineText {
        color: #969799;
        font-size: 12px;
        padding: 0 16px
      }
    }
  }
}

.box-4 {
  padding: 6px 16px 24px 16px;

  .noData {
    min-height: 150px;
    color: #ccc;
    text-align: center;
    font-size: 12px;
    line-height: 150px;
  }
}

.font-11 {
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  margin-bottom: 4px;
}

.font-12 {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
}

.font-13 {
  font-size: 13px;
  font-weight: 400;
  color: #323233;
  line-height: 13px;
}

.font-14 {
  font-size: 14px;
  font-weight: 500;
  color: #969799;
  line-height: 14px;
  position: absolute;
  left: 16px;
  top: 5px;
}

.font-15 {
  font-size: 13px;
  color: #969799;
}

.text-3 {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}

.text-4 {
  font-size: 14px;
  font-weight: 400;
  color: #999;
  line-height: 20px;
}

.dialog-content {
  .dialog-item {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    color: #323233;
    line-height: 20px;
  }

  .dialog-item-active {
    color: #4D88D2 !important;
  }

  .dialog-tips {
    font-size: 13px;
    font-weight: 400;
    color: #646566;
    line-height: 20px;
    padding: 0 25px 25px 25px;
  }
}
</style>
