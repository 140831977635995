import Vue from 'vue';

export function campusList () {
  return Vue.axios({
    url: '/api/campus/list',
    method: 'get'
  })
}

export function campusItem (params) {
  return Vue.axios({
    url: '/api/campus/item',
    method: 'get',
    params: params
  })
}

export function getSuccessFeedback(params){
  return Vue.axios({
    url: '/api/success/feedback',
    method: 'get',
    params: params
  })
}
