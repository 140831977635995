<template>
  <div :class="stepStatus == 4  && dataDetails.isCommon==1? 'clickNone' : ''">
    <van-row class="bus">
      <div class="color3 font15">{{ $t("coll.beijing_insurance") }}</div>
      <van-radio-group
        v-model="initData.is_insured"
        @change="is_insured()"
        class="mt-20"
      >
        <van-radio name="1">
          <span class="color6 font14">{{ $t("coll.yes") }}</span>
        </van-radio>
        <van-radio name="2" class="mt-15"
          ><span class="color6 font14">{{ $t("coll.no") }}</span>
        </van-radio>
      </van-radio-group>
    </van-row>
    <div v-if="initData.is_insured == '1'">
      <div class="flex mt-20">
        <div class="flexWidth"><span class="icon"></span></div>
        <div class="flex1">
          <div class="title">{{ $t("coll.renewal") }}</div>
        </div>
      </div>
      <van-radio-group v-model="initData.is_continued" class="mt-20">
        <van-cell-group inset>
          <van-cell
            :title="$t('coll.yes')"
            clickable
            @click="initData.is_continued = '1'"
          >
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>
          <van-cell
            :title="$t('coll.no')"
            clickable
            @click="initData.is_continued = '2'"
          >
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div v-if="initData.is_insured == '1'">
      <div v-if="initData.is_continued == '2'">
        <div class="color6 font14 mt-20 enText">
          {{ $t("coll.not_insurance") }}
        </div>
      </div>
      <div v-if="initData.is_continued == '1'">
        <div>
          <div class="flex mt-20">
            <div class="flexWidth"><span class="icon"></span></div>
            <div class="flex1">
              <div class="title">{{ $t("coll.stu_id") }}</div>
            </div>
          </div>
          <van-row class="mt-20">
            <van-field
              :placeholder="$t('global.please_input')"
              class="bgGray mt-10"
              v-model="initData.student_id"

            />
          </van-row>
        </div>
        <div>
          <div class="flex mt-20">
            <div class="flexWidth"><span class="icon"></span></div>
            <div class="flex1">
              <div class="title">{{ $t("coll.insurance_tel") }}</div>
            </div>
          </div>
          <van-row class="mt-20">
            <van-field
              :placeholder="$t('global.please_input')"
              class="bgGray mt-10"
              v-model="initData.insured_phone"

            />
          </van-row>
        </div>
        <div>
          <div class="flex mt-20">
            <div class="flexWidth"><span class="icon"></span></div>
            <div class="flex1">
              <div class="title">{{ $t("coll.insurance_card") }}</div>
            </div>
          </div>
          <van-row class="mt-20">
            <van-uploader
              v-model="initData.insurance_card_url"
              @delete="deleteimg"
              :after-read="afterRead"
              :before-read="beforeRead"
              name="insurance_card"
            />
          </van-row>
        </div>
        <div class="color6 font14 mt-20 enText">
          {{ $t("coll.500yuan") }}
        </div>
      </div>
    </div>
    <div v-if="initData.is_insured == '2'">
      <div class="flex mt-20">
        <div class="flexWidth"><span class="icon"></span></div>
        <div class="flex1">
          <div class="title">{{ $t("coll.click_one") }}</div>
        </div>
      </div>
      <van-radio-group v-model="initData.insurance_type" class="mt-20">
        <van-cell-group>
          <van-cell
            :title="marker.title"
            clickable
            v-for="(marker, index) in specialList"
            :key="index"
            @click="initData.insurance_type = marker.id"
          >
            <template #right-icon>
              <van-radio :name="marker.id" class="pl-20" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>

      <div
        v-if="
          initData.insurance_type == 3
        "
      >
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">{{ $t("coll.student_account") }}</div>
          </div>
        </div>
        <van-row class="mt-20">
          <van-uploader
            v-model="insurance_file_url.student_account_url"
            @delete="deleteimg"
            :after-read="afterRead"
            :before-read="beforeRead"
            name="student_account"
          />
        </van-row>
      </div>
      <div
        v-if="
          initData.insurance_type == 4 ||
          initData.insurance_type == 5 ||
          initData.insurance_type == 6
        "
      >
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">{{ $t("coll.student_account1") }}</div>
          </div>
        </div>
        <van-row class="mt-20">
          <van-uploader
            v-model="insurance_file_url.student_account_url"
            @delete="deleteimg"
            :after-read="afterRead"
            :before-read="beforeRead"
            name="student_account"
          />
        </van-row>
      </div>
      <div v-if="initData.insurance_type == 3">
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">
              {{ $t("coll.student_id_card") }}
            </div>
          </div>
        </div>
        <van-row class="mt-20">
          <van-uploader
            v-model="insurance_file_url.student_id_card_url"
            @delete="deleteimg"
            :after-read="afterRead"
            :before-read="beforeRead"
            name="student_id_card"
          />
        </van-row>
      </div>
      <div
        v-if="
          initData.insurance_type == 3 ||
          initData.insurance_type == 4 ||
          initData.insurance_type == 5 ||
          initData.insurance_type == 6 ||
          initData.insurance_type == 7
        "
      >
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">
              {{ $t("coll.student_photo") }}
            </div>
          </div>
        </div>
        <van-row class="mt-20">
          <van-uploader
            v-model="insurance_file_url.student_photo_url"
            @delete="deleteimg"
            :after-read="afterRead"
            :before-read="beforeRead"
            name="student_photo"
          />
        </van-row>
      </div>

      <div v-if="initData.insurance_type == 4">
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">
              {{ $t("coll.parent_account") }}
            </div>
          </div>
        </div>
        <van-row class="mt-20">
          <van-uploader
            v-model="insurance_file_url.parent_account_url"
            @delete="deleteimg"
            :after-read="afterRead"
            :before-read="beforeRead"
            name="parent_account"
          />
        </van-row>
      </div>
      <div
        v-if="
          initData.insurance_type == 4 ||
          initData.insurance_type == 5 ||
          initData.insurance_type == 6
        "
      >
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">
              {{ $t("coll.student_birth") }}
            </div>
          </div>
        </div>
        <van-row class="mt-20">
          <van-uploader
            v-model="insurance_file_url.student_birth_url"
            @delete="deleteimg"
            :after-read="afterRead"
            :before-read="beforeRead"
            name="student_birth"
          />
        </van-row>
      </div>
      <div
        v-if="
          initData.insurance_type == 4 ||
          initData.insurance_type == 5 ||
          initData.insurance_type == 6
        "
      >
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">
              {{ $t("coll.parent_marry") }}
            </div>
          </div>
        </div>
        <van-row class="mt-20">
          <van-uploader
            v-model="insurance_file_url.parent_marry_url"
            @delete="deleteimg"
            :after-read="afterRead"
            :before-read="beforeRead"
            name="parent_marry"
          />
        </van-row>
      </div>
      <div
        v-if="
          initData.insurance_type == 4 ||
          initData.insurance_type == 5 ||
          initData.insurance_type == 6
        "
      >
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">
              {{ $t("coll.student_id_card") }}
            </div>
          </div>
        </div>
        <van-row class="mt-20">
          <van-uploader
            v-model="insurance_file_url.student_id_card1_url"
            @delete="deleteimg"
            :after-read="afterRead"
            :before-read="beforeRead"
            name="student_id_card1"
          />
        </van-row>
      </div>
      <div v-if="initData.insurance_type == 5">
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">{{ $t("coll.work_permit") }}</div>
          </div>
        </div>
        <van-row class="mt-20">
          <van-uploader
            v-model="insurance_file_url.work_permit_url"
            @delete="deleteimg"
            :after-read="afterRead"
            name="work_permit"
          />
        </van-row>
      </div>
      <div v-if="initData.insurance_type == 6">
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">{{ $t("coll.political_organ") }}</div>
          </div>
        </div>
        <van-row class="mt-20">
          <van-uploader
            v-model="insurance_file_url.political_organ_url"
            @delete="deleteimg"
            :after-read="afterRead"
            :before-read="beforeRead"
            name="political_organ"
          />
        </van-row>
      </div>
      <div v-if="initData.insurance_type == 7">
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">{{ $t("coll.stu_passport") }}</div>
          </div>
        </div>
        <van-row class="mt-20">
          <van-uploader
            v-model="insurance_file_url.student_passport_url"
            @delete="deleteimg"
            :after-read="afterRead"
            :before-read="beforeRead"
            name="student_passport"
          />
        </van-row>
      </div>
      <div v-if="initData.insurance_type == 7">
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">
              {{ $t("coll.foreigner_id_card") }}
            </div>
          </div>
        </div>
        <van-row class="mt-20">
          <van-uploader
            v-model="insurance_file_url.foreigner_id_card_url"
            @delete="deleteimg"
            :after-read="afterRead"
            :before-read="beforeRead"
            name="foreigner_id_card"
          />
        </van-row>
      </div>
      <div v-if="initData.insurance_type == 7">
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">{{ $t("coll.commitment") }}</div>
            <div  class="color6 font14 pt-5">
              {{$t("coll.download_click")}}
              <a href="https://m2.files.ivykids.cn/cloud01-file-8025768FpYQwkghWyhmnxxyKo14AH7vrejg.pdf">{{$t("coll.download_here")}}</a> 
              {{$t("coll.download_download")}}
            </div>
          </div>
        </div>
        <van-row class="mt-20">
          <van-uploader
            v-model="insurance_file_url.commitment_url"
            @delete="deleteimg"
            :after-read="afterRead"
            :before-read="beforeRead"
            name="commitment"
          />
        </van-row>
      </div>
      <div
        v-if="
          initData.insurance_type == 3 ||
          initData.insurance_type == 4 ||
          initData.insurance_type == 5 ||
          initData.insurance_type == 6 ||
          initData.insurance_type == 7
        "
      >
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">
              {{ $t("coll.only_Beijing") }}
            </div>
          </div>
        </div>
        <van-row class="mt-20">
          <van-field
            :placeholder="$t('global.please_input')"
            class="bgGray mt-10"
            v-model="initData.insured_phone_bj"
          />
        </van-row>
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">
              {{ $t("coll.medical_hospital") }}
            </div>
          </div>
        </div>
        <van-row class="mt-20">
          <van-field
            :placeholder="$t('global.please_input')"
            v-for="(lists, index) in medical1"
            :key="index"
            class="bgGray mt-10"
            v-model="medical[index]"
          />
        </van-row>
      </div>
      <div
        v-if="
          initData.insurance_type == 3 ||
          initData.insurance_type == 4 ||
          initData.insurance_type == 5 ||
          initData.insurance_type == 6
        "
        class="color6 font14 mt-20 enText"
      >
        {{ $t("coll.confirm_insurance") }}
      </div>
      <div
        v-if="initData.insurance_type == 7"
        class="color6 font14 mt-20 enText"
      >
        {{ $t("coll.payment_insurance") }}
      </div>
      <div
        v-if="initData.insurance_type == 1"
        class="color6 font14 mt-20 enText"
      >
        {{ $t("coll.incompatible_insurance") }}
      </div>
      <div
        v-if="initData.insurance_type == 2"
        class="color6 font14 mt-20 enText"
      >
        {{ $t("coll.not_insurance") }}
      </div>
    </div>
    <div
      v-if="
        (initData.is_insured != '0' && initData.insurance_type != '0') ||
        (initData.is_insured != '0' && initData.is_continued != '0')
      "
    >
      <div v-if="dataDetails.protocol.length != 0">
        <protocol-sign
          @click="baseImg"
          :busProtocol="dataDetails.protocol"
          :base64="base64"
        ></protocol-sign>
      </div>
    </div>
    <div class="mt-20">
      <van-button
        v-if="stepStatus == 4  && dataDetails.isCommon==1"
        disabled
        type="info"
        block
        round
        color="#4d88d2"
        class="saveSub"
        >{{ $t("coll.step_reviewed") }}</van-button
      >
      <a
        href="javascript:;"
        v-else
        @click="submitData()"
        :class="btnDisabled ? 'disabledSubmit' : ''"
        class="saveBtnStep mt-20"
        >{{ $t("global.submit") }}</a
      >
    </div>
  </div>
</template>

<script>
import ProtocolSign from "@/components/steps/ProtocolSign";
import Vue from "vue";
import { Toast } from "vant";
import Compressor from 'compressorjs';

Vue.use(Toast);
export default {
  components: {
    "protocol-sign": ProtocolSign,
  },
  props: {
    dataDetails: {
      type: Object,
    },
    status: {
      type: Number,
    },
    disabled: {
      type: Number,
    },
  },
  name: "homeAddress",
  data() {
    return {
      initData: "",
      qiniuToken: "",
      stepStatus: "",
      specialList: [
        { title: this.$t("coll.city_child"), id: 3 },
        {
          title: this.$t("coll.city_parent"),
          id: 4,
        },
        { title: this.$t("coll.work_parent"), id: 5 },
        { title: this.$t("coll.underage_child"), id: 6 },
        {
          title: this.$t("coll.alien_id_card"),
          id: 7,
        },
        { title: this.$t("coll.Stu_not_insurance"), id: 1 },
        { title: this.$t("coll.abandon_insurance"), id: 2 },
      ],
      medical: ["", "", ""],
      medical1: ["", "", ""],
      base64: "",
      sign: "",
      sign_url: "",
      signShow: false,
      newSign: false,
      insurance_file: {
        student_account: [],
        student_id_card: [],
        student_photo: [],
        parent_account: [],
        student_birth: [],
        parent_marry: [],
        student_id_card1: [],
        work_permit: [],
        political_organ: [],
        student_passport: [],
        foreigner_id_card: [],
        commitment: [],
      },
      insurance_file_url: {
        student_account_url: [],
        student_id_card_url: [],
        student_photo_url: [],
        parent_account_url: [],
        student_birth_url: [],
        parent_marry_url: [],
        student_id_card1_url: [],
        work_permit_url: [],
        political_organ_url: [],
        student_passport_url: [],
        foreigner_id_card_url: [],
        commitment_url: [],
      },
      btnDisabled: false,
      pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
      pattern_card: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
      
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
  },
  created() {
    this.stepStatus = this.status;
    this.initData = this.dataDetails.data;
    this.qiniuToken = this.dataDetails.qiniuToken;
    this.initData.is_insured = this.initData.is_insured + "";
    this.initData.is_continued = this.initData.is_continued + "";
    if (this.dataDetails.sign_url != "") {
      this.base64 = this.dataDetails.sign_url;
    }
    if (this.initData.insurance_hospitals.length != 0) {
      this.medical = this.initData.insurance_hospitals;
      this.medical1 = this.initData.insurance_hospitals;
    }
    if (this.initData.insurance_card_url.length != 0) {
      this.initData.insurance_card_url = this.tojson(
        this.initData.insurance_card_url
      );
    }

    if (Object.keys(this.initData.insurance_file_url).length != 0) {
      for (var key in this.initData.insurance_file_url) {
        this.insurance_file_url[key + "_url"] = this.tojson(
          this.initData.insurance_file_url[key]
        );
      }
    }
    if (Object.keys(this.initData.insurance_file).length != 0) {
      this.insurance_file = this.initData.insurance_file;
    }
  },
  methods: {
    validator(val) {
      return /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(val);
    },
    tojson(rows) {
      var ids = [];
      for (var i = 0, len = rows.length; i < len; i++) {
        var signAgainReq = new Object();
        signAgainReq.url = rows[i];
        ids.push(signAgainReq);
      }
      return ids;
    },
    is_insured() {
      if (this.initData.is_insured == "1") {
        this.initData.insurance_type = "0";
      } else {
        this.initData.is_continued = "0";
      }
    },
    beforeRead(file) {
      return new Promise((resolve) => {
        new Compressor(file, {
          maxWidth:1200,
          quality:0.6,
          success: resolve,
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
    afterRead(file, detail) {
      this.type = detail.name;
      if (file.length > 1) {
        for (let i in file) {
          this.upimg(file[i].content);
        }
      } else {
        this.upimg(file.content);
      }
    },
    upimg(data) {
      const that = this;
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: that.$t("pickupcard.uploading"),
      });
      var pic = data.substring(data.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            if (that.type == "insurance_card") {
              that.initData.insurance_card.push(resJson.data.key);
            } else {
              that.insurance_file[that.type].push(resJson.data.key);
            }

            Toast(that.$t("pickupcard.Uploaded"));
          } else {
            Toast(resJson.msg);
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.qiniuToken);
      xhr.send(pic);
    },
    //删除图片
    deleteimg(file, detail) {
      if (detail.name == "insurance_card") {
        this.initData.insurance_card.splice(detail.index - 1, 1);
      } else {
        this.insurance_file[detail.name].splice(detail.index - 1, 1);
      }
    },
    signature() {
      this.signShow = true;
    },
    baseImg(data) {
      if (data != "cancel") {
        this.base64 = data;
        this.newSign = true;
      }
      this.signShow = false;
    },
    phoneValidate(params,type) {
      if (type.test(params)) {
        return true;
      } else {
        return false;
      }
    },
    submitData() {
      const that = this;
      if (this.initData.is_insured == 0 || this.initData.is_insured == "") {
        Toast(this.$t("coll.choose") + this.$t("coll.beijing_insurance"));
        return;
      }
      if (this.initData.is_insured == 1) {
        if (this.initData.is_continued == 0) {
           Toast(this.$t("global.please_choose") + this.$t("coll.renewal"));
          return;
        }
        if (this.initData.is_continued == 1) {
          if(this.initData.student_id==''){
            Toast(this.$t("global.please_input") + this.$t("coll.stu_id"));
            return;
          }
          if (!this.phoneValidate(this.initData.student_id,this.pattern_card)) {
              Toast(this.$t("coll.invalid_card"));
              return;
            }
          if(this.initData.insured_phone==''){
            Toast(this.$t("global.please_input")+ this.$t("coll.insurance_tel"));
            return;
          }
           if (!this.phoneValidate(this.initData.insured_phone,this.pattern)) {
              Toast(this.$t("pickupcard.invalid_phone"));
              return;
            }
          if(this.initData.insurance_card.length==0){
            Toast(this.$t("coll.insurance_card"));
            return;
          }
        }
      }
      if (this.initData.is_insured == 2) {
        if (this.initData.insurance_type == 0) {
          Toast(this.$t("coll.click_one"));
          return;
        }
        if (
          (this.initData.insurance_type == 3) &&
          this.insurance_file.student_account.length == 0
        ) {
          Toast(this.$t("global.please_upload") + this.$t("coll.student_account"));
          return;
        }
        if (
          (
            this.initData.insurance_type == 4 ||
            this.initData.insurance_type == 5 ||
            this.initData.insurance_type == 6) &&
          this.insurance_file.student_account.length == 0
        ) {
          Toast(this.$t("global.please_upload") + this.$t("coll.student_account1"));
          return;
        }
        if (
          (this.initData.insurance_type == 3 ||
            this.initData.insurance_type == 4 ||
            this.initData.insurance_type == 5 ||
            this.initData.insurance_type == 6 ||
            this.initData.insurance_type == 7) &&
          this.insurance_file.student_photo.length == 0
        ) {
          Toast(this.$t("global.please_upload") + this.$t("coll.student_photo"));
          return;
        }
        if (
          this.initData.insurance_type == 4 &&
          this.insurance_file.parent_account.length == 0
        ) {
          Toast(this.$t("global.please_upload") + this.$t("coll.parent_account"));
          return;
        }

        if (
          (this.initData.insurance_type == 4 ||
            this.initData.insurance_type == 5 ||
            this.initData.insurance_type == 6) &&
          this.insurance_file.student_birth.length == 0
        ) {
          Toast(this.$t("global.please_upload") + this.$t("coll.student_birth"));
          return;
        }
        if (
          (this.initData.insurance_type == 4 ||
            this.initData.insurance_type == 5 ||
            this.initData.insurance_type == 6) &&
          this.insurance_file.parent_marry.length == 0
        ) {
          Toast(this.$t("global.please_upload") + this.$t("coll.parent_marry"));
          return;
        }
        if (
          this.initData.insurance_type == 5 &&
          this.insurance_file.work_permit.length == 0
        ) {
          Toast(this.$t("global.please_upload") + this.$t("coll.work_permit"));
          return;
        }
        if (
          this.initData.insurance_type == 6 &&
          this.insurance_file.political_organ.length == 0
        ) {
          Toast(this.$t("global.please_upload") + this.$t("coll.political_organ"));
          return;
        }
        if (
          this.initData.insurance_type == 7 &&
          this.insurance_file.student_passport.length == 0
        ) {
          Toast(this.$t("global.please_upload") + this.$t("coll.stu_passport"));
          return;
        }
        if (
          this.initData.insurance_type == 7 &&
          this.insurance_file.foreigner_id_card.length == 0
        ) {
          Toast(this.$t("global.please_upload") + this.$t("coll.foreigner_id_card"));
          return;
        }
        if (
          this.initData.insurance_type == 3 ||
          this.initData.insurance_type == 4 ||
          this.initData.insurance_type == 5 ||
          this.initData.insurance_type == 6 ||
          this.initData.insurance_type == 7
        ) {
          if(this.initData.insured_phone_bj==''){
            Toast(
                this.$t("global.please_input") +
                  this.$t("coll.only_Beijing")
              );
              return;
          }
          if (!this.phoneValidate(this.initData.insured_phone_bj,this.pattern)) {
              Toast(this.$t("pickupcard.invalid_phone"));
              return;
            }
          
          for (var i = 0; i < this.medical.length; i++) {
            if (this.medical[i] == "") {
              Toast(
                this.$t("global.please_input") +
                  this.$t("coll.medical_hospital")
              );
              return;
            }
          }
        }
      }
      if (this.btnDisabled) {
        return;
      }
      if (this.dataDetails.protocol.length != 0 && this.base64 == "") {
        Toast(that.$t("coll.please_sign"));
        return;
      }
      this.$nextTick(function () {
        this.btnDisabled = true;
      });
      if (!that.newSign) {
        that.putb64();
        return;
      }
      var pic = this.base64.substring(this.base64.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // toast.clear()
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that.initData, "sign_url", resJson.data.url);
            Vue.set(that.initData, "sign", resJson.data.key);
            that.putb64();
          } else {
            that.btnDisabled = false;
            Toast(resJson.msg);
          }
        } else {
          that.btnDisabled = false;
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader(
        "Authorization",
        "UpToken " + that.dataDetails.signQiniuToken
      );
      xhr.send(pic);
    },
    putb64() {
      this.initData.insurance_hospitals = this.medical;
      this.initData.insurance_file = this.insurance_file;
      // this.foodData.eat_lunch = parseInt(this.dataDetails.eat_lunch);
      this.$emit("change", this.initData);
    },
  },
};
</script>

<style lang="less" scoped>
</style>