<template>
  <div class="more">
    <div class="item" v-for="(item, index) in items" :key="index" @click="topage(item.href)">
      <div class="item-warp">
        <div class="block-1">
          <div><img :src="item.icon" alt="" class="the-icon"></div>
          <div class="" v-if="item.subTitle && locale == 'en'">
            <div class="text text-01">{{ item.title }}</div>
            <div class="text-02">{{ item.subTitle }}</div>
          </div>
          <div class="text" v-else>{{item.title}}</div>
        </div>
        <div><van-icon name="arrow" class="font14" color="#969799" /></div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Vue from "vue";
import { Icon } from "vant";
import i18n from "../i18n";

Vue.use(Icon);

export default {
  name: "More",
  components: {},
  data() {
    return {
      locale: i18n.locale,
      items: [
        {
          title: this.$t("student.my_class"),
          icon: require("../assets/my_class@2x.png"),
          href: 'student'
        },
        {
          title: this.$t("more.student_handbook"),
          icon: require("../assets/user.png"),
          href: 'handbook'
        },
        {
          title: this.$t("more.payment_history"),
          icon: require("../assets/payment_pistory@2x.png"),
          href: 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb1a42b81111e29f3&redirect_uri=http://www.ivyonline.cn/wechat/user/paidInvoices/lang/{lang}&response_type=code&scope=snsapi_userinfo&state=ds#wechat_redirect'
        },
        {
          title: this.$t("more.balance"),
          icon: require("../assets/balance@2x.png"),
          href: 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb1a42b81111e29f3&redirect_uri=http://www.ivyonline.cn/wechat/user/credit/lang/{lang}&response_type=code&scope=snsapi_userinfo&state=ds#wechat_redirect'
        },
        {
          title: this.$t("more.spac"),
          subTitle: this.$t("more.spac_01"),
          icon: require("../assets/SPAC.png"),
          href: 'spac'
        },
        {
          title: this.$t("more.ss"),
          icon: require("../assets/ss.png"),
          href: 'stuOnlineSs'
        },
        {
          title: this.$t("more.es"),
          icon: require("../assets/es.png"),
          href: 'stuOnlineEs'
        },
      ]
    };
  },
  beforeCreate() {},
  created() {},
  methods: {
    topage(path) {
      if (path.indexOf('http://') > -1 || path.indexOf('https://') > -1) {
        const langV2 = i18n.locale == 'zh' ? 'zh_cn' : 'en_us'
        window.location.href = path.replace('{lang}', langV2)
      }
      else {
        this.$router.push({ path: path })
      }
    }
  }
};
</script>
  
<style lang="less" scoped>
.more {
  padding: 16px;
  .item {
    height: 76px;
    line-height: 76px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 10px;
    .item-warp {
      height: 76px;
      line-height: 76px;
      margin: 0 16px;
      display: flex;
      justify-content: space-between;
      .the-icon {
        width: 44px;
        height: 44px;
        vertical-align: middle;
        margin-right: 16px;
      }
      .block-1 {
        display: flex;
        .text {
          font-size: 15px;
          font-weight: 500;
          color: #333333;
        }
        .text-01 {
          line-height: 21px;
          margin-top: 18px;
        }
        .text-02 {
          font-size: 12px;
          font-weight: 400;
          color: #646566;
          line-height: 17px;
        }
      }
    }
  }
}
</style>
  
