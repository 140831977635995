<template>
  <div>
    <div v-if="initData.status == 1">
      <div class="text-center pt-50 mt-50">
        <img class="img" src="../assets/completed.png" alt="" style="width: 200px" />
        <div class="font17 color3 fontBold mt-12">退学申请提交成功</div>
      </div>
    </div>
    <div class="text-center pt-50 mt-50" v-if="initData.status == 99">
      <img class="img" src="../assets/completed.png" alt="" style="width: 200px" />
      <div class="font17 color3 fontBold mt-12">{{$t('withdrawal.confirmed')}}</div>
    </div>
    <div v-if="initData.status == 0">
      <van-notice-bar left-icon="volume-o" :text="$t('notice.preview_model')" v-if="$route.name == 'previewWithdrawal'" />
      <div class="childDetail">
        <van-row type="flex" justify="end" class="mb-20" v-if="$route.name == 'previewWithdrawal'">
          <switch-lang color="#377ED7" />
        </van-row>
        <div class="flex align_items">
          <div><img :src="initData.childInfo.avatar" alt="" class="img44" /></div>
          <div class="flex1 ml-12">
            <div class="color3 font14 fontBold">{{ initData.childInfo.name }}</div>
            <div class="color6 font12">{{ initData.childInfo.className }}</div>
          </div>
        </div>
      </div>
      <div>
        <div class="appForms">
          <div class="flex requiredIcon">
            <div class="font14 color3 fontBold">{{ $t("withdrawal.Withdrawal_Date") }}</div>
            <span class="flex1 important">*</span>
          </div>
          <div class="flex selectCSs" @click="showDateEvent()">
            <div class="flex1">
              <span v-if="currentDateCopy != ''">{{ currentDateCopy }}</span
              ><span v-else  class="color9">{{ $t("global.please_choose") }}</span>
            </div>
            <van-icon name="arrow" />
          </div>
          <div class="mt-16">
            <div class="flex requiredIcon">
              <div class="font14 color3 fontBold">{{ $t("withdrawal.transferring_to")}}</div>
              <span class="flex1 important">*</span>
            </div>
            <div class="flex selectCSs" @click="showWhereEvent()">
              <div class="flex1">
                <span v-if="withdrawal_where != ''">{{ withdrawal_where }}</span
                ><span v-else class="color9">{{ $t("global.please_choose") }}</span>
              </div>
              <van-icon name="arrow" />
            </div>
            <div>
              <van-field v-model="withdrawal_where_memo" :placeholder="$t('global.please_input')" class="inputCSs mt-12" />
            </div>
          </div>
          <div class="mt-16">
            <div class="flex requiredIcon">
              <div class="font14 color3 fontBold">{{ $t("withdrawal.Reason_withdrawal")}}</div>
              <span class="flex1 important">*</span>
            </div>
            <div class="flex selectCSs" @click="showReasonEvent()">
              <div class="flex1">
                <span v-if="withdrawal_reason != ''">{{ withdrawal_reason }}</span
                ><span v-else class="color9">{{ $t("global.please_choose") }}</span>
              </div>
              <van-icon name="arrow" />
            </div>
            <div>
              <van-field v-model="withdrawal_reason_memo" :placeholder="$t('global.please_input')" class="inputCSs mt-12" />
            </div>
          </div>
          <div class="mt-16">
            <div class="flex requiredIcon">
              <div class="font14 color3 fontBold">{{ $t("withdrawal.unsatisfied_with")}}</div>
              <span class="flex1 important">*</span>
            </div>
            <div>
              <van-field v-model="withdrawal_unsatisfied" :placeholder="$t('global.please_input')" rows="2" type="textarea" class="inputCSs mt-12" />
            </div>
          </div>
          <div class="mt-16">
            <div class="flex requiredIcon">
              <div class="font14 color3 fontBold">{{ $t("withdrawal.for_us")}}</div>
              <span class="flex1 important">*</span>
            </div>
            <div>
              <van-field v-model="withdrawal_recommendations" :placeholder="$t('global.please_input')" rows="2" type="textarea" class="inputCSs mt-12" />
            </div>
          </div>
          <div class="mt-16">
            <div class="flex requiredIcon">
              <div class="font14 color3 fontBold">{{ $t("withdrawal.Who_contact_withdrawal")}}</div>
              <span class="flex1 important">*</span>
            </div>
            <div class="mt-16">
              <van-radio-group v-model="withdrawal_contact_user">
                <van-radio :name="key" class="radioItems" icon-size="17px" :key="index" v-for="(item, key, index) in initData.parentInfo">
                  <div class="color3 font14 mb-5">
                    {{ item.name }}
                    <van-tag round type="primary" color="rgba(77, 136, 210, 0.1)" text-color="#4D88D2" class="ml-10">{{ key == initData.childInfo.fid ? $t("coll.Father") : key == initData.childInfo.mid ? $t("coll.Mother") : "" }}</van-tag>
                  </div>
                  <div class="color6 font14"><van-icon name="phone-o" />{{ item.phone }}</div>
                </van-radio>
                <!-- <van-radio name="2" class="radioItems" icon-size="17px">
                  <div class="color3 font14">{{ $t("coll.Others") }}</div>
                </van-radio> -->
              </van-radio-group>
              <!-- <div v-if="withdrawal_contact_user == '2'">
                <div class="font12 color6">请填写其他联系人</div>
                <van-field v-model="withdrawal_contact_name" input-align="right" :label="$t('global.name')" :placeholder="$t('global.please_input')" />
                <van-field v-model="withdrawal_contact_phone" input-align="right" type="tel" :label="$t('coll.Mobile')" :placeholder="$t('global.please_input')" />
              </div> -->
            </div>
          </div>
        </div>
        <div class="submitBtn" v-if="$route.name != 'previewWithdrawal'">
          <van-button type="primary" block round color="#4D88D2" @click="submitApply">{{ $t("global.submit") }}</van-button>
        </div>
      </div>

      <van-popup v-model="showDate" round position="bottom">
        <van-datetime-picker v-model="currentDate" type="date" @confirm="dateConfirm" @change="showDateInit" @cancel="showDate = false" />
      </van-popup>
      <van-popup v-model="whereShow" round position="bottom">
        <van-picker :title="$t('coll.choose')" :columns="whereList" @cancel="whereShow = false" @confirm="onConfirmWhere" value-key="title" show-toolbar />
      </van-popup>
      <van-popup v-model="reasonShow" round position="bottom">
        <van-picker :title="$t('coll.choose')" :columns="reasonList" @cancel="reasonShow = false" @confirm="onConfirmReason" value-key="title" show-toolbar />
      </van-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast, Dialog, Tag, RadioGroup, Radio, DatetimePicker, ActionSheet, Popup, Picker } from "vant";
import {} from "vant";
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Tag);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(DatetimePicker);
Vue.use(ActionSheet);
Vue.use(Popup);
Vue.use(Picker);

import { appForm, parentAppFormSave, withdrawalAppForm } from "@/api/withdrawal";
import SwitchLang from "@/components/SwitchLang";

export default {
  name: "PTC",
  components: {
    "switch-lang": SwitchLang,
  },
  data() {
    return {
      appid: "",
      initData: {},
      currentDateCopy: "",
      currentDate: "",
      showDate: false,
      queryShow: false,
      message: "",
      withdrawal_where: "",
      withdrawal_where_memo: "",
      withdrawal_reason: "",
      withdrawal_reason_memo: "",
      withdrawal_unsatisfied: "",
      withdrawal_recommendations: "",
      withdrawal_contact_user: "",
      withdrawal_contact_name: "",
      withdrawal_contact_phone: "",
      reasonShow: false,
      whereShow: false,
      whereList: [],
      reasonList: [],
      withdrawal_where_id: "",
      withdrawal_reason_id: "",
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background-color:#fff;");
  },
  created() {
    this.appid = this.$route.params.id;
    if (this.$route.name == "previewWithdrawal") {
      withdrawalAppForm(this.appid, { token: this.$route.query.token }).then((res) => {
        this.initData = res.data;
        if (this.initData.status == 0) {
          this.reasonList = [];
          this.whereList = [];
          for (let key in res.data.config.whereList) {
            this.whereList.push({ title: res.data.config.whereList[key], id: key });
          }
          for (let key in res.data.config.reasonList) {
            this.reasonList.push({ title: res.data.config.reasonList[key], id: key });
          }
        }
        this.currentDateCopy = this.getFormattedCurrentDate();
      });
    } else {
      this.getInit();
    }
  },
  watch: {},
  methods: {
    getInit() {
      appForm(this.appid).then((res) => {
        this.initData = res.data;
        if (this.initData.status == 0) {
          this.reasonList = [];
          this.whereList = [];
          for (let key in res.data.config.whereList) {
            this.whereList.push({ title: res.data.config.whereList[key], id: key });
          }
          for (let key in res.data.config.reasonList) {
            this.reasonList.push({ title: res.data.config.reasonList[key], id: key });
          }
        }
        this.currentDateCopy = this.getFormattedCurrentDate();
      });
    },
    getFormattedCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      let day = today.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;

      return year + "-" + month + "-" + day;
    },
    showDateEvent() {
      this.showDate = true;
      let date = this.currentDateCopy.split("-");
      this.currentDate = new Date(date[0], date[1] - 1, date[2]);
    },
    dateConfirm(value) {
      this.currentDate = this.timestampToTime(value);
      this.currentDateCopy = this.timestampToTime(value);
      this.showDate = false;
    },
    showDateInit() {
      this.currentDate = this.currentDateCopy;
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return Y + M + D;
    },
    showWhereEvent() {
      this.whereShow = true;
    },
    onConfirmWhere(value) {
      this.withdrawal_where_id = value.id;
      this.withdrawal_where = value.title;
      this.whereShow = false;
    },
    showReasonEvent() {
      this.reasonShow = true;
    },
    onConfirmReason(value) {
      this.withdrawal_reason_id = value.id;
      this.withdrawal_reason = value.title;
      this.reasonShow = false;
    },
    submitApply() {
      if (this.currentDateCopy == "") {
        Toast("请选择退学日期");
        return;
      }
      if (this.withdrawal_where == "") {
        Toast(this.$t("withdrawal.transferring_to"));
        return;
      }
      if (this.withdrawal_reason == "") {
        Toast(this.$t("withdrawal.Reason_withdrawal"));
        return;
      }
      if (this.withdrawal_unsatisfied == "") {
        Toast(this.$t("withdrawal.unsatisfied_with"));
        return;
      }
      if (this.withdrawal_recommendations == "") {
        Toast(this.$t("withdrawal.for_us"));
        return;
      }
      if (this.withdrawal_contact_user == "") {
        Toast(this.$t("withdrawal.Who_contact_withdrawal"));
        return;
      }
      if (this.withdrawal_contact_user == "2" && this.withdrawal_contact_name == "") {
        Toast("请输入联系人姓名");
        return;
      }
      if (this.withdrawal_contact_user == "2" && this.withdrawal_contact_phone == "") {
        Toast("请输入联系人电话");
        return;
      }
      if (this.withdrawal_contact_user != "2") {
        this.withdrawal_contact_name = this.initData.parentInfo[this.withdrawal_contact_user].name;
        this.withdrawal_contact_phone = this.initData.parentInfo[this.withdrawal_contact_user].phone;
      }
      var sendData = {
        app_id: this.appid,
        withdrawal_date: this.currentDateCopy,
        withdrawal_where: this.withdrawal_where_id,
        withdrawal_where_memo: this.withdrawal_where_memo,
        withdrawal_reason: this.withdrawal_reason_id,
        withdrawal_reason_memo: this.withdrawal_reason_memo,
        withdrawal_unsatisfied: this.withdrawal_unsatisfied,
        withdrawal_recommendations: this.withdrawal_recommendations,
        withdrawal_contact_user: this.withdrawal_contact_user,
        withdrawal_contact_name: this.withdrawal_contact_name,
        withdrawal_contact_phone: this.withdrawal_contact_phone,
      };
      parentAppFormSave(sendData).then((res) => {
        if (res.code == 0) {
          Toast(res.msg);
          this.getInit();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.fontBold {
  font-weight: bold;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.pt-24 {
  padding-top: 24px;
}

.important {
  color: #d9534f;
}

.img28 {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
}

.align_items {
  align-items: center;
}

.childDetail {
  align-items: center;
  padding: 16px;
  background: #fff;

  .img44 {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.contact {
  padding: 0 0 0 16px !important;
  margin: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.appForms {
  background: #fff;
  padding: 16px;
  border-top: 16px solid #f7f8fa;
  .requiredIcon {
    align-items: center;
    font-weight: 500;
  }

  .selectCSs {
    padding: 12px 0;
    border-bottom: 1px solid #ebedf0;
    font-size: 14px;
    color: #333;
  }

  .inputCSs {
    border: 1px solid #ebedf0;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
  }

  .radioItems {
    margin-bottom: 12px;
    padding: 12px;
    border: 1px solid #ebedf0;
    border-radius: 8px;
    align-items: start;
  }
}

.submitBtn {
  padding: 5px 16px;
  background: #fff;
}

.applicationInfo {
  padding: 16px 16px 16px 0;
  background: #fff;
  position: relative;
  margin: 16px;

  .lineHeight {
    line-height: 1;
  }

  .dropoutConfirm {
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
    z-index: 99;
  }

  .width16 {
    width: 16px;

    .itemsBg {
      width: 6px;
      height: 16px;
      background: #4d88d2;
      border-radius: 3px;
      display: inline-block;
    }
  }

  .mt-8 {
    margin-top: 8px;
  }

  .memo {
    padding: 12px;
    background: #f7f8fa;
    border-radius: 4px;
    font-size: 13px;
    color: #666;
  }

  .refundInfo {
    height: 86px;
    background: linear-gradient(131deg, #dee8ff 0%, #eff5ff 100%);
    border-radius: 12px;
    margin-top: 24px;
    position: relative;
    display: flex;

    .refund {
      background: #476bf5;
      border-radius: 0px 199px 199px 0px;
      left: 0;
      margin-top: 12px;
      color: #fff;
      font-size: 14px;
      padding: 5px 12px;
      height: 14px;
    }

    .amount {
      font-size: 20px;
      padding-left: 16px;
      padding-top: 8px;
      font-weight: 600;
    }

    img {
      height: 100%;
    }
  }

  .refundItems {
    padding-top: 16px;
  }

  .bto {
    border-bottom: 1px solid #ebedf0;
    padding-bottom: 16px;
  }

  .tips {
    background: #fdf8f1;
    padding: 13px 16px;

    .colorYellow {
      color: #f5a429;
    }

    .icon {
      padding-top: 2px;
    }
  }
}
.titleAmount {
  background: #f7f8fa;
  border-radius: 12px;
  font-size: 14px;
  color: #323233;
  line-height: 20px;
  text-align: center;
  font-style: normal;
  padding: 2px 0;
}
</style>
