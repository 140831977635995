import Vue from 'vue';

export function surveyList () {
  return Vue.axios({
    url: '/api/parentSurvey/list',
    method: 'get'
  })
}

export function getQuestions (params) {
    return Vue.axios({
      url: '/api/parentSurvey/questions',
      method: 'get',
      params: params
    })
}

export function saveQuestions (data) {
    return Vue.axios({
        url: '/api/parentSurvey/save',
        method: 'post',
        data: data
    })
}

export function teacherSaveQuestions (data) {
    return Vue.axios({
        url: '/api/parentSurvey/teacherSave',
        method: 'post',
        data: data
    })
}



