<template>
  <div class="container" :class="stepStatus == 4 && dataDetails.isCommon==1? 'clickNone' : ''">
    <div class="flex" v-if='schoolId=="BJ_DS" || schoolId=="BJ_QFF"'>
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{ $t("pickupcard.car_plates") }}</div>
        <div class="text mt-5">{{ cardData.tips_plates }}</div>
        <div class="fr relative mt-20" @click="addCar">
          <van-icon name="add-o" class="addIcon font22" />
          <span class="addText">{{ $t("pickupcard.add_plate") }}</span>
        </div>
        <div class="clearfix"></div>
        <div class="mt-15">
          <div class="flex" v-for="(list, index) in carTel" :key="index">
            <van-field
              class="bgGray mb-10 flex1"
              v-model="carTelCopy[index]"
              :placeholder="$t('pickupcard.input_plate')"
            />
            <span class="flexRemove">
              <van-icon
                name="delete-o"
                v-if="index > 0"
                @click="delCar(index)"
                class="removeIcon font22"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{ $t("coll.Student_Profile")}}</div>
      </div>
    </div>
    <van-row class="mt-20">
      <van-uploader
        v-model="childAvatar_url"
        @delete="deleteimg"
        :after-read="afterRead"
        name="childAvatar"
        :max-count='1'
      />
    </van-row>
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{ $t("pickupcard.people") }}</div>
        <div class="text mt-5">{{ cardData.tips_pickup }}</div>
      </div>
    </div>
    <div v-for="(list, index) in contact" :key="index">
      <div class="title relative mt-20 mb-15 flex">
        <div>
          <van-icon name="manager" class="color6 absolute flexWidth font18" />
          <span class="ml-10 font15 ml-25 line18 flex1">#{{ index + 1 }}</span>
        </div>
        <div class="flex1" v-if='showClear(index)'>
          <div class="text-right color6 font12" >
            <span @click='clearInfo(index)'> 
              <van-icon name="clear" color="#cccccc" class='mr-5 flexWidth font14' />
              <span class="">{{$t('coll.revoke_information')}}</span>  
            </span> 
          </div>
        </div>
      </div>
      <van-form class="border">
        <van-field name="uploader" :label="$t('pickupcard.photo')">
          <template #input>
            <div class="cropper">
              <div v-if="list.head_url">
                <img :src="list.head_url" class="img" />
                <div class="del" @click.stop="delFile(index)">
                  <van-icon name="cross" class="del-icon" />
                </div>
              </div>
              <div v-else>
                <van-icon name="photograph" class="graph" />
                <h5-cropper
                  :option="option"
                  @getbase64Data="getbase64Data($event, index)"
                />
              </div>
            </div>
          </template>
        </van-field>
        <van-field
          v-model="list.name"
          :name="$t('pickupcard.name')"
          :label="$t('pickupcard.name')"
          :placeholder="$t('pickupcard.name')"
        />
        <van-field
          readonly
          clickable
          v-model="list.relationText"
          :name="$t('pickupcard.relation')"
          :label="$t('pickupcard.relation')"
          :placeholder="$t('pickupcard.relation')"
          @click="relationPicker(index)"
        />
        <van-field
          v-model="list.phone"
          :name="$t('pickupcard.tel')"
          :label="$t('pickupcard.tel')"
          :placeholder="$t('pickupcard.tel')"
          :rules="[{ pattern, message: $t('pickupcard.invalid_phone') }]"
        />
      </van-form>
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :default-index="defaultIndex" 
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <div v-if="dataDetails.protocol.length != 0">
      <protocol-sign
        @click="baseImg"
        :busProtocol="dataDetails.protocol"
        :base64="base64"
      ></protocol-sign>
    </div>
    <div class="mt-20">
      <van-button
        v-if="stepStatus == 4 && dataDetails.isCommon==1"
        disabled
        type="info"
        block
        round
        color="#4d88d2"
        class="saveSub"
        >{{ $t("coll.step_reviewed") }}</van-button
      >
      <a
        href="javascript:;"
        v-else
        @click="submitData()"
        class="saveBtnStep"
        :class="btnDisabled ? 'disabledSubmit' : ''"
        >{{ $t("global.submit") }}</a
      >
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast } from "vant";
import H5Cropper from '@/components/vueCropper.vue';
import ProtocolSign from "@/components/steps/ProtocolSign";

Vue.use(Toast);

export default {
  components: {
    H5Cropper,
    "protocol-sign": ProtocolSign,
  },
  props: {
    dataDetails: {
      type: Object,
    },
    status: {
      type: Number,
    },
    disabled: {
      type: Number,
    },
    schoolId:{
      type: String,
    }
  },
  name: "homeAddress",
  data() {
    return {
      cardData: "",
      carTel: [""],
      contact: [
        {
          name: "",
          relation: "",
          head: "",
          phone: "",
          head_url: "",
          relationText:''
        },
        {
          name: "",
          relation: "",
          head: "",
          phone: "",
          head_url: "",
          relationText:''
          
        },
        {
          name: "",
          relation: "",
          head: "",
          phone: "",
          head_url: "",
          relationText:''
        },
        {
          name: "",
          relation: "",
          head: "",
          phone: "",
          head_url: "",
          relationText:''
        },
      ],
      carTelCopy: [""],
      uptoken: "",
      childid: "",
      isShow: false,
      childrenList: [],
      childIds: [],
      plates: [],
      pickups: [],
      saveCurrent: false,
      option: {
        cancelButtonText: this.$t("global.cancel"),
        confirmButtonText: this.$t("global.ok"),
        info: false, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "png", // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // autoCropWidth: 300, // 默认生成截图框宽度
        // autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMove: false, // 截图能否拖动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      childrenStatus: {},
      loading: true,
      emptyChild: false,
      fileinfo: null,
      pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
      stepStatus: "",
      btnDisabled: false,
      base64: "",
      newSign: false,
      columns:[],
      showPicker:false,
      currentRelation:null,
      defaultIndex:null,
      childAvatar_url:[],
      childAvatar:[]
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
  },
  created() {
    this.stepStatus = this.status;
    this.cardData = this.dataDetails;
    if(this.cardData.child_avatar_url!=''){
        this.childAvatar_url=[{url: this.cardData.child_avatar_url, isImage: true}]
        this.childAvatar=[this.cardData.child_avatar]
    }
    if (this.cardData.plates.length > 0) {
      this.carTel = this.cardData.plates;
    } else {
      this.carTel = [""];
    }
    for(var key in this.cardData.relationList){
      this.columns.push({
        value:key,
        text:this.cardData.relationList[key]
      })
    }
    this.carTelCopy = this.carTel;
    if (this.cardData.pickups.length != 0) {
      this.contact.map((item, index) => {
        if( this.cardData.pickups[index]){
          this.contact[index] = this.cardData.pickups[index];
          if(this.contact[index].relation!=''){
            this.contact[index].relationText=this.cardData.relationList[this.contact[index].relation]
          }
        }
      });
    }
    if (this.dataDetails.sign_url != "") {
      this.base64 = this.dataDetails.sign_url;
    }
  },
  methods: {
    showClear(index){
      for(var key in this.contact[index]){
        if(this.contact[index][key]!='' && this.contact[index][key]!=null && this.contact[index][key]!=undefined){
          return true
        }
      }
    },
    clearInfo(index){
      for(var key in this.contact[index]){
        this.contact[index][key]=''
      }
    },
    afterRead(file, detail) {
      this.type = detail.name;
      if (file.length > 1) {
        for (let i in file) {
          this.upimg(file[i].content);
        }
      } else {
        this.upimg(file.content);
      }
    },
    upimg(data) {
      const that = this;
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: that.$t("pickupcard.uploading"),
      });
      var pic = data.substring(data.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            that.childAvatar.push(resJson.data.key);
            Toast(that.$t("pickupcard.Uploaded"));
          } else {
            Toast(resJson.msg);
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.dataDetails.qiniuToken);
      xhr.send(pic);
    },
    deleteimg(file, detail) {
      this.childAvatar.splice(detail.index - 1, 1);
    },
    relationPicker(index){
      this.defaultIndex=this.pickerIndex(this.contact[index].relation)
      this.currentRelation=index
      this.showPicker = true
    },
    pickerIndex(value){
      let cIndex = this.columns.indexOf(this.columns.find(item => item.value == value))
      return cIndex
    },
    onConfirm(val){
      Vue.set(this.contact[this.currentRelation], "relationText", val.text);
      Vue.set(this.contact[this.currentRelation], "relation", val.value);
      this.showPicker=false
    },
    addCar() {
      if (this.carTel.length < 3) {
        this.carTel.push("");
        this.carTelCopy = this.carTel;
      } else {
        Toast(this.$t("pickupcard.max_number"));
      }
    },
    delCar(index) {
      if (this.carTel.length == 1) {
        return;
      }
      this.carTel.splice(index, 1);
    },
    getbase64Data(data, i) {
      this.uploadToQiniu(data, i);
    },
    uploadToQiniu(data, i) {
      this.uptoken = this.cardData.qiniuToken;
      this.putb64(data, i);
    },
    putb64(data, i) {
      const that = this;
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: that.$t("pickupcard.uploading"),
      });
      var pic = data.substring(data.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // toast.clear()
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that.contact[i], "head", resJson.data.key);
            Vue.set(that.contact[i], "head_url", resJson.data.url);
            Toast(that.$t("pickupcard.Uploaded"));
          } else {
            Toast(resJson.msg);
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + this.uptoken);
      xhr.send(pic);
    },
    delFile(i) {
      this.contact[i].head = "";
      this.contact[i].head_url = "";
    },
    paramsValidate(params) {
      let flag = true;
      for (var key in params) {
        if (params[key].trim() == "" || params[key] == null) {
          return false;
        }
      }
      return flag;
    },
    phoneValidate(params) {
      if (this.pattern.test(params)) {
        return true;
      } else {
        return false;
      }
    },
    baseImg(data) {
      if (data != "cancel") {
        this.base64 = data;
        this.newSign = true;
      }
    },
    submitData() {
      let that = this;
      if (this.btnDisabled) {
        return;
      }
      if (that.dataDetails.protocol.length != 0 && this.base64 == "") {
        Toast(that.$t("coll.please_sign"));
        return;
      }
      this.$nextTick(function () {
        this.btnDisabled = true
      })
      if (that.dataDetails.protocol.length == 0) {
        that.saveData();
        return;
      }
      if (!that.newSign) {
        that.saveData();
        return;
      }
      var pic = this.base64.substring(this.base64.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // toast.clear()
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that.cardData, "sign_url", resJson.data.url);
            Vue.set(that.cardData, "sign", resJson.data.key);
            that.saveData();
          } else {
            Toast(resJson.msg);
            that.btnDisabled = false;
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader(
        "Authorization",
        "UpToken " + that.dataDetails.qiniuToken
      );
      xhr.send(pic);
    },
    saveData() {
        if(this.childAvatar.length==0){
            Toast(this.$t("global.please_upload")+this.$t("coll.Student_Profile"));
            this.$nextTick(function () {
                 this.btnDisabled = false
            })
            return;
        }
        this.plates = [];
      if (this.carTel.length != 0) {
        for (var k = 0; k < this.carTel.length; k++) {
          if (this.carTel[k] != "") {
            this.plates.push(this.carTel[k]);
          }
        }
      }
      this.pickups = [];
      var listData = [];
      this.pickups = JSON.parse(JSON.stringify(this.contact));
      for (var i = 0; i < this.pickups.length; i++) {
        delete this.pickups[i].head_url;
        if (this.pickups[i].head == null) {
          this.pickups[i].head = "";
        }
        if (
          this.pickups[i].name != "" ||
          (this.pickups[i].relation != "" && this.pickups[i].relation !=null)  ||
          this.pickups[i].phone != ""
        ) {
          listData.push(this.pickups[i]);
        }
      }
      if (listData.length == 0) {
        Toast(this.$t("pickupcard.needed"));
         this.$nextTick(function () {
          this.btnDisabled = false
        })
        return;
      }
      for (var j = 0; j < listData.length; j++) {
        if (!this.paramsValidate(listData[j])) {
          Toast(this.$t("pickupcard.information"));
          this.$nextTick(function () {
          this.btnDisabled = false
        })
          return;
        }
        if (!this.phoneValidate(listData[j].phone)) {
          Toast(this.$t("pickupcard.invalid_phone"));
          this.$nextTick(function () {
          this.btnDisabled = false
        })
          return;
        }
      }
      let dats = {
        plates: this.plates,
        pickups: this.pickups,
        sign: this.cardData.sign,
        sign_url: this.cardData.sign_url,
        child_avatar:this.childAvatar[0]
      };
      this.$emit("change", dats);
    },
  },
};
</script>

<style lang="less" scoped>
.text-right{
  text-align: right;
}
.cropper {
  width: 80px;
  height: 80px;
  line-height: 80px;
  position: relative;
  overflow: hidden;
  text-align: center;
  background-color: #f7f8fa;
  .img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .del {
    position: absolute;
    top: 0;
    right: 0;
    width: 14px;
    height: 14px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0 0 0 12px;
    .del-icon {
      position: absolute;
      top: -2px;
      right: -2px;
      color: #fff;
      font-size: 16px;
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
  }
  .graph {
    position: absolute;
    font-size: 24px;
    color: #dcdee0;
    top: 28px;
    left: 28px;
  }
}
.relative {
  position: relative;
}
.container {
  background-color: #fff;
  padding: 25px 0 0px;
  font-size: 12px;
  .flex {
    display: flex;
    .flexWidth {
      width: 16px;
      padding-top: 5px;
      .icon {
        display: inline-block;
        width: 6px;
        height: 14px;
        background: #4d88d2;
        border-radius: 3px;
      }
    }
    .flex1 {
      flex: 1;
      .text {
        font-size: 12px;
        font-weight: 400;
        color: #646566;
        line-height: 20px;
      }
    }
    .flexRemove {
      width: 30px;
      .removeIcon {
        margin-top: 10px;
        float: right;
      }
    }
  }
  .addIcon {
    color: #4d88d2;
    position: absolute;
    left: -18px;
  }
  .addText {
    font-size: 14px;
    font-weight: 400;
    color: #646566;
    line-height: 22px;
    margin-left: 8px;
  }
  .title {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 26px;
    .font15 {
      font-size: 15px;
    }
    .absolute {
      position: absolute;
    }
    .color6 {
      color: #666 !important;
    }
  }
  .border {
    border: 1px solid #ebedf0;
    border-radius: 0px;
  }
}
</style>