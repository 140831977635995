<template>
    <div :class="stepStatus == 4 && dataDetails.isCommon == 1 ? 'clickNone' : ''">
        <div class="color6 font14 mt-20">{{ $t("coll.trip_Dietary") }}</div>
        <van-radio-group v-model="step_data.dietet">
            <van-radio class="radioList" name="none" icon-size="16px">{{ $t("coll.None") }}</van-radio>
            <van-radio class="radioList" name="vegetarian_diet" icon-size="16px">{{ $t("coll.vegetarian_diet")
            }}</van-radio>
            <van-radio class="radioList" name="no_pork" icon-size="16px">{{ $t("coll.no_pork") }}</van-radio>
            <van-radio class="radioList" name="food_allergy" icon-size="16px">{{ $t("coll.food_allergy") }}</van-radio>
        </van-radio-group>
        <van-field v-model="step_data.dietet_desc" clearable rows="1" type="textarea" autosize
            :placeholder="$t('coll.Please_specify')" class="bgGray mt-10 max90" />
        <div class="color6 font14 mt-20">{{ $t("coll.restrictions_details") }}</div>
        <van-checkbox-group v-model="step_data.warning">
            <van-checkbox class="radioList" icon-size="16px" name="none" shape="square">{{ $t("coll.None") }}</van-checkbox>
            <van-checkbox class="radioList" icon-size="16px" name="Asthma" shape="square">{{ $t("coll.Asthma")
            }}</van-checkbox>
            <van-checkbox class="radioList" icon-size="16px" name="heart_Disease" shape="square">{{ $t("coll.heart_Disease")
            }}</van-checkbox>
            <van-checkbox class="radioList" icon-size="16px" name="Epilepsy" shape="square">{{ $t("coll.Epilepsy")
            }}</van-checkbox>
            <van-checkbox class="radioList" icon-size="16px" name="Sleepwalking" shape="square">{{ $t("coll.Sleepwalking")
            }}</van-checkbox>
            <van-checkbox class="radioList" icon-size="16px" name="Others" shape="square">{{ $t("coll.Others")
            }}</van-checkbox>
        </van-checkbox-group>
        <van-field v-model="step_data.warning_specify" clearable :placeholder="$t('coll.Please_specify')"
            class="bgGray mt-10" />
        <div class="color6 font14 mt-20">{{ $t("coll.bring_medication") }}</div>
        <van-field v-model="medicine" is-link readonly clearable class="bgGray mt-10" :placeholder="$t('coll.choose')"
            @click="showMedication()" />
        <div v-if="step_data.medicine == 1">
            <div class="color6 font14 mt-20">{{ $t("coll.specify_medication") }}</div>
            <div v-if="medicationData.length > 0">
                <div class="medicationList" v-for="(list, index) in medicationData" :key="index">
                    <span class="close" v-if="index > 0" @click="delList(index)"><van-icon name="cross" /></span>
                    <div class="color6 font14">{{ $t("coll.medication_name") }}</div>
                    <van-field v-model="list.name" :placeholder="$t('global.please_input')" class="bgGray mt-10" />
                    <div class="color6 font14 mt-20">{{ $t("coll.administration") }}</div>
                    <van-field v-model="list.dose" :placeholder="$t('global.please_input')" class="bgGray mt-10" />
                    <div class="color6 font14 mt-20">{{ $t("coll.Time_per_day") }}</div>
                    <van-field v-model="list.use_time" :placeholder="$t('global.please_input')" class="bgGray mt-10" />
                    <div class="color6 font14 mt-20">{{ $t("coll.duration_treatment") }}</div>
                    <div class="flex">
                        <div class="flex1">
                            <div class="color9 font12 mt-10">{{ $t("coll.From") }}</div>
                            <van-field v-model="list.use_days[0]" is-link readonly class="bgGray mt-10"
                                :placeholder="$t('coll.choose')" @click="showDateEvent(0, index)" />
                        </div>
                        <div class="flex1 ml-10">
                            <div class="color9 font12 mt-10">{{ $t("coll.To") }}</div>
                            <van-field v-model="list.use_days[1]" is-link readonly class="bgGray mt-10"
                                :placeholder="$t('coll.choose')" @click="showDateEvent(1, index)" />
                        </div>
                    </div>
                    <!-- <van-field v-model="list.use_days" :placeholder="$t('global.please_input')" class="bgGray mt-10" /> -->
                    <div class="color6 font14 mt-20">{{ $t("coll.specify_needed") }}</div>
                    <van-field v-model="list.medical_special" :placeholder="$t('global.please_input')"
                        class="bgGray mt-10" />
                    <div class="color6 font14 mt-20 mb-10">{{ $t("coll.Is_prescription") }}</div>
                    <van-radio-group v-model="list.Rx" direction="horizontal">
                        <van-radio name="1" icon-size="16px">{{ $t('coll.yes') }}</van-radio>
                        <van-radio name="0" icon-size="16px" class="ml-20">{{ $t('coll.no') }}</van-radio>
                    </van-radio-group>
                    <div v-if="list.Rx == 1">
                        <div class="color6 font14 mt-20">{{ $t("coll.upload_prescription") }}</div>
                        <van-uploader class="mt-10" :before-read="beforeRead" v-model="list.Rx_photo_url"
                            @delete="deleteimg" :after-read="afterRead" :name="index" />
                    </div>
                </div>
            </div>
            <div class="font14 blue" @click="addPlus"><van-icon name="plus" />{{ $t("coll.Add_medication") }}</div>
        </div>
        <div class="color6 font14 mt-20">{{ $t("coll.student_insurance") }}</div>
        <van-field v-model="medical_insurance" is-link readonly class="bgGray mt-10" :placeholder="$t('coll.choose')"
            @click="showInsurance()" />
        <div v-if="step_data.medical_insurance == 1">
            <div class="color6 font14 mt-20">{{ $t("coll.insurance_type") }}</div>
            <!-- <van-field v-model="medical_type" is-link readonly class="bgGray mt-10" :placeholder="$t('coll.choose')"
                @click="showInsuranceType()" /> -->
            <van-radio-group v-model="step_data.medical_type">
                <van-radio class="radioList" name="Accidental" icon-size="16px">{{ $t("coll.accidental_insurance")
                }}</van-radio>
                <van-radio class="radioList" name="High-End" icon-size="16px">{{ $t("coll.High-End_insurance")
                }}</van-radio>
                <van-radio class="radioList" name="Other" icon-size="16px">{{ $t("coll.Other_insurance")
                }}</van-radio>
            </van-radio-group>
            <div class="color6 font14 mt-20">{{ $t("coll.insurance_provider") }}</div>
            <van-field v-model="step_data.medical_provider" clearable :placeholder="$t('global.please_input')"
                class="bgGray mt-10" />
            <div class="color6 font14 mt-20">{{ $t("coll.insurance_number") }}</div>
            <van-field v-model="step_data.medical_insurance_number" clearable :placeholder="$t('global.please_input')"
                class="bgGray mt-10" />
            <div class="color6 font14 mt-20">{{ $t("coll.insurance_expiry_date") }}</div>
            <van-field v-model="step_data.medical_insurance_validity" clearable :placeholder="$t('global.please_input')"
                class="bgGray mt-10" />
            <div class="color6 font14 mt-20">{{ $t("coll.upload_insurance_card") }}</div>
            <van-uploader class="mt-10" :before-read="beforeRead" v-model="medical_insurance_photoList" @delete="deleteimg"
                :after-read="afterRead" name="medical_insurance_photo" />
        </div>
        <van-popup v-model="showType" round position="bottom">
            <van-picker :title="$t('coll.choose')" :columns="showColumns" @cancel="showType = false"
                @confirm="onConfirmShow" value-key="title" ref="picker2" id="picker2" show-toolbar />
        </van-popup>
        <div v-if="dataDetails.protocol.length != 0">
            <protocol-sign @click="baseImg" :busProtocol="dataDetails.protocol" :base64="base64"></protocol-sign>
        </div>
        <div class="mt-20">
            <van-button v-if="stepStatus == 4 && dataDetails.isCommon == 1" disabled type="info" block round color="#4d88d2"
                class="saveSub">{{ $t("coll.step_reviewed") }}</van-button>
            <a href="javascript:;" v-else @click="submitData()" class="saveBtnStep"
                :class="btnDisabled ? 'disabledSubmit' : ''">{{ $t("global.submit") }}</a>
        </div>
        <van-popup v-model="insuranceshowType" round position="bottom">
            <van-picker :title="$t('coll.choose')" :columns="showColumns" @cancel="insuranceshowType = false"
                @confirm="onConfirmInsuranceShow" value-key="title" ref="picker1" id="picker1" show-toolbar />
        </van-popup>
        <van-popup v-model="insuranceType" round position="bottom">
            <van-picker :title="$t('coll.choose')" :columns="insuranceColumns" @cancel="insuranceType = false"
                @confirm="onConfirmInsurance" value-key="title" ref="picker3" id="picker3" show-toolbar />
        </van-popup>
        <van-popup v-model="showDate" round position="bottom">
            <van-datetime-picker v-model="currentDate" type="date" @confirm="dateConfirm" :min-date="minDate" />
        </van-popup>
    </div>
</template>
  
<script>
import ProtocolSign from "@/components/steps/ProtocolSign";
import Vue from "vue";
import { Toast } from "vant";
import Compressor from 'compressorjs';

export default {
    props: {
        dataDetails: {
            type: Object,
        },
        status: {
            type: Number,
        },
        disabled: {
            type: Number,
        },
    },
    components: {
        "protocol-sign": ProtocolSign,
    },
    name: "homeAddress",
    data() {
        return {
            qiniuToken: "",
            step_data: "",
            stepStatus: "",
            showType: false,
            showColumns: [{ id: 1, title: this.$t('coll.yes') }, { id: 0, title: this.$t('coll.no') }],
            value: "",
            type: "",
            index: "",
            medicine: '',//是否携带药物
            btnDisabled: false,
            base64: "",
            newSign: false,
            identity_photoList: [],
            medical_insurance_photoList: [],
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            addData: {
                "name": "",
                "dose": "",
                "use_time": "",
                "use_days": ['', ''],
                "Rx": "",
                "Rx_photo": [],
                'Rx_photo_url': [],
                'medical_special': ''
            },
            medicationData: [],
            insuranceType: false,
            insuranceshowType: false,
            identity_type: "",//证件类型
            medical_insurance: '',//是否有保险
            medical_type: '',//保险类型
            insuranceColumns: [{ id: 'Accidental', title: this.$t('coll.accidental_insurance') }, { id: 'High-End', title: this.$t('coll.High-End_insurance') }, { id: 'Other', title: this.$t('coll.Other_insurance') }],
            showDate: false,
            currentDate: '',
            listType: '',
            listIndex: '',
            minDate:new Date(2023, 11, 1),
        };
    },
    watch: {
        disabled() {
            this.btnDisabled = false;
        },
    },
    created() {
        this.qiniuToken = this.dataDetails.qiniuToken;
        this.stepStatus = this.status;
        this.step_data = this.dataDetails.step_data;
        if (this.step_data.medicine !== '') {
            this.medicine = this.showColumns[this.lanIndex(this.step_data.medicine, 'showColumns')].title
        }
        if (this.step_data.medicine_specify.length != 0) {
            for (var i = 0; i < this.step_data.medicine_specify.length; i++) {
                if (this.step_data.medicine_specify[i].Rx_photo_url) {
                    this.step_data.medicine_specify[i].Rx_photo_url = this.tojson(this.step_data.medicine_specify[i].Rx_photo_url);
                }
            }
            this.medicationData = this.step_data.medicine_specify
        }else{
            this.medicationData={
                "name": "",
                "dose": "",
                "use_time": "",
                "use_days": ['', ''],
                "Rx": "",
                "Rx_photo": [],
                'Rx_photo_url': [],
                'medical_special': ''
            }
        }
        if (this.step_data.medicine != '') {
            this.medicine = this.showColumns[this.lanIndex(this.step_data.medicine, 'showColumns')].title;
        }
        if (this.step_data.medical_insurance_photo.length != 0) {
            this.medical_insurance_photoList = this.tojson(this.step_data.medical_insurance_photo_url);
        }
        if (this.step_data.medical_insurance.length != 0) {
            this.medical_insurance = this.showColumns[this.lanIndex(this.step_data.medical_insurance, 'showColumns')].title;
        }
        if (this.step_data.medical_type != '') {
            this.medical_type = this.insuranceColumns[this.lanIndex(this.step_data.medical_type, 'insuranceColumns')].title;
        }
        if (this.dataDetails.sign_url != "") {
            this.base64 = this.dataDetails.sign_url;
        }
    },
    methods: {
        showDateEvent(type, index) {
            this.listType = type
            this.listIndex = index
            this.showDate = true;
            let date = this.medicationData[index].use_days[type].split('-')
            this.currentDate = new Date(date[0], date[1] - 1, date[2])
        },
        dateConfirm(value) {
            this.medicationData[this.listIndex].use_days[this.listType] = this.timestampToTime(value)
            this.showDate = false
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
            return Y + M + D
        },
        showInsurance() {
            this.insuranceshowType = true;
            let that = this;
            this.$nextTick(() => {
                that.index = this.lanIndex(that.step_data.medical_insurance, 'showColumns');
                that.$refs.picker1.setIndexes([that.index]);
            });
        },
        onConfirmInsuranceShow(value) {
            this.step_data.medical_insurance = value.id;
            this.medical_insurance = value.title;
            this.insuranceshowType = false;
        },
        showInsuranceType() {
            this.insuranceType = true;
            let that = this;
            this.$nextTick(() => {
                that.index = this.lanIndex(that.step_data.medical_type, 'insuranceColumns');
                that.$refs.picker3.setIndexes([that.index]);
            });
        },
        onConfirmInsurance(value) {
            this.step_data.medical_type = value.id;
            this.medical_type = value.title;
            this.insuranceType = false;
        },
        addPlus() {
            this.addData = {
                "name": "",
                "dose": "",
                "use_time": "",
                "use_days": ['', ''],
                "Rx": "",
                "Rx_photo": [],
                'Rx_photo_url': [],
                'medical_special': ''
            }
            this.medicationData.push(this.addData)
        },
        showMedication() {
            this.showType = true;
            let that = this;
            this.$nextTick(() => {
                that.index = this.lanIndex(that.step_data.medicine, 'showColumns');
                that.$refs.picker2.setIndexes([that.index]);
            });
        },
        lanIndex(id, type) {
            for (var i = 0; i < this[type].length; i++) {
                if (this[type][i].id == id) {
                    return i;
                }
            }
        },
        onConfirmShow(value) {
            this.step_data.medicine = value.id;
            this.medicine = value.title;
            this.showType = false;
            if (this.step_data.medicine_specify.length == 0 && this.step_data.medicine == 1) {
                this.medicationData = []
                this.addData = {
                    "name": "",
                    "dose": "",
                    "use_time": "",
                    "use_days": ['', ''],
                    "Rx": "",
                    "Rx_photo": [],
                    'Rx_photo_url': [],
                    'medical_special': ''
                }
                this.medicationData.push(this.addData)
            }
        },
        tojson(rows) {
            var ids = []
            for (var i = 0, len = rows.length; i < len; i++) {
                var signAgainReq = new Object();
                signAgainReq.url = rows[i];
                ids.push(signAgainReq);
            }
            return ids
        },
        beforeRead(file) {
            return new Promise((resolve) => {
                new Compressor(file, {
                    maxWidth: 1200,
                    quality: 0.6,
                    success: resolve,
                    error(err) {
                        console.log(err.message);
                    },
                });
            });
        },
        afterRead(file, detail) {
            this.type = detail.name;
            if (file.length > 1) {
                for (let i in file) {
                    this.upimg(file[i].content);
                }
            } else {
                this.upimg(file.content);
            }
        },
        upimg(data) {
            const that = this;
            Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: that.$t("pickupcard.uploading"),
            });
            var pic = data.substring(data.indexOf(",") + 1);
            var url = "https://upload-z1.qiniup.com/putb64/-1";
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    var resJson = JSON.parse(xhr.responseText);
                    if (resJson.code == 0) {
                        if (that.type == 'medical_insurance_photo') {
                            that.step_data[that.type].push(resJson.data.key);
                        } else {
                            that.medicationData[that.type].Rx_photo.push(resJson.data.key);
                        }
                        Toast(that.$t("pickupcard.Uploaded"));
                    } else {
                        Toast(resJson.msg);
                    }
                }
            };
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/octet-stream");
            xhr.setRequestHeader("Authorization", "UpToken " + that.qiniuToken);
            xhr.send(pic);
        },
        //删除图片
        deleteimg(file, detail) {
            if (detail.name == 'medical_insurance_photo') {
                this.step_data[detail.name].splice(detail.index, 1);
            } else {
                this.medicationData[detail.name].Rx_photo.splice(detail.index, 1);
            }
        },
        delList(index) {
            this.medicationData.splice(index, 1);
        },
        baseImg(data) {
            if (data != "cancel") {
                this.base64 = data;
                this.newSign = true;
            }
        },
        phoneValidate(params) {
            if (this.pattern.test(params)) {
                return true;
            } else {
                return false;
            }
        },
        submitData() {
            let that = this;
            if (this.btnDisabled) {
                return;
            }
            if (this.step_data.dietet == '') {
                Toast(this.$t('global.please_choose') +' '+ this.$t("coll.trip_Dietary"));
                return;
            }
            if (this.step_data.warning.length == 0) {
                Toast(this.$t('global.please_choose')+' '+this.$t("coll.restrictions_details"));
                return;
            }
            if (this.step_data.medicine.length == 0) {
                Toast(this.$t('global.please_choose') +' '+ this.$t("coll.bring_medication"));
                return;
            }
            if (this.medicationData.length != 0 && this.step_data.medicine == 1) {
                for (var i = 0; i < this.medicationData.length; i++) {
                    delete this.medicationData[i].Rx_photo_url
                    if(this.medicationData[i].name==''){
                        Toast(this.$t("global.please_input")+' '+this.$t("coll.medication_name"));
                        return
                    }
                    if(this.medicationData[i].dose==''){
                        Toast(this.$t("global.please_input")+' '+this.$t("coll.administration"));
                        return
                    }
                    if(this.medicationData[i].use_time==''){
                        Toast(this.$t("global.please_input")+' '+this.$t("coll.Time_per_day"));
                        return
                    }
                    if(this.medicationData[i].use_days[0]==''){
                        Toast(this.$t("global.please_choose")+' '+this.$t("coll.duration_treatment"));
                        return
                    }if(this.medicationData[i].use_days[1]==''){
                        Toast(this.$t("global.please_choose")+' '+this.$t("coll.duration_treatment"));
                        return
                    }
                    if(this.medicationData[i].Rx==''){
                        Toast(this.$t("global.please_choose")+' '+this.$t("coll.Is_prescription"));
                        return
                    }
                    if(this.medicationData[i].Rx==1 && this.medicationData[i].Rx_photo.length==0){
                        Toast(this.$t("coll.upload_prescription"));
                        return
                    }
                }
            }
            if(this.medical_insurance==''){
                Toast(this.$t("global.please_choose")+' '+this.$t("coll.student_insurance"));
                return
            }
            if(this.step_data.medical_insurance==1){
                if(this.step_data.medical_type==''){
                    Toast(this.$t("global.please_choose")+' '+this.$t("coll.insurance_type"));
                    return
                }
                if(this.step_data.medical_provider==''){
                    Toast(this.$t("global.please_input")+' '+this.$t("coll.insurance_provider"));
                    return
                }
                if(this.step_data.medical_insurance_number==''){
                    Toast(this.$t("global.please_input")+' '+this.$t("coll.insurance_number"));
                    return
                }
                if(this.step_data.medical_insurance_validity==''){
                    Toast(this.$t("global.please_input")+' '+this.$t("coll.insurance_expiry_date"));
                    return
                }
                if(this.medical_insurance_photoList.length==0){
                    Toast(this.$t("global.please_upload")+' '+this.$t("coll.upload_insurance_card"));
                    return
                }
            }
            console.log(this.medicationData)
            this.step_data.medicine_specify = this.medicationData
            this.$nextTick(function () {
                this.btnDisabled = true
            })
            if (that.dataDetails.protocol.length == 0) {
                that.saveData();
                return;
            }
            if (!that.newSign) {
                that.saveData();
                return;
            }
            var pic = this.base64.substring(this.base64.indexOf(",") + 1);
            var url = "https://upload-z1.qiniup.com/putb64/-1";
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                // toast.clear()
                if (xhr.readyState == 4) {
                    var resJson = JSON.parse(xhr.responseText);
                    if (resJson.code == 0) {
                        Vue.set(that.step_data, "sign_url", resJson.data.url);
                        Vue.set(that.step_data, "sign", resJson.data.key);
                        that.saveData();
                    } else {
                        Toast(resJson.msg);
                        that.btnDisabled = false;
                    }
                }
            };
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/octet-stream");
            xhr.setRequestHeader("Authorization", "UpToken " + that.dataDetails.signQiniuToken);
            xhr.send(pic);
        },
        saveData() {
            this.$emit("change", this.step_data);
        },
    },
};
</script>
  
<style lang="less" scoped>
.radioList {
    margin-top: 10px;
}

.blue {
    color: #4D88D2;
    margin-top: 16px;
}

.medicationList {
    border-radius: 4px;
    border: 1px solid #EBEDF0;
    padding: 12px 16px;
    margin-top: 12px;
    position: relative;

    .close {
        position: absolute;
        right: 0;
        top: 0;
        width: 18px;
        height: 16px;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 0 0 0 12px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        line-height: 16px;

    }
}

.max90 {
    max-height: 90px;
    overflow-y: auto;
}
</style>