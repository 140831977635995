<template>
  <div>
    <div class="bg-1">
      <p>{{ $t('subscribe.info_1', {s: accounts[current].title}) }}</p>
    </div>
    <div class="bg-2">
      <div class="block-1">
        <div class="title">{{ $t('subscribe.info_2') }}</div>
        <div class="desc">{{ $t('subscribe.info_3', {s: accounts[current].title}) }}</div>
        <div class="text-center">
          <img :src="accounts[current].qrcode" alt="" class="qrcode">
          <div class="tips">{{ $t('subscribe.info_4') }}</div>
        </div>
      </div>
      <div class="block-1">
        <div class="title">{{ $t('subscribe.info_5') }}</div>
        <div class="desc">{{ $t('subscribe.info_6', {s: accounts[current].title}) }}</div>
        <div>
          <img :src="accounts[current].image" alt="" class="image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store'
import logger from '@/plugins/logger'

export default {
  name: "Subscribe",
  data() {
    return {
      current: '',
      accounts: {
        ds: {
          title: '启明星学校家长服务',
          qrcode: 'http://m2.files.ivykids.cn/cloud01-file-8025768Fv7pWhEc3QJesq0lptTJFWh58veL.png',
          image: 'http://m2.files.ivykids.cn/cloud01-file-8025768FibtSqG9OquadM3rRkQpo5oRbmar.png'
        },
        ivy: {
          title: '艾毅幼儿园家长服务',
          qrcode: 'http://m2.files.ivykids.cn/cloud01-file-5FsARshO2fm3-L28sddDViUr149qe.jpeg',
          image: 'http://m2.files.ivykids.cn/cloud01-file-5FqCNtwhxgXDbO0PKrRxKexq7cPCq.png'
        }
      }
    }
  },
  created() {
    this.current = store.getters.wxState || 'ds'

    logger.send({
      category: 'subscribe'
    })
  }
}
</script>

<style lang="less" scoped>
.bg-1 {
  background-image:url('../assets/head_bg@2x.png');
  background-size: cover;
  width: 375px;
  // height: 142px;
  p {
    margin-top: 0;
    margin-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 22px;
    &:first-child {
        padding-top: 34px;
        padding-bottom: 50px
    }
  }
}
.bg-2 {
    background: #FFFFFF;
    border-radius: 8px;
    margin: -28px 16px 16px 16px;
    padding: 16px;
    .block-1 {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
        .title {
          font-size: 16px;
          font-weight: 500;
          color: #323233;
          line-height: 22px;
          margin-bottom: 8px;
        }
        .desc {
          font-size: 13px;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
        }
        .tips {
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          line-height: 17px;
        }
        .qrcode {
          width: 168px;
        }
        .image {
          width: 100%;
          margin-top: 15px;
        }
    }
}
</style>

