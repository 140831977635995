<template>
  <div>
    <van-button :color="color" size="mini" @click="switchLang">{{ btnText }}</van-button>
  </div>
</template>

<script>
import Vue from 'vue';
import { Button } from 'vant';
import store from '../store'
import storage from 'store'
import i18n from '../i18n'
import { setDocumentTitle } from '@/plugins/domUtil'
import { APP_LANGUAGE } from '@/store/mutation-types'

Vue.use(Button);

export default {
  name: 'SwitchLang',
  inject: ['reload'],
  props: {
    color: {
      type: String,
      default: () => {
        return '#4D88D2'
      }
    }
  },
  data() {
    return {
      btnText: ''
    }
  },
  created () {
    this.showBtnText()
  },
  methods: {
    switchLang() {
      let cuLang = storage.get(APP_LANGUAGE) || 'zh'
      i18n.locale = cuLang == 'zh' ? 'en' : 'zh'
      this.showBtnText()
      store.dispatch('setLang', i18n.locale)
      this.$route.meta && (typeof this.$route.meta.title !== 'undefined' && setDocumentTitle(i18n.t('router.'+this.$route.meta.title)))
      this.reload()
    },
    showBtnText() {
      this.btnText = i18n.locale == 'zh' ? 'For English Version' : '中文版本'
    }
  }
}
</script>
