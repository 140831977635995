<template>
  <div>
    <div class="searchList">
      <van-sticky class="searchInput">
        <van-search v-model="search" show-action shape="round" :placeholder="$t('notice.Input_search')" left-icon="">
          <template #label>
            <div @click="showAllTeacher = true" class="flex">
              <span v-if="teacherid == ''" class="mr-10"> {{ $t("global.All") }} </span>
              <van-image v-else-if="teacherList[teacherid]" round :src="teacherList[teacherid].photoUrl" class="avatar-1 mr-10 avatarImg" />
              <van-icon name="arrow-down" />
            </div>
          </template>
          <template #action>
            <div @click="showNotice()">{{ $t("global.cancel") }}</div>
          </template>
        </van-search>
      </van-sticky>
      <div class="searchHistory" v-if="teacherid == '' && search == ''">
        <div class="searchText">
          <van-row type="flex" justify="space-between">
            <van-col span="10"
              ><span class="font16">{{ $t("notice.Search_History") }}</span>
            </van-col>
            <van-col span="1" v-if="noticeHistory.length > 0"><van-icon name="delete-o" size="18" @click="clearHistory" /></van-col>
          </van-row>
        </div>
        <van-list v-if="noticeHistory.length > 0">
          <van-cell v-for="(item, index) in noticeHistory" :key="index" :title="item" @click="history(item)" />
        </van-list>
        <van-list v-else>
          <div class="text-center p16 color9 font14">{{ $t("notice.No_history") }}</div>
        </van-list>
      </div>
      <div v-else class="notice mt-10">
        <div class="expiredCheckbox">
          <van-checkbox v-model="expired" shape="square" icon-size="14px" @click="searchTextData('init')"
            ><span slot="default" class="color3 font14">{{ $t("notice.expired_notice") }}</span></van-checkbox
          >
        </div>
        <van-loading size="24" class="text-center" v-if="searchLoading" />
        <div v-if="searchNoticeData.length > 0">
          <van-list v-model="searchListLoading" :finished="searchFinished" finished-text="" @load="searchOnLoad">
            <div v-for="(item, i) in searchNoticeData" :key="i">
              <router-link class="item-1" :to="$route.name == 'PreviewNotice' ? `/preview/notice/${item.id}` : `/notice/${item.id}`">
                <img v-if="item.expired == 1" :src="expiredImg" class="Expired" alt="Expired" />
                <van-row class="item-1-1">
                  <div class="on-top" :class="$t('notice.on_top_icon_class')" v-if="item.on_top"></div>
                  <div class="level-alert" :class="`level-${item.level}`" v-if="item.level">{{ item.level_text }}</div>
                  <van-col span="24">
                    <div class="name mb-5">{{ item.title }}</div>
                    <div class="sub-name mb-5">{{ item.grade_group_text.join(" | ") }}</div>
                    <van-col span="24" class="mb-10" v-if="item.category != 1 && item.category != 0"
                      ><van-tag type="primary" class="categoryTag" color="#E7F4FF" text-color="#4D88D2">{{ showCategory(item.category) }}</van-tag></van-col
                    >
                    <div class="sub-name mb-5">
                      <van-icon name="manager" class="mr-5 color9" />
                      <span style="color: #000">{{ signAs[item.sign_as_uid].name }}</span> - {{ item.sign_title }}
                    </div>
                  </van-col>

                  <van-col span="24" class="title">{{ item.summary }}</van-col>
                  <van-col span="24">
                    <van-divider />
                    <van-row type="flex" justify="space-between" align="center" class="item-1-foot">
                      <van-row class="color6">{{ item.publish_at }}</van-row>
                      <van-row>
                        <span v-if="item.response">
                          <span style="color: #ee0a24" v-if="item.has_response == 0">{{ $t("global.need_response") }}</span>
                          <span style="color: #999" v-if="item.has_response == 1">{{ $t("global.confirmed") }}</span>
                        </span>
                        <van-icon name="arrow" color="#969799" style="vertical-align: middle" />
                      </van-row>
                    </van-row>
                  </van-col>
                </van-row>
              </router-link>
            </div>
          </van-list>
        </div>
        <van-empty :description="$t('global.empty')" v-else-if="!searchLoading" />
      </div>
    </div>
    <van-action-sheet v-model="showAllTeacher" :title="$t('notice.Select_publisher')">
      <div class="content">
        <div
          class="color3 font15 pb-10 all"
          @click="
            teacherid = '';
            showAllTeacher = false;
            expired = false;
            searchTextData('init');
          "
        >
          <label>{{ $t("global.All") }}</label>
        </div>
        <van-list>
          <van-cell v-for="(item, key, index) in teacherList" :key="index">
            <van-row
              class="list"
              @click="
                teacherid = key;
                showAllTeacher = false;
                expired = false;
                searchTextData('init');
              "
            >
              <van-col span="4">
                <van-image round :src="item.photoUrl" class="avatar-1 mr-10 avatarImg" />
              </van-col>
              <van-col span="20" class="item-1-1">
                <div class="name">{{ item.name }}</div>
                <div class="sub-name">{{ item.hrPosition }}</div>
              </van-col>
            </van-row>
          </van-cell>
        </van-list>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from "vue";
import { Col, Row, Icon, Empty, DropdownMenu, DropdownItem, Divider, List, Loading, ActionSheet, Search, Dialog, Sticky, Checkbox } from "vant";

import { noticeList, noticeTeacherList } from "@/api/notice";
import storage from "store";
import { APP_LANGUAGE, NOTICE_HISTORY, CURRENT_CAMPUS } from "@/store/mutation-types";
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(Empty);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Divider);
Vue.use(List);
Vue.use(ActionSheet);
Vue.use(Search);
Vue.use(Dialog);
Vue.use(Sticky);
Vue.use(Checkbox);
export default {
  name: "Notice",
  components: {
    "van-loading": Loading,
  },
  data() {
    return {
      signAs: {},
      expiredImg: "",
      categoryList: [],
      currentTag: "",
      searchIcon: true,
      search: "",
      showAllTeacher: false,
      teacherList: {},
      teacherid: "",
      searchFinished: false,
      searchNoticeData: [],
      searchPages: {},
      noticeHistory: [],
      searchLoading: false,
      searchListLoading: false,
      expired: false,
      showLoading: false,
    };
  },
  activated: function () {
    if (this.$route.query.uid) {
      this.Issearchs();
    }
  },
  created() {
    let cuLang = storage.get(APP_LANGUAGE) || "zh";
    if (cuLang == "en") {
      this.expiredImg = "http://m2.files.ivykids.cn/cloud01-file-8025768FpRD26R7F4PGAoCaE-vnvfTjjbO0.png";
    } else {
      this.expiredImg = "http://m2.files.ivykids.cn/cloud01-file-8025768FhS0-jBWrxm_CpOeYMI9mWOg_hOy.png";
    }
    this.schoolid = storage.get(CURRENT_CAMPUS);
    this.noticeHistory = storage.get(NOTICE_HISTORY) || [];
    this.getTeacher();
  },
  watch: {
    search(newVal) {
      if (newVal != "" || this.teacherid != "") {
        this.searchLoading = true;
        this.searchNoticeData = [];
      }
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (newVal != "" || this.teacherid != "") {
          this.searchPages.page = 1;
          this.expired = false;
          this.searchTextData("init");
        }
      }, 1000);
    },
    schoolid(newVal) {
      if (newVal != "" && this.teacherid != "") {
        this.searchPages.page = 1;
        this.expired = false;
        this.searchTextData("init");
      }
    },
  },
  methods: {
    showNotice() {
      this.teacherid = "";
      this.search = "";
      this.searchNoticeData = [];
      this.$router.push({ path: "/notice" });
    },
    showCategory(id) {
      return this.categoryList.find((item2) => item2.key === id).value;
    },
    getTeacher() {
      noticeTeacherList({
        schoolid: this.schoolid,
      }).then((res) => {
        this.teacherList = res.data;
      });
    },
    Issearchs() {
      this.searchNoticeData = [];
      this.search = "";
      if (this.$route.query.uid) {
        this.teacherid = this.$route.query.uid;
        if (this.schoolid != "") {
          this.expired = false;
          this.searchTextData("init");
        }
      } else {
        this.teacherid = "";
      }
    },
    clearHistory() {
      Dialog.confirm({
        title: this.$t("global.Warning"),
        message: this.$t("notice.Clear_history"),
      })
        .then(() => {
          this.noticeHistory = [];
          storage.set(NOTICE_HISTORY, this.noticeHistory);
        })
        .catch(() => {
          // on cancel
        });
    },
    searchTextData(type) {
      if (type) {
        this.searchNoticeData = [];
        this.searchLoading = true;
      }
      if (this.search.trim() != "") {
        if (this.noticeHistory.indexOf(this.search) === -1) {
          this.noticeHistory.unshift(this.search);
          this.noticeHistory = this.noticeHistory.slice(0, 10);
          storage.set(NOTICE_HISTORY, this.noticeHistory);
        }
      }
      this.searchFinished = false;
      noticeList({
        schoolid: this.schoolid,
        page: this.searchPages.page,
        teacherid: this.teacherid,
        search: this.search,
        showExpired: this.expired ? 1 : 0,
      }).then((res) => {
        if (type) {
          this.searchNoticeData = res.data.items;
        } else {
          this.searchNoticeData.push(...res.data.items);
        }
        this.categoryList = [
          {
            key: "",
            value: this.$t("global.All"),
          },
        ];
        this.categoryList = this.categoryList.concat(res.data.categoryList);
        this.searchPages = res.data.pages;
        Object.assign(this.signAs, res.data.signAs);
        this.searchListLoading = false;
        this.searchLoading = false;
      });
    },
    searchOnLoad() {
      if (this.searchPages.page < this.searchPages.count) {
        this.searchPages.page += 1;
        this.searchTextData();
      } else {
        this.searchFinished = true;
      }
    },
    history(item) {
      this.search = item;
      this.expired = false;
      this.searchNoticeData = [];
    },
  },
};
</script>

<style lang="less" scoped>
.searchList {
  .searchInput {
    .flex {
      align-items: center;
      margin-right: 10px;
      padding-right: 10px;
      border-right: 1px solid #ddd;
      margin-top: 2px;
      height: 30px;
    }
    .avatarImg {
      width: 29px;
      height: 29px;
    }
  }
  .searchHistory {
    margin: 16px;
    border-radius: 8px;
    background: #fff;
    .searchText {
      padding: 16px 16px 10px 16px;
    }
  }
}
.content {
  .all {
    padding: 10px 16px;
    position: relative;
  }
  .all::after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: 4.2666666666667vw;
    bottom: 0;
    left: 4.2666666666667vw;
    border-bottom: 1px solid #ebedf0;
    transform: scaleY(0.5);
  }
  .list {
    .avatarImg {
      width: 44px;
      height: 44px;
    }
    .mt-3 {
      margin-top: 3px;
    }
  }
}
.notice {
   padding-bottom: 20px;
  .expiredCheckbox {
    margin: 12px 0 12px 15px;
  }
  .item-1 {
    background-color: #fff;
    display: block;
    margin: 0 15px 12px 15px;
    padding: 15px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    .Expired {
      position: absolute;
      right: -8px;
      top: 38px;
      width: 48px;
      height: 48px;
    }
    .item-1-1 {
      position: relative;

      .on-top {
        background-repeat: no-repeat;
        position: absolute;
        top: -16px;
        left: -16px;
        width: 30px;
        height: 30px;
        background-size: contain;
      }
      .on-top-en {
        background-image: url("https://m2.files.ivykids.cn/ds2/top.png");
      }
      .on-top-cn {
        background-image: url("https://m2.files.ivykids.cn/ds2/top.png");
      }
      .level-alert {
        position: absolute;
        right: -14px;
        top: -16px;
        border-radius: 0 8px 0 0;
        font-size: 12px;
        padding: 1px 6px;
        color: #fff;
      }
      .level-1 {
        background-color: #ee0a24;
      }
      .level-2 {
        background-color: #f5a429;
      }
      .level-4 {
        background-color: rgb(71, 126, 202);
      }
    }
    .name {
      font-size: 15px;
      font-weight: bold;
      color: #323233;
      margin-top: 5px;
    }
    .sub-name {
      font-size: 13px;
      font-weight: 400;
      color: #646566;
    }
    .categoryTag {
      padding: 1px 5px;
    }
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #646566;
      line-height: 20px;
      margin: 8px 0 0 0;
    }
    .sub-title {
      height: 16px;
      font-size: 12px;
      font-weight: 400;
      color: #969799;
      line-height: 16px;
    }
    .item-1-foot {
      font-size: 13px;
      font-weight: 400;
      color: #323233;
      height: 18px;
    }
  }
  .filter {
    padding: 10px 15px 10px 15px;
    font-size: 14px;
    .ele-1 {
      color: #646566;
    }
    .ele-2 {
      .van-icon {
        font-size: 18px;
        vertical-align: bottom;
      }
    }
  }
}
.the-popup {
  min-height: 200px;
  .van-loading {
    text-align: center;
    margin: 100px 0;
  }
}
</style>

<style lang="less">
.expiredCheckbox {
  .van-checkbox__icon .van-icon {
    border-radius: 2px;
  }
}
</style>
