<template>
  <div>
    <div class="layer3 text-center" v-if="pageData.childInfo">
      <div class="bd1" >
        <div>
          <img
            class="img1"
            referrerpolicy="no-referrer"
            :src="pageData.childInfo.avatar"
          />
        </div>
        <div class="mt-5">
          <span class="word4">{{ pageData.childInfo.name }}</span>
        </div>
        <div class="">
          <span class="word5">{{ pageData.childInfo.class }}</span>
        </div>
      </div>
    </div>
    
    <div class="layer4" v-if="pageData.options">
      <van-row type="flex" justify="space-between">
        <van-col>
          <span class="info1" v-if="pageData.options && pageData.options.length > 0">{{ $t("ptc.ptc_schedule") }}</span>
        </van-col>
        <van-col style="display: flex; align-items: center" @click="refresh">
          <van-loading v-if="loading" />
          <i class="iconfont iconfont-refresh" style="color: #666" v-else />
          <span class="word6 ml-5">{{ $t("ptc.refresh") }}</span>
        </van-col>
      </van-row>
      <div v-if="pageData.options && pageData.options.length > 0">
        <div
          class="section2"
          v-for="(item, index) in pageData.options"
          :key="index"
        >
          <van-row type="flex" align="center" class="mb-25">
            <van-col>
              <div class="main2">
                <div class="main2-1">{{ item.month }}</div>
                <div class="main2-2">{{ item.day }}</div>
              </div>
            </van-col>
            <van-col>
              <div class="main3">
                <div class="word4 mb-5">{{ item.date }}</div>
                <div class="word7">{{ item.weekday }}</div>
              </div>
            </van-col>
          </van-row>
          <van-row
            type="flex"
            justify="space-between"
            align="center"
            class="main4"
            v-for="(_item, _index) in item.times"
            :key="_index"
          >
            <van-col>
              <div class="word8 mb-5">{{ _item.time }}</div>
              <div class="word10" v-if="_item.checked">
                {{ $t("ptc.my_schedule_tips") }}
              </div>
              <div class="word9" v-else>
                {{ $t("ptc.x_available", { n: _item.active }) }}
              </div>
            </van-col>
            <van-col>
              <div>
                <van-button
                  round
                  type="info"
                  size="small"
                  style="padding: 0 20px"
                  :disabled="!_item.active"
                  :loading="bookedLoading"
                  v-if="!_item.checked"
                  @click="booked(_item, item)"
                  >{{ $t("ptc.schedule") }}</van-button
                >
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
      <custom-empty
        :description="$t('ptc.no_active_ptc')"
        v-else-if="pageData.options && pageData.options.length == 0"
      />
    </div>
    <div class="online"  v-if="pageData.options && pageData.options.length > 0">
      <div class="m16 color3 font14 flex">
        <img src="@/assets/onlinePTC.png" alt="online"  />
        <div class="flex1">
          <strong> {{ $t("ptc.online_schedule") }}</strong>
          <div class="font12 color9 mt-5" style="color:#999">{{ $t("ptc.takes_online") }}</div>
        </div>
      </div>
      <van-row justify="center" type="flex" class="mb16">
        <van-col
          ><van-button
            round
            type="info"
            size="small"
            @click="onlinePTC"
            >{{ $t("ptc.apply_online") }}</van-button
          ></van-col
        >
      </van-row>
    </div>
    <van-dialog v-model="show"  show-cancel-button message-align='left' @confirm='confirmOnlinePTC'>
      <div class="dialogAlert" >
        <van-notice-bar class="mb-15 iconTop" v-if='pageData.childInfo && pageData.childInfo.checked' left-icon="warning-o" wrapable>
          {{this.$t("ptc.please_note")}}          
        </van-notice-bar>
        <div class="color3">
          {{this.$t("ptc.online_PTC")}}
        </div>
        <div  class="color3">
          {{this.$t("ptc.want_PTC")}}
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { Toast, Dialog, Loading } from "vant";
import CustomEmpty from "@/components/CustomEmpty";
import { reserve, booked, setOnlineChild} from "@/api/ptc";

Vue.use(Loading);
Vue.use(Toast);
Vue.use(Dialog);

export default {
  name: "PTCBooked",
  components: {
    "custom-empty": CustomEmpty,
  },
  data() {
    return {
      loading: false,
      bookedLoading: false,
      childId: this.$route.params.id,
      year: this.$route.params.year,
      pageData: {},
      show:false
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    refresh() {
      this.loading = true;
      this.fetchData();
    },
    fetchData() {
      reserve({
        child_id: this.childId,
        year_semester: this.year,
      }).then((res) => {
        this.pageData = res.data;
        this.loading = false;
      });
    },
    booked(_item, item) {
      Dialog.confirm({
        title: this.$t("ptc.confirm_this_schedule"),
        message: item.date + " " + item.weekday + "\n" + _item.time,
      })
        .then(() => {
          this.bookedLoading = true;
          booked({
            child_id: this.childId,
            year_semester: this.year,
            schedule_id: _item.id,
          }).then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: "PTC",
              });
            } else {
              this.fetchData();
              this.bookedLoading = false;
            }
          });
        })
        .catch(() => {});
    },
    onlinePTC(){
      this.show=true
    },
    confirmOnlinePTC(){
      setOnlineChild({
          child_id: this.childId,
          year_semester: this.year,
          status:1
        }).then((res) => {
          if (res.code == 0) {
            Dialog.alert({
              title: this.$t("ptc.online_PTC_meet"),
              message: this.$t("ptc.teacher_connect"),
              theme: 'round-button',
              confirmButtonColor:'#4D88D2'
            }).then(() => {
              this.$router.push({
                name: "PTC",
              });
            });
          }else {
            // this.fetchData();
          }
        });
    }
  },
};
</script>

<style scoped lang="less">
.layer3 {
  height: 150px;
  background-color: #fff;
  .bd1 {
    padding: 20px 0 30px 0;
    .img1 {
      width: 52px;
      height: 52px;
      border-radius: 8px;
    }
  }
}
.online {
  background: #ffffff;
  border-radius: 10px;
  margin: 8px 16px 16px 16px;
  .m16 {
    padding: 16px;
    img{
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
  .mb16 {
    padding-bottom: 16px;
  }
}
.layer4 {
  padding: 16px;
  .info1 {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
  }
  .section2 {
    margin-top: 16px;
    padding: 16px;
    background: #ffffff;
    border-radius: 10px;
    .main2 {
      width: 64px;
      height: 64px;
      text-align: center;
      margin-right: 12px;
      .main2-1 {
        height: 28px;
        background-color: #f19043;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
        border-radius: 8px 8px 0px 0px;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        line-height: 28px;
      }
      .main2-2 {
        height: 36px;
        background-color: #ffffff;
        border-radius: 0px 0px 8px 8px;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
        font-size: 20px;
        font-weight: 500;
        color: #f19043;
        line-height: 36px;
      }
    }
    .main4 {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #d7dae2;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }
}
.word4 {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
}
.word5 {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 12px;
}
.word6 {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 12px;
}
.word7 {
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  line-height: 20px;
}
.word8 {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 25px;
}
.word9 {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
}
.word10 {
  font-size: 13px;
  font-weight: 400;
  color: #45a367;
  line-height: 18px;
}
.van-loading {
  span {
    width: 18px;
    height: 18px;
  }
}
.dialogAlert{
    padding:24px;
    font-size: 14px;
    line-height: 20px;
    .iconTop{
      align-items: baseline;
      padding:8px 10px;
      font-weight: 600;
      border-radius: 4px;
    }
}
</style>