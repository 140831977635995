<template>
  <div class="campus">
    <van-row class="campus-body">
      <div class="logo">
        <img src="@/assets/ivy_logo.png" alt="Logo" v-if="state == 'ivy'" />
        <img src="@/assets/logo.png" alt="Logo" v-else />
      </div>
      <div class="flex1 campusTitle" :class="search?'':'pr-30'">
        <van-dropdown-menu v-if="campusData.length > 0" active-color="#4D88D2">
          <van-dropdown-item v-model="value" :options="campusData" />
        </van-dropdown-menu>
      </div>
      <router-link class="search color3" :to="{ name: 'NoticeSearch' }">
      <div class="" v-if="search">
        <span class="iconfont iconfont-search font18"></span>
      </div>
      </router-link>
    </van-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Col, Row, DropdownMenu, DropdownItem } from "vant";
import storage from "store";
import { CURRENT_CAMPUS } from "@/store/mutation-types";
import { campusList, campusItem } from "@/api/campus";
import store from "../store";

Vue.use(Col);
Vue.use(Row);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);

export default {
  name: "Campus",
  props: {
    schoolid: String,
    search: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      campusData: [],
      value: 0,
      state: "",
    };
  },
  watch: {
    value(val) {
      this.$emit("change", val);
      storage.set(CURRENT_CAMPUS, val, 60);
    },
  },
  created() {
    this.state = store.getters.wxState;
    if (this.schoolid) {
      this.campusItem();
    } else {
      this.campusList();
    }
  },
  methods: {
    campusList() {
      campusList().then((res) => {
        this.campusData = res.data;
        this.value = this.campusData[0].value;
      });
    },
    campusItem() {
      campusItem({
        schoolid: this.schoolid,
      }).then((res) => {
        this.campusData = [res.data];
        this.value = this.campusData[0].value;
      });
    },
  },
};
</script>

<style lang="less" scoped>

.campus {
  .campus-body {
    text-align: center;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    color: #323233;
    display: flex;
    background-color: #fff;
    box-shadow: 0 0.5333333333333vw 3.2vw rgba(100, 101, 102, 0.12);
    width: 100%;
    align-items: center;
    .logo {
      // position: absolute;
      padding-left: 16px;
      // z-index: 99;
      img {
        width: 50px;
      }
    }
    .flex1 {
      flex: 1;
    }
    .search {
      z-index: 99;
      padding: 14px 16px;
    }
  }
}
</style>

<style lang="less">
.campus {
  .van-cell__title {
    flex: 3;
  }
}
.campusTitle {
  overflow: hidden;
  .van-dropdown-menu__bar {
    background-color: #fff;
    box-shadow: none;
  }
}
</style>
