import Vue from 'vue';

export function cardList(params) {
  return Vue.axios({
    url: '/api/parent/pickup/card',
    method: 'get',
    params: params
  })
}

export function token(params) {
  return Vue.axios({
    url: '/api/parent/pickup/card/token',
    method: 'get',
    params: params
  })
}

export function upload(data) {
  return Vue.axios({
    url:'https://upload-z1.qiniup.com',
    method: 'post',
    data: data
  })
}

export function cardSave(data) {
  return Vue.axios({
    url:'/api/parent/pickup/card/save',
    method: 'post',
    data: data
  })
}

export function childrenStatus() {
  return Vue.axios({
    url: '/api/parent/pickup/card/status',
    method: 'get'
  })
}
