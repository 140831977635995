<template>
  <div :class="stepStatus == 4 && dataDetails.isCommon==1? 'clickNone' : ''">
    <div>
      <div v-for="(list, index) in livePeople" :key="index">
        <div class="flex mt-20">
          <div class="flex1">
            <div class="title">{{$t('coll.Co-resident')}} #{{ index+1 }}</div>
          </div>
        </div>
<!--        姓名-->
        <van-field
            :label="$t('global.name')"
            :placeholder="$t('global.please_input')"
            v-model="livePeopleCopy[index]['name']"
            class="bgGray mt-10"
        />
<!--        身份证号-->
        <van-field
            :label="$t('coll.IDCard')"
            :placeholder="$t('global.please_input')"
            :rules="[{ IDCardReg, message: $t('coll.invalid_card') }]"
            v-model="livePeopleCopy[index]['IDCard']"
            class="bgGray mt-10"
        />
<!--        电话-->
        <van-field
            type="tel"
            :label="$t('coll.Mobile')"
            :placeholder="$t('global.please_input')"
            :rules="[{ pattern, message: $t('pickupcard.invalid_phone') }]"
            v-model="livePeopleCopy[index]['mobile']"
            class="bgGray mt-10"
        />
<!--        工作单位-->
        <van-field
            :label="$t('coll.Company')"
            :placeholder="$t('global.please_input')"
            v-model="livePeopleCopy[index]['company']"
            class="bgGray mt-10"
        />
        <div class="text-right color6 font12 mt-10"  v-if="index > 0">
          <span @click='delLivePeople(index)' class="flex">
            <van-icon name="clear" color="#cccccc"  class='mr-5 flexWidth font14'/>
            <span style="color:#646566;" class="flex1">{{$t('global.delete')}}</span>
          </span>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="fl relative mt-20 mb-10 flexRemove font12" @click="addLivePeople">
        <van-icon name="add-o"  class="mr-5 flexWidth font14 emergency-icon addIcon" />
        <span class="addText emergency">{{$t('coll.AddCo-resident')}}</span>
      </div>
      <div class="clearfix"></div>
    </div>
    <div v-if="dataDetails.protocol.length != 0">
      <protocol-sign
        @click="baseImg"
        :busProtocol="dataDetails.protocol"
        :base64="base64"
      ></protocol-sign>
    </div>
    <div class="mt-20">
      <van-button
        v-if="stepStatus == 4 && dataDetails.isCommon==1"
        disabled
        type="info"
        block
        round
        color="#4d88d2"
        class="saveSub"
        >{{ $t("coll.step_reviewed") }}
      </van-button>
      <a
        href="javascript:;"
        v-else
        @click="submitData()"
        class="saveBtnStep"
        :class="btnDisabled ? 'disabledSubmit' : ''"
        >{{ $t("global.submit") }}</a>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast } from "vant";
import ProtocolSign from "@/components/steps/ProtocolSign";
import Compressor from 'compressorjs';

Vue.use(Toast);
export default {
  props: {
    dataDetails: {
      type: Object,
    },
    status: {
      type: Number,
    },
    protocol: {
      type: Array,
    },
    disabled: {
      type: Number,
    },
  },
  components: {
    "protocol-sign": ProtocolSign,
  },
  name: "livePeople",
  data() {
    return {
      stepStatus:'',
      step_data: {},
      type: "",
      base64: "",
      signShow: false,
      qiniuToken: "",
      newSign: false,
      btnDisabled: false,
      signQiniuToken: "",
      sign: "",
      sign_url: "",
      pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
      IDCardReg: /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/,
      livePeople: [{
        name: "",
        IDCard:"",
        mobile: "",
        company: ""
      }],//同住人信息
      livePeopleCopy: [{
        name: "",
        IDCard:"",
        mobile: "",
        company: ""
      }],
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
  },
  created() {
    this.stepStatus = this.status;
    this.step_data = this.dataDetails.step_data;
    if (this.step_data.live_people.length != 0) {
      this.livePeople = this.step_data.live_people;
    }
    this.livePeopleCopy = this.livePeople;
  },
  methods: {
    addLivePeople() {
        let push_data = {
          name: "",
          IDCard:"",
          mobile: "",
          company: ""
        };
        this.livePeople.push(push_data);
        this.livePeopleCopy = this.livePeople;
    },
    delLivePeople(index) {
      if (this.livePeople.length == 1) {
        return;
      }
      this.livePeople.splice(index, 1);
    },
    tojson(rows) {
      var ids = [];
      for (var i = 0, len = rows.length; i < len; i++) {
        var signAgainReq = new Object();
        signAgainReq.url = rows[i];
        ids.push(signAgainReq);
      }
      return ids;
    },
    beforeRead(file) {
      return new Promise((resolve) => {
        new Compressor(file, {
          maxWidth:1200,
          quality:0.6,
          success: resolve,
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
    baseImg(data) {
      if (data != "cancel") {
        this.base64 = data;
        this.newSign = true;
      }
    },
    phoneValidate(params) {
      if (this.pattern.test(params)) {
        return true;
      } else {
        return false;
      }
    },
    IDCardValidate(params){
      if (this.IDCardReg.test(params)) {
        return true;
      } else {
        return true;//暂不验证
      }
    },
    submitData() {
      const that = this;
      if (this.btnDisabled) {
        return;
      }
      // 同住人
      that.in_einfo = [];
      for (let item of  that.livePeople){
        if (item.name.trim().length == 0) {
          Toast(this.$t("pickupcard.information"));
          return false;
        }

        if(item.IDCard.trim().length == 0){
          Toast(this.$t("coll.Please_input_ID"));
          return false;
        }

        // if(!this.IDCardValidate(item.IDCard)){
        //   Toast(this.$t("coll.invalid_card"));
        //   return false;
        // }

        if(item.mobile.trim().length == 0){
          Toast( this.$t("bind.phone_number"));
          return false;
        }
        if (!this.phoneValidate(item.mobile)) {
          Toast(this.$t("pickupcard.invalid_phone"));
          return false;
        }
        if(item.company.trim().length == 0){
          Toast( this.$t("coll.Please_input_company"));
          return false;
        }
        that.in_einfo.push({
          name: item.name,
          IDCard:item.IDCard,
          mobile: item.mobile,
          company: item.company
        });
      }
      this.$nextTick(function () {
        this.btnDisabled = true;
      });
      if (this.dataDetails.protocol.length == 0) {
        that.saveData();
        return;
      }
      if (!that.newSign) {
        that.saveData();
        return;
      }
      var pic = this.base64.substring(this.base64.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that.step_data, "sign", resJson.data.key);
            Vue.set(that.step_data, "sign_url", resJson.data.url);
            that.saveData();
          } else {
            that.btnDisabled = false;
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader(
        "Authorization",
        "UpToken " + that.dataDetails.signQiniuToken
      );
      xhr.send(pic);
    },
    saveData() {
      this.step_data.live_people = this.in_einfo;
      this.$emit("change", this.step_data);
    },
  },
};
</script>

<style lang="less" scoped>
.addIcon {
  color: #4d88d2;
}
.addText {
  font-size: 12px;
  font-weight: 400;
  color: #646566;
  line-height: 22px;
}
.emergency,.emergency-icon{
  line-height: 20px;
  vertical-align: middle;
  text-align: center;
}
.text-right{
  text-align: right;
  float: right;
  .flexWidth{
    padding-top: 2px;
  }
}
</style>