import Vue from 'vue';

export function appForm(childid) {
  return Vue.axios({
    url: '/api/parent/withdrawal/appForm/' + childid,
    method: 'get',
  })
}

export function childInfo(childid) {
  return Vue.axios({
    url: '/api/parent/withdrawal/index/' + childid,
    method: 'get',
  })
}

export function parentAppFormSave(data) {
  return Vue.axios({
    url: '/api/parent/withdrawal/appFormSave',
    method: 'post',
    data: data
  })
}

export function feedback(data) {
  return Vue.axios({
    url: '/api/parent/withdrawal/feedback',
    method: 'post',
    data: data
  })
}

export function parentConfirm(data) {
  return Vue.axios({
    url: '/api/parent/withdrawal/confirm',
    method: 'post',
    data: data
  })
}

export function withdrawalAppForm(id,params) {
  return Vue.axios({
    url: '/api/preview/withdrawalAppForm/'+id,
    method: 'get',
    params:params
  })
}

export function withdrawalFinance(id,params) {
  return Vue.axios({
    url: '/api/preview/withdrawalFinance/'+id,
    method: 'get',
    params:params
  })
}

