import Vue from 'vue';

export function address(params) {
  return Vue.axios({
    url: '/api/home/address/show',
    method: 'get',
    params: params
  })
}

export function addressSave(params) {
  return Vue.axios({
    url: '/api/home/address/save',
    method: 'post',
    data: params
  })
}

export function childrenStatus() {
  return Vue.axios({
    url: '/api/home/address/status',
    method: 'get'
  })
}