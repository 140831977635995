<template>
  <div>
    <div class="searchList">
      <van-sticky class="searchInput">
        <children :children="journalData.child_items" @change="childChange" />
        <van-search v-model="search" shape="round" :placeholder="$t('notice.Input_search')" left-icon="">
          <template #label>
            <div @click="showAllTeacher = true" class="flex">
              <span v-if="teacherid == ''" class="mr-10"> {{ $t("global.All") }} </span>
              <van-image v-else-if="teacherList[teacherid]" round :src="teacherList[teacherid].photoUrl" class="avatar-1 mr-10 avatarImg" />
              <van-icon name="arrow-down" />
            </div>
          </template>
          <!-- <template #action>
            <div @click="showSearch = true">取消</div>
          </template> -->
        </van-search>
      </van-sticky>
      <div class="journal mt-10">
        <van-loading size="24" class="text-center" v-if="searchLoading" />
        <div v-if="journalData.length > 0">
          <van-list v-model="searchListLoading" :finished="searchFinished" finished-text="" @load="searchOnLoad" :immediate-check="false">
            <div v-for="(item, i) in journalData" :key="i">
              <router-link class="item-1" :to="`/journal/${item.id}`">
                <van-row>
                  <van-col span="24" class="title">{{ item.title }}</van-col>
                  <van-col span="24">
                    <van-tag type="primary" v-if="item.category == 1" class="categoryTag" color="#E7F4FF" text-color="#4D88D2">  {{ $t("journal.normal_journal") }} </van-tag>
                    <van-tag type="primary" v-if="item.category == 2" class="categoryTag" color="#FFFBE8" text-color="#F5A429"> {{ $t("journal.direct_message") }}</van-tag>
                  </van-col>
                  <van-col span="24" class="updated_at">{{ item.updated_at }}</van-col>
                </van-row>
                <div class="flex" v-if="teacherList[item.uid]">
                  <van-image round width="32px" height="32px" :src="teacherList[item.uid].photoUrl" class="avatar-1 mr-10" />
                  <div class="item-1-1 flex1">
                    <span class="name">{{ teacherList[item.uid].name }}</span>
                    <span class="sub-name">{{ item.sign_as.title }}</span>
                  </div>
                </div>
              </router-link>
            </div>
          </van-list>
        </div>
        <van-empty :description="$t('global.empty')" v-else-if="!searchLoading" />
      </div>
    </div>
    <van-action-sheet v-model="showAllTeacher" :title="$t('notice.Select_publisher')">
      <div class="content">
        <div
          class="color3 font15 pb-10 all"
          @click="
            teacherid = '';
            showAllTeacher = false;
            searchTextData('init');
          "
        >
          <label>{{ $t("global.All") }}</label>
        </div>
        <van-list>
          <van-cell v-for="(item, key, index) in teacherList" :key="index">
            <van-row
              class="list"
              @click="
                teacherid = key;
                showAllTeacher = false;
                searchTextData('init');
              "
            >
              <van-col span="4">
                <van-image round :src="item.photoUrl" class="avatar-1 mr-10 avatarImg" />
              </van-col>
              <van-col span="20" class="item-1-1">
                <div class="name">{{ item.name }}</div>
                <div class="sub-name">{{ item.hrPosition }}</div>
              </van-col>
            </van-row>
          </van-cell>
        </van-list>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import Vue from "vue";
import { Col, Row, Icon, Empty, DropdownMenu, DropdownItem, Divider, List, Loading, ActionSheet, Search, Dialog } from "vant";
import Children from "@/components/Children";
import storage from 'store'
import { CURRENT_CHILD } from '@/store/mutation-types'
import { journalTeacherList, journalSearchList } from "@/api/journal";

Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(Empty);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Divider);
Vue.use(List);
Vue.use(ActionSheet);
Vue.use(Search);
Vue.use(Dialog);
export default {
  name: "Notice",
  components: {
    "van-loading": Loading,
    children: Children,
  },
  data() {
    return {
      journalData: {},
      searchIcon: true,
      search: "",
      showSearch: true,
      showAllTeacher: false,
      teacherList: {},
      teacherid: "",
      searchFinished: false,
      searchPages: {},
      noticeHistory: [],
      searchLoading: false,
      searchListLoading: false,
    };
  },
  created() {
    if(this.$route.query.uid){
        this.teacherid=this.$route.query.uid
    }else{
      this.teacherid=''
    }
  },
  watch: {
    search(newVal) {
      if (newVal != "") {
        this.searchLoading = true;
      }
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
          this.searchPages.page=1
          this.searchTextData("init");
      }, 1000);
    },
  },
  methods: {
    childChange(val) {
      this.childid = val.id;
      this.searchPages.page=1
      this.teacherData();
      this.searchTextData("init");
    },
    teacherData() {
      journalTeacherList({
        childid: this.childid,
      }).then((res) => {
        this.teacherList = res.data;
      });
    },
    searchTextData(type) {
      if (type) {
        this.searchPages.page=1
        this.searchNoticeData = [];
        document.documentElement.scrollTop = 0
      }
      this.searchLoading = true;
      this.searchFinished = false;
      journalSearchList({
        childid: this.childid,
        page: this.searchPages.page,
        teacherid: this.teacherid,
        search: this.search,
      }).then((res) => {
        storage.set(CURRENT_CHILD, {id: parseInt(this.$route.params.childid)})
        if (type) {
          this.journalData = res.data.items;
        } else {
          this.journalData.push(...res.data.items);
        }
        this.searchLoading = false
        res.data.pages.page=parseInt(res.data.pages.page)
        this.searchPages=res.data.pages
        this.searchListLoading = false;
      });
    },
    searchOnLoad() {
      if (this.searchPages.page < this.searchPages.count) {
        this.searchPages.page += 1;
        this.searchTextData();
      } else {
        this.searchFinished = true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  .all {
    padding: 10px 16px;
    position: relative;
  }
  .all::after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: 4.2666666666667vw;
    bottom: 0;
    left: 4.2666666666667vw;
    border-bottom: 1px solid #ebedf0;
    transform: scaleY(0.5);
  }
  .list {
    .avatarImg {
      width: 44px;
      height: 44px;
    }
    .mt-3 {
      margin-top: 3px;
    }
  }
}
.searchList {
  .searchInput {
    .flex {
      align-items: center;
      margin-right: 10px;
      padding-right: 10px;
      border-right: 1px solid #ddd;
      margin-top: 2px;
      height: 30px;
    }
    .avatarImg {
      width: 29px;
      height: 29px;
    }
  }
  .searchHistory {
    margin: 16px;
    border-radius: 8px;
    background: #fff;
    .searchText {
      padding: 16px 16px 10px 16px;
    }
  }
}
.journal {
  //   background-color: #fff;
  .item-1 {
    background-color: #fff;
    display: block;
    margin: 0 15px 12px 15px;
    border-radius: 8px;
    padding: 16px;
    border-bottom: 1px solid #ebedf0;
    &:last-child {
      border-bottom: 0;
    }
    .flex {
    //   align-items: center;
    }
    .item-1-1 {
      position: relative;
      // line-height: 32px;
      margin-top:5px;
      .van-icon {
        position: absolute;
        right: 0;
        top: 10px;
      }
    }
    .name {
      font-size: 14px;
      font-weight: 500;
      color: #323233;
      margin-bottom: 3px;
    }
    .sub-name {
      font-size: 12px;
      font-weight: 400;
      color: #646566;
      margin-left: 8px;
    }
    .title {
      font-size: 15px;
      color: #323233;
      line-height: 20px;
      margin-bottom: 4px;
      font-weight: bold;
    }
    .updated_at {
      font-size: 12px;
      font-weight: 400;
      color: #646566;
      margin-bottom:8px;
    }
    .categoryTag {
      padding: 1px 5px;
      margin-bottom:7px;
    }
  }
}
</style>
