<template>
    <div :class="stepStatus == 4 && dataDetails.isCommon == 1 ? 'clickNone' : ''">
        <div class="color6 font14 mt-20">{{ $t("coll.What_identification") }}</div>
        <van-field v-model="identity_type" is-link readonly class="bgGray mt-10" :placeholder="$t('coll.choose')"
            @click="showCardType(step_data.identity_type)" />
        <div v-if="step_data.identity_type == 'ID_card'">
            <div class="color6 font14 mt-20">{{ $t("coll.fill_chinese_ID") }}</div>
            <van-field v-model="step_data.identity_number" clearable :placeholder="$t('global.please_input')"
                class="bgGray mt-10" />
            <div class="color6 font14 mt-20">{{ $t("coll.card_expiry_date") }}</div>
            <van-field v-model="step_data.identity_validity" is-link readonly class="bgGray mt-10"
                :placeholder="$t('coll.choose')" @click="showDateEvent(step_data.identity_validity)"  />
            <div class="color6 font14 mt-20">{{ $t("coll.upload_visa_card") }}</div>
            <van-uploader class="mt-10" :before-read="beforeRead" v-model="identity_photoList" @delete="deleteimg"
                :after-read="afterRead" name="identity_photo" />
        </div>
        <div v-if="step_data.identity_type == 'passport'">
            <div class="color6 font14 mt-20">{{ $t("coll.fill_passport") }}</div>
            <van-field v-model="step_data.identity_number" clearable :placeholder="$t('global.please_input')"
                class="bgGray mt-10" />
            <div class="color6 font14 mt-20">{{ $t("coll.Passport_expiry_date") }}</div>
            <van-field v-model="step_data.identity_validity" is-link readonly class="bgGray mt-10"
                :placeholder="$t('coll.choose')" @click="showDateEvent(step_data.identity_validity)" />
            <div class="color6 font14 mt-20">{{ $t("coll.fill_visa_number") }}</div>
            <van-field v-model="step_data.visa_number" clearable :placeholder="$t('global.please_input')"
                class="bgGray mt-10" />
            <div class="color6 font14 mt-20">{{ $t("coll.upload_visa_page") }}</div>
            <van-uploader class="mt-10" :before-read="beforeRead" v-model="identity_photoList" @delete="deleteimg"
                :after-read="afterRead" name="identity_photo" />
        </div>
        <div v-if="step_data.identity_type == 'other'">
            <div class="color6 font14 mt-20">{{ $t("coll.fill_number") }}</div>
            <van-field v-model="step_data.identity_number" clearable :placeholder="$t('global.please_input')"
                class="bgGray mt-10" />
            <div class="color6 font14 mt-20">{{ $t("coll.card_expiry_date") }}</div>
            <van-field v-model="step_data.identity_validity" is-link readonly class="bgGray mt-10"
                :placeholder="$t('coll.choose')" @click="showDateEvent(step_data.identity_validity)" />
            <div class="color6 font14 mt-20">{{ $t("coll.upload_visa_card") }}</div>
            <van-uploader class="mt-10" :before-read="beforeRead" v-model="identity_photoList" @delete="deleteimg"
                :after-read="afterRead" name="identity_photo" />
        </div>
        <div class="color6 font14 mt-20">{{ $t("coll.name_Card_Passport") }}</div>
        <van-field v-model="step_data.identity_name" clearable :placeholder="$t('global.please_input')"
            class="bgGray mt-10" />
        <div class="color6 font14 mt-20">{{ $t("coll.trip_Emergency") }}</div>
        <van-field v-model="step_data.emergency_contact" clearable :placeholder="$t('global.please_input')"
            class="bgGray mt-10" />
        <div class="color6 font14 mt-20">{{ $t("coll.relationship_student") }}</div>
        <van-field v-model="step_data.emergency_relation" clearable :placeholder="$t('global.please_input')"
            class="bgGray mt-10" />
        <div class="color6 font14 mt-20">{{ $t("coll.Emergency_number") }}</div>
        <van-field type="tel" :placeholder="$t('global.please_input')" clearable
            :rules="[{ pattern, message: $t('pickupcard.invalid_email') }]" v-model="step_data.emergency_tel"
            class="bgGray mt-10" />
        <van-popup v-model="cardType" round position="bottom">
            <van-picker :title="$t('coll.choose')" :columns="cardColumns" @cancel="cardType = false"
                @confirm="onConfirmCard" value-key="title" ref="picker1" id="picker1" show-toolbar />
        </van-popup>
        <div v-if="dataDetails.protocol.length != 0">
            <protocol-sign @click="baseImg" :busProtocol="dataDetails.protocol" :base64="base64"></protocol-sign>
        </div>
        <div class="mt-20">
            <van-button v-if="stepStatus == 4 && dataDetails.isCommon == 1" disabled type="info" block round color="#4d88d2"
                class="saveSub">{{ $t("coll.step_reviewed") }}</van-button>
            <a href="javascript:;" v-else @click="submitData()" class="saveBtnStep"
                :class="btnDisabled ? 'disabledSubmit' : ''">{{ $t("global.submit") }}</a>
        </div>
        <van-popup v-model="showDate" round position="bottom">
            <van-datetime-picker v-model="currentDate" type="date" @confirm="dateConfirm" :max-date="maxDate"  />
        </van-popup>
    </div>
</template>
  
<script>
import ProtocolSign from "@/components/steps/ProtocolSign";
import Vue from "vue";
import { Toast } from "vant";
import Compressor from 'compressorjs';

export default {
    props: {
        dataDetails: {
            type: Object,
        },
        status: {
            type: Number,
        },
        disabled: {
            type: Number,
        },
    },
    components: {
        "protocol-sign": ProtocolSign,
    },
    name: "homeAddress",
    data() {
        return {
            qiniuToken: "",
            step_data: "",
            stepStatus: "",
            show: false,
            cardType: false,
            insuranceType: false,
            showType: false,
            cardColumns: [{ id: 'ID_card', title: this.$t('coll.chinese_card') }, { id: 'passport', title: this.$t('coll.passport') }, { id: 'other', title: this.$t('coll.other_documents') }],
            showColumns: [{ id: 1, title: this.$t('coll.yes') }, { id: 0, title: this.$t('coll.no') }],
            insuranceColumns: [{ id: 'Accidental', title: this.$t('coll.accidental_insurance') }, { id: 'High-End', title: this.$t('coll.High-End_insurance') }, { id: 'Other', title: this.$t('coll.Other_insurance') }],
            value: "",
            type: "",
            index: "",
            btnDisabled: false,
            base64: "",
            newSign: false,
            identity_photoList: [],
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            pattern_card: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            showDate:false,
            currentDate:'',
            maxDate:new Date(2100, 12, 31),

        };
    },
    watch: {
        disabled() {
            this.btnDisabled = false;
        },
    },
    created() {
        this.qiniuToken = this.dataDetails.qiniuToken;
        this.stepStatus = this.status;
        this.step_data = this.dataDetails.step_data;
        if (this.step_data.identity_photo && this.step_data.identity_photo.length != 0) {
            this.identity_photoList = this.tojson(this.step_data.identity_photo_url);
        }
        if (this.step_data.identity_type != '') {
            this.identity_type = this.cardColumns[this.lanIndex(this.step_data.identity_type, 'cardColumns')].title
        }
        if (this.dataDetails.sign_url != "") {
            this.base64 = this.dataDetails.sign_url;
        }
    },
    methods: {
        showDateEvent(){
            this.showDate = true;
            let date=this.step_data.identity_validity.split('-')
            this.currentDate= new Date(date[0], date[1]-1, date[2])
        },
        dateConfirm(value){
            this.step_data.identity_validity=this.timestampToTime(value)
            this.showDate = false
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1):date.getMonth()+1) + '-';
            var D = (date.getDate()< 10 ? '0'+date.getDate():date.getDate())
            return Y+M+D
        },
        showCardType() {
            this.cardType = true;
            let that = this;
            this.$nextTick(() => {
                that.index = this.lanIndex(that.step_data.identity_type, 'cardColumns');
                that.$refs.picker1.setIndexes([that.index]);
            });
        },
        lanIndex(id, type) {
            for (var i = 0; i < this[type].length; i++) {
                if (this[type][i].id == id) {
                    return i;
                }
            }
        },
        onConfirmCard(value) {
            this.step_data.identity_type = value.id;
            this.identity_type = value.title;
            this.cardType = false;
        },
        tojson(rows) {
            var ids = []
            for (var i = 0, len = rows.length; i < len; i++) {
                var signAgainReq = new Object();
                signAgainReq.url = rows[i];
                ids.push(signAgainReq);
            }
            return ids
        },
        beforeRead(file) {
            return new Promise((resolve) => {
                new Compressor(file, {
                    maxWidth: 1200,
                    quality: 0.6,
                    success: resolve,
                    error(err) {
                        console.log(err.message);
                    },
                });
            });
        },
        afterRead(file, detail) {
            this.type = detail.name;
            if (file.length > 1) {
                for (let i in file) {
                    this.upimg(file[i].content);
                }
            } else {
                this.upimg(file.content);
            }
        },
        upimg(data) {
            const that = this;
            Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: that.$t("pickupcard.uploading"),
            });
            var pic = data.substring(data.indexOf(",") + 1);
            var url = "https://upload-z1.qiniup.com/putb64/-1";
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    var resJson = JSON.parse(xhr.responseText);
                    if (resJson.code == 0) {
                        that.step_data[that.type].push(resJson.data.key);
                        Toast(that.$t("pickupcard.Uploaded"));
                    } else {
                        Toast(resJson.msg);
                    }
                }
            };
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/octet-stream");
            xhr.setRequestHeader("Authorization", "UpToken " + that.qiniuToken);
            xhr.send(pic);
        },
        //删除图片
        deleteimg(file, detail) {
            this.step_data[detail.name].splice(detail.index, 1);
        },
        baseImg(data) {
            if (data != "cancel") {
                this.base64 = data;
                this.newSign = true;
            }
        },
        phoneValidate(params, type) {
            if (type.test(params)) {
                return true;
            } else {
                return false;
            }
        },
        submitData() {
            let that = this;
            if (this.btnDisabled) {
                return;
            }
            if (this.step_data.identity_type == '') {
                Toast(this.$t('global.please_input') + this.$t("coll.What_identification"));
                return;
            }
            if (this.step_data.identity_type == 'ID_card') {
                if (!this.phoneValidate(this.step_data.identity_number, this.pattern_card)) {
                    Toast(this.$t("coll.invalid_card"));
                    return;
                }
            }
            if (this.step_data.identity_type == 'passport') {
                if (!this.step_data.visa_number || this.step_data.visa_number.trim()=='') {
                    Toast(this.$t('global.please_input') + this.$t("coll.fill_visa_number") );
                    return;
                }
            }
            if (this.step_data.identity_name == '') {
                Toast(this.$t('global.please_input') + this.$t("coll.name_Card_Passport"));
                return;
            }
            if (this.step_data.emergency_contact == '') {
                Toast(this.$t('global.please_input') + this.$t("coll.trip_Emergency"));
                return;
            }
            if (this.step_data.emergency_relation == '') {
                Toast(this.$t('global.please_input') + this.$t("coll.relationship_student"));
                return;
            }
            if (!this.phoneValidate(this.step_data.emergency_tel, this.pattern)) {
                Toast(this.$t('coll.contact_person') + this.$t("pickupcard.invalid_phone"));
                return false;
            }
            this.$nextTick(function () {
                this.btnDisabled = true
            })
            if (that.dataDetails.protocol.length == 0) {
                that.saveData();
                return;
            }
            if (!that.newSign) {
                that.saveData();
                return;
            }
            var pic = this.base64.substring(this.base64.indexOf(",") + 1);
            var url = "https://upload-z1.qiniup.com/putb64/-1";
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                // toast.clear()
                if (xhr.readyState == 4) {
                    var resJson = JSON.parse(xhr.responseText);
                    if (resJson.code == 0) {
                        Vue.set(that.step_data, "sign_url", resJson.data.url);
                        Vue.set(that.step_data, "sign", resJson.data.key);
                        that.saveData();
                    } else {
                        Toast(resJson.msg);
                        that.btnDisabled = false;
                    }
                }
            };
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/octet-stream");
            xhr.setRequestHeader("Authorization", "UpToken " + that.dataDetails.signQiniuToken);
            xhr.send(pic);
        },
        saveData() {
            this.$emit("change", this.step_data);
        },
    },
};
</script>
  
<style lang="less" scoped></style>