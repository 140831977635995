import Vue from 'vue';

export function indexData (id) {
    return Vue.axios({
      url: '/api/collection/index/' + id,
      method: 'get'
    })
}

export function childrenStatus (id) {
    return Vue.axios({
      url: '/api/collection/childrenStatus/' + id,
      method: 'get'
    })
}

export function stepInfo (id,stepName,params) {
    return Vue.axios({
      url: '/api/collection/stepInfo/' + id +'/'+ stepName,
      method: 'get',
      params: params
    })
}

export function saveStep (id,stepName,data) {
    return Vue.axios({
      url: '/api/collection/saveStep/' + id +'/'+ stepName,
      method: 'post',
      data:data
    })
}
export function auditSuccess (id) {
  return Vue.axios({
    url: '/api/collection/auditSuccess/' + id ,
    method: 'get',
  })
}

export function faPiao(pageUrl)
{
    return Vue.axios({
        url: '/api/collection/JSApiConfig/faPiao',
        method: 'get',
        params: {
            pageUrl: pageUrl
        }
    })
}