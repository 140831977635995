import Vue from 'vue';

export function login (data) {
  return Vue.axios({
    url: '/api/login',
    method: 'post',
    data: data
  })
}

export function getInfo (params) {
  return Vue.axios({
    url: '/api/getInfo',
    method: 'get',
    params: params
  })
}

export function logout (data) {
  return Vue.axios({
    url: '/api/logout',
    method: 'post',
    data: data
  })
}

export function sendCode (data) {
  return Vue.axios({
    url: '/api/sendCode',
    method: 'post',
    data: data
  })
}

export function bindByPhone (data) {
  return Vue.axios({
    url: '/api/bindByPhone',
    method: 'post',
    data: data
  })
}

export function subscribe (params) {
  return Vue.axios({
    url: '/api/subscribe',
    method: 'get',
    params: params
  })
}

export function home (params) {
  return Vue.axios({
    url: '/api/home',
    method: 'get',
    params: params
  })
}
