<template>
  <div>
    <!-- <van-sticky> -->
    <children @change="childChange" :children="childrenList" v-if="childrenList.length > 0" />
    <!-- </van-sticky>  -->
    <div>
      <div v-if="childStatus" class="childNone">
        <van-notice-bar wrapable :scrollable="false" left-icon="info-o">
          {{ $t("contact.not_available") }}
        </van-notice-bar>
      </div>
      <div v-else>
        <van-row v-if="deptItem.length > 0 && initData">
          <van-tabs @click="onClick" color="#4D88D2" v-model="activeName" animated sticky>
            <van-tab :title="$t('contact.teachers')" name="teacher" v-if="openTeacher">
              <div v-if="teacherItem.length > 0">
                <div class="list" v-for="(list, index) in teacherItem" :key="index">
                  <div class="flex">
                    <div><img :src="list.info.photoUrl" alt="" class="teacherImg" /></div>
                    <div class="flex1 ml-10">
                      <div class="color3 font16 titleText mb-5">
                        <strong>{{ list.info.name }}</strong>
                      </div>
                      <div class="color6 font13 titleText">{{ list.info.hrPosition }}</div>
                      <div class="message flex">
                        <span class="flex1">
                          <i class="iconfont iconfont-mail icon" @click="showTel(list.info.email, 'email')" />
                          <!-- <img src="@/assets/contact_email.png" alt="email" @click="showTel(list.info.email, 'email')" /> -->
                        </span>
                        <span class="ponitre">
                          <van-button icon="chat" class="pl-15 pr-15" :to="{ name: 'ContactMessage', params: { id: list.id, type: 3, childId: childid } }" round type="info" size="small">{{ $t("contact.message") }}</van-button>
                          <span class="ponit" v-if="list.notifier"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="initData">
                <van-empty :description="$t('calendar.no_data')" />
              </div>
            </van-tab>
            <van-tab :title="$t('contact.teams')" name="dept">
              <div class="list" v-for="(item, index) in deptItem" :key="index">
                <div class="color3 font16 titleText mb-10">
                  <strong>{{ item.name }}</strong>
                </div>
                <div class="color6 font13 titleText descText">{{ item.desc }}</div>
                <div class="message flex">
                  <div class="flex1">
                    <i class="iconfont iconfont-phone icon" v-if="item.phone_number && item.phone_number != ''" @click="showTel(item.phone_number, 'tel')" />
                    <i class="iconfont iconfont-mail icon" v-if="item.email && item.email != ''" @click="showTel(item.email, 'email')" />
                    <!-- <img src="@/assets/contact_tel.png" alt="tel"  />
                <img src="@/assets/contact_email.png" alt="email" /> -->
                  </div>
                  <span class="ponitre">
                    <van-button icon="chat" round type="info" size="small" class="pl-15 pr-15" :to="{ name: 'ContactMessage', params: { id: item.id, type: item.type, childId: childid } }">{{ $t("contact.message") }} </van-button>
                    <span class="ponit" v-if="item.notifier"></span>
                  </span>
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </van-row>
        <div v-else-if="initData">
          <div v-if="teacherItem.length > 0">
            <div class="list" v-for="(list, index) in teacherItem" :key="index">
              <div class="flex">
                <div><img :src="list.info.photoUrl" alt="" class="teacherImg" /></div>
                <div class="flex1 ml-10">
                  <div class="color3 font16 titleText mb-5">
                    <strong>{{ list.info.name }}</strong>
                  </div>
                  <div class="color6 font13 titleText">{{ list.info.hrPosition }}</div>
                  <div class="message flex">
                    <span class="flex1">
                      <i class="iconfont iconfont-mail icon" @click="showTel(list.info.email, 'email')" />
                    </span>
                    <span class="ponitre">
                      <van-button icon="chat" class="pl-15 pr-15" :to="{ name: 'ContactMessage', params: { id: list.id, type: 3, childId: childid } }" round type="info" size="small">{{ $t("contact.message") }}</van-button>
                      <span class="ponit" v-if="list.notifier"></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="initData">
            <van-empty :description="$t('calendar.no_data')" />
          </div>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="show" :actions="actions" :cancel-text="$t('global.cancel')" :description="tel" close-on-click-action @select="onSelect" />
  </div>
</template>

<script>
import Vue from "vue";
import { Cell, Icon, Picker, Col, Row, ActionSheet, Empty, Toast, Sticky } from "vant";
import Children from "@/components/Children";
import storage from "store";
import { CURRENT_CHILD, CURRENT_CONTACT } from "@/store/mutation-types";
import { childList } from "@/api/child";
import { deptList, teacherList } from "@/api/contactCampus";

Vue.use(Cell);
Vue.use(Icon);
Vue.use(Picker);
Vue.use(Toast);
Vue.use(Col);
Vue.use(Row);
Vue.use(ActionSheet);
Vue.use(Empty);
Vue.use(Sticky);
export default {
  name: "Assessments",
  components: {
    children: Children,
  },
  data() {
    return {
      childrenList: [],
      childrenStatus: {},
      childid: "",
      show: false,
      columns: [],
      expand: false,
      listData: null,
      yearId: false,
      yearindex: "",
      infoUrl: "",
      activeName: "teacher",
      actions: [{ name: this.$t("contact.call") }, { name: this.$t("contact.copy_phone") }],
      deptItem: [],
      tel: "",
      showType: "",
      teacherItem: [],
      initData: false,
      childStatus: false,
      schoolId: "",
      openTeacher: true
    };
  },
  beforeCreate() {},
  created() {
    this.activeName = storage.get(CURRENT_CONTACT) || "teacher";
    childList().then((res) => {
      this.childrenList = res.data;
      this.loading = false;
      let cIds = [];
      this.childrenList.map((item) => {
        this.childrenStatus[item.id] = true;
        cIds.push(item.id);
      });
      if (this.childrenList.length < 1) {
        this.emptyChild = true;
      }
      let cid = storage.get(CURRENT_CHILD) ? storage.get(CURRENT_CHILD).id : "";
      this.childid = cIds.indexOf(cid) > -1 ? cid : cIds[0];
    });
  },
  methods: {
    childChange(val) {
      this.childid = val.id;
      this.schoolId = val.schoolid;
        this.deptData();
        this.teacherData();
    },
    deptData() {
      deptList({
        childId: this.childid,
      }).then((res) => {
        if (res.data.childStatus === 0) {
          this.childStatus = true;
        } else {
          this.deptItem = res.data;
          this.initData = true;
          this.childStatus = false;
        }
      });
    },
    teacherData() {
      teacherList({ childId: this.childid }).then((res) => {
        if (res.data.childStatus === 0) {
          this.childStatus = true;
        }
        else if (res.code == 1) {
          this.openTeacher = false
        }
        else {
          this.teacherItem = res.data;
          this.initData = true;
          this.childStatus = false;
        }
      });
    },
    showTel(numTel, type) {
      this.showType = type;
      if (type == "email") {
        this.tel = numTel;
        this.actions = [{ name: this.$t("contact.copy_email"), value: 1 }];
      } else {
        this.numTel = numTel;
        let phone_number = numTel.split("-");
        let number1 = "";
        let number2 = "";
        let number3 = "";
        let ext = "";
        if (phone_number[0] && phone_number[0] != "") {
          number1 = phone_number[0] + "-";
        }
        number2 = phone_number[1];
        if (phone_number[2] && phone_number[2] != "") {
          number3 = "-" + phone_number[2];
          ext = phone_number[2];
        }
        this.tel = number1 + number2 + number3;
        if (ext != "") {
          this.actions = [
            { name: this.$t("contact.call"), subname: this.$t("contact.ext_number", { x: ext }), value: 1 },
            { name: this.$t("contact.copy_phone"), value: 2 },
          ];
        } else {
          this.actions = [
            { name: this.$t("contact.call"), value: 1 },
            { name: this.$t("contact.copy_phone"), value: 2 },
          ];
        }
      }
      this.show = true;
    },
    onSelect(item) {
      if (this.showType == "tel" && item.value == 1) {
        let number = this.numTel.split("-");
        let numberPhone = "";
        if (number[0] != "") {
          numberPhone = number[0] + "-" + number[1];
        } else {
          numberPhone = number[1];
        }
        window.location.href = `tel:${numberPhone}`;
        // window.location.href = `tel:${this.tel}`;
      } else {
        var input = document.createElement("input");
        //将input的值设置为需要复制的内容
        input.value = this.tel;
        //添加input标签
        document.body.appendChild(input);
        //选中input标签
        input.select();
        //执行复制
        document.execCommand("copy");
        //移除input标签
        document.body.removeChild(input);
        Toast(this.$t("student.copy_success"));
      }
    },
    onClick() {
      storage.set(CURRENT_CONTACT, this.activeName);
      if (this.activeName == "dept") {
        this.deptData();
      } else {
        this.teacherData();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.childNone {
  min-height: 100vh;
  background: #fff;
}
.schoolYear {
  line-height: 52px;
  padding-right: 16px;
  margin-right: 16px;
  position: relative;
  .open {
    position: absolute;
    right: 0;
    top: 18px;
  }
}
.list {
  background-color: #fff;
  display: block;
  margin: 12px 16px;
  padding: 16px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
.titleText {
  line-height: 20px;
}
.message {
  margin-top: 16px;
  align-items: center;
  display: flex;
  .ponitre {
    position: relative;
    .ponit {
      width: 8px;
      height: 8px;
      display: inline-block;
      background: #d9534f;
      position: absolute;
      border-radius: 50%;
      top: -4px;
      right: 5px;
      border: 1px solid #fff;
    }
  }

  // img {
  //   width: 28px;
  //   margin-right: 16px;
  // }
}
.teacherImg {
  width: 52px;
  height: 52px;
  border-radius: 10px;
  object-fit: cover;
}
.icon {
  display: inline-block;
  background: #ebedf0;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-right: 16px;
}
.descText {
  word-break: break-all;
}
</style>
