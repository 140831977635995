import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import "./icons/iconfont.css";
import './permission'
import './global.less'

import '@vant/touch-emulator';

Vue.config.productionTip = false
// 引入高德地图
import VueAMap from 'vue-amap';

Vue.use(VueAMap);


// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: 'a34ec0a9d808c39f9aea30362670ef78',
  // 插件集合
  plugin: [
    'AMap.Geolocation','MarkerClusterer','AMap.Heatmap','AMap.Geocoder'
  ],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.14'
});
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
