<template>
  <div class="uniform">
    <div v-if="checkList.length != 0">
      <!-- <div class="flex child">
        <van-image round width="44px" height="44px" :src="childData.avatar" class="avatar-1 mr-10" />
        <div class="item-1-1 flex1">
          <div class="name">{{ childData.name }}</div>
          <div class="sub-name pt-3">{{ childData.class_name }}</div>
        </div>
        <img src="@/assets/shipping.png" alt="Logo" width="20px" />
      </div> -->
      <div v-if="orderData.length != 0">
        <div class="order" v-for="(list, i) in orderData" :key="i">
          <div class="color6 font12">{{ $t("uniform.Order_NO") }}：{{ list.order_id }}</div>
          <van-divider />
          <div v-for="(item, index) in list.children" :key="index">
            <div class="flex mb-15" @click="router(list)">
              <img :src="item.img" class="img72 mr-10" />
              <div class="item-1-1 flex1">
                <div class="name">{{ item.title }}</div>
                <div class="sub-name pt-3">{{ item.attr1 }} ｜ {{ item.attr2 }}</div>
                <div class="flex mt-10">
                  <div class="flex1 name">¥ {{ item.unit_price }}</div>
                  <div class="color6 font14">× {{ item.num }}</div>
                </div>
                <!-- "status": 40//20待确认 30已确认 40 已发放 41 等待退款 45退款中 50已退款 89超时取消 99已取消 -->
                <div class="font12 flex align mt-10 color3 orange" v-if="item.status == 20">
                  <van-icon name="question-o" size="14" /><span class="flex1 ml-5">{{ $t("uniform.waiting_confirmation") }}</span>
                </div>
                <div class="font12 flex align mt-10 color3 blue" v-if="item.status == 30">
                  <van-icon name="info-o" size="14" /><span class="flex1 ml-5"> {{ $t("uniform.waiting_distribution") }}</span>
                </div>
                <div class="font12 flex align mt-10 color3 green" v-if="item.status == 40">
                  <van-icon name="passed" size="14" /><span class="flex1 ml-5"> {{ $t("uniform.Completed") }}</span>
                </div>
                <div class="font12 flex align mt-10 color3 red" v-if="item.status == 41">
                  <van-icon name="warning-o" size="14" /><span class="flex1 ml-5"> {{ $t("uniform.waiting_refund") }}</span>
                </div>
                <div class="font12 flex align mt-10 color3 red" v-if="item.status == 45">
                  <van-icon name="warning-o" size="14" /><span class="flex1 ml-5"> {{ $t("uniform.Refunding") }}</span>
                </div>
                <div class="font12 flex align mt-10 color3 red" v-if="item.status == 50">
                  <van-icon name="warning-o" size="14" /><span class="flex1 ml-5"> {{ $t("uniform.Refunded") }}</span>
                </div>
                <div class="font12 flex align mt-10 color3 red" v-if="item.status == 99">
                  <van-icon name="warning-o" size="14" /><span class="flex1 ml-5"> {{ $t("uniform.Cancelled") }}</span>
                </div>
                <div class="font12 flex align mt-10 color3 red" v-if="item.status == 89">
                  <van-icon name="warning-o" size="14" /><span class="flex1 ml-5"> {{ $t("uniform.Transaction_Expired") }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right">
            <span class="font12 color6 mr-15"> {{ $t("uniform.Subtotal", { x: list.product_count }) }} </span> <span class="font12 color6">{{ $t("uniform.Total") }}</span> <span class="color3 font12 fontWeight">¥ </span><span class="font16 color3">{{ list.order_amount }}</span>
          </div>
          <van-divider />
          <div class="flex align">
            <div class="color6 font14 flex1">{{ list.order_date }}</div>
            <div v-if="schoolType != 'BJ_DS' && schoolType != 'BJ_QFF' && schoolType != 'BJ_SLT'">
              <van-button type="default" round size="small" @click="cancelOrder(list)" v-if="showBtn(list)"> {{ $t("uniform.Cancel_order") }}</van-button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <van-empty :description="$t('calendar.no_data')" image="https://m2.files.ivykids.cn/cloud01-file-8025768Fpkv74gsyXNIWDf0HfWgAyaI0Smi.png" />
      </div>
    </div>
    <van-action-sheet v-model="show" :title="$t('uniform.Cancel_order')">
      <div class="content" v-if="cancelData.children">
        <div v-if="!isNext">
          <div class="flex mb-20" v-for="(item, index) in cancelData.children" :key="index">
            <van-checkbox v-if="item.disabled" :disabled="item.disabled"><img :src="item.img" alt="" class="img72 mr-10" /></van-checkbox>
            <van-checkbox v-model="item.checked" v-else>
              <img :src="item.img" alt="" class="img72 mr-10" />
            </van-checkbox>
            <div class="item-1-1 flex1">
              <div class="name">{{ item.title }}</div>
              <div class="sub-name pt-3">{{ item.attr1 }} ｜ {{ item.attr2 }}</div>
              <div class="flex mt-10">
                <div class="flex1 name">¥ {{ item.unit_price }}</div>
                <div class="color6 font14">× {{ item.num }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <van-radio-group v-model="options">
            <van-cell-group>
              <van-cell v-for="(item, index) in checkList" clickable :key="index" :title="item.title" class="padding">
                <template #right-icon>
                  <van-radio :name="item.id" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
          <div class="mt-10 mb-10 color3 font14">{{ $t("notice.memo") }}</div>
          <van-field v-model="reason" rows="2" autosize type="textarea" class="textareaVal" :placeholder="$t('notice.please_memo')" show-word-limit />
        </div>
        <van-goods-action>
          <van-goods-action-button v-if="!isNext" color="#4D88D2" type="warning" :text="$t('uniform.Next_Step')" @click="next()" />
          <van-goods-action-button v-else color="#4D88D2" type="warning" :text="$t('global.submit')" @click="clearOrder()" />
        </van-goods-action>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import storage from "store";
import Vue from "vue";
import { CURRENT_CHILD } from "@/store/mutation-types";
import i18n from "../i18n";
import { NoticeBar, ActionSheet, Empty, Toast, Icon, GoodsAction, GoodsActionIcon, GoodsActionButton, Sku, Divider, RadioGroup, Radio } from "vant";
import { orderList, clearOrderGoods } from "@/api/uniform";
Vue.use(ActionSheet);
Vue.use(NoticeBar);
Vue.use(Empty);
Vue.use(Icon);
Vue.use(Toast);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Divider);
Vue.use(Sku);
Vue.use(RadioGroup);
Vue.use(Radio);
export default {
  name: "uniform",

  data() {
    return {
      orderData: {},
      cancelData: {},
      show: false,
      checked: [],
      child_id: "",
      childData: {},
      isNext: false,
      checkList: [],
      options: [],
      reason: "",
      schoolType: "",
    };
  },
  beforeCreate() {
    // document.querySelector("body").setAttribute("style", "background-color:#fff;");
  },
  mounted() {},
  created() {
    this.childData = storage.get(CURRENT_CHILD + i18n.locale);
    this.schoolType = this.childData.schoolid;
    this.child_id = this.childData.id;
    this.getList();
  },
  methods: {
    showBtn(list) {
      if (list.children.findIndex((target) => target.status == 20) == -1) {
        return false;
      } else {
        return true;
      }
    },
    getList() {
      orderList({ child_id: this.child_id }).then((res) => {
        this.orderData = res.data.list;
        this.checkList = res.data.refund_reason;
      });
    },
    cancelOrder(list) {
      list.children.forEach((item) => {
        this.$set(item, "checked", false);
        item.disabled = true;
        if (item.status == 20) {
          item.disabled = false;
        }
      });
      this.cancelData = list;
      this.isNext = false;
      this.show = true;
    },
    next() {
      let checked = this.cancelData.children.filter((item) => item.checked);
      if (checked.length == 0) {
        return;
      }
      this.isNext = true;
    },
    clearOrder() {
      let checked = this.cancelData.children
        .filter((item) => item.checked)
        .map((_item) => {
          return _item.id;
        });
      clearOrderGoods({ ids: checked, reason: this.reason, options: this.options }).then(() => {
        this.show = false;
        this.getList();
        Toast.success(this.$t("ptc.successful"));
      });
    },

    router(item) {
      this.$router.push({
        path: "OrderDetail/" + item.invoice_id,
      });
    },
  },
};
</script>

<style scoped lang="less">
.uniform {
  overflow-y: scroll;
  height: 100vh;
}
.uniform::-webkit-scrollbar {
  display: none;
}
.child {
  padding: 13px 16px;
  background: #fff;
  align-items: center;
}
.order {
  background: #ffffff;
  border-radius: 8px;
  margin: 12px 16px;
  padding: 16px;

  .text-right {
    text-align: right;
  }
}
.img72 {
  width: 72px;
  height: 72px;
  border-radius: 8px;
  object-fit: cover;
}
.name {
  font-size: 14px;
  color: #323233;
}
.sub-name {
  color: #646566;
  font-size: 12px;
}
.orange {
  color: #ed6a0c;
}
.blue {
  color: #4d88d2;
}
.green {
  color: #45a367;
}
.red {
  color: #dc3838;
}
.align {
  align-items: center;
}
.content {
  padding: 16px;
  padding-bottom: 60px;
}
.textareaVal {
  background: #f7f8fa;
  border-radius: 8px;
}
.fontWeight {
  font-weight: 500;
}
.padding {
  padding: 10px 0;
}
.van-cell::after {
  left: 0;
  right: 0;
}
</style>
