<template>
  <div>
    <div v-if="stepList[currentIndex]">
      <van-notice-bar
          color="#45A367"
          background="#EBF5ED"
          left-icon="passed"
          v-if="stepList[currentIndex].status == 4"
      >
        该步骤审核已通过
      </van-notice-bar>
      <van-notice-bar
          color="#DC3838"
          background="#FBE7E7"
          left-icon="passed"
          wrapable
          v-if="stepList[currentIndex].status == 3"
      >
        <div>该步骤审核未通过，请重新填写并提交！</div>
        <div>
          {{ $t("coll.reason") }}{{ stepList[currentIndex].auditComments }}
        </div>
      </van-notice-bar>
      <van-notice-bar
          color="#646566"
          background="#EBEDF0"
          v-if="stepList[currentIndex].status == 1"
      >
        该步骤等待审核中
      </van-notice-bar>
    </div>
    <div v-if="stepList.length != 1">
      <div :class="stepList.length > 3 ? 'stepScroll' : ''">
        <div
            :class="stepList.length > 3 ? 'stepsWidth' : 'stepsList'"
            id="stepsWidth"
            ref="stepsWidth"
        >
          <div
              v-for="(list, index) in stepList"
              :key="index"
              class="step"
              :id="'stepWidth'+index"
              :class="stepList.length > 3 ? 'stepWidth' : 'stepFlex'"
          >
            <span
                class="stepNum"
                :class="stepName == list.flag ? 'active' : ''"
                @click="currentStep(list, index)"
            >{{ index + 1 }}</span
            >
            <div class="border"></div>
            <div class="font12 mt-5" v-if='showStatus'>
              <span  class="color6"  v-if="list.status == 1">
                <van-icon name="info" class="mr-5" />{{
                  statusList[list.status]
                }}
              </span>
              <span v-if="list.status == 4" class="passed"
              ><van-icon name="checked" class="mr-5" />{{
                  statusList[list.status]
                }}</span
              >
              <span v-if="list.status == 3" class="warning">
                <van-icon name="warning" class="mr-5" />{{
                  statusList[list.status]
                }}</span
              >
              <span v-if="list.status == 2" class="warning">
                <van-icon name="warning" class="mr-5" />{{
                  statusList[list.status]
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-row class="config mb-10" v-if="stepList.length != 0 && stepList[currentIndex].title!=''">
      <div class="color3 font16 text-center">
        <strong>{{ stepList[currentIndex].title }}</strong>
      </div>
      <div
          class="color6 font14 desc mt-15"
          v-if="stepList[currentIndex].extra_info != null && stepList[currentIndex].extra_info.desc!=null"
          v-html="stepList[currentIndex].extra_info.desc"
          @click="textClick"
      ></div>
    </van-row>
    <!--  v-if="Object.keys(dataDetails).length != 0" -->
    <van-row class="mt-10 stepconfig pt-10" v-if="Object.keys(dataDetails).length != 0">
      <school-bus
          v-if="stepName == 'schoolBus'"
          :dataDetails="dataDetails"
          :status="status"
          :disabled="disabled"
          @change="saveStep"
      />
      <school-bus2
          v-if="stepName == 'schoolBus2'"
          :dataDetails="dataDetails"
          :status="status"
          :disabled="disabled"
          @change="saveStep"
      />
      <school-bus3
          v-if="stepName == 'schoolBus3'"
          :dataDetails="dataDetails"
          :status="status"
          :disabled="disabled"
          @change="saveStep"
      />
      <parents-information
          v-if="stepName == 'parentsInformation'"
          :dataDetails="dataDetails"
          :status="status"
          :disabled="disabled"
          @change="saveStep"
      />
      <home-address
          v-if="stepName == 'homeAddress'"
          :dataDetails="dataDetails"
          :status="status"
          :disabled="disabled"
          @change="saveStep"
      />
      <bank-account
          v-if="stepName == 'bankAccount'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :status="status"
      />
      <pickup-card
          v-if="stepName == 'pickUpCard'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :status="status"
          :schoolId='schoolId'
      />
      <Uniform
          v-if="stepName == 'uniform'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :status="status"
      />
      <medical
          v-if="stepName == 'medical'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :protocol="protocol"
          :status="status"
      />
      <catering
          v-if="stepName == 'catering'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :status="status"
      />
      <catering2
          v-if="stepName == 'catering2'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :status="status"
      />
      <language
          v-if="stepName == 'language'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :status="status"
      />
      <agreement
          v-if="stepName == 'agreement'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :protocol="protocol"
          :status="status"
      />
      <successiveSteps1
          v-if="stepName == 'successiveSteps1'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :protocol="protocol"
          :status="status"
      />
      <successiveSteps2
          v-if="stepName == 'successiveSteps2'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :protocol="protocol"
          :status="status"
      />
      <ownSnack
          v-if="stepName == 'ownSnack'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :protocol="protocol"
          :status="status"
      />
      <safety
          v-if="stepName == 'safety'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :protocol="protocol"
          :disabled="disabled"
          :status="status"
      />
      <family-identity
          v-if="stepName == 'familyIdentity'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :status="status"
      />
      <material-fee
          v-if="stepName == 'materialFee'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :status="status"
      />
      <insurance
          v-if="stepName == 'insurance'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :status="status"
          :schoolId="schoolId"
      />
      <return-school
          v-if="stepName == 'returnSchool'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :status="status"
      />
      <return-school2
          v-if="stepName == 'returnSchool2'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :status="status"
      />
      <return-school3
          v-if="stepName == 'returnSchool3'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :status="status"
      />
      <medical2
          v-if="stepName == 'medical2'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :protocol="protocol"
          :status="status"
      />
      <studentIdentity
          v-if="stepName == 'studentIdentity'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :protocol="protocol"
          :status="status"
      />
      <pickupcard-new
        v-if="stepName == 'pickUpCardNew'"
        :dataDetails="dataDetails"
        @change="saveStep"
        :disabled="disabled"
        :protocol="protocol"
        :schoolId="schoolId"
        :status="status"
      />
      <live-people
          v-if="stepName == 'livePeople'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :protocol="protocol"
          :status="status"
      />
      <medical3
          v-if="stepName == 'medical3'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :protocol="protocol"
          :status="status"
      />
      <highSchool
          v-if="stepName == 'xueji6to7'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :protocol="protocol"
          :status="status"
      />
      <outing-card
          v-if="stepName == 'outingCard'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :protocol="protocol"
          :status="status"
      />
      <outing-health
          v-if="stepName == 'outingHealth'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :protocol="protocol"
          :status="status"
      />
      <special-medication
          v-if="stepName == 'specialMedication'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :protocol="protocol"
          :status="status"
      />
      <exempt-PE
          v-if="stepName == 'exemptPE'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :protocol="protocol"
          :status="status"
      />
      <faPiao
          v-if="stepName == 'faPiao'"
          :dataDetails="dataDetails"
          @change="saveStep"
          :disabled="disabled"
          :protocol="protocol"
          :status="status"
          :deviceType="deviceType"
      />
      
    </van-row>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Col,
  NoticeBar,
  Toast,
  RadioGroup,
  Radio,
  ActionSheet,
  CellGroup,
  Uploader,
  Picker,
  Popup,
  Checkbox,
  ImagePreview,
  Cascader,
  Search,
  Dialog ,
  DatetimePicker
} from "vant";
Vue.use(NoticeBar);
Vue.use(Toast);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Col);
Vue.use(ActionSheet);
Vue.use(CellGroup);
Vue.use(Uploader);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Checkbox);
Vue.use(Cascader);
Vue.use(Search);
Vue.use(Dialog);
Vue.use(DatetimePicker);
import { childrenStatus, stepInfo, saveStep} from "@/api/collection";
import SchoolBus from "@/components/steps/SchoolBus";
import homeAddress from "@/components/steps/HomeAddress";
import bankAccount from "@/components/steps/BankAccount";
import PickUpCard from "@/components/steps/PickUpCard";
import Uniform from "@/components/steps/Uniform";
import Medical from "@/components/steps/Medical";
import Catering from "@/components/steps/Catering";
import Catering2 from "@/components/steps/Catering2";
import Language from "@/components/steps/Language";
import Agreement from "@/components/steps/Agreement";
import Safety from "@/components/steps/Safety";
import FamilyIdentity from "@/components/steps/FamilyIdentity";
import MaterialFee from "@/components/steps/MaterialFee";
import Insurance from "@/components/steps/Insurance";
import SchoolBus2 from "@/components/steps/SchoolBus2";
import SchoolBus3 from "@/components/steps/SchoolBus3";
import parentsInformation from "@/components/steps/parentsInformation";
import ReturnSchool from "@/components/steps/ReturnSchool";
import ReturnSchool2 from "@/components/steps/ReturnSchool2";
import ReturnSchool3 from "@/components/steps/ReturnSchool3";
import Medical2 from "@/components/steps/MedicalInfo";
import studentIdentity from "@/components/steps/studentIdentity";
import pickUpCardNew from "@/components/steps/pickUpCardNew";
import SuccessiveSteps1 from "@/components/steps/SuccessiveSteps1";
import SuccessiveSteps2 from "@/components/steps/SuccessiveSteps2";
import OwnSnack from "@/components/steps/OwnSnack";
import LivePeople from "@/components/steps/LivePeople";
import Medical3 from "@/components/steps/Medical3";
import HighSchool from "@/components/steps/HighSchool";
import outingCard from "@/components/steps/outingCard";
import outingHealth from "@/components/steps/outingHealth";
import SpecialMedication from "@/components/steps/specialMedication";
import ExemptPE from "@/components/steps/ExemptPE";
import FaPiao from "@/components/steps/FaPiao";
import vueEsign from "vue-esign";
import { getimgsrc } from "@/plugins/util";

Vue.use(vueEsign);
export default {
  name: "Course",
  components: {
    "school-bus": SchoolBus,
    "home-address": homeAddress,
    "bank-account": bankAccount,
    "pickup-card": PickUpCard,
    Uniform: Uniform,
    medical: Medical,
    catering: Catering,
    catering2: Catering2,
    language: Language,
    agreement: Agreement,
    safety: Safety,
    "family-identity": FamilyIdentity,
    "material-fee": MaterialFee,
    insurance: Insurance,
    "school-bus2": SchoolBus2,
    "school-bus3": SchoolBus3,
    "parents-information": parentsInformation,
    "return-school": ReturnSchool,
    "return-school2": ReturnSchool2,
    "return-school3": ReturnSchool3,
    "medical2" : Medical2,
    "studentIdentity" : studentIdentity,
    "pickupcard-new" : pickUpCardNew,
    "successiveSteps1": SuccessiveSteps1,
    "successiveSteps2": SuccessiveSteps2,
    "ownSnack": OwnSnack,
    "live-people": LivePeople,
    "medical3": Medical3,
    'highSchool' : HighSchool,
    'outing-card' : outingCard,
    'outing-health' : outingHealth,
    'special-medication' :SpecialMedication,
    'exempt-PE' :ExemptPE,
    'faPiao':FaPiao,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      dataDetails: {},
      coll_id: "",
      stepName: "",
      childid: "",
      stepList: "",
      currentIndex: 0,
      statusList: [],
      status: 0,
      protocol: [],
      disabled: 0,
      imgs: [],
      marginLeft: 0,
      signQiniuToken: "",
      schoolId:'',
      showStatus:true,
      deviceType:'',
    };
  },
  beforeCreate() {
    document
        .querySelector("body")
        .setAttribute("style", "background-color:#F7F8FA;");
  },
  created() {
    this.detectDevice();
  },
  mounted() {
    if (this.$route.params.id) {
      this.coll_id = this.$route.params.id;
      this.stepName = this.$route.params.stepName;
      this.childid = this.$route.params.childid;
      this.getData();
    }
  },
  methods: {
    detectDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        this.deviceType = 'android';
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        this.deviceType = 'ios';
      } else {
        this.deviceType = 'other';
      }
    },
    textClick(e) {
      if (e.target.tagName.toUpperCase() === "IMG") {
        this.imgs = getimgsrc(this.stepList[this.currentIndex].extra_info.desc);
        let index = this.imgs.indexOf(e.target.src);
        ImagePreview({
          images: this.imgs,
          closeable: true,
          startPosition: index,
        });
      }
    },
    getData() {
      childrenStatus(this.coll_id).then((res) => {
        this.isCommon= res.data.type
        this.schoolId = res.data.schoolId;
        this.stepList = res.data.childrenStatus[this.childid].steps;
        this.statusList = res.data.stepStatusList;
        this.signQiniuToken = res.data.signQiniuToken;
        this.stepData();
      });
    },
    currentStep(list, index) {
      this.stepName = list.flag;
      this.currentIndex = index;
      this.stepData('scroll');
    },
    stepData(type) {
      this.dataDetails = {};
      // this.dataDetails.isCommon=this.isCommon
      stepInfo(this.coll_id, this.stepName, { childId: this.childid }).then(
          (res) => {
            if (Object.keys(res.data).length != 0) {
              this.dataDetails = res.data;
            }

            Vue.set(this.dataDetails, "signQiniuToken", this.signQiniuToken);
            Vue.set(this.dataDetails, "isCommon", this.isCommon);
            this.disabled = 0;
            for (var i = 0; i < this.stepList.length; i++) {
              if (this.stepList[i].flag == this.stepName) {
                this.currentIndex = i;
                Vue.set(this.dataDetails, "sign_url", this.stepList[i].sign_url==undefined?"":this.stepList[i].sign_url);
                Vue.set(this.dataDetails, "sign", this.stepList[i].sign==undefined?"":this.stepList[i].sign);
                if (
                    this.stepList[i].extra_info != null &&
                    this.stepList[i].extra_info.protocol
                ) {
                  this.dataDetails.protocol =
                      this.stepList[i].extra_info.protocol;
                } else {
                  this.dataDetails.protocol = [];
                }
              }
            }
            this.status = this.stepList[this.currentIndex].status;
            if(type!='scroll'){
              this.$nextTick(() => {
                let stepsWidth = document.getElementById("stepsWidth");
                if (this.currentIndex > 2) {
                  let width=document.querySelector('#stepWidth0').offsetWidth
                  stepsWidth.scrollLeft = this.currentIndex * width;
                }

              });
            }

          }
      );

    },
    saveStep(obj) {
      let saveData = Object.assign({ childId: this.childid }, obj);
      // return
      saveStep(this.coll_id, this.stepName, saveData).then((response) => {
        if (response.code == 0) {
          this.stepList[this.currentIndex].status=1
          let currentIndexStatus=this.stepList[this.currentIndex]
          Vue.set(this.stepList, this.currentIndex, currentIndexStatus);
          this.showStatus=false
          this.$nextTick(() => {
            this.showStatus=true
          })
          if (saveData.sign != "") {
            Vue.set(
                this.stepList[this.currentIndex],
                "sign_url",
                saveData.sign_url
            );
            Vue.set(this.stepList[this.currentIndex], "sign", saveData.sign);
          }
          this.disabled += 1;
          if(this.isCommon!=2){
            if(this.completed()){
              this.$router.push({
                name: 'Completed',
                params: {id: this.coll_id,childid:this.childid }
              })
              return
            }else{
              Toast.success("success");
            }
            if ((this.stepList.length > this.currentIndex + 1)) {
              this.stepName = this.stepList[this.currentIndex + 1].flag;
              this.stepData();
            }
          } else{
            Dialog.alert({
              // title: '标题',
              message: this.$t("coll.info_submit")+ '\n' +this.$t("coll.info_medical"),
            }).then(() => {
              this.stepData();
              // on close
            });
          }
        } else {
          this.disabled += 1;
        }
      });
    },
    completed(){
      let flag = true;
      for(var i=0;i<this.stepList.length;i++){
        if(this.stepList[i].status==0 || this.stepList[i].status==3){
          return false;
        }
      }
      return flag
    }
  },
};
</script>
<style lang="less" >
.clickNone {
  pointer-events: none;
}
.ride {
  display: inline-block;
  background: #f7f8fa;
  width: 100%;
  // height: 40px;
  text-align: center;
  // line-height: 40px;
  border-radius: 4px;
  padding: 10px 0px;
}
.rideActive {
  background: #4d88d2;
  color: #fff !important;
}
.signText {
  width: 100%;
  height: 86px;
  border-radius: 8px;
  background: #f7f8fa;
  text-align: center;
  line-height: 86px;
}
.signImg {
  border: 1px solid #dcdee0;
  max-width: 100%;
  border-radius: 4px;
}
.signCon {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  left: 0;
  top: 0;
}
.saveBtnStep {
  z-index: 99;
  width: 100%;
  display: inline-block;
  height: 43px;
  color: #fff;
  background: #4d88d2;
  text-align: center;
  line-height: 43px;
  border-radius: 100px;
  font-size: 14px;
}
.bgGray {
  background: #f7f8fa;
  border-radius: 4px;
}
.flex {
  display: flex;
  .flexWidth {
    width: 16px;
    padding-top: 3px;
    .icon {
      display: inline-block;
      width: 6px;
      height: 14px;
      background: #4d88d2;
      border-radius: 3px;
    }
  }
  .flex1 {
    flex: 1;
    .text {
      font-size: 12px;
      font-weight: 400;
      color: #646566;
      line-height: 20px;
    }
    .title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 26px;
      // word-wrap:break-word;
      // text-align: justify;
    }
  }
}
.enText {
  word-wrap: break-word;
  text-align: justify;
}
.bus {
  background: #f7f8fa;
  padding: 20px 16px;
  border-radius: 8px;
}
.desc {
  img {
    max-width: 100%;
  }
}
.protocolImg {
  max-width: 100%;
}
.disabledSubmit {
  opacity: 0.5;
}
.borderInput{
  .van-search__content{
    border-radius: 100px;
  }
  .van-field__left-icon{
    color: #C8C9CC;
  }
}
</style>


<style lang="less" scoped>
.stepsList {
  display: flex;
  background: #fff;
  padding: 16px 16px 0;
}
.stepFlex {
  flex: 1;
}
.stepScroll {
  padding: 16px 0 5px;
  background: #fff;
}
.stepsWidth {
  height: 100%;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.stepsWidth::-webkit-scrollbar {
  display: none;
}
.step {
  text-align: center;
  position: relative;
  display: inline-table;
  width: 110px;
  height: 50px;
  .stepNum {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #c8c9cc;
    font-size: 12px;
    color: #fff;
    text-align: center;
    line-height: 20px;
  }
  .border {
    border-top: 1px solid #ccc;
    position: absolute;
    right: 50%;
    top: 12px;
    width: 70%;
    margin-right: 15%;
  }
  .active {
    background: #4d88d2;
  }
}
.step:first-child .border {
  border-top: none;
}
.config {
  background: #fff;
  padding: 16px;
}
.stepconfig{
  background: #fff;
  padding:16px;
}
.warning {
  color: #dc3838;
}
.passed {
  color: #45a367;
}
.desc {
  max-width: 100% !important;
}

img {
  max-width: 100% !important;
}
body::-webkit-scrollbar {
  display: none;
}
</style>
