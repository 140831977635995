<template>
    <div>
        <van-sticky>
            <children @change="childChange" @click='commentData' :startYear='columns' :children="childrenList"
                v-if="childrenList.length > 0" />
        </van-sticky>

        <van-row v-if='listData != 0'>
            <van-row class="currentAssessment " v-if='infoUrl != ""'>
                <a class="flex attachmentImg" :href="infoUrl">
                    <img src="@/assets/Assessment.png" alt="Assessment" />
                    <span class="ml-10 color3 font14 flex1">{{ $t('assessments.Annual_Standardized') }}</span>
                    <van-icon name="arrow" color="#969799" />
                </a>
            </van-row>
            <van-row class='list' v-if="listData != 0">
                <van-row class="item-1" v-for='(list, index) in listData' :key="index">
                    <van-row class="title">
                        <strong> {{ list.title }}</strong>
                        <a class="guide" :href="list.guide" v-if="list.guide != ''">
                            <span><van-icon name="question-o" size='16' class='questionIcon' color='#4D88D2' />{{
                    $t('assessments.Guide') }} </span>
                        </a>
                    </van-row>
                    <van-row :class="ind == 0 ? 'mt-20' : ''" v-for='(_item, ind) in list.items' :key="ind">
                        <van-row v-if='_item.attachments.length > 0'>
                            <a class="files" :href="item.url" v-for='(item, id) in _item.attachments' :key="id">
                                <van-row type="flex" justify="space-between">
                                    <van-col :span="22">
                                        <van-row class="attachment-icon">
                                            <van-icon size="20" class='filesIcon' color="#4C98FC" name="column" />
                                            <!-- <img :src="`http://mega.ivymik.cn/file-icon/223017b546a29cfe7aa7f1efcefc0b88.png`" /> -->
                                            <span class="ml-5 font14"> {{ item.title }}</span>
                                        </van-row>
                                    </van-col>
                                    <van-col>
                                        <van-icon name="arrow" color="#969799" style="vertical-align: middle;" />
                                    </van-col>
                                </van-row>
                            </a>
                        </van-row>
                        <van-row class="comment" :class='list.items.length != 1 ? "mb-20" : ""'
                            v-if='_item.comment != ""'>
                            <van-row class="comment-1">
                                <strong> {{ $t('assessments.Comment') }}</strong>
                            </van-row>
                            <van-row class="comment-text" :class="_item.showTotal ? '' : 'textOVerThree'">
                                <div v-html="_item.comment" :ref='_item.refParent'></div>
                                <a href='javascript:;' class="expand" :class='_item.exchangeButton ? "showText" : ""'
                                    @click="showTotalIntro(_item, index, ind)" v-if="_item.showExchangeButton">
                                    {{ _item.exchangeButton ? $t('assessments.Expand') : $t('assessments.Collapse') }}
                                    <van-icon name="arrow-down" v-if='_item.exchangeButton' />
                                    <van-icon name="arrow-up" v-else />
                                </a>
                            </van-row>
                        </van-row>
                    </van-row>
                </van-row>
            </van-row>
            <van-row class='list' v-else>
                <van-empty :description="$t('calendar.no_data')" />
            </van-row>
        </van-row>
        <van-row v-else>
            <van-empty :description="$t('global.empty')" />
        </van-row>
    </div>
</template>

<script>
import Vue from 'vue';
import { Cell, Icon, Picker, Col, Row, ActionSheet, Empty, Toast, Sticky } from 'vant';
import Children from '@/components/ChildrenAction'
import storage from 'store'
import { CURRENT_CHILD } from '@/store/mutation-types'
import { childList } from '@/api/child'
import { yearList, commentList } from '@/api/Assessments'

Vue.use(Cell);
Vue.use(Icon);
Vue.use(Picker);
Vue.use(Toast);
Vue.use(Col);
Vue.use(Row);
Vue.use(ActionSheet);
Vue.use(Empty);
Vue.use(Sticky);
export default {
    name: 'Assessments',
    components: {
        'children': Children,
    },
    data() {
        return {
            childrenList: [],
            childrenStatus: {},
            childid: '',
            show: false,
            columns: [],
            expand: false,
            listData: null,
            yearId: false,
            yearindex: '',
            infoUrl: ''
        }
    },
    beforeCreate() {
    },
    created() {
        childList().then(res => {
            this.childrenList = res.data
            this.loading = false
            let cIds = [];
            this.childrenList.map(item => {
                this.childrenStatus[item.id] = true
                cIds.push(item.id)
            })
            if (this.childrenList.length < 1) {
                this.emptyChild = true
            }
            let cid = storage.get(CURRENT_CHILD) ? storage.get(CURRENT_CHILD).id : ''
            this.childid = cIds.indexOf(cid) > -1 ? cid : cIds[0]
        })

    },
    methods: {
        childChange(val) {
            this.childid = val.id
            this.yearList()
        },
        showTotalIntro(list, index, ind) {
            this.listData[index].items[ind].showTotal = !this.listData[index].items[ind].showTotal;
            this.listData[index].items[ind].exchangeButton = !this.listData[index].items[ind].exchangeButton
        },
        yearList() {
            console.log(this.childid)
            yearList(this.childid).then(res => {
                this.columns = res.data
                if (res.data.length != 0) {
                    if (this.yearId) {
                        this.commentData(this.yearindex)
                    }
                }
            })
        },
        commentData(index) {
            this.yearId = true
            this.yearindex = index
            if (this.columns.length == 0) {
                this.listData = 0
                return
            }
            commentList(this.childid, this.columns[index].value).then(res => {
                var data = res.data.assessment
                if (data.length != 0) {
                    for (var i = 0; i < data.length; i++) {
                        for (var j = 0; j < data[i].items.length; j++) {
                            data[i].items[j].showExchangeButton = false
                            data[i].items[j].showTotal = false
                            data[i].items[j].exchangeButton = false
                            data[i].items[j].refParent = 'parent' + j
                        }
                    }
                    this.listData = data
                    this.infoUrl = res.data.info
                    this.$nextTick(function () {
                        this.showData()
                    })
                } else {
                    this.listData = 0
                    this.infoUrl = res.data.info
                }
            })
        },
        showData() {
            this.$nextTick(function () {
                for (var j = 0; j < this.listData.length; j++) {
                    for (var k = 0; k < this.listData[j].items.length; k++) {
                        let refParent = this.listData[j].items[k].refParent
                        if (this.$refs[refParent]) {
                            let descHeight = window.getComputedStyle(this.$refs[refParent][0]).height.replace('px', '');
                            if (descHeight > 60) {
                                Vue.set(this.listData[j].items[k], 'showExchangeButton', true);
                                Vue.set(this.listData[j].items[k], 'exchangeButton', true);
                                Vue.set(this.listData[j].items[k], 'showTotal', false);
                            } else {
                                Vue.set(this.listData[j].items[k], 'showTotal', true);
                                Vue.set(this.listData[j].items[k], 'showExchangeButton', false);
                            }
                        }
                    }
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.schoolYear {
    line-height: 52px;
    padding-right: 16px;
    margin-right: 16px;
    position: relative;

    .open {
        position: absolute;
        right: 0;
        top: 18px;
    }
}

.list {
    margin-top: 15px;

    .item-1 {
        background-color: #fff;
        display: block;
        margin: 0 16px 12px 16px;
        padding: 15px;
        border-radius: 8px;
        position: relative;
        overflow: hidden;

        .title {
            // padding-bottom:8px;
            font-size: 15px;
            display: flex;

            strong {
                flex: 1
            }

            .guide {
                width: 82px;
                font-size: 13px;
                color: #4D88D2;
                vertical-align: middle;
                margin-left: 20px;
                text-align: right;
                margin-top: 2px;

                span {
                    position: relative;
                }

                .questionIcon {
                    position: absolute;
                    left: -20px;
                    top: 2px;
                }
            }
        }

        .files {
            color: #323233;
            font-size: 14px;
            background: #F7F8FA;
            margin-top: 8px;
            padding: 8px 10px;
            display: block;
            border-radius: 5px;

            .attachment-icon {
                line-height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                position: relative;
                line-height: 22px;

                .font14 {
                    font-size: 14px;
                    padding-left: 22px;
                }

                .filesIcon {
                    vertical-align: middle;
                    position: absolute;
                    top: 1px
                }
            }
        }

        .comment {
            margin-top: 16px;
            color: #323233;
            font-size: 14px;

            .comment-1 {
                color: #646566;
                margin-bottom: 8px;
                font-size: 14px;
            }

            .textOVerThree {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                text-align: justify;
                -webkit-box-orient: vertical;
                position: relative;
            }

            .comment-text {
                line-height: 20px;

                .expand {
                    color: #4D88D2;
                }

                .showText {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    background: #fff;
                    padding-left: 14px;
                }
            }
        }
    }
}

.currentAssessment {
    background-color: #E5EDFC;
    display: block;
    margin: 16px 16px 12px 16px;
    padding: 13px;
    border-radius: 8px;
    position: relative;

    .attachmentImg {
        // position: relative;
        align-items: center;

        img {
            width: 18px;
            // position: absolute;
            // top:2px
        }
    }

}
</style>
