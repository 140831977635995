<template>
  <div v-if="journalData">
    <children :children="journalData.child_items" @change="childChange" v-if="$route.name != 'PreviewJournalContent'" />
    <div class="content" :class="{ 'mb-50': $route.name != 'PreviewJournalContent' }">
      <div class="contentText">
        <van-row type="flex" justify="end"
          v-if="typeof journalData.isBilingual != 'undefined' && journalData.isBilingual == 1">
          <switch-lang color="#377ED7" />
        </van-row>
        <router-link class="journalAll" :to="`/JournalSearch`">
          <div class="mb-10"><van-icon name="bars" size="16" /> {{ $t("router.journal_search") }}</div>
        </router-link>
        <div class="title">{{ journalData.title }}</div>
        <div class="sub-title mb-15">{{ journalData.updated_at }}</div>
        <div class="mt-15 " v-if="journalData.sign_as">
          <router-link class="color3 flex" :to="{ name: 'JournalSearch', query: { uid: journalData.sign_as.uid } }">
            <div>
              <van-image round width="44px" height="44px" :src="journalData.sign_as.avatar" class="avatar-1 mr-10" />
            </div>
            <div class="item-1-1 flex1">
              <div class="flex align-items">
                <div class="flex1">
                  <div class="name">{{ journalData.sign_as.name }}</div>
                  <div class="sub-name">{{ journalData.sign_as.title }}</div>
                </div>
                <div><van-icon name="arrow" class="color9" /></div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="rich-text journal_content mb-50" v-html="journalData.content" @click="textClick"></div>
        <attachment :attachments="journalData.attachments" v-if="Object.values(journalData.attachments).length > 0"
          class="mb-50 pb-50" />
      </div>
      <comment type="journal" :id="journalData.id" :childid="childid" :bottomBar="true"
        v-if="$route.name != 'PreviewJournalContent' && commentReload" />
    </div>
    <div class="bottom-box" v-if="journalData.journal_items && journalData.journal_items.length > 0">
      <van-row type="flex" align="center">
        <van-col span="10"><van-button round type="default" @click="prve">{{ $t("journal.prev_journal")
            }}</van-button></van-col>
        <van-col span="4"><van-icon name="more-o" @click="show = true" /></van-col>
        <van-col span="10"><van-button round type="info" @click="next">{{ $t("journal.next_journal")
            }}</van-button></van-col>
      </van-row>
    </div>
    <van-action-sheet v-model="show" :actions="actions" :cancel-text="$t('global.cancel')"
      :description="$t('journal.all_journals')" @select="onSelect" />
    <van-dialog v-model="qrcodeShow" :title="$t('notice.Video_Link')" :show-cancel-button="false"
      :show-confirm-button="false">
      <div class="noticeQrcodeLink">
        <div class="color6 font12 text-center mb-15">{{ $t('notice.download_or_QR') }}</div>
        <vue-qr ref="qrCode" :logoSrc="logoUrl" :text="currentLink" :logoScale="0.2" :logoMargin="2"
          :logoCornerRadius="2" :size="144" :margin="0" :correntLevel="1" />
        <div class="noticeCopyLink">{{ currentLink }}</div>
      </div>
      <van-row type="flex" justify="center" class="buttonCopy">
        <van-col span="12" @click="cancelCopy">{{ $t('global.close') }}</van-col>
        <van-col span="12" class="btnText" @click="copyLink" v-html="copyText"></van-col>
      </van-row>
    </van-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { ActionSheet, Toast, ImagePreview, Dialog } from "vant";
import storage from "store";
import Comment from "@/components/Comment";
import Children from "@/components/Children";
import Attachment from "@/components/Attachment";
import { journalItem, journalItemPreview } from "@/api/journal";
import { CURRENT_CHILD, APP_LANGUAGE } from "@/store/mutation-types";
import { getimgsrc } from "@/plugins/util";
import SwitchLang from "@/components/SwitchLang";
import vueQr from 'vue-qr'
import store from "../store";

Vue.use(ActionSheet);
Vue.use(Toast);
Vue.use(Dialog);

export default {
  name: "JournalContent",
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    attachment: Attachment,
    children: Children,
    "switch-lang": SwitchLang,
    comment: Comment,
    vueQr
  },
  data() {
    return {
      journalData: null,
      show: false,
      actions: [],
      childid: "",
      commentReload: false,
      imgs: [],
      qrcodeShow: false,
      currentLink: '',
      copyText: '',
      logoUrl: '',
      schoolType: '',
      currentLang: ''
    };
  },
  created() {
    this.currentLang = storage.get(APP_LANGUAGE) || 'zh'

  },
  mounted() {
    window.showVideo = this.showVideo
    this.fetchData(this.$route.params.id);
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background:#fff')
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style')
  },
  methods: {
    fetchData(id, _childid) {
      this.schoolType = store.getters.wxState || 'ds'
      window.scrollTo(0, 0);
      if (this.$route.name == "PreviewJournalContent") {
        journalItemPreview(id).then((res) => {
          this.journalData = res.data;
          this.viewLink()
        });
      } else {
        let ccId = storage.get(CURRENT_CHILD) ? storage.get(CURRENT_CHILD).id : "";
        this.childid = parseInt(_childid) || parseInt(this.$route.query.childId) || ccId || "";
        journalItem(id, this.childid).then((res) => {
          this.commentReload = false;
          this.$nextTick(() => {
            this.commentReload = true;
          });
          this.journalData = res.data;
          this.viewLink()
          this.actions = [];
          if (this.childid == "") {
            this.childid = this.journalData.child_items[0].id;
          }
          this.journalData.journal_items.map((item) => {
            this.actions.push({
              id: item.id,
              name: item.title,
              subname: item.sign_as.name,
              color: item.id == id ? "#ee0a24" : "",
            });
          });
        });
      }
    },
    viewLink() {
      const regex = /<\/video>/gi;
      var text = ''
      if (this.schoolType == 'ds') {
        text = "</video><div class='joutnalCopyRight'><span class='joutnalCopyVideo'><span class='iconfont iconfont-copy  mr-4'></span>" + this.$t('notice.Video_Link') + "</span> </div>"
      } else {
        text = "</video><div class='joutnalCopyRight'><span class='mr-20 download'><span class='iconfont iconfont-wenhao  mr-4'></span>" + this.$t('notice.Download_Videos') + "</span><span class='joutnalCopyVideo'><span class='iconfont iconfont-copy mr-4'></span> " + this.$t('notice.Video_Link') + "</span> </div>"
      }
      const html = this.journalData.content.replace(regex, text);
      this.journalData.content = html
      this.$nextTick(() => {
        var newEle = document.getElementsByClassName('joutnalCopyVideo')
        for (let i = 0; i < newEle.length; i++) {
          newEle[i].onclick = () => {
            this.showVideo(i)
          };
        }
        var downloads = document.getElementsByClassName('download')
        for (let i = 0; i < downloads.length; i++) {
          downloads[i].onclick = () => {
            if (this.currentLang == 'zh') {
              ImagePreview({
                images:['https://m2.files.ivykids.cn/cloud01-file-8025768FhqVad_IuSH7i0eD4nb1Wf7d02Kd.jpg'],
              });
            }else{
              ImagePreview({
                images:['https://m2.files.ivykids.cn/cloud01-file-8025768Fhgwc-9-2tE_02XD8mOt9LKEU9DV.jpg'],
              });
            }
          };
        }
      });
    },
    getvideoFromRichtext(html) {
      const regex = /<source.*?src=['"](.*?)['"].*?>/g;
      const srcList = [];
      let match;
      while ((match = regex.exec(html)) !== null) {
        srcList.push(match[1]);
      }
      return srcList;
    },
    showVideo(index) {
      if (this.schoolType == 'ds') {
        this.logoUrl = require('@/assets/qr_ds_logo.png');
      } else {
        this.logoUrl = require('@/assets/qr_ivy_logo.png');
      }
      this.qrcodeShow = true
      this.copyText = this.$t('notice.Copy_Link')
      this.currentLink = this.getvideoFromRichtext(this.journalData.content)[index]
    },
    cancelCopy() {
      this.qrcodeShow = false
    },
    copyLink() {
      var input = document.createElement("input"); // 创建input对象
      input.value = this.currentLink; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.copyText = '<span class="iconfont iconfont-success"></span> ' + this.$t("notice.Copied")
      setTimeout(() => {
        this.qrcodeShow = false
      }, 1000);
    },
    onSelect(item) {
      this.show = false;
      // this.$router.push({ name: 'JournalContent', params: {id: item.id} })
      this.fetchData(item.id);
    },
    childChange(val) {
      // this.$router.push({ name: 'JournalContent', params: {id: this.$route.params.id} })
      this.fetchData(this.journalData.id, val.id);
    },
    prve() {
      let cIndex = this.journalData.journal_items.indexOf(this.journalData.journal_items.find((item) => item.id == this.journalData.id));
      if (cIndex > 0) {
        this.fetchData(this.journalData.journal_items[cIndex - 1].id);
      } else {
        Toast(this.$t("journal.first_one"));
      }
    },
    next() {
      let cIndex = this.journalData.journal_items.indexOf(this.journalData.journal_items.find((item) => item.id == this.journalData.id));
      if (cIndex + 1 < this.journalData.journal_items.length) {
        this.fetchData(this.journalData.journal_items[cIndex + 1].id);
      } else {
        Toast(this.$t("journal.last_one"));
      }
    },
    textClick(e) {
      if (e.target.tagName.toUpperCase() === "IMG") {
        this.imgs = getimgsrc(this.journalData.content);
        let index = this.imgs.indexOf(e.target.src);
        ImagePreview({
          images: this.imgs,
          closeable: true,
          startPosition: index,
        });
      }
    },
  },
};
</script>
<style lang="less">
.journal_content ol,
.journal_content ul {
  margin: auto !important;
  list-style: revert;
  padding: revert !important;
}
</style>
<style lang="less" scoped>
.content {
  .p15 {
    padding: 15px;
  }

  .contentText {
    background-color: #fff;
    padding: 15px;
  }

  .journalAll {
    color: #4d88d2;
    font-size: 14px;
  }

  .title {
    padding: 0 0 10px 0;
    font-size: 17px;
    font-weight: bold;
    color: #323233;
    line-height: 26px;
  }

  .sub-title {
    font-size: 12px;
    font-weight: 400;
    color: #969799;
    line-height: 12px;
  }

  .item-1-1 {
    .name {
      font-size: 14px;
      font-weight: bold;
      color: #323233;
      margin-bottom: 3px;
      margin-top: 3px;
    }

    .sub-name {
      font-size: 13px;
      font-weight: 400;
      color: #646566;
    }
  }

  .rich-text {
    font-size: 14px;
    font-weight: 400;
    color: #323233;
    overflow: hidden;
    line-height: 22px;

    & img {
      max-width: 100%;
      margin-bottom: 1em;
    }

    & video {
      width: 100%;
      max-width: 100%;
    }
  }
}

.bottom-box {
  text-align: center;
  position: fixed;
  height: 50px;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ebedf0;

  .van-row {
    height: 50px;

    .van-button {
      width: 136px;
      height: 40px;
    }

    .van-icon {
      font-size: 24px;
      color: #646566;
    }
  }
}

.van-action-sheet__item {
  text-align: left;
}

.buttonCopy {
  text-align: center;
  height: 55px;
  line-height: 55px;
  border-top: 1px solid #ebedf0;

  .btnText {
    border-left: 1px solid #ebedf0;
    color: #4D88D2
  }
}
</style>

<style lang="less">
.joutnalCopyRight {
  text-align: right;
  margin-top: -5px;
  padding-bottom: 10px;
  .mr-4{
    padding-right:4px
  }

  .joutnalCopyVideo {
    margin: 0;
    color: #4D88D2;
    font-size: 14px;
    align-items: center;
    display: inline-flex
  }
  .download{
    color: #646566;
    font-size: 14px;
    align-items: center;
    display: inline-flex
  }
}

.content {
  .avatar-1 {
    img {
      object-fit: cover;
    }
  }
}
</style>
