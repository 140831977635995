<template>
    <div :class="stepStatus == 4 && dataDetails.isCommon==1? 'clickNone' : ''">
      <!-- <div class="flex mt-20">
        <div class="flexWidth"><span class="icon"></span></div>
        <div class="flex1">
          <div class="title">{{ $t("coll.medical_report") }}</div>
          <div class="color6 font14 pt-5">{{ $t("coll.multiple_img") }}</div>
        </div>
      </div>
      <van-row class="mt-20">
        <van-uploader
          v-model="medicalList"
          @delete="deleteimg"
          :after-read="afterRead"
          :before-read="beforeRead"
          name="medical"
        />
      </van-row> -->
      <div class="flex mt-20">
        <div class="flexWidth"><span class="icon"></span></div>
        <div class="flex1">
          <div class="title">{{ $t("coll.copy_record") }}</div>
          <div class="color6 font14 pt-5">{{ $t("coll.multiple_img") }}</div>
        </div>
      </div>
      <van-row class="mt-20">
        <van-uploader
          v-model="fileList"
          @delete="deleteimg"
          :after-read="afterRead"
          :before-read="beforeRead"
          name="vaccine"
        />
      </van-row>
      <div class="flex mt-20">
        <div class="flexWidth"><span class="icon"></span></div>
        <div class="flex1">
          <div class="title">{{ $t("coll.preferred_hospital") }}</div>
        </div>
      </div>
      <div v-for="(item, index) in hospital1" :key="index + 10">
        <div class="color6 font14 mt-20">
          {{ $t("coll.preferred_hospital") }} {{ index + 1 }}
        </div>
        <van-field
          v-model="hospital[index]"
          :placeholder="$t('global.please_input')"
          class="bgGray mt-10"
        />
      </div>
      <div class="flex mt-20">
        <div class="flexWidth"><span class="icon"></span></div>
        <div class="flex1">
          <div class="title">{{ $t("coll.insurance_company") }}</div>
        </div>
      </div>
      <van-field
        :placeholder="$t('coll.please_provide')"
        v-model="step_data.insurance_companies"
        class="bgGray mt-10"
      />
      <div class="flex mt-20">
        <div class="flexWidth"><span class="icon"></span></div>
        <div class="flex1">
          <div class="title">{{ $t("coll.please_up_insurance") }}</div>
          <div class="color6 font14 pt-5">{{ $t("coll.multiple_img") }}</div>
        </div>
      </div>
      <van-row class="mt-20">
        <van-uploader
          v-model="insuranceList"
          @delete="deleteimg"
          :after-read="afterRead"
          :before-read="beforeRead"
          name="insurance"
        />
      </van-row>
      <!-- <div class="flex mt-20">
        <div class="flexWidth"><span class="icon"></span></div>
        <div class="flex1">
          <div class="title">{{ $t("coll.contact_person") }}</div>
        </div>
      </div>
       <div v-for="(list, index) in contact" :key="index + 1000">
        <div class="color6 font14 mt-20">
          {{ $t("coll.contact_person") }} {{ index + 1 }}
        </div>
        <van-field
          :placeholder="$t('global.please_input')"
          :label="$t('pickupcard.name')"
          class="bgGray mt-10"
          v-model="list.name"
        />
        <van-field
          :placeholder="$t('global.please_input')"
          :label="$t('pickupcard.tel')"
          class="bgGray mt-10"
          v-model="list.tel"
        />
      </div> -->
      <div>
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">{{ $t('coll.Emergency') }}</div>
          </div>
        </div>
        <div class="fr relative mt-20 flexRemove" @click="addEmergency">
          <van-icon name="add-o"  class="addIcon font22 emergency-icon" />
          <span class="addText emergency">{{ $t('coll.please_add_contact') }} </span>
        </div>
        <div class="clearfix"></div>
        <div v-for="(list, index) in EmergencyNum" :key="index">
          <div class="flex mt-20">
            <div class="flex1">
              <div class="title">{{ $t('coll.contact_person') }}{{ index + 1 }}</div>
            </div>
          </div>
          <van-field
              :label="$t('global.name')"
              :placeholder="$t('global.please_input')"
              v-model="EmergencyNumCopy[index]['name']"
              class="bgGray mt-10"
          />
          <van-field
              type="tel"
              :label="$t('coll.Mobile')"
              :placeholder="$t('global.please_input')"
              :rules="[{ pattern, message: $t('pickupcard.invalid_email') }]"
              v-model="EmergencyNumCopy[index]['mobile']"
              class="bgGray mt-10"
          />
          <van-field
              :label="$t('coll.Email')"
              :placeholder="$t('global.please_input')"
              :rules="[{ email_rule, message: $t('pickupcard.invalid_phone') }]"
              v-model="EmergencyNumCopy[index]['email']"
              class="bgGray mt-10"
          />
          <div class="color6 font12 mt-10 fr" v-if="index > 0">
          <span @click='delEmergency(index)' class="flex">
            <van-icon name="clear" color="#cccccc"  class='mr-5 font19'/>
            <span style="color:#646566;" class="font14">{{$t('global.delete')}}</span>
          </span>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="flex mt-20">
        <div class="flexWidth"><span class="icon"></span></div>
        <div class="flex1">
          <div class="title">{{ $t("coll.student_health") }}</div>
          <div class="color6 font14 mt-5">
            {{ $t("coll.medical_history") }}
          </div>
        </div>
      </div>
      <van-button type="default" round block class="saveSub mt-20" @click="allNo"
        >{{ $t("coll.all_nefative")}}</van-button
      >
      <div class="color6 font14 mt-20">{{ $t("coll.ADD") }}</div>
      <van-row gutter="20" class="mt-20">
        <van-col span="12" v-for="(list, key, index) in pageList" :key="index">
          <span
            class="ride font14 color3"
            :class="step_data.is_adhd == key ? 'rideActive' : ''"
            @click="toRide('is_adhd', key)"
            >{{ list }}</span
          >
        </van-col>
      </van-row>
      <div class="color6 font14 mt-20">{{ $t("coll.heart_disorder") }}</div>
      <van-row gutter="20" class="mt-20">
        <van-col span="12" v-for="(list, key, index) in pageList" :key="index">
          <span
            class="ride font14 color3"
            :class="step_data.is_heart_disease == key ? 'rideActive' : ''"
            @click="toRide('is_heart_disease', key)"
            >{{ list }}</span
          >
        </van-col>
      </van-row>
      <div class="color6 font14 mt-20">
        {{ $t("coll.tuberculosis") }}
      </div>
      <van-row gutter="20" class="mt-20">
        <van-col span="12" v-for="(list, key, index) in pageList" :key="index">
          <span
            class="ride font14 color3"
            :class="step_data.tuberculosisFour == key ? 'rideActive' : ''"
            @click="toRide('tuberculosisFour', key)"
            >{{ list }}</span
          >
        </van-col>
      </van-row>
      <div class="color6 font14 mt-20">{{ $t("coll.AllergiesFood") }}</div>
      <van-row gutter="20" class="mt-20">
        <van-col span="12" v-for="(list, key, index) in pageList" :key="index">
          <span
            class="ride font14 color3"
            :class="step_data.allergies == key ? 'rideActive' : ''"
            @click="toRide('allergies', key)"
            >{{ list }}</span
          >
        </van-col>
      </van-row>
      <div v-if="step_data.allergies==1">
        <van-field
          rows="3"
          autosize
          class="bgGray mt-10"
          v-model="step_data.allergies_food"
          type="textarea"
          :placeholder="$t('coll.FoodAllergy')"
        />
        <van-field
          rows="3"
          autosize
          class="bgGray mt-10"
          v-model="step_data.allergies_medication"
          type="textarea"
          :placeholder="$t('coll.medAllergy')"
        />
        <van-field
          rows="3"
          autosize
          class="bgGray mt-10"
          v-model="step_data.allergies_other"
          type="textarea"
          :placeholder="$t('coll.otherAllergy')"
        />
      </div>
      <div class="color6 font14 mt-20">{{ $t("coll.Fracture_Dislocate") }}</div>
      <van-row gutter="20" class="mt-20">
        <van-col span="12" v-for="(list, key, index) in pageList" :key="index">
          <span
            class="ride font14 color3"
            :class="step_data.fracture_dislocate == key ? 'rideActive' : ''"
            @click="toRide('fracture_dislocate', key)"
            >{{ list }}</span
          >
        </van-col>
      </van-row>
      <div v-if="step_data.fracture_dislocate==1">
        <van-field
          rows="3"
          autosize
          class="bgGray mt-10"
          v-model="step_data.fracture_dislocate_desc"
          type="textarea"
          :placeholder="$t('coll.Fracture_Dislocate_desc')"
        />
      </div>
      <div class="color6 font14 mt-20">{{ $t("coll.Hyper_pyretic_convulsion") }}</div>
      <van-row gutter="20" class="mt-20">
        <van-col span="12" v-for="(list, key, index) in pageList" :key="index">
          <span
            class="ride font14 color3"
            :class="step_data.pyretic_convulsion == key ? 'rideActive' : ''"
            @click="toRide('pyretic_convulsion', key)"
            >{{ list }}</span
          >
        </van-col>
      </van-row>
      <div class="color6 font14 mt-20"> {{ $t("coll.other") }} </div>
      <div>
        <van-field
          rows="3"
          autosize
          class="bgGray mt-10"
          type="textarea"
          v-model="step_data.other"
          :placeholder="$t('coll.other')"
        />
      </div>
      <div v-if="dataDetails.protocol.length != 0">
        <protocol-sign
          @click="baseImg"
          :busProtocol="dataDetails.protocol"
          :base64="base64"
        ></protocol-sign>
      </div>
      <div class="mt-20">
        <van-button
          v-if="stepStatus == 4 && dataDetails.isCommon==1"
          disabled
          type="info"
          block
          round
          color="#4d88d2"
          class="saveSub"
          >{{ $t("coll.step_reviewed") }}</van-button
        >
        <a
          href="javascript:;"
          v-else
          @click="submitData()"
          class="saveBtnStep"
          :class="btnDisabled ? 'disabledSubmit' : ''"
          >{{ $t("global.submit") }}</a
        >
      </div>
    </div>
  </template>
  
  <script>
  import Vue from "vue";
  import { Toast } from "vant";
  import ProtocolSign from "@/components/steps/ProtocolSign";
  import Compressor from 'compressorjs';
  
  Vue.use(Toast);
  export default {
    props: {
      dataDetails: {
        type: Object,
      },
      status: {
        type: Number,
      },
      protocol: {
        type: Array,
      },
      disabled: {
        type: Number,
      },
    },
    components: {
      "protocol-sign": ProtocolSign,
    },
    name: "homeAddress",
    data() {
      return {
        fileList: [],
        // medicalList: [],
        insuranceList:[],
        // contact: [
        //   {
        //     name: "",
        //     tel: "",
        //   },
        //   {
        //     name: "",
        //     tel: "",
        //   },
        // ],
        hospital: ["", ""],
        hospital1: ["", ""],
        step_data: {},
        type: "",
        base64: "",
        signShow: false,
        qiniuToken: "",
        newSign: false,
        btnDisabled: false,
        pageList: {
          1: this.$t("coll.yes"),
          2: this.$t("coll.no"),
        },
        pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
        email_rule: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,
        EmergencyNum: [{
          name: "",
          mobile: "",
          email: ""
        }],//紧急联系人
        EmergencyNumCopy: [{
          name: "",
          mobile: "",
          email: ""
        }],
      };
    },
    watch: {
      disabled() {
        this.btnDisabled = false;
      },
    },
    created() {
      this.stepStatus = this.status;
      this.step_data = this.dataDetails.step_data;
      if (this.step_data.emergency_contact.length != 0) {
        this.EmergencyNum = this.step_data.emergency_contact;
      }
      this.EmergencyNumCopy = this.EmergencyNum;
      if (this.step_data.intended_hospitals.length != 0) {
        this.hospital = this.step_data.intended_hospitals;
        this.hospital1 = this.step_data.intended_hospitals;
      }
      if (this.dataDetails.sign_url != "") {
        this.base64 = this.dataDetails.sign_url;
      }
      if (this.step_data.vaccines_reports.length != 0) {
        this.fileList = this.tojson(this.step_data.vaccines_reports_url);
      }
    //   if (this.step_data.examination_reports.length != 0) {
    //     this.medicalList = this.tojson(this.step_data.examination_reports_url);
    //   }
      if (this.step_data.insurance_card_photos.length != 0) {
        this.insuranceList = this.tojson(this.step_data.insurance_card_photos_url);
      }
      this.qiniuToken = this.dataDetails.qiniuToken;
    },
    methods: {
      addEmergency() {
        if (this.EmergencyNum.length < 5) {
          let push_data = {
            name: "",
            mobile: "",
            email: ""
          };
          this.EmergencyNum.push(push_data);
          this.EmergencyNumCopy = this.EmergencyNum;
        } else {
          Toast('最大数');
        }
      },
      delEmergency(index) {
        if (this.EmergencyNum.length == 1) {
          return;
        }
        this.EmergencyNum.splice(index, 1);
      },
      tojson(rows) {
        var ids = [];
        for (var i = 0, len = rows.length; i < len; i++) {
          var signAgainReq = new Object();
          signAgainReq.url = rows[i];
          ids.push(signAgainReq);
        }
        return ids;
      },
      beforeRead(file) {
        return new Promise((resolve) => {
          new Compressor(file, {
            maxWidth:1200,
            quality:0.6,
            success: resolve,
            error(err) {
              console.log(err.message);
            },
          });
        });
      },
      afterRead(file, detail) {
        this.type = detail.name;
        if (file.length > 1) {
          for (let i in file) {
            this.upimg(file[i].content);
          }
        } else {
          this.upimg(file.content);
        }
      },
      upimg(data) {
        const that = this;
        Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: that.$t("pickupcard.uploading"),
        });
        var pic = data.substring(data.indexOf(",") + 1);
        var url = "https://upload-z1.qiniup.com/putb64/-1";
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            var resJson = JSON.parse(xhr.responseText);
            if (resJson.code == 0) {
              if (that.type == "medical") {
                that.step_data.examination_reports.push(resJson.data.key);
              } else if(that.type=='insurance'){
                that.step_data.insurance_card_photos.push(resJson.data.key);
              } else {
                that.step_data.vaccines_reports.push(resJson.data.key);
              }
              Toast(that.$t("pickupcard.Uploaded"));
            } else {
              Toast(resJson.msg);
            }
          }
        };
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/octet-stream");
        xhr.setRequestHeader("Authorization", "UpToken " + that.qiniuToken);
        xhr.send(pic);
      },
      //删除图片
      deleteimg(file, detail) {
        if (detail.name == "medical") {
          this.step_data.examination_reports.splice(detail.index - 1, 1);
        }else if(detail.name=='insurance'){
            this.step_data.insurance_card_photos.splice(detail.index - 1, 1);
        }  else {
          this.step_data.vaccines_reports.splice(detail.index - 1, 1);
        }
      },
      toRide(type, index) {
        this.step_data[type] = index;
      },
      allNo() {
        Vue.set(this.step_data, 'is_adhd', 2);
        Vue.set(this.step_data, 'is_heart_disease', 2);
        Vue.set(this.step_data, 'tuberculosisFour', 2);
        Vue.set(this.step_data, 'allergies', 2);
        Vue.set(this.step_data, 'fracture_dislocate', 2);
        Vue.set(this.step_data, 'pyretic_convulsion', 2);
        
      },
      baseImg(data) {
        if (data != "cancel") {
          this.base64 = data;
          this.newSign = true;
        }
      },
      phoneValidate(params) {
        if (this.pattern.test(params)) {
          return true;
        } else {
          return false;
        }
      },
      emailValidate(params) {
        if (this.email_rule.test(params)) {
          return true;
        } else {
          return false;
        }
      },
      submitData() {
        const that = this;
        if (this.btnDisabled) {
          return;
        }
       if (this.hospital[0] == "" && this.hospital[1] == "" ) {
          Toast(this.$t("coll.please_hospital"));
          return;
        }
        // if ((this.contact[0].name == "" || this.contact[0].tel == "") && (this.contact[1].name == "" || this.contact[1].tel == "")) {
        //   Toast(this.$t("coll.please_contact"));
        //   return;
        // }
        // for(var i=0;i<this.contact.length;i++){
        //   if(this.contact[i].tel!=''){
        //     if (!this.phoneValidate(this.contact[i].tel,this.pattern_card)) {
        //      Toast(this.$t("pickupcard.invalid_phone"));
        //       return;
        //     }
        //   }
        // }
        // 紧急联系人  
        that.in_einfo = [];
        for (let item of  that.EmergencyNum){
          if (item.name.trim().length == 0) {
            Toast(this.$t('coll.contact_person') + this.$t("pickupcard.information"));
            return false;
          }
          if (!item.mobile && !item.email) {
            Toast(this.$t('coll.contact_person') + this.$t("pickupcard.information"));
            return false;
          }
          if (item.name.trim() && (item.mobile || item.email)) {
            if (item.mobile=='' || !this.phoneValidate(item.mobile)) {
              Toast(this.$t('coll.contact_person') + this.$t("pickupcard.invalid_phone"));
              return false;
            }
            that.in_einfo.push({
              name: item.name,
              mobile: item.mobile,
              email: item.email
            });
          }
        }
        if (that.in_einfo.length == 0) {
          Toast(this.$t('coll.contact_person') + this.$t("pickupcard.needed"));
          return;
        }
        if(this.step_data.insurance_companies==''){
          Toast(this.$t("global.please_input")+this.$t("coll.insurance_company"));
          return;
        }
        if (this.step_data.is_adhd == 0) {
          Toast(this.$t("coll.please_choose")+this.$t("coll.ADD"));
          return;
        }
        if (this.step_data.is_heart_disease == 0) {
          Toast(this.$t("coll.please_choose")+this.$t("coll.heart_disorder"));
          return;
        }
        if (this.step_data.tuberculosisFour == 0) {
          Toast(this.$t("coll.please_choose")+ this.$t("coll.tuberculosis"));
          return;
        }
        if(this.step_data.allergies==0){
          Toast(this.$t("coll.please_choose")+this.$t("coll.AllergiesFood"));
           return;
        }
        if(this.step_data.allergies==1 &&this.step_data.allergies_food==''){
            Toast(this.$t('global.please_input')+this.$t("coll.FoodAllergy"));
           return;
        }
        if(this.step_data.allergies==1 && this.step_data.allergies_medication==''){
            Toast(this.$t('global.please_input')+this.$t("coll.medAllergy"));
           return;
        }
        if(this.step_data.allergies==1 && this.step_data.allergies_other==''){
            Toast(this.$t('global.please_input')+this.$t("coll.otherAllergy"));
           return;
        }
        if(this.step_data.fracture_dislocate==0){
          Toast(this.$t("coll.please_choose")+this.$t("coll.Fracture_Dislocate"));
           return;
        }
        if(this.step_data.fracture_dislocate==1 && this.step_data.fracture_dislocate_desc==''){
            Toast(this.$t("coll.Fracture_Dislocate_desc"));
           return;
        }
        if(this.step_data.pyretic_convulsion==0){
          Toast(this.$t("coll.please_choose")+this.$t("coll.Hyper_pyretic_convulsion"));
           return;
        }
        if(this.step_data.other==''){
           Toast(this.$t('global.please_input')+this.$t("coll.other"));
           return;
        }
        if (this.dataDetails.protocol.length != 0 && this.base64 == "") {
          Toast(this.$t("coll.please_sign"));
          return;
        }
        this.$nextTick(function () {
          this.btnDisabled = true
        })
        if (this.dataDetails.protocol.length == 0) {
          that.saveData();
          return;
        }
        if (!that.newSign) {
          that.saveData();
          return;
        }
        var pic = this.base64.substring(this.base64.indexOf(",") + 1);
        var url = "https://upload-z1.qiniup.com/putb64/-1";
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            var resJson = JSON.parse(xhr.responseText);
            if (resJson.code == 0) {
              Vue.set(that.step_data, "sign", resJson.data.key);
              Vue.set(that.step_data, "sign_url", resJson.data.url);
              that.saveData();
            } else {
              that.btnDisabled = false;
            }
          }
        };
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/octet-stream");
        xhr.setRequestHeader(
          "Authorization",
          "UpToken " + that.dataDetails.signQiniuToken
        );
        xhr.send(pic);
      },
      saveData() {
        this.step_data.emergency_contact = this.in_einfo;
        this.step_data.intended_hospitals = this.hospital;
        this.$emit("change", this.step_data);
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .addIcon {
    color: #4d88d2;
  }
  .emergency,.emergency-icon{
    line-height: 20px;
    vertical-align: middle;
    text-align: center;
  }
  </style>