import Vue from 'vue';

export function commentList (type, id) {
  return Vue.axios({
    url: '/api/parent/comment/list/' + type + '/' + id,
    method: 'get'
  })
}

export function commentSave (data) {
  return Vue.axios({
    url: '/api/parent/comment/save',
    method: 'post',
    data: data
  })
}
