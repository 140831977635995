<template>
  <div class="lunchInfo">
    <children @change="childChange" :children="childrenList" v-if="childrenList.length > 0" />
        <!-- v-if="lunchList.cancel_auth" -->
    <van-notice-bar 
      ><span class="font14">
        {{ $t("lunch.Use") }}<a href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx903fba9d4709cf10&redirect_uri=https://www.ivyonline.cn/wechat/contactUs/index/selectvalue/2&response_type=code&scope=snsapi_userinfo&state=ivy#wechat_redirect" class="blue" >{{$t("lunch.Leave")}}</a> </span>{{ $t("lunch.cancel_lunch") }}
    </van-notice-bar>
    <div class="schoolYear" @click="ActionShow = true" v-if="yearList.length>0">
      {{ currentYear.year }}
      <van-icon name="arrow-up" class="open" v-if="ActionShow" />
      <van-icon name="arrow-down" class="open" v-if="!ActionShow" />
    </div>
    <van-action-sheet v-model="ActionShow">
      <div class="content">
        <van-picker show-toolbar :default-index="selectIndex" value-key="year" :columns="yearList" @confirm="onConfirm" @cancel="onCancel" />
      </div>
    </van-action-sheet>
    <div v-if="lunchList.allDays && lunchList.allDays.length == 0">
      <van-empty image="error" :description="$t('lunch.No_paid_lunch')" />
    </div>
    <div v-else-if="lunchList.allDays">
      <div class="example">
        <div class="font14 color6 mb-10 flex"><span class="round orange"></span><span class="flex1">{{$t('lunch.Lunch_awaiting')}}</span></div>
        <div class="font14 color6 flex"><span class="round green"></span><span class="flex1">{{$t('lunch.Lunch_refunded')}}</span></div>
      </div>
      <div v-for="(item, i) in lunchList.allDays" :key="i">
        <div class="month">
          <strong class="font16 color3"> {{ item.title }}</strong>
          <span class="dayFr color6 font14">{{ item.school_day_num }} {{$t('lunch.Day')}}</span>
        </div>
        <div class="calendar">
          <div class="fixed">{{ item.month }}</div>
          <div class="flex text-center">
            <span v-for="(list, index) in weekList" :key="index" class="flex1">{{ list }}</span>
          </div>
          <div class="flex text-center" v-for="(list, index) in item.week" :key="index">
            <div class="flex1 date" v-for="(_list, idx) in list" :key="idx" >
              <div v-if="lunchList.lunchDay[item.year_month] && lunchList.lunchDay[item.year_month][_list]">
                <div v-if="lunchList.lunchDay[item.year_month][_list].status == -99" class="greenText">
                  <div class="dateType" :class="item.year == newYear && item.month == newMonth && _list == newDate ? 'bgBlue' : ''" @click="showIcon('refunded')"><strong>{{ _list }}</strong></div>
                  <div class="font12"> <i class="iconfont iconfont-Refunded"></i></div>
                </div>
                <div v-else-if="lunchList.lunchDay[item.year_month][_list].status == 1" class=" orangeText">
                  <div class="dateType" :class="item.year == newYear && item.month == newMonth && _list == newDate ? 'bgBlue' : ''" @click="showIcon('awaiting')"><strong>{{ _list }}</strong></div>
                  <div class="font12"><i class="iconfont iconfont-Awaiting"></i></div>
                </div>
                <div v-else class="dateType" :class="item.year == newYear && item.month == newMonth && _list == newDate ? 'bgBlue' : ''">
                 <strong>{{ _list }}</strong> 
                </div>
                <div class="font12 blue" v-if="item.year == newYear && item.month == newMonth && _list == newDate">{{$t('lunch.Today')}}</div>
              </div>
              <div v-else>
                <div class="dateType colorc" :class="item.year == newYear && item.month == newMonth && _list == newDate ? 'bgBlue' : ''"
                  ><strong>{{ _list }}</strong>
                </div>
                <div class="font12 blue" v-if="item.year == newYear && item.month == newMonth && _list == newDate">{{$t('lunch.Today')}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Children from "@/components/Children";
import storage from "store";
import { CURRENT_CHILD } from "@/store/mutation-types";
import { childList } from "@/api/child";
import { lunchData } from "@/api/lunch";
import Vue from "vue";
import { NoticeBar, ActionSheet, Empty,Toast  } from "vant";

Vue.use(ActionSheet);
Vue.use(NoticeBar);
Vue.use(Empty);
Vue.use(Toast);
export default {
  name: "lunch",
  components: {
    children: Children,
  },
  data() {
    return {
      childrenList: [],
      childrenStatus: {},
      lunchList: {},
      yid: null,
      yearList: [],
      weekList: [this.$t('lunch.Sunday'), this.$t('lunch.Tuesday'),this.$t('lunch.Wednesday'),this.$t('lunch.Thursday'),this.$t('lunch.Friday'),this.$t('lunch.Saturday'),this.$t('lunch.Monday')],
      currentYear: {},
      selectIndex: "",
      ActionShow: false,
      newYear: "",
      newMonth: "",
      newDate: "",
    };
    
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background-color:#fff;");
  },
  mounted() {},
  created() {
    childList().then((res) => {
      this.childrenList = res.data;
      this.loading = false;
      let cIds = [];
      this.childrenList.map((item) => {
        this.childrenStatus[item.id] = true;
        cIds.push(item.id);
      });
      if (this.childrenList.length < 1) {
        this.emptyChild = true;
      }
      let cid = storage.get(CURRENT_CHILD) ? storage.get(CURRENT_CHILD).id : "";
      this.childid = cIds.indexOf(cid) > -1 ? cid : cIds[0];
    });
  },
  methods: {
    childChange(val) {
      this.childid = val.id;
      this.yid = "";
      this.currentYear = {};
      this.selectIndex = '';
      this.getLunch();
    },
    onConfirm(value, index) {
      this.currentYear = value;
      this.selectIndex = index;
      this.yid = value.yid;
      this.ActionShow = false;
      this.getLunch();
    },
    onCancel() {
      this.ActionShow = false;
    },
    getLunch() {
      lunchData({
        child_id: this.childid,
        yid: this.yid,
      }).then((res) => {
        this.lunchList = res.data;
        this.yid = res.data.yid - 0;
        this.yearList = res.data.yearList;
        this.newYear = res.data.today.y;
        this.newMonth =res.data.today.m;
        this.newDate =res.data.today.d;
        res.data.yearList.forEach((list) => {
          if (list.yid == res.data.yid) {
            this.currentYear = list;
          }
        });
      });
    },
    showIcon(type){
if(type=='refunded'){
    Toast(this.$t('lunch.Lunch_refunded'));
}else{
    Toast(this.$t('lunch.Lunch_awaiting'));

}
    }
  },
};
</script>

<style scoped lang="less">
.blue {
  color: #4d88d2;
}
.dateType {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
}
.bgBlue {
  color: #fff !important;
  background: #4d88d2;
}
.schoolYear {
  padding: 13px 16px 0;
  background: #fff;
  font-weight: 600;
  display: inline-block;
}
.example {
  padding: 13px 16px 24px 16px;
  background: #fff;
  .flex {
  }
  .round {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    display: inline-block;
    border-radius: 50%;
    margin-top: 2px;
  }
  .green {
    background: #45a367;
  }
  .orange {
    background: #f5a429;
  }
}
.month {
  padding: 12px;
  text-align: center;
  background:#F7F8FA;
  position: relative;
  .dayFr{
    position: absolute;
    right: 16px;
    top:16px
  }
}
.calendar {
  background: #fff;
  color: #323233;
  position: relative;
  padding:16px 0;
  .fixed {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    color: rgba(242, 243, 245, 0.8);
    font-size: 100px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  span {
    font-size: 12px;
    padding: 7px 0 20px 0;
  }
  .date {
    height: 50px;
    font-size: 16px;
    padding-top: 5px;
    z-index: 9;
  }
  .colorc {
    color: #c8c9cc;
  }
  .greenText {
    color: #45a367;
  }
  .orangeText {
    color: #f5a429;
  }
}
</style>
