<template>
  <div class="uniform">
    <div class="itemList" v-if="stockList.cat && Object.keys(infoList).length != 0">
      <div class="uniformList" v-for="(lists, key, indexs) in stockList.stock" :key="indexs">
        <div class="flex child" v-if="childrenList[key]">
          <van-image round width="44px" height="44px" :src="childrenList[key].avatar" class="avatar-1 mr-10" />
          <div class="item-1-1 flex1">
            <div class="color3 font14">{{ childrenList[key].name }}</div>
            <div class="color6 font12 pt-3">{{ childrenList[key].class_name }}</div>
          </div>
          <!-- <img src="@/assets/shipping.png" alt="Logo" width="20px" height="20px" /> -->
        </div>
        <!-- <van-divider /> -->
        <div v-for="(list, key1, index) in lists" :key="index">
          <div class="font12 color3 mb-15 flex align-items">
            <img src="@/assets/classify.png" alt="Logo" width="20px" /><span class="ml-5">{{ stockList.cat[key1].title
            }}</span>
          </div>
          <div v-for="(_item, idx) in list" :key="idx" class="listShop">
            <div v-if="_item.status == 1">
              <van-swipe-cell>
                <div class="flex alignStart">
                  <van-checkbox v-model="_item.check" @change="shopCheck" :disabled="_item.num < infoList[key][_item.paid]">
                    <img :src="_item.img" alt="" class="img72" />
                  </van-checkbox>
                  <div class="flex1 flex ml-10">
                    <div class="flex1">
                      <div class="color3 font14 fontWeight">{{ _item.title }}</div>
                      <div class="font12 color6 mt-5 flex align-items">
                        <span class="flex1">{{ _item.attr1 }} | {{ _item.attr2 }}</span>
                        <span>{{ $t("uniform.Stock_Quantity") }}{{ _item.num }}</span>
                      </div>
                      <div class="flex align-items mt-5">
                        <div class="flex1 font14"><span class=" colorRed fontWeight">¥ {{ _item.unit_price }} </span>
                          <span class="van-card__origin-price" v-if="_item.scribing_price != ''">¥ {{ _item.scribing_price
                          }}</span>
                        </div>
                        <div class="flex align-items">
                          <span class="colorRed font12" v-if="_item.num < infoList[key][_item.paid]">{{
                            $t("uniform.out_of_stock")
                          }}</span>
                          <div class="van-stepper van-sku__stepper"><button type="button" class="van-stepper__minus"
                              :class="infoList[key][_item.paid] == 1 ? 'van-stepper__minus--disabled' : ''"
                              @click="subtract(_item, key, _item.paid)"></button><input type="tel"
                              v-model="infoList[key][_item.paid]" class="van-stepper__input" /><button type="button"
                              class="van-stepper__plus"
                              :class="infoList[key][_item.paid] >= _item.num ? 'van-stepper__minus--disabled' : ''"
                              @click="plus(_item, key, _item.paid)"></button></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <template #right>
                  <van-button square :text="$t('global.delete')" type="danger" class="delete-button"
                    @click="remove(_item, key, key1, _item.paid, idx)" />
                </template>
              </van-swipe-cell>
            </div>
            <div v-else>
              <van-swipe-cell>
                <div class="flex alignStart" style="opacity: 0.5;">
                  <van-checkbox disabled>
                    <img :src="_item.img" alt="" class="img72" />
                  </van-checkbox>
                  <div class="flex1 flex ml-10">
                    <div class="flex1 ml-10">
                      <div class="color3 font14 fontWeight">{{ _item.title }}</div>
                      <div class="font12 color6 mt-5 flex align-items">
                        <span class="flex1">{{ _item.attr1 }} | {{ _item.attr2 }}</span>
                      </div>
                      <div class="flex align-items mt-10">
                        <div class="flex1 font14"><span class=" colorRed fontWeight">¥ {{ _item.unit_price }} </span>
                          <span class="van-card__origin-price" v-if="_item.scribing_price != ''">¥ {{ _item.scribing_price
                          }}</span>
                        </div>
                        <div>
                          <span class="colorRed font12">{{ $t("uniform.Invalid_Item") }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <template #right>
                  <van-button square :text="$t('global.delete')" type="danger" class="delete-button"
                    @click="remove(_item, key, key1, _item.paid, idx)" />
                </template>
              </van-swipe-cell>
            </div>
            <!-- <van-divider /> -->
            <!-- </div> -->
            <!-- <van-divider v-if="Object.keys(item).length == 1 && id + 1 < Object.keys(list).length" /> -->
          </div>
        </div>
      </div>
      <van-submit-bar :price="prices" class="submitBar" :loading="showLoad" :button-text='$t("uniform.Submit")'
        @submit="onSubmit" button-color="#4D88D2">
        <van-checkbox v-model="checkAll" @click="checkedAll($event)" ref="checkbox">{{ $t("uniform.Select_All") }}
        </van-checkbox>
      </van-submit-bar>
    </div>
    <div v-else-if="stockList.cat">
      <van-empty :description="$t('uniform.cart_empty')" class="mt-30"
        image="https://m2.files.ivykids.cn/cloud01-file-8025768Fpkv74gsyXNIWDf0HfWgAyaI0Smi.png" />
    </div>
  </div>
</template>

<script>
import storage from "store";
import Vue from "vue";
import { CURRENT_CHILD, UNIFORM_SHOPPING } from "@/store/mutation-types";
import i18n from "../i18n";
import { NoticeBar, ActionSheet, Empty, Toast, Icon, GoodsAction, GoodsActionIcon, GoodsActionButton, Sku, SwipeCell, SubmitBar, Checkbox, Divider, Card } from "vant";
import { childList } from "@/api/child";
import { getStock, submitOrderCheck } from "@/api/uniform";
Vue.use(ActionSheet);
Vue.use(NoticeBar);
Vue.use(Empty);
Vue.use(Icon);
Vue.use(Toast);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Sku);
Vue.use(SwipeCell);
Vue.use(SubmitBar);
Vue.use(Divider);
Vue.use(Checkbox);
Vue.use(Card);
export default {
  name: "lunch",
  components: {},
  data() {
    return {
      infoList: {},
      checkAll: true,
      child_id: "",
      uniformList: [],
      shopNum: 0,
      result: [],
      prices: 0,
      childrenList: {},
      pay: null,
      childData: {},
      stockList: {},
      showLoad: false
    };
  },
  beforeCreate() {
    // document.querySelector("body").setAttribute("style", "background-color:#fff;");
  },
  created() {
    this.showLoad = false
    childList().then((res) => {
      res.data.forEach((item) => {
        this.childrenList[item.id] = item;
      });
    });
    this.childData = storage.get(CURRENT_CHILD + i18n.locale);
    this.child_id = this.childData.id;
    let data = storage.get(UNIFORM_SHOPPING + '_' + this.childData.family_id) || {};
    this.infoList = data.shopping ? data.shopping : {};
    getStock({ data: this.infoList }).then((res) => {
      this.pay = res.data.once;
      this.check(res.data.stock, true);
      this.stockList = res.data
      this.getPrices();
    });
  },
  methods: {
    check(data, bool) {
      for (let item in data) {
        if (typeof data[item] == "object") {
          if (data[item].title && data[item].status == 1) {
            if(data[item].num >= this.infoList[data[item].child_id][data[item].paid]){
              this.shopNum += 1;
              data[item].check = bool;
            }
          }
          this.check(data[item], bool);
        }
      }
    },
    getPrices() {
      this.result = this.totalPrices(this.stockList.stock, []);
      let total = this.result.reduce((a, b) => {
        return a + b;
      }, 0);
      this.prices = Math.round(Number(total) * 100);
    },
    totalPrices(data, children) {
      for (let item in data) {
        if (typeof data[item] == "object") {
          if(data[item].status == 1 && (data[item].num >= this.infoList[data[item].child_id][data[item].paid])){
            this.checkNum += 1;
          }
          if (data[item].check && data[item].status == 1 && (data[item].num >= this.infoList[data[item].child_id][data[item].paid])) {
            children.push(this.infoList[data[item].child_id][data[item].paid] * data[item].unit_price);
          }
          this.totalPrices(data[item], children);
        }
      }
      return children;
    },
    checkedAll() {
      this.shopNum = 0;
      this.check(this.stockList.stock, this.$refs.checkbox.value);
      this.getPrices();
    },
    shopCheck() {
      this.checkNum = 0;
      this.getPrices();
      if (this.result.length == this.checkNum) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    },
    pushItem() {
      this.show = true;
    },
    //加
    plus(item, key, paid) {
      if (this.infoList[key][paid] >= item.num) {
        // Toast(this.$t("超出库存"));
        return;
      }
      this.infoList[key][paid]++;
      this.infoList[key][paid] = this.infoList[key][paid]++;
      this.getPrices();
      let shop = {
        shopping: this.infoList,
        order: {},
      };
      storage.set(UNIFORM_SHOPPING + '_' + this.childData.family_id, shop);
    },
    //减
    subtract(item, key, paid) {
      if (this.infoList[key][paid] <= 1) {
        // Toast(this.$t("不能低于一件"));
        return;
      }
      this.infoList[key][paid]--;
      this.getPrices();
      this.shopCheck()
      this.infoList[key][paid] = this.infoList[key][paid]--;
      let shop = {
        shopping: this.infoList,
        order: {},
      };
      storage.set(UNIFORM_SHOPPING + '_' + this.childData.family_id, shop);
    },
    //删除
    remove(item, key, key1, paid, index) {
      this.$delete(this.infoList[key], paid);
      this.stockList.stock[key][key1].splice(index, 1);
      if (Object.keys(this.infoList[key]).length === 0) {
        this.$delete(this.infoList, key);
      }
      this.getPrices();
      let shop = {
        shopping: this.infoList,
        order: {},
      };
      storage.set(UNIFORM_SHOPPING + '_' + this.childData.family_id, shop);
      Toast.success(this.$t("uniform.Success"));
    },
    confirmOrder(data, children) {
      for (let item in data) {
        if (typeof data[item] == "object") {
          if (data[item].check) {
            data[item].stock = this.infoList[data[item].child_id][data[item].paid]
            children.push(data[item]);
          }
          this.confirmOrder(data[item], children);
        }
      }
      return children;
    },
    onSubmit() {
      var result = this.confirmOrder(this.stockList.stock, []);
      if (result.length == 0) {
        return;
      }
      this.showLoad = true
      let product = {};
      result.forEach((item) => {
        if (product[item.child_id]) {
          product[item.child_id].push({
            paid: item.paid,
            num: item.stock,
            pid: item.pid,
          });
        } else {
          product[item.child_id] = [];
          product[item.child_id].push({
            paid: item.paid,
            num: item.stock,
            pid: item.pid,
          });
        }
      });
      submitOrderCheck({ product }).then((res) => {
        if (res.code == 0) {
          let shop = {
            order: result,
            shopping: this.infoList,
          };
          storage.set(UNIFORM_SHOPPING + '_' + this.childData.family_id, shop);
          this.$router.push({
            name: "UniformConfirm",
          });
        } else {
          this.showLoad = false
        }
      })
    },
  },
};
</script>

<style scoped lang="less">
.img72 {
  width: 72px;
  height: 72px;
  border-radius: 8px;
  object-fit: contain;
}

.uniformList {
  background: #fff;
  padding: 12px;
  //   border-radius: 8px;
  margin: 12px 8px;
}

.align-items {
  align-items: center;
}

.colorRed {
  color: #ee0a24;
}

.fontWeight {
  font-weight: 500;
}

.word-break {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.word-break2 {
  overflow: hidden; //超出文本隐藏
  text-overflow: ellipsis; ///超出部分省略号显示
  display: -webkit-box; //弹性盒模型
  -webkit-box-orient: vertical; //上下垂直
  -webkit-line-clamp: 2; //自定义行数
}

.goods-card {
  margin: 0;
}

.delete-button {
  height: 100%;
  margin: 0 0 0 10px;
  padding: 10px;
  border-radius: 0 4px 4px 0;
}

.van-submit-bar__button--danger {
  background-color: #4d88d2;
}

.itemList {
  margin-bottom: 60px;
}

.listShop {
  padding: 16px 0;
  border-top: 1px solid #f7f8fa;
}

.alignStart {
  align-items: start;
}

.van-stepper__input {
  pointer-events: none;
}

.child {
  align-items: center;
  margin-bottom: 20px;
}

.uniform {
  overflow-y: scroll;
  height: 100vh;
}

.uniform::-webkit-scrollbar {
  display: none;
}
</style>
<style>
.submitBar .van-submit-bar__text span {
  margin-left: 5px;
}
</style>
