<template>
  <div>
    <van-row>
      <img src="../../assets/message.png" alt="" class="indexImg" />
      <van-row type="flex" justify="space-between" class="p15 mt-5 lan">
        <div class="pl-10"><img :src="dataDetails.logo" alt="" style="height:44px"></div>
        <switch-lang color="#377ED7" class="mr-20 mt-5" />
      </van-row>
    </van-row>
    <van-row class="text-center mt-20 p16">
      <van-row class="font17 color3">
        <strong>{{ dataDetails.title }}</strong>
      </van-row>
      <van-row class="color6 p20 font13 textjustify" v-html='dataDetails.desc'>
      </van-row>
      <van-row class="color3 font14 mt-10" v-if="dataDetails.type!=2">
        {{$t("coll.registration")}} {{ dataDetails.start }} / {{ dataDetails.end }}
      </van-row>
       <router-link :to="`/collection/childList/${coll_id}`" v-if='dataDetails.status==1'>
        <van-button type="info" block class="saveSub mt-20" >{{$t("coll.Start")}}</van-button>
       </router-link>
        <van-button type="info" block class="saveSub mt-20" v-else disabled>{{dataDetails.status==2?$t('coll.not_started'):dataDetails.status==3?$t('coll.closed'):"无需填写"}}</van-button>
    </van-row>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Image as VanImage,
  Empty,
  Tag,
  Step,
  Steps,
  ActionSheet,
  NoticeBar,
} from "vant";
import SwitchLang from "@/components/SwitchLang";

Vue.use(VanImage);
Vue.use(Empty);
Vue.use(Tag);
Vue.use(Step);
Vue.use(Steps);
Vue.use(ActionSheet);
Vue.use(NoticeBar);
import { indexData } from "@/api/collection";

export default {
  name: "Course",
  components: {
    "switch-lang": SwitchLang,
  },
  data() {
    return {
      dataDetails: {},
      coll_id:''
    };
  },
  created() {
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#fff;");
  },
  mounted() {
    sessionStorage.setItem('LandingPage', window.location.href)
    if (this.$route.params.id) {
      this.coll_id = this.$route.params.id;
      this.getData();
    }
  },
  methods: {
      getData(){
        indexData(this.coll_id).then((res) => {
        this.dataDetails = res.data;
        });
      }
  },
};
</script>

<style lang="less" scoped>
.indexImg {
  width: 100%;
  position: relative;
}
.p20{
    padding:20px
}
.lan {
  position: absolute;
  width: 100%;
  top: 10px;
}
.text-center {
  text-align: center;
  padding: 16px;
}

.saveSub {
  height: 40px;
  background: #4d88d2;
  border-radius: 100px;
}
.textjustify{
  text-align:justify;
  text-justify:inter-ideograph; 
}
</style>
