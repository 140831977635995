<template>
  <div
    v-if="
      Object.keys(collData).length != 0 && Object.keys(dataDetails).length != 0
    "
  >
    <van-notice-bar v-if="common!=2" :text="'请于' + collData.end + '日之前填写完成'" />
    <van-row>
      <div v-for="(list, index) in childrenList" :key="index">
        <div
          class="m16 p16 list"
          :class="index == currentIndex ? 'check' : ''"
          v-if="dataDetails.childrenStatus[list.id]"
          @click="showSteps(index)"
        >
          <div class="mb-20 flex bg">
            <div class="">
              <img :src="list.avatar" alt="" class="Avatar" />
            </div>
            <div class="flex1 pt-3">
              <div class="color6">
                <strong class="color3 font14">{{ list.name }}</strong>
                <van-tag
                  color="#ffe1e1"
                  text-color="#ad0000"
                  class="ml-5"
                  v-if="
                    dataDetails.childrenStatus[list.id].studentStatus == 0 ||
                    dataDetails.childrenStatus[list.id].studentStatus == 4
                  "
                  >{{
                    dataDetails.studentStatusList[
                      dataDetails.childrenStatus[list.id].studentStatus
                    ]
                  }}</van-tag
                >
                <van-tag
                  color="#EDF3FA"
                  text-color="#4D88D2"
                  class="ml-5"
                  v-if="dataDetails.childrenStatus[list.id].studentStatus == 1"
                  >{{
                    dataDetails.studentStatusList[
                      dataDetails.childrenStatus[list.id].studentStatus
                    ]
                  }}</van-tag
                >
                <van-tag
                  color="#FDF0E6"
                  text-color="#ED6A0C"
                  class="ml-5"
                  v-if="dataDetails.childrenStatus[list.id].studentStatus == 2"
                  >{{
                    dataDetails.studentStatusList[
                      dataDetails.childrenStatus[list.id].studentStatus
                    ]
                  }}</van-tag
                >
                <van-tag
                  color="#ECF5EF"
                  text-color="#45A367"
                  class="ml-5"
                  v-if="dataDetails.childrenStatus[list.id].studentStatus == 3"
                  >{{
                    dataDetails.studentStatusList[
                      dataDetails.childrenStatus[list.id].studentStatus
                    ]
                  }}</van-tag
                >
              </div>
              <div class="color6 mt-10 font14">
                <van-icon
                  class-prefix="iconfont"
                  name="birthday"
                  color="#646566 font15"
                  class="mr-5"

                />{{ list.birthday }}
               </div>
              <div class="color6 mt-5 font14">
                <van-icon
                  class-prefix="iconfont"
                  name="school"
                  color="#646566 font15"
                  class="mr-5"
                />{{ schoolList[list.schoolid] }}
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
          <van-row justify="space-between" class="pt-15 borderTop" type="flex">
            <div class="lineHeight font14" >
              <span  v-if="dataDetails.type!=2">
                {{ $t("coll.registration") }} {{ string(collData.start) }} /
                {{ string(collData.end) }}
              </span>
            </div>
            <router-link
              :to="`/collection/stepInfo/${coll_id}/${
                dataDetails.childrenStatus[list.id].steps[0].flag
              }/${list.id}`"
            >
              <van-button round type="primary" size="small">{{
                $t("coll.Start")
              }}</van-button>
            </router-link>
          </van-row>
        </div>
        <div
          v-if="dataDetails.childrenStatus[list.id] && index == currentIndex"
          class="p16"
        >
          <div
            class="flex pb-10"
            v-for="(item, idx) in dataDetails.childrenStatus[list.id].steps"
            :key="idx"
          >
            <div class="flexWidth">
              <div class="border"></div>
              <span class="font12">{{ idx + 1 }}</span>
            </div>
            <router-link
              :to="`/collection/stepInfo/${coll_id}/${item.flag}/${list.id}`"
              class="flex1"
            >
              <div>
                <div class="font14 color3">{{ item.title }}</div>
                <div class="font12 mt-5">
                  <span class="color6">{{ $t("coll.Status") }}</span>
                  <span
                    :class="
                      item.status == 4
                        ? 'success'
                        : item.status == 3
                        ? 'error'
                        : 'color6'
                    "
                    >{{ stepStatusList[item.status] }}</span
                  >
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </van-row>
    <!-- <van-steps
      direction="vertical"
      :active="-1"
      class="m16 mt0"
      v-if="stepList.length!=0 &&  stepList.steps.length!=1"
    >
      <van-step
        v-for="(list, index) in stepList
          .steps"
        :key="index"
      >
        <van-tag
          color="#F2F4F9"
          round
          text-color="#666666"
          class="font14"
          size="large"
          >{{ index + 1 }}</van-tag
        >
        <span class="color3 font14 ml-20">{{ list.title }}</span>
      </van-step>
    </van-steps> -->
  </div>
</template>

<script>
import Vue from "vue";
import {
  Image as VanImage,
  Empty,
  Tag,
  Step,
  Steps,
  ActionSheet,
  NoticeBar,
} from "vant";

Vue.use(VanImage);
Vue.use(Empty);
Vue.use(Tag);
Vue.use(Step);
Vue.use(Steps);
Vue.use(ActionSheet);
Vue.use(NoticeBar);
import { childrenStatus, indexData } from "@/api/collection";
import { childList } from "@/api/child";

export default {
  name: "Course",
  components: {},
  data() {
    return {
      collData: {},
      dataDetails: {},
      childrenList: [],
      stepList: [],
      stepStatusList: [],
      currentIndex: "",
      common:null
    };
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#fff;");
  },
  mounted() {
    if (this.$route.params.id) {
      this.coll_id = this.$route.params.id;
      childList().then((res) => {
        this.childrenList = res.data;
        this.getData();
      });
    }
  },
  methods: {
    string(date) {
      return date.substring(5, 10);
    },
    getData() {
      indexData(this.coll_id).then((res) => {
        this.collData = res.data;
        this.common=res.data.type
      });
      childrenStatus(this.coll_id).then((res) => {
        this.dataDetails = res.data;
        this.stepStatusList = res.data.stepStatusList;
        this.schoolList = res.data.schoolList;
        for (var key in this.dataDetails.childrenStatus) {
          Vue.set(this.dataDetails.childrenStatus[key], "show", false);
        }
        for (var i = 0; i < this.childrenList.length; i++) {
          if (this.dataDetails.childrenStatus[this.childrenList[i].id]) {
            this.currentIndex = i;
            return;
          }
        }
      });
    },
    showSteps(index) {
      this.currentIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
.lineHeight {
  line-height: 35px;
}
.van-button--primary {
  background: #4d88d2;
  border: 1px solid #4d88d2;
}
.m16 {
  margin: 16px;
  border-radius: 8px;
}
.mt0 {
  margin-top: 0;
}
.p16 {
  padding: 16px;
}
.flexWidth {
  width: 50px !important;
  position: relative;
  span {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: #4d88d2;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    line-height: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -10px;
    margin-left: -10px;
  }
  .border {
    border-left: 1px solid #dcdee0;
    height: 100%;
    position: absolute;
    top: -50%;
    width: 1px;
    left: 50%;
  }
}
.bg {
  background: #fff;
  .flex1 {
    background: #fff;
    padding: 3px 10px 0;
  }
}
.success {
  color: #45a367;
}
.error {
  color: #dc3838;
}
.check {
  border: 1px solid #4d88d2;
}
.flex1 {
  background: #f7f8fa;
  padding: 10px;
  border-radius: 8px;
}
.list {
  background: #fff;
  box-shadow: 0px 0px 5px #ddd;
  .Avatar {
    width: 74px;
    height: 74px;
    border-radius: 5px;
  }
  .borderTop {
    padding-top: 20px;
    border-top: 1px solid #d7dae2;
  }
}
</style>
