<template>
  <div :class="stepStatus == 4 && dataDetails.isCommon==1? 'clickNone' : ''">
    <van-radio-group v-model="uniformData.buy_status" class="pt-20">
      <van-radio name="1">
        <span class="color3 font14">{{ $t("coll.dont_need") }}</span>
      </van-radio>
      <van-radio name="2" class="mt-15"
        >
        <span class="color3 font14" v-if="collId==110">{{ $t("coll.buy_Uniform1") }}</span>
        <span class="color3 font14" v-else>{{ $t("coll.buy_Uniform") }}</span>
      </van-radio>
    </van-radio-group>
    <div v-if="dataDetails.protocol.length != 0">
      <protocol-sign
        @click="baseImg"
        :busProtocol="dataDetails.protocol"
        :base64="base64"
      ></protocol-sign>
    </div>
    <van-row gutter="20" class="mt-20">
      <!-- <van-col span="12">
        <a
          href="javascript:;"
         
          @click="submitData()"
          class="saveBtnStep"
          :class="btnDisabled ? 'disabledSubmit' : ''"
          >去购买</a
        >
      </van-col> -->
      <van-col span="24">
        <van-button
          v-if="stepStatus == 4 && dataDetails.isCommon==1"
          disabled
          type="info"
          block
          round
          color="#4d88d2"
          class="saveSub"
          >{{ $t("coll.step_reviewed") }}</van-button
        >
        <a
          href="javascript:;"
          v-else
          @click="submitData()"
          class="saveBtnStep"
          :class="btnDisabled ? 'disabledSubmit' : ''"
          >{{ $t("global.submit") }}</a
        >
      </van-col>
    </van-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast } from "vant";
import ProtocolSign from "@/components/steps/ProtocolSign";

Vue.use(Toast);
export default {
  components: {
    "protocol-sign": ProtocolSign,
  },
  props: {
    dataDetails: {
      type: Object,
    },
    status: {
      type: Number,
    },
    disabled: {
      type: Number,
    },
  },
  name: "homeAddress",
  data() {
    return {
      uniformData: "",
      stepStatus: "",
      btnDisabled: false,
      base64: "",
      newSign: false,
      collId:''
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
  },
  created() {
    this.collId=this.$route.params.id
    this.stepStatus = this.status;
    this.uniformData = this.dataDetails;
    this.uniformData.buy_status = this.dataDetails.buy_status + "";
    if (this.dataDetails.sign_url != "") {
      this.base64 = this.dataDetails.sign_url;
    }
  },
  methods: {
    baseImg(data) {
      if (data != "cancel") {
        this.base64 = data;
        this.newSign = true;
      }
    },
    submitData() {
      let that = this;
      if (this.btnDisabled) {
        return;
      }
      if (
        this.uniformData.buy_status === "" ||
        this.uniformData.buy_status === "0"
      ) {
        Toast(this.$t('coll.choose')+"是否已购买");
        return;
      }
      if (that.dataDetails.protocol.length != 0 && this.base64 == "") {
        Toast(that.$t("coll.please_sign"));
        return;
      }
      this.$nextTick(function () {
        this.btnDisabled = true
      })
      if (that.dataDetails.protocol.length == 0) {
        that.saveData();
        return;
      }
      if (!that.newSign) {
        that.saveData();
        return;
      }
      var pic = this.base64.substring(this.base64.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // toast.clear()
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that.uniformData, "sign_url", resJson.data.url);
            Vue.set(that.uniformData, "sign", resJson.data.key);
            that.saveData();
          } else {
            Toast(resJson.msg);
            that.btnDisabled = false;
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader(
        "Authorization",
        "UpToken " + that.dataDetails.signQiniuToken
      );
      xhr.send(pic);
    },
    saveData() {
      this.$emit("change", this.uniformData);
    },
  },
};
</script>

<style lang="less" scoped>
</style>