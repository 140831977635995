<template>
    <van-row class="">
        <div class="box">
            <img src="../assets/completed.png" alt="" class="img">
            <div class="font17 color3 font500 completing">{{$t('survey.completing')}}</div>
            <router-link :to="{ name: 'SurveyIndex' }">
                <van-button round type="info" class="backBtn">{{$t('survey.Back')}}</van-button>
            </router-link>
        </div>
    </van-row>
</template>
<script>
import { Row, Button, Image, Skeleton } from 'vant'
import Vue from "vue";
Vue.use(Row, Button, Image, Skeleton);
export default {
    data() {
        return {
            childData: [],
            surveyData: []
        }
    },
    beforeCreate() {
        document.querySelector("body").setAttribute("style", "background-color:#fff;");
    },
    created() {

    },
    methods: {}
}
</script>
<style lang="less" scoped>
.box {
    height: 100vh;
    background: linear-gradient(180deg, #FFFFFF 0%, #E1F0FF 100%);
    text-align: center;

    .img {
        width: 130px;
        margin-top: 100px;
        margin-bottom: 12px;
    }

    .backBtn {
        padding: 0 48px;
        margin-top: 24px;
    }
    .completing{
        width:80%;
        margin:auto
    }
}
</style>