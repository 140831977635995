<template>
    <div class="uniform">
        <div v-if="actionShow && Object.keys(childrenList).length > 0">
            <div class="font20 color3 pb-20 weight500">{{ $t('uniform.Who_buy') }}</div>
            <div class="childData" v-for="(list, index) in childrenList" :key="index">
                <div class="flex child" @click="routerDetail(list)">
                    <span class="abb">{{ list.abb }}</span>
                    <img class="avatar52" :src="list.avatar" alt="">
                    <div class="flex1 ml-10">
                        <div class="font16 color3">{{ list.bilingual_name }}</div>
                        <div class="font12 color6 mt-5">{{ list.className }}</div>
                    </div>
                    <div><van-icon name="arrow" /></div>
                </div>
            </div>
        </div>
        <van-empty description="暂无数据" v-else-if="actionShow" />
    </div>
</template>
  
<script>
import storage from "store";
import { CURRENT_CHILD } from "@/store/mutation-types";
import { childList } from "@/api/uniform";
import Vue from "vue";
import i18n from "../i18n";
import { NoticeBar, ActionSheet, Empty, Toast, Icon, Card } from "vant";

Vue.use(ActionSheet);
Vue.use(NoticeBar);
Vue.use(Empty);
Vue.use(Icon);
Vue.use(Toast);
Vue.use(Card);
export default {
    name: "lunch",
    components: {
    },
    data() {
        return {
            childrenList: [],
            childrenStatus: {},
            childid: "",
            uniformList: {},
            actionShow: false,
            shoppingData: {},
        };
    },
    beforeCreate() {
        //   document.querySelector("body").setAttribute("style", "background-color:#fff;");
    },
    mounted() { },
    created() {
        this.pid = this.$route.params.id;
        childList().then((res) => {
            this.actionShow = true
            this.childrenList = res.data;
            if (Object.keys(res.data).length == 1) {
                this.routerDetail(Object.values(res.data)[0])
            }
        });
    },
    methods: {
        routerDetail(item) {
            storage.set(CURRENT_CHILD + i18n.locale, item)
            this.$router.push({
                path: "/uniformDetail/" + this.pid,
            });
        }
    },
};
</script>
  
<style scoped lang="less">
.uniform {
    padding: 24px;
    height: 100vh;
    background-color: #fff;
}

.childData {
    background: #F7F8FA;
    border-radius: 10px;
    padding: 20px;
    margin: 24px 0
}

.avatar52 {
    width: 52px;
    height: 52px;
    border-radius: 50%;
}

.child {
    align-items: center;
    position: relative;
}

.abb {
    position: absolute;
    left: -20px;
    top: -20px;
    background: rgba(77, 136, 210, 0.1);
    border-radius: 4px 0px 4px 0px;
    color: #4D88D2;
    padding: 2px 5px;
    font-size: 12px;
}

.weight500 {
    font-weight: 500;
}
</style>
  