<template>
  <div>
    <div>
      <div>
        <van-notice-bar left-icon="volume-o" :text="$t('notice.preview_model')" v-if="$route.name == 'PreviewNotice'" />
      </div>
      <campus :schoolid="$route.query.schoolid" @change="changeCampus" v-if="$route.name == 'PreviewNotice'" />
      <campus @change="changeCampus" :search="searchIcon" v-else />
      <div v-if="show">
        <div class="filterLabel" v-if="schoolType=='ds'">
          <span
            v-for="(item, i) in categoryList"
            :class="currentTag == item.key ? 'tagLabelBg' : ''"
            :key="i"
            class="tagLabel"
            @click="
              currentTag = item.key;
              noticeData=[];
              pages.page=1;
              fetchData('tab');
            "
            >{{ item.value }}</span
          >
        </div>
        <div class="notice" :class="schoolType!='ds'?'mt-12':''">
          <!-- <div>
          <van-dropdown-menu>
            <van-dropdown-item v-model="gradeGroup" :options="gradeGroupData" @change="changeGrade" />
            <van-dropdown-item v-model="level" :options="levelData"  @change="changeLevel" />
          </van-dropdown-menu>
        </div> -->
          <div v-if="textPicker" class="tag-month" @click="cancelArch">
            {{ textPicker }}
            <van-icon name="clear" size="24" style="vertical-align: middle; margin-bottom: 3px" />
          </div>
          <!-- <van-row class="filter" type="flex" justify="space-between">
          <van-col></van-col>
          <van-col class="ele-1">
            <span v-if="pages.total">{{$t('notice.items', {count: pages.total})}}</span>
          </van-col>
          <van-col class="ele-2" @click="onShowPicker">
            <van-icon name="clock-o" color="#646566" size="18" />
          </van-col>
        </van-row> -->
          <van-loading size="24" class="text-center" v-if="showLoading"  />
          <div v-if="noticeData.length > 0">
            <van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad">
              <div v-for="(item, i) in noticeData" :key="i">
                <router-link class="item-1" :to="$route.name == 'PreviewNotice' ? `/preview/notice/${item.id}` : `/notice/${item.id}`">
                  <img v-if="textPicker" :src="expiredImg" class="Expired" alt="Expired" />
                  <van-row class="item-1-1">
                    <div class="on-top" :class="$t('notice.on_top_icon_class')" v-if="item.on_top"></div>
                    <div class="level-alert" :class="`level-${item.level}`" v-if="item.level">{{ item.level_text }}</div>
                    <van-col span="24">
                      <div class="name mb-5">{{ item.title }}</div>
                      <div class="color6 font12 mb-5">{{ item.grade_group_text.join(" | ") }}</div>
                      <van-col span="24" class="mb-10" v-if="item.category != 1 && item.category != 0"
                        ><van-tag type="primary" class="categoryTag" color="#E7F4FF" text-color="#4D88D2">{{ showCategory(item.category) }}</van-tag></van-col
                      >
                      <div class="sub-name mb-5">
                        <van-icon name="manager" class="mr-5 color9" />
                        <span style="color: #000">{{ signAs[item.sign_as_uid].name }}</span> - {{ item.sign_title }}
                      </div>
                    </van-col>

                    <van-col span="24" class="title">{{ item.summary }}</van-col>
                    <!-- <van-col span="24" class="sub-title" style="text-align: right">
                      {{ item.publish_at }}
                    </van-col> -->
                    <van-col span="24">
                      <van-divider />
                      <van-row type="flex" justify="space-between" align="center" class="item-1-foot">
                        <van-row class="color6">{{ item.publish_at }}</van-row>
                        <!-- <van-row>{{ $t("notice.view_details") }}</van-row> -->
                        <van-row>
                          <span v-if="item.response">
                            <span style="color: #ee0a24" v-if="item.has_response == 0">{{ $t("global.need_response") }}</span>
                            <span style="color: #999" v-if="item.has_response == 1">{{ $t("global.confirmed") }}</span>
                          </span>
                          <van-icon name="arrow" color="#969799" style="vertical-align: middle" />
                        </van-row>
                      </van-row>
                    </van-col>
                  </van-row>
                </router-link>
              </div>
            </van-list>
          </div>
          <van-empty :description="$t('global.empty')" v-else-if="!showLoading" />
        </div>
      </div>

      <van-popup v-model="showPicker" round position="bottom" class="the-popup">
        <van-loading v-if="columns.length == 0" />
        <van-picker v-else show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" class="month-select" />
      </van-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Col, Row, Icon, Empty, DropdownMenu, DropdownItem, Divider, List, Popup, Picker, Loading, ActionSheet, Search, Dialog, Sticky, Checkbox } from "vant";
import Campus from "@/components/Campus";
import { noticeList, noticeListPreview, yearMonthCount, yearMonthItems } from "@/api/notice";
import { prefixZero } from "@/plugins/util";
import storage from "store";
import { APP_LANGUAGE } from "@/store/mutation-types";
import store from "../store";

Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(Empty);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Divider);
Vue.use(List);
Vue.use(ActionSheet);
Vue.use(Search);
Vue.use(Dialog);
Vue.use(Sticky);
Vue.use(Checkbox);
export default {
  name: "Notice",
  components: {
    "van-popup": Popup,
    "van-picker": Picker,
    "van-loading": Loading,
    campus: Campus,
  },
  data() {
    return {
      showPicker: false,
      textPicker: "",
      columns: [],
      year: "",
      month: "",
      show: false,
      noticeData: [],
      gradeGroupData: [],
      levelData: [],
      gradeGroup: "",
      level: 0,
      schoolid: "",
      signAs: {},
      loading: false,
      finished: false,
      pages: {},
      expiredImg: "",
      categoryList: [],
      currentTag: "",
      searchIcon: true,
      schoolType:''
    };
  },
  created() {
    this.schoolType= store.getters.wxState || 'ds'
    let cuLang = storage.get(APP_LANGUAGE) || "zh";
    if (cuLang == "en") {
      this.expiredImg = "http://m2.files.ivykids.cn/cloud01-file-8025768FpRD26R7F4PGAoCaE-vnvfTjjbO0.png";
    } else {
      this.expiredImg = "http://m2.files.ivykids.cn/cloud01-file-8025768FhS0-jBWrxm_CpOeYMI9mWOg_hOy.png";
    }
  },
  activated: function () {
    if (this.$route.query.type || this.$route.query.type=='') {
      this.currentTag = this.$route.query.type;
      this.noticeData=[]
      if (this.schoolid != "") {
        this.fetchData('init');
      }
    } else {
      this.currentTag = "";
    }
  },
  methods: {
    showCategory(id) {
      return this.categoryList.find((item2) => item2.key === id).value;
    },
    cancelArch() {
      this.textPicker = "";
      this.pages.page = 1;
      this.noticeData = [];
      this.searchData();
    },
    onShowPicker() {
      this.showPicker = true;
      if (this.columns.length == 0) {
        yearMonthCount({ schoolId: this.schoolid }).then((res) => {
          this.columns = res.data;
        });
      }
    },
    onConfirm(value, index) {
      this.showPicker = false;
      this.textPicker = this.columns[index[0]].text + "." + prefixZero(this.columns[index[0]]["children"][index[1]].month, 2);
      this.year = value[0];
      this.month = this.columns[index[0]]["children"][index[1]].month;
      this.pages.page = 1;
      this.noticeData = [];
      this.fetchArchData();
    },
    fetchArchData() {
      this.finished = false;
      yearMonthItems({
        schoolid: this.schoolid,
        year: this.year,
        month: this.month,
        page: this.pages.page,
      }).then((res) => {
        this.noticeData.push(...res.data.items);
        this.pages = res.data.pages;
        Object.assign(this.signAs, res.data.signAs);
        this.loading = false;
        this.show = true;
      });
    },
    changeCampus(val) {
      this.schoolid = val;
      this.fetchData();
    },
    changeGrade() {
      this.fetchData();
    },
    changeLevel() {
      this.fetchData();
    },
    fetchData(type) {
      this.showLoading=true
      if (this.$route.name == "PreviewNotice") {
        noticeListPreview({
          schoolid: this.schoolid,
          // grade_group: this.gradeGroup,
          // level: this.level,
          page: this.pages.page,
        }).then((res) => {
          this.gradeGroupData = res.data.grade_group;
          this.levelData = res.data.level;
          if (type) {
            this.noticeData = res.data.items;
          } else {
            this.noticeData.push(...res.data.items);
          }
          this.pages = res.data.pages;
          this.categoryList = [
            {
              key: "",
              value: this.$t("global.All"),
            },
          ];
          this.categoryList = this.categoryList.concat(res.data.categoryList);
          Object.assign(this.signAs, res.data.signAs);
          this.loading = false;
          this.show = true;
          this.showLoading=false
        });
      } else {
        this.finished = false;
        noticeList({
          schoolid: this.schoolid,
          // grade_group: this.gradeGroup,
          // level: this.level,
          page: this.pages.page,
          category: this.currentTag,
        }).then((res) => {
          this.gradeGroupData = res.data.grade_group;
          this.levelData = res.data.level;
          if (type) {
            this.noticeData = res.data.items;
          } else {
            this.noticeData.push(...res.data.items);
          }
          this.pages = res.data.pages;
          Object.assign(this.signAs, res.data.signAs);
          this.categoryList = [
            {
              key: "",
              value: this.$t("global.All"),
            },
          ];
          this.categoryList = this.categoryList.concat(res.data.categoryList);
          this.loading = false;
          this.show = true;
          this.showLoading=false
        });
      }
    },
    onLoad() {
      if (this.pages.page < this.pages.count) {
        this.pages.page += 1;
        if (this.textPicker != "") {
          this.fetchArchData();
        } else {
          this.fetchData();
        }
      } else {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.filterLabel::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.showLoading{
  position: fixed;
  background: #ccc;
}
.filterLabel {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px;
  overflow-x: auto;
  white-space: nowrap;
  .tagLabel {
    display: inline-block;
    padding: 6px 12px;
    background: #fff;
    border-radius: 16px;
    margin-right: 8px;
    font-size: 13px;
    color: #323233;
  }
  .tagLabelBg {
    background: #4d88d2;
    color: #fff;
  }
}
.searchList {
  .searchInput {
    .flex {
      align-items: center;
      margin-right: 10px;
      padding-right: 10px;
      border-right: 1px solid #ddd;
      margin-top: 2px;
      height: 30px;
    }
    .avatarImg {
      width: 29px;
      height: 29px;
    }
  }
  .searchHistory {
    margin: 16px;
    border-radius: 8px;
    background: #fff;
    .searchText {
      padding: 16px 16px 10px 16px;
    }
  }
}
.content {
  .all {
    padding: 10px 16px;
    position: relative;
  }
  .all::after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: 4.2666666666667vw;
    bottom: 0;
    left: 4.2666666666667vw;
    border-bottom: 1px solid #ebedf0;
    transform: scaleY(0.5);
  }
  .list {
    .avatarImg {
      width: 44px;
      height: 44px;
    }
    .mt-3 {
      margin-top: 3px;
    }
  }
}
.notice {
  padding-bottom: 20px;
  .expiredCheckbox{
    margin: 12px 0 12px 15px;
  }
  .item-1 {
    background-color: #fff;
    display: block;
    margin: 0 15px 12px 15px;
    padding: 15px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    .Expired {
      position: absolute;
      right: -8px;
      top: 38px;
      width: 48px;
      height: 48px;
    }
    .item-1-1 {
      position: relative;

      .on-top {
        background-repeat: no-repeat;
        position: absolute;
        top: -16px;
        left: -16px;
        width: 30px;
        height: 30px;
        background-size: contain;
      }
      .on-top-en {
        background-image: url("https://m2.files.ivykids.cn/ds2/top.png");
      }
      .on-top-cn {
        background-image: url("https://m2.files.ivykids.cn/ds2/top.png");
      }
      .level-alert {
        position: absolute;
        right: -14px;
        top: -16px;
        border-radius: 0 8px 0 0;
        font-size: 12px;
        padding: 1px 6px;
        color: #fff;
      }
      .level-1 {
        background-color: #ee0a24;
      }
      .level-2 {
        background-color: #f5a429;
      }
      .level-4 {
        background-color: rgb(71, 126, 202);
      }
    }
    .name {
      font-size: 15px;
      font-weight: bold;
      color: #323233;
      margin-top: 5px;
    }
    .sub-name {
      font-size: 13px;
      font-weight: 400;
      color: #646566;
    }
    .categoryTag {
      padding: 1px 5px;
    }
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #646566;
      line-height: 20px;
      margin: 8px 0 0 0;
    }
    .sub-title {
      height: 16px;
      font-size: 12px;
      font-weight: 400;
      color: #969799;
      line-height: 16px;
    }
    .item-1-foot {
      font-size: 13px;
      font-weight: 400;
      color: #323233;
      height: 18px;
    }
  }
  .filter {
    padding: 10px 15px 10px 15px;
    font-size: 14px;
    .ele-1 {
      color: #646566;
    }
    .ele-2 {
      .van-icon {
        font-size: 18px;
        vertical-align: bottom;
      }
    }
  }
}
.the-popup {
  min-height: 200px;
  .van-loading {
    text-align: center;
    margin: 100px 0;
  }
}
.mt-12{
  margin-top: 12px;
}
</style>

<style lang="less">
.tag-month {
  position: fixed;
  top: 42px;
  left: 0;
  height: 38px;
  line-height: 38px;
  padding: 0 6px 0 10px;
  background: #4d88d2;
  border-radius: 0px 19px 19px 0px;
  z-index: 999;
  color: #fff;
  font-weight: bold;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.14);
}
// span {
//   font-size: 12px;
//   margin-left: 10px;
// }
.month-select {
  .van-picker-column__item {
    span {
      font-size: 12px;
      margin-left: 10px;
      color: #999;
    }
  }
}
.expiredCheckbox{
  .van-checkbox__icon .van-icon{
    border-radius: 2px;
  }
}
</style>
