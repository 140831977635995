<template>
  <div>
    <div class="initList" v-if="infoData.pageData">
      <van-sticky>
        <div class="info" ref="info">
          <div class="font16 color3">
            <strong> {{ infoData.contactInfo.name }}</strong>
          </div>
          <!-- <div class="font12 color6 mt-5 oneText">
            {{infoData.contactInfo.desc}}
          </div> -->
        </div>
      </van-sticky>
      <div class="flex1 list" ref="scroll" :style="'margin-bottom:' + height + 'px;height:' + messHeight + 'px'">
        <van-list v-model="loading" :finished="finished" direction="up" :immediate-check="false" @load="onLoad" :offset="100">
          <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
          <div v-if="loadList.length != 0">
            <div class="flex" v-for="(list, index) in loadList" :key="index">
              <div>
                <img v-if="list.creator_type != 's'" :src="childData.avatar" alt="" class="teacherImg" />
                <img v-else :src="staffInfo[list.created_by].photoUrl" alt="" class="teacherImg" />
              </div>
              <div class="flex1 ml-10 hidden">
                <div class="font12 mb-10">
                  <strong class="color3 mr-10" v-if="list.creator_type != 's'">{{ childData.name }}</strong>
                  <strong class="color3 mr-10" v-else>{{ staffInfo[list.created_by].name }}</strong>
                  <span class="color6" v-if="list.creator_type != 's'">{{ childData.className }}</span>
                </div>
                <div class="titleText font14 color3" :class="list.creator_type == 'f' ? 'blue' : 'yellow'" :style="{display: list.pdfUrl.length==0? 'inline-block' : ''}">
                  <span class=" " v-html="list.content"> </span>
                  <div>
                    <div class="mt-10" v-if="list.imgUrl.length>0">
                      <van-uploader v-model="list.imgUrl" :deletable="false" :show-upload="false" preview-size="80"></van-uploader>
                    </div>
                    <div class="pdfList flex" v-for="(item,i) in list.pdfUrl" :key="i">
                      <!-- <img src="@/assets/PDF.png" alt="" class="pdfImg"  /> -->
                      <img src="@/assets/PDF.png" alt="" class="pdfImg" v-if='item.mimetype=="application/pdf"' />
                      <img src="@/assets/excel.png" alt="" class="pdfImg" v-else-if='item.mimetype=="application/vnd.ms-excel" || item.mimetype=="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"' />
                      <img src="@/assets/word.png" alt="" class="pdfImg" v-else-if='item.mimetype=="application/msword" || item.mimetype=="application/vnd.openxmlformats-officedocument.wordprocessingml.document"' />
                      <img src="@/assets/zip.png" alt="" class="pdfImg" v-else-if='item.mimetype=="application/x-zip-compressed"' />
                      <img src="@/assets/link.png" alt="" class="pdfImg" v-else /> 
                      <a class="ml-10 color3 flex1" :href="item.url">{{ item.title }}</a> 
                    </div>
                  </div>
                  </div>
                <div class="forward" v-if="list.repost && list.repost != 0">
                  <div v-if="list.repost == 1">
                    <span v-if="list.repost_to_type == 4"> {{ $t("contact.handovered_to") }} 
                      <router-link class="colorBlue" :to="{ name: 'ContactMessage', params: { id: list.repost_to_target, type:4, childId: childId } }">{{ deptInfo[list.repost_to_target] }}</router-link> 
                    </span>
                    <span v-if="list.repost_to_type == 3"> {{ $t("contact.handovered_to") }} 
                      <router-link  class="colorBlue" :to="{ name: 'ContactMessage', params: { id: list.repost_to_target, type: 3, childId: childId } }">{{ staffInfo[list.repost_to_target].name }}</router-link> </span>
                  </div>
                  <div v-if="list.repost == 2">{{ $t("contact.handovered_from", { x: staffInfo[list.repost_by].name }) }}</div>
                </div>
                <div class="color9 mt-10 font12">
                  <span class="mr-10">{{ list.updated_at }}</span>
                  <span v-if="list.creator_type != 's'">
                    <span class="divider">|</span>
                    <span class="color3 ml-10" v-if="list.creator_type == 'f'">{{ $t("contact.form_f") }}</span>
                    <span class="color3 ml-10" v-if="list.creator_type == 'm'">{{ $t("contact.form_m") }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <van-empty :description="$t('calendar.no_data')" v-else />
        </van-list>
      </div>

      <div class="message" ref="message">
        <van-notice-bar wrapable color="#646566" class="font12" background="#FFFBE8" :scrollable="false" :text="$t('contact.normally')" />
        <div class="uploadList">
          <van-uploader v-model="qiniuFile" :show-upload="false" :before-delete="delImg" preview-size="60" v-if="qiniuFile.length>0" class="imgList"></van-uploader>
          <!-- <van-uploader v-model="showFiles" :show-upload="false"  class="imgList"></van-uploader> -->

          
          <div v-if="qiniuDataPdf.length>0">
            <div class="color6 flex font13 items-center filePdf" v-for="(list,ix) in qiniuDataPdf" :key="ix" >
              <span class="iconfont iconfont-link iconColor"></span>
              <a class="flex1 ml-4 color6" :href="list.file_key">{{ list.title }}</a>
              <van-icon name="edit"  size="18" class="mr-10" v-if='list.file_key!=""' @click="editTitle(list,ix)"/>
              <van-icon  name="close" size="18"  v-if='list.file_key!=""'  @click="delPdf(list,ix)" /> 
            </div>
            <!-- <div class="color6 flex font13 items-center filePdf" v-for="(list,ix) in showFiles" :key="ix" >
              <span class="iconfont iconfont-link iconColor"></span>
              <a class="flex1 ml-4 color6" :href="list.file_key">{{ list.title }}</a>
            </div> -->
          </div>
        </div>
        <!-- <div class="flex showText" v-if="!showText">
          <div @click="showInput()"  class="textShow flex1">{{ $t("contact.input_message") }}</div>
          <div>
            <van-uploader  :after-read="afterRead">
              <span class="iconfont iconfont-link"></span>
            </van-uploader>
          </div>
        </div> -->
        <!-- v-if="showText" -->
        <div class="flex" >
          <div class="flex1">
            <van-field v-model="messageText" rows="2"  :autosize="{ maxHeight: 150, minHeight: 50 }" type="textarea" maxlength="5000" show-word-limit ref="name" class="messageText" :placeholder="$t('contact.input_message')" />
          </div>
          <div class="sendText">
            <van-uploader  :after-read="afterRead" multiple :max-size="1024*1024*50" @oversize="onOversize"   accept="image/*,.pdf,.doc,.docx,.zip,.xls,.xlsx">
              <span class="iconfont iconfont-link"></span>
            </van-uploader>
            <div class="btn">
              <van-button round type="info" size="small" v-if="messageText != '' || uploadList.length!=0 || qiniuDataPdf.length!=0" @click="sendEessage"  :loading="disabledSend">{{ $t("contact.send") }}</van-button>
              <van-button round type="info" size="small" v-else disabled>{{ $t("contact.send") }}</van-button>
              </div>
          </div>
        </div>
      </div>
    </div>
    <van-dialog v-model="titleShow" title="重命名" show-cancel-button :before-close="beforeClose">
      <div class="rename">
        <van-field
          v-model="title"
          rows="2"
          autosize
          type="textarea"
          class="textarea"
        />
      </div>
    </van-dialog>
    <van-dialog v-model="selectChild" :title='$t("notice.select_child")' @confirm="checkChild"
      :beforeClose="checkChild">
      <div class="p24">
        <van-radio-group v-model="childRadio">
          <van-cell-group>
            <van-cell v-for="(item, index) in childListData" clickable :key="index" :title="item.name"
              @click="toggle(index)">
              <template #right-icon>
                <van-radio :name="index" ref="checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
    </van-dialog>
  </div>
  
</template>

<script>
import Vue from "vue";
import { Cell, Icon, Picker, Col, Row, ActionSheet, Empty, Toast, Sticky, List, NoticeBar,Uploader, Dialog   } from "vant";
import { directMessage, contact, getQiniuToken, deleteFile, updateFileTitle } from "@/api/contactCampus";
import { childList } from "@/api/child";
//七牛
import * as qiniu from 'qiniu-js'
Vue.use(qiniu)
Vue.use(Cell);
Vue.use(Icon);
Vue.use(Picker);
Vue.use(Toast);
Vue.use(Col);
Vue.use(Row);
Vue.use(ActionSheet);
Vue.use(Empty);
Vue.use(Sticky);
Vue.use(List);
Vue.use(NoticeBar);
Vue.use(Uploader);
Vue.use(Dialog);
export default {
  name: "message",
  data() {
    return {
      childId: this.$route.params.childId,
      id: this.$route.params.id,
      type: this.$route.params.type,
      messageText: "",
      height: "",
      infoData: {},
      messHeight: "",
      childData: [],
      loading: false,
      finished: false,
      scrollHeight: 0,
      page: 1,
      loadList: [],
      showText: false,
      disabledSend: false,
      staffInfo:{},
      deptInfo:{},
      Token:'',
      qiniuFile: [],
      qiniuDataUrl:[],
      qiniuDataPdf:[],
      uploadList:[],
      attachmentList:{},
      titleShow:false,
      editList:{},
      editIndex:null,
      title:'',
      confirmBtn:false,
      files:[],
      countIndex:0,
      showFiles:[],
      uploadStatus:true,
      childListData: {},
      selectChild: false,
      childRadio: '',
    };
  },
  beforeCreate() {
    childList().then((res) => {
      this.childListData = res.data
      if(this.childId=='scan'){
        if (res.data.length != 1) {
          this.selectChild = true
        } else {
          this.childId = res.data[0].id
          this.childData = res.data[0];
          this.getData();
        }
      }else{
        res.data.forEach((item) => {
          if (item.id == this.childId) {
            this.childData = item;
          }
        });
        this.getData();
      }
    });
    // document.querySelector('body').setAttribute('style', 'background-color:#fff;')
  },
  watch: {
    messageText() {
      this.getInitHeight();
    },
    $route() {
       window.location.reload();
   },
  },
  created() {
    this.getQiniu()
  },
  methods: {
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    checkChild(action, done) {
      if (action === 'confirm') {
        if (this.childRadio === "") {
          Toast(this.$t("notice.select_child"));
          done(false)
          return;
        }
        this.childId = this.childListData[this.childRadio].id
        this.childData = this.childListData[this.childRadio]
        this.getData();
        this.selectChild = false
      }
    },
    showInput() {
      this.showText = true;
      this.$nextTick(function () {
        this.$refs.name.focus();
        this.getInitHeight();
      });
    },
    getInitHeight() {
      setTimeout(() => {
        this.height = this.$refs.message.offsetHeight;
        let topHeight = this.$refs.info.offsetHeight;
        this.messHeight = document.documentElement.clientHeight - topHeight - this.height - 30;
        this.scrollToBottom();
      }, 100);
    },
    getData() {
      directMessage(this.id, this.type, {
        childId: this.childId,
        page: this.page,
      }).then((res) => {
        this.infoData = res.data;
        this.attachmentList=this.infoData.attachmentList.length==0?{}:this.infoData.attachmentList
        res.data.pageData.items.forEach((list) => {
          list.imgUrl=[]
          list.pdfUrl=[]
          if(list.attachments.length!=0){
              list.attachments.forEach((item) => {
                if(this.attachmentList[item]){
                  let type=this.attachmentList[item].mimetype.split('/')[0]
                  if(type=="image"){
                    list.imgUrl.push({url:this.attachmentList[item].url})
                  }else{
                    list.pdfUrl.push(this.attachmentList[item])
                  }
                }
              })
            }
          });
        this.loadList = res.data.pageData.items.reverse();
        this.staffInfo=this.infoData.staffInfo.length==0?{}:this.infoData.staffInfo
        this.deptInfo=this.infoData.deptInfo.length==0?{}:this.infoData.deptInfo
        
        if (this.page == 1) {
          this.$nextTick(function () {
            this.getInitHeight();
          });
        }
      });
    },
    onLoad() {
      this.loading = true;
      if (this.loadList.length >= this.infoData.pageData.total) {
        this.finished = true;
        this.loading = false;
        return;
      }
      this.page += 1;
      directMessage(this.id, this.type, {
        childId: this.childId,
        page: this.page,
      }).then((res) => {
        this.attachmentList =Object.assign(res.data.attachmentList,this.attachmentList);
        res.data.pageData.items.forEach((list) => {
          list.imgUrl=[]
          list.pdfUrl=[]
          if(list.attachments.length!=0){
              list.attachments.forEach((item) => {
                if(this.attachmentList[item]){
                  if(this.attachmentList[item].mimetype=="image/png" ||this.attachmentList[item].mimetype=="image/jpeg" ||this.attachmentList[item].mimetype=="image/jpg"){
                    list.imgUrl.push({url:this.attachmentList[item].url})
                  }else{
                    list.pdfUrl.push(this.attachmentList[item])
                  }
                }
              })
            }
          });
        this.loadList = res.data.pageData.items.reverse().concat(this.loadList);
        this.isCompleted = res.data.pageData.last_page;
        this.$refs.scroll.scrollTop = this.$refs.scroll.scrollHeight - this.scrollHeight;
        this.scrollHeight = this.$refs.scroll.scrollTop;
        this.deptInfo = Object.assign(res.data.deptInfo,this.deptInfo);
        this.staffInfo =Object.assign(res.data.staffInfo,this.staffInfo);
        
      
        setTimeout(() => {
          if (this.page < this.isCompleted) {
            this.page += 1;
          }
        }, 500);
        this.loading = false;
      });
    },
    sendEessage() {
      if (!this.uploadStatus) {
        Toast(this.$t("contact.Uploading"));
        return;
      } 
      this.disabledSend = true;
      var ids=[]
      this.uploadList.forEach((item) => {
        ids.push(item._id)
        this.attachmentList[item._id]={
          url:item.file_key,
          id:item._id,
          mimetype:item.mimetype,
          title:item.title
        }
      })
      this.qiniuDataPdf.forEach((item) => {
        ids.push(item._id)
        this.attachmentList[item._id]={
          url:item.file_key,
          id:item._id,
          mimetype:item.mimetype,
          title:item.title
        }
      })
      contact(this.id, this.type, {
        childId: this.childId,
        content: this.messageText,
        attachments:ids
      }).then((res) => {
        if(res.code==0){
          this.disabledSend = false;
          this.messageText = "";
          this.uploadList=[]
          this.qiniuFile=[]
          this.qiniuDataPdf=[]
          res.data.attachments=ids
          res.data.imgUrl=[]
          res.data.pdfUrl=[]
          if(ids.length!=0){
            ids.forEach((item) => {
              if(this.attachmentList[item]){
                if(this.attachmentList[item].mimetype=="image/png" ||this.attachmentList[item].mimetype=="image/jpeg" ||this.attachmentList[item].mimetype=="image/jpg"){
                  res.data.imgUrl.push({url:this.attachmentList[item].url})
                }else{
                  res.data.pdfUrl.push(this.attachmentList[item])
                }
              }
            })
          }
          this.loadList.push(res.data);
          this.$nextTick(function () {
            setTimeout(() => {
              this.getInitHeight();
            }, 100);
          });
        }else{
          this.disabledSend = false;
        }
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const top = this.$refs.scroll;
        top.scrollTop = top.scrollHeight;
      });
    },
    getQiniu(){
      return new Promise((resolve, reject) => {
        getQiniuToken({linkId:this.id}).then((res) => {
          this.Token=res.data
          resolve(res);
        }).catch(e =>{
            reject(e)
        })
      });
    },
    sendQiniu(){
      if(this.countIndex >= this.files.length) {
          this.uploadStatus=true
          return;
      }
      this.uploadStatus=false
      let that=this
      let type=that.files[that.countIndex].file.type.split('/')
      let config = { useCdnDomain: true, region: qiniu.region.z1 }
      let putExtra = { fname: that.files[that.countIndex].file.name, params: {}, mimeType: null }
      let headers = qiniu.getHeadersForMkFile(that.Token)
      let key=that.files[that.countIndex].file.name
      var lens=''
      if(type[0]=="image"){
        if(this.files.length==that.qiniuFile.length){
          lens=that.countIndex
        }else{
          lens=that.qiniuFile.length-that.files.length+that.countIndex
        }
        that.qiniuFile[lens].message=this.$t("contact.Uploading")
      }else{
        if(this.files.length==that.qiniuDataPdf.length){
          lens=that.countIndex
        }else{
          lens=that.qiniuDataPdf.length-this.files.length+that.countIndex
        }
        that.qiniuDataPdf[lens].title=this.$t("contact.Uploading")
        that.qiniuDataPdf[lens].file_key=''
      }
      that.$nextTick(function () {
        setTimeout(() => {
          that.getInitHeight();
        }, 100);
      });
      let observe = {
        next() {
        },
        error() {
          that.getQiniu().then(() => {
            that.sendQiniu();
          });
        },
        complete(res) {
          let fileType=that.files[that.countIndex].file.type.split('/')
          if(fileType[0]=="image"){
            that.uploadList.push(res.data)
            Vue.set(that.qiniuFile[lens], 'url', res.data.file_key);
            Vue.set(that.qiniuFile[lens], 'status', 'done');
            Vue.set(that.qiniuFile[lens], 'name',  res.data.title);
            that.countIndex++;
            that.sendQiniu();
          }else{
            Vue.set(that.qiniuDataPdf, lens, res.data);
            
            that.countIndex++;
            that.sendQiniu();
          }
          
        }
      }
      let observable = qiniu.upload(that.files[that.countIndex].file, key, that.Token, headers, putExtra, config)
      that.subscription = observable.subscribe(observe)
    },
    onOversize() {
      Toast(this.$t("contact.keep_below"));
    },
    afterRead(file) {
      if(Array.isArray(file)){
        this.files=file
      }else{
        this.files=[file]
      }
      let type=this.files[0].file.type.split('/')
      // this.files.some(ele=>(alert(ele.file.name)))
      if(type[0]=="image"){
        let arr = this.qiniuFile.filter(item=>this.files.some(ele=>ele.file.name === item.name));
       if(arr.length>0){
        Toast(this.$t("contact.Data_duplication"));
        return
       }
      }
      this.files.forEach((item) => {
        let type=item.file.type.split('/')
        if(type[0]=="image"){
          this.qiniuFile.push(
              {url:'',
                status: 'uploading',
                message: this.$t("contact.Upload_queue")
              }
            )
          }else{
            this.qiniuDataPdf.push(
              {
                title: this.$t("contact.Upload_queue"),
                file_key:''
              }
            )
          }
      })
      this.countIndex=0
      this.sendQiniu();
    },
    delImg(file,detail){
      deleteFile(this.uploadList[detail.index]._id).then(() => {
        this.uploadList.splice(detail.index,1)
        this.qiniuFile.splice(detail.index,1)
      })
    },
    delPdf(list,index){
      deleteFile(list._id).then(() => {
        this.qiniuDataPdf.splice(index,1)
      })
    },
    editTitle(list,index){
      this.editList=list
      this.editIndex=index
      this.titleShow=true
    },
    beforeClose(action,done){
      if (action === 'confirm') {
          if (this.title == "") {
            Toast(this.$t("global.please_input"));
            done(false)
            return;
          }
          this.confirmBtn=true
          updateFileTitle({ id: this.editList._id, title: this.title}).then(
            (res) => {
              if (res.code == 0) {
                this.qiniuDataPdf[this.editIndex].title=res.data.title 
                this.qiniuDataPdf[this.editIndex].file_key=res.data.url 
                this.titleShow = false;
                this.confirmBtn=false
                done()
              }else{
                this.confirmBtn=false
                done()
              }
            }
          );
      } else {
        this.titleShow = false;
        done()
      }
    }
  },
};
</script>

<style lang="less" scoped>
.flex_direction {
  flex-direction: column;
}
.hidden{
  overflow: hidden;
}
.info {
  padding: 10px;
  text-align: center;
  background: #f2f3f5;
  .oneText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.list {
  background-color: #fff;
  display: block;
  padding: 12px 16px 0 16px;
  padding: 15px;
  border-radius: 8px;
  position: relative;
  overflow-y: auto;
  .flex {
    margin-bottom: 16px;
  }
  .titleText {
    padding: 10px 16px;
    border-radius: 6px;
   
    .pdfList{
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #F2F3F5;
        font-size: 14px;
        padding: 8px;
        margin-top: 8px;
        margin-bottom: 0;
        img{
          height: 22px;
          width: 22px;
        }
        a{
          line-height: 22px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
  }
  .yellow {
    background: rgba(245, 164, 41, 0.1);
  }
  .blue {
    background: rgba(77, 136, 210, 0.1);
  }
  .divider {
    color: #dcdee0;
  }
}
.teacherImg {
  width: 38px;
  height: 38px;
  border-radius: 8px;
  object-fit: cover;
}
.message {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #fafafa;
  .showText{
    align-items: center;
    margin-right: 16px;
    .textShow {
      background: #f2f3f5;
      border-radius: 8px;
      margin: 8px 16px;
      padding: 12px 8px;
      color: #c8c9cc;
      font-size: 12px;
    }
  }
  .flex {
    // align-items: flex-end;
    .flex1 {
      margin: 7px 16px;
      overflow-y: auto;
      border: none;
      max-height: 240px;

      .messageText {
        border-radius: 8px;
        background: #f2f3f5;
        ::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
      }
    }
    .sendText {
      margin:7px 10px 10px 0;
      position: relative;
      min-width:45px;
      text-align: center;
      .iconfont {
        
        margin-top: 12px;
        font-size: 17px;
      }
      .btn{
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
  .uploadList{
    // margin:16px;
    .imgList{
      margin:16px 16px 0;
    }
  .filePdf{
      margin:8px 16px 0;
      background: #F2F3F5;
      padding:2px 8px;
      border-radius: 4px;
      align-items: center;
      color: #646566;
      .iconfont{
        font-size: 14px;
      }
      a{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-left: 6px;
      }
    }
  }
}
.messageText:after {
  border-bottom: none;
}
.colorBlue {
  color: #4d88d2;
}
.fontWeight {
  font-weight: 600;
}
.initList {
  min-height: 100vh;
  background: #fff;
  overflow: hidden;
}
.forward {
  background: #f2f3f5;
  border-radius: 4px;
  color: #646566;
  padding: 10px;
  font-size: 12px;
  position: relative;
  margin-top: 10px;
}
.forward:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 13px solid;
  border-color: transparent transparent #f2f3f5 transparent;
  left: 15px;
  top: -20px;
}
.rename{
  margin:8px 24px;
  .textarea{
    background: #F7F8FA;
    border-radius: 8px;
  }
}
.p24 {
  padding: 12px 1px 0
}
</style>
