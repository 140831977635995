<template>
  <div>
    <van-empty :description="$t('pickupcard.no_student')" v-if="emptyChild" />
    <van-skeleton title :row="23" :loading="loading" v-else>
      <van-notice-bar
        ref='notice'
        v-if="dataList.audit_status==2"
        wrapable
        left-icon="volume-o"
        :scrollable="false"
        :text="dataList.audit_comments"
      />
      <van-notice-bar
        ref='notice'
        v-if="dataList.audit_status==1"
        wrapable
        left-icon="volume-o"
        :scrollable="false"
        :text="$t('homeAddress.info_complete')"
      />
      <div class="mb-10 word relative" ref='bgImg'>
        <div class="bgImg" >
          <van-row type="flex" justify="end">
            <switch-lang color='#377ED7' />
          </van-row>
          <div class="mb-10 mt-10" v-html="tipsText"></div> 
        </div>
      </div>
      <div class="background" :style="{ height: heights + 'px' }">
        <div class="mt-15">
            <children @change="childChange" :children="childrenList" v-if="childrenList.length>0" />
        </div>
        <div class="container">
          <div class="title ">{{$t('homeAddress.myAddress')}}</div>
          <van-field
            v-model="address"
            rows="3"
            autosize
            class='bgGray'
            type="textarea"
            :placeholder="$t('homeAddress.myAddress')"
          />
          <div class="btnBox">
            <van-button type="info" block class="saveSub" disabled v-if="!dataList.state">{{$t('pickupcard.not_edit')}}</van-button>
             <van-button type="info" block class="saveSub" v-else-if='dataList.audit_status==1' disabled >{{$t('global.ok')}}</van-button>
            <van-button type="info" block class="saveSub" v-else @click='saveAddress()' :disabled='saveCurrent' >{{$t('global.ok')}}</van-button>
          </div>
        </div>
      </div>
       <van-dialog
        v-model="isShow"
        show-cancel-button
        :beforeClose="beforeClose"
      >
        <div class="p15">
          <van-row type="flex" justify="center">
            <div class="mb-10"><strong>{{$t('pickupcard.apply_to')}}</strong></div>
          </van-row>
          <van-checkbox-group v-model="childIds"  class="mb-10">
            <van-checkbox v-for="(list,i) in childrenList" :key='i' class="mt-15" :name='list.id' :disabled="!childrenStatus[list.id]"> {{list.name}}</van-checkbox>
          </van-checkbox-group>
        </div>
      </van-dialog>
    </van-skeleton>
  </div>
</template>

<script>
import Vue from 'vue';
import { Form, Icon, Toast, Button, Row, Skeleton, Field, Dialog} from 'vant';
import Children from '@/components/Children'
import storage from 'store'
import { CURRENT_CHILD } from '@/store/mutation-types'
import { childList } from '@/api/child'
import SwitchLang from '@/components/SwitchLang'
import {address, addressSave, childrenStatus } from '@/api/homeAddress'

Vue.use(Form);
Vue.use(Icon);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Row);
Vue.use(Skeleton);
Vue.use(Field);
export default {
  name: 'Pickup',
   components: {
    'children': Children,
    'switch-lang':SwitchLang
  },
  data() {
    return {
      loading: false,
      tipsText:'',
      childrenList:[],
      childrenStatus: {},
      address:'',
      heights:'',
      childIds:[],
      isShow:false,
      saveCurrent:false,
      emptyChild: false,
      dataList:{}
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color:#fff;')
  },
  created () {
    childList().then(res => {
      this.childrenList=res.data
      this.loading = false
      let cIds = [];
      this.childrenList.map(item => {
        this.childrenStatus[item.id] = true
        cIds.push(item.id)
      })
      if (this.childrenList.length < 1) {
        this.emptyChild = true
      }
      let cid = storage.get(CURRENT_CHILD) ? storage.get(CURRENT_CHILD).id : ''
      this.childid = cIds.indexOf(cid) > -1 ? cid : cIds[0]
    })
  },
  methods: {
    childChange(val) {
      this.childid = val.id
      this.getAddress()
    },
    getAddress(){
      address({childId:this.childid}).then(res => {
         this.dataList=res.data
         this.tipsText=res.data.tipsText
         this.address=res.data.address
        })
    },
    saveAddress(){
      if (this.childrenList.length > 1) {
        this.childIds=[]
        this.childIds.push(this.childid)
        childrenStatus().then(res => {
        this.isShow = !this.isShow
        this.childrenStatus = res.data
      })
      }else{
        this.save()
      }
    },
    save(done){
      this.saveCurrent=true
      var childid=[]
      if (this.childrenList.length > 1) {
        childid=this.childIds
      }else{
        childid.push(this.childid)
      }
      if(childid.length==0){
        Toast(this.$t('homeAddress.select_child'))
        done(false)
        this.saveCurrent=false
        return
      }
      addressSave({
        childId: childid,
        address: this.address,
      }).then(response => {
        if (response.code == 0) {
          this.dataList.audit_status=0
          Toast(response.msg || '提交成功')
          if(done){
            done()
          }
          this.saveCurrent=false
        }else{
          done(false) 
          this.saveCurrent=false
        }
      })
    },
    beforeClose(action, done) {
      if(action === 'confirm') {
          this.save(done)
      } else if(action === 'cancel') {
         done() //关闭
      }
    },
  }
}
</script>

<style lang="less" scoped>
.relative{
  position: relative;
}
.word{
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 21px;
    background:#7EA9F6;
    .bgImg{
      background-image:url('../assets/address.png');
      padding:16px;
      background-repeat:no-repeat;
      background-position:bottom right;
      min-height:110px;
    }
}
.background{
  background:#fff;
}
.p15{
  padding:20px
}
.container{
  padding:25px 16px 0px;
  font-size:12px;
  .flex{
    display: flex;
    .flex1{
      flex:1;
    }
  }
  .bgGray{
    background: #F7F8FA;
  }
  .title{
    font-size: 14px;
    color: #646566;
    line-height:26px;
    margin-bottom:10px
  }
  .btnBox{
    width: calc(100% - 32px);
    height: 55px;
    position: fixed;
    bottom:0px;
    // background: #fff;
    .saveSub{
      height: 40px;
      background: #4D88D2;
      border-radius: 100px;
    }
  }
  
}
</style>
