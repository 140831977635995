<template>
    <div :class="stepStatus == 4 && dataDetails.isCommon == 1 ? 'clickNone' : ''">
        <div class="color6 font14">{{ $t("coll.medication_school") }}</div>
        <van-field v-model="step_data.reasons" clearable :placeholder="$t('global.please_input')"
            class="bgGray mt-10" />
        <div class="color6 font14 mt-20">{{ $t("coll.specialMedication") }}</div>
        <div v-if="medicationList.length > 0">
            <div class="medicationList" v-for="(list, index) in medicationList" :key="index">
                <span class="close" v-if="index > 0" @click="delList(index)"><van-icon name="cross" /></span>
                <div class="color6 font14">{{ $t("coll.medication_name") }}</div>
                <van-field v-model="list.name" :placeholder="$t('global.please_input')" class="bgGray mt-10" />
                <div class="color6 font14 mt-20">{{ $t("coll.administration") }}</div>
                <van-field v-model="list.dose" :placeholder="$t('global.please_input')" class="bgGray mt-10" />
                <div class="color6 font14 mt-20">{{ $t("coll.Time_per_day") }}</div>
                <van-field v-model="list.use_time" :placeholder="$t('global.please_input')" class="bgGray mt-10" />
                <div class="color6 font14 mt-20">{{ $t("coll.duration_treatment") }}</div>
                <div class="flex">
                    <div class="flex1">
                        <div class="color9 font12 mt-10">{{ $t("coll.From") }}</div>
                        <van-field v-model="list.use_days[0]" is-link readonly class="bgGray mt-10"
                            :placeholder="$t('coll.choose')" @click="showDateEvent(0, index)" />
                    </div>
                    <div class="flex1 ml-10">
                        <div class="color9 font12 mt-10">{{ $t("coll.To") }}</div>
                        <van-field v-model="list.use_days[1]" is-link readonly class="bgGray mt-10"
                            :placeholder="$t('coll.choose')" @click="showDateEvent(1, index)" />
                    </div>
                </div>
                <!-- <van-field v-model="list.use_days" :placeholder="$t('global.please_input')" class="bgGray mt-10" /> -->
                <div class="color6 font14 mt-20">{{ $t("coll.specify_needed") }}</div>
                <van-field v-model="list.medical_special" :placeholder="$t('global.please_input')"
                    class="bgGray mt-10" />
                <div class="color6 font14 mt-20 mb-10">{{ $t("coll.Is_prescription") }}</div>
                <van-radio-group v-model="list.Rx" direction="horizontal">
                    <van-radio name="1" icon-size="16px">{{ $t('coll.yes') }}</van-radio>
                    <van-radio name="0" icon-size="16px" class="ml-20">{{ $t('coll.no') }}</van-radio>
                </van-radio-group>
                <div v-if="list.Rx == 1">
                    <div class="color6 font14 mt-20">{{ $t("coll.upload_prescription") }}</div>
                    <van-uploader class="mt-10" :before-read="beforeRead" v-model="list.Rx_photo_url"
                        @delete="deleteimg" :after-read="afterRead" :name="index" />
                </div>
            </div>
        </div>
        <div class="font14 blue" @click="addPlus"><van-icon name="plus" />{{ $t("coll.Add_medication") }}</div>

        <div v-if="dataDetails.protocol.length != 0">
            <protocol-sign @click="baseImg" :busProtocol="dataDetails.protocol" :base64="base64"></protocol-sign>
        </div>
        <div class="mt-20">
            <van-button v-if="stepStatus == 4 && dataDetails.isCommon == 1" disabled type="info" block round
                color="#4d88d2" class="saveSub">{{ $t("coll.step_reviewed") }}</van-button>
            <a href="javascript:;"  v-else-if="stepStatus ==1 && dataDetails.isCommon == 2" @click="submitData()" class="saveBtnStep"
                :class="btnDisabled ? 'disabledSubmit' : ''">{{ $t("coll.Resubmit") }}</a>
            <a href="javascript:;" v-else @click="submitData()" class="saveBtnStep"
                :class="btnDisabled ? 'disabledSubmit' : ''">{{ $t("global.submit") }}</a>
        </div>
        <van-popup v-model="showDate" round position="bottom">
            <van-datetime-picker v-model="currentDate" type="date" @confirm="dateConfirm" :min-date="minDate" />
        </van-popup>
    </div>
</template>

<script>
import ProtocolSign from "@/components/steps/ProtocolSign";
import Vue from "vue";
import { Toast } from "vant";
import Compressor from 'compressorjs';

export default {
    props: {
        dataDetails: {
            type: Object,
        },
        status: {
            type: Number,
        },
        disabled: {
            type: Number,
        },
    },
    components: {
        "protocol-sign": ProtocolSign,
    },
    name: "homeAddress",
    data() {
        return {
            base64:'',
            qiniuToken: "",
            step_data: "",
            stepStatus: "",
            showDate: false,
            currentDate: '',
            minDate: new Date(2023, 11, 1),
            btnDisabled: false,
            medicationList:[],
        };
    },
    watch: {
        disabled() {
            this.btnDisabled = false;
        },
    },
    created() {
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1;  // 注意月份从0开始，需要加1
        let day = currentDate.getDate();
        
        this.currentDate=year + "-" + month + "-" + day;
        this.qiniuToken = this.dataDetails.qiniuToken;
        this.stepStatus = this.status;
        console.log(this.status)
        console.log(this.dataDetails)
        this.step_data = this.dataDetails.step_data;
        if (this.step_data.drugs.length != 0) {
            for (var i = 0; i < this.step_data.drugs.length; i++) {
                if (this.step_data.drugs[i].Rx_photo_url) {
                    this.step_data.drugs[i].Rx_photo_url = this.tojson(this.step_data.drugs[i].Rx_photo_url);
                }
            }
            this.medicationList = this.step_data.drugs
        } else {
            this.medicationList = [{
                "name": "",
                "dose": "",
                "use_time": "",
                "use_days": [this.currentDate, this.currentDate],
                "Rx": "",
                "Rx_photo": [],
                'Rx_photo_url': [],
                'medical_special': ''
            }]
        }
        if (this.dataDetails.sign_url != "") {
            this.base64 = this.dataDetails.sign_url;
        }
    },
    methods: {
        showDateEvent(type, index) {
            this.listType = type
            this.listIndex = index
            this.showDate = true;
            let date = this.medicationList[index].use_days[type].split('-')
            this.currentDate = new Date(date[0], date[1] - 1, date[2])
        },
        dateConfirm(value) {
            this.medicationList[this.listIndex].use_days[this.listType] = this.timestampToTime(value)
            this.showDate = false
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
            return Y + M + D
        },
        addPlus() {
            this.medicationList.push({
                "name": "",
                "dose": "",
                "use_time": "",
                "use_days": [this.currentDate, this.currentDate],
                "Rx": "",
                "Rx_photo": [],
                'Rx_photo_url': [],
                'medical_special': ''
            })
        },
        tojson(rows) {
            var ids = []
            for (var i = 0, len = rows.length; i < len; i++) {
                var signAgainReq = new Object();
                signAgainReq.url = rows[i];
                ids.push(signAgainReq);
            }
            return ids
        },
        beforeRead(file) {
            return new Promise((resolve) => {
                new Compressor(file, {
                    maxWidth: 1200,
                    quality: 0.6,
                    success: resolve,
                    error(err) {
                        console.log(err.message);
                    },
                });
            });
        },
        afterRead(file, detail) {
            this.type = detail.name;
            if (file.length > 1) {
                for (let i in file) {
                    this.upimg(file[i].content);
                }
            } else {
                this.upimg(file.content);
            }
        },
        upimg(data) {
            const that = this;
            Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: that.$t("pickupcard.uploading"),
            });
            var pic = data.substring(data.indexOf(",") + 1);
            var url = "https://upload-z1.qiniup.com/putb64/-1";
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    var resJson = JSON.parse(xhr.responseText);
                    if (resJson.code == 0) {
                        if (that.type == 'medical_insurance_photo') {
                            that.step_data[that.type].push(resJson.data.key);
                        } else {
                            that.medicationList[that.type].Rx_photo.push(resJson.data.key);
                        }
                        Toast(that.$t("pickupcard.Uploaded"));
                    } else {
                        Toast(resJson.msg);
                    }
                }
            };
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/octet-stream");
            xhr.setRequestHeader("Authorization", "UpToken " + that.qiniuToken);
            xhr.send(pic);
        },
        //删除图片
        deleteimg(file, detail) {
            if (detail.name == 'medical_insurance_photo') {
                this.step_data[detail.name].splice(detail.index, 1);
            } else {
                this.medicationList[detail.name].Rx_photo.splice(detail.index, 1);
            }
        },
        delList(index) {
            this.medicationList.splice(index, 1);
        },
        baseImg(data) {
            if (data != "cancel") {
                this.base64 = data;
                this.newSign = true;
            }
        },
        phoneValidate(params) {
            if (this.pattern.test(params)) {
                return true;
            } else {
                return false;
            }
        },
        submitData() {
            let that = this;
            if (this.btnDisabled) {
                return;
            }
            if (this.step_data.reasons == '') {
                Toast(this.$t("global.please_input") + ' ' + this.$t("coll.medication_school"));
                return
            }
            let uploadData=JSON.parse( JSON.stringify (this.medicationList))
            if (this.medicationList.length != 0) {
                for (var i = 0; i < this.medicationList.length; i++) {
                    delete uploadData[i].Rx_photo_url
                    if (this.medicationList[i].name == '') {
                        Toast(this.$t("global.please_input") + ' ' + this.$t("coll.medication_name"));
                        return
                    }
                    if (this.medicationList[i].dose == '') {
                        Toast(this.$t("global.please_input") + ' ' + this.$t("coll.administration"));
                        return
                    }
                    if (this.medicationList[i].use_time == '') {
                        Toast(this.$t("global.please_input") + ' ' + this.$t("coll.Time_per_day"));
                        return
                    }
                    if (this.medicationList[i].use_days[0] == '') {
                        Toast(this.$t("global.please_choose") + ' ' + this.$t("coll.duration_treatment"));
                        return
                    } if (this.medicationList[i].use_days[1] == '') {
                        Toast(this.$t("global.please_choose") + ' ' + this.$t("coll.duration_treatment"));
                        return
                    }
                    if (this.medicationList[i].Rx == '') {
                        Toast(this.$t("global.please_choose") + ' ' + this.$t("coll.Is_prescription"));
                        return
                    }
                    if (this.medicationList[i].Rx == 1 && this.medicationList[i].Rx_photo.length == 0) {
                        Toast(this.$t("coll.upload_prescription"));
                        return
                    }
                }
            }
            this.step_data.drugs =uploadData
            this.$nextTick(function () {
                this.btnDisabled = true
            })
            if (that.dataDetails.protocol.length == 0) {
                that.saveData();
                return;
            }
            if (!that.newSign) {
                that.saveData();
                return;
            }
            var pic = this.base64.substring(this.base64.indexOf(",") + 1);
            var url = "https://upload-z1.qiniup.com/putb64/-1";
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                // toast.clear()
                if (xhr.readyState == 4) {
                    var resJson = JSON.parse(xhr.responseText);
                    if (resJson.code == 0) {
                        Vue.set(that.step_data, "sign_url", resJson.data.url);
                        Vue.set(that.step_data, "sign", resJson.data.key);
                        that.saveData();
                    } else {
                        Toast(resJson.msg);
                        that.btnDisabled = false;
                    }
                }
            };
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/octet-stream");
            xhr.setRequestHeader("Authorization", "UpToken " + that.dataDetails.signQiniuToken);
            xhr.send(pic);
        },
        saveData() {
            this.$emit("change", this.step_data);
        },
    },
};
</script>

<style lang="less" scoped>
.radioList {
    margin-top: 10px;
}

.blue {
    color: #4D88D2;
    margin-top: 16px;
}

.medicationList {
    border-radius: 4px;
    border: 1px solid #EBEDF0;
    padding: 12px 16px;
    margin-top: 12px;
    position: relative;

    .close {
        position: absolute;
        right: 0;
        top: 0;
        width: 18px;
        height: 16px;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 0 0 0 12px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        line-height: 16px;

    }
}

.max90 {
    max-height: 90px;
    overflow-y: auto;
}
</style>