import Vue from 'vue';

export function getWeek(params) {
    return Vue.axios({
      url: '/api/child/getYearSchedule',
      method: 'get',
      params:params
    })
}

export function getCourse (params) {
    return Vue.axios({
      url: '/api/child/childCourse',
      method: 'get',
      params:params

    })
}

export function preCourse (child,school,sign,params) {
    return Vue.axios({
      url: '/api/preview/course/'+child+'/'+school+"/"+sign,
      method: 'get',
      params:params

    })
}