<template>
  <div>
    <van-row type="flex" justify="end" class="p15 mt-5">
      <switch-lang color="#377ED7" />
    </van-row>
    <children @change="childChange" v-if="$route.name == 'Course'" />
    <children :children="courseData.child" v-else-if="courseData.child" />
    <div v-if="showCourse">
      <div class="bg" v-if="currentWeek != null">
        <div class="van-cell van-cell--center">
          <div class="van-cell__value van-cell__value--alone">
            <div class="week" @click="weekListShow()">
              <strong class="mr-10">{{ weekList[currentWeek].weekName
                }}<van-icon name="arrow-down" class="ml-5" /></strong>
              <van-tag color="rgba(77, 136, 210, 0.1)" text-color="#4D88D2"
                v-if="weekList[currentWeek].category != null">{{ weekList[currentWeek].category }}
                {{ $t("course.Weekly") }}</van-tag>
            </div>
            <div class="color6 font12" @click="weekListShow()">
              {{ weekList[currentWeek].daysInterval }}
            </div>
          </div>
          <van-icon name="arrow-left" class="leftRight" size="15" :color="currentWeek == 1 ? '#C8C9CC' : '#323233'"
            @click="prev()" />
          <van-icon name="arrow" class="leftRight ml-15" size="15"
            :color="currentWeek == weekLen ? '#C8C9CC' : '#323233'" @click="next()" />
        </div>
        <van-row v-if='weekList[currentWeek].days.length == 5' class="text-center font14 plr10 pb-10 flex">
          <div class="flex1" ref="" v-for="(list, index) in weekList[currentWeek].days" :key="index">
            <div @click.capture="CourseList(index + 1)" :class="index + 1 == currentDay ? 'blue colorf borderf' : ''"
              class="weekData" style="width:59px;margin:0 auto">
              <div class="mb-5">
                {{ weekList[currentWeek].weekDayData[index] }}
              </div>
              <div class="font12" :class="index + 1 == currentDay ? 'colorf ' : 'color6'">
                {{ splitDay(list) }}
              </div>
            </div>
          </div>
        </van-row>
        <div class="text-center font14 plr10" v-else>
          <div style="overflow-x: auto;white-space: nowrap;" class="pb-10 flex">
            <div v-for="(list, index) in weekList[currentWeek].days" :key="index" class="flex1"
              :style="{ minWidth: (index == 0 ? '20%' : '22%') }">
              <div @click.capture="CourseList(index + 1)" :class="index + 1 == currentDay ? 'blue colorf borderf' : ''"
                class="weekData" style="width:59px;margin:0 auto">
                <div class="mb-5">
                  {{ weekList[currentWeek].weekDayData[index] }}
                </div>
                <div class="font12" :class="index + 1 == currentDay ? 'colorf ' : 'color6'">
                  {{ splitDay(list) }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="Object.keys(courseData).length != 0">
          <van-notice-bar v-if="courseData.replace[clickDay]" class="mt-10 text-center"
            :text="courseData.replace[clickDay].replace_tip" />
          <van-notice-bar color="#1989fa" v-if="holidays()" background="#ecf9ff" class="mt-10 text-center">
            {{ courseData.holidays[clickDay] }}
          </van-notice-bar>
          <div v-if="holidays()">
            <van-empty image="error" :description="$t('course.no_course')" />
          </div>
          <div class="mt-20" v-else>
            <div v-if="courseData.timetableTimes.length != 0">
              <van-row class="p15" v-for="(list, index) in courseData.timetableTimes" :key="index">
                <van-col span="4" class="relative text-center">
                  <div class="point" :class="courseData.replace[clickDay] ? 'replace' : 'blueBg'">
                    <span :class="courseData.replace[clickDay] ? 'replaceBg' : 'blue'"></span>
                  </div>
                  <div class="mt-5 week mb-5 font15">
                    <strong> {{ list.label }}</strong>
                  </div>
                  <div class="color6 font12">{{ list.timeStart }}</div>
                  <div class="color6 font12">≀</div>
                  <div class="color6 font12">{{ list.timeEnd }}</div>
                </van-col>
                <van-col span="20" class="stepLeft pb-10">
                  <div class="ml-10 course" :class="courseData.replace[clickDay] ? 'replace' : 'blueBg'"
                    v-if="courseData.schedule[index]">
                    <div class="font15 color">
                      <strong>{{
                        courseData.schedule[index].courses_name
                        }}</strong>
                    </div>
                    <van-row class="mt-10">
                      <div style="float: left" v-if="courseData.schedule[index].course_code != ''">
                        <div class="infoBg">
                          <span class="icons"><van-icon class-prefix="iconfont" name="number" size="16"
                              color="#646566" /></span>
                          <span class="color6 font13 ml-2">{{
                            courseData.schedule[index].course_code
                            }}</span>
                        </div>
                      </div>
                      <div style="float: left" class="ml-10" v-if="courseData.schedule[index].class_room_name != ''">
                        <div class="infoBg">
                          <span class="icons"><van-icon class-prefix="iconfont" name="address" size="16"
                              color="#646566" /></span>
                          <span class="color6 font13 ml-2">{{
                            courseData.schedule[index].class_room_name
                            }}</span>
                        </div>
                      </div>
                    </van-row>
                    <div class="mt-10 van-row" v-for="(item, id) in courseData.schedule[index]
                      .teacherCover" :key="id">
                      <div class="van-col van-col--4">
                        <div class="mr-10 van-image van-image--round img">
                          <img :src="item" class="van-image__img" />
                        </div>
                      </div>
                      <div class="van-col van-col--20 mt-5">
                        <div class="font13 color">
                          {{ courseData.schedule[index].teacherName[id] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="ml-10 course noCourse color6">
                    {{ $t("course.no_course") }}
                  </div>
                </van-col>
              </van-row>
            </div>
            <div v-else>
              <van-empty image="error" :description="$t('course.no_course')" />
            </div>
          </div>
        </div>
        <van-action-sheet v-model="show" :cancel-text="$t('global.cancel')" close-on-click-action
          :description="$t('course.week_list')">
          <div style="max-height: 400px; overflow-y: auto" ref="scroll">
            <div>
              <div v-for="(list, key, index) in weekList" :key="index" class="p15 mt-20 mb-10">
                <div class="color font16 mb-5" :class="key == currentWeek ? 'color4' : ''"
                  @click="CourseList(currentDay, key)">
                  {{ list.weekName }}
                  <span class="font14 ml-10" :class="key == currentWeek ? 'color4' : 'color6'">{{ list.daysInterval
                    }}</span>
                </div>
                <div class="font12 color9" v-if="list.category != null">
                  {{ list.category }} {{ $t("course.Weekly") }}
                </div>
                <div class="font12 color9" v-else>
                  {{ $t("course.no_course") }}
                </div>
              </div>
            </div>
          </div>
        </van-action-sheet>
      </div>
    </div>
    <van-empty image="error" :description="$t('course.no_course')" v-else class="mt-50" />
  </div>
</template>

<script>
import Vue from "vue";
import {
  Image as VanImage,
  Empty,
  Tag,
  Step,
  Steps,
  ActionSheet,
  NoticeBar,
} from "vant";
import Children from "@/components/Children";
import { getWeek, getCourse, preCourse } from "@/api/Course";
import SwitchLang from "@/components/SwitchLang";

Vue.use(VanImage);
Vue.use(Empty);
Vue.use(Tag);
Vue.use(Step);
Vue.use(Steps);
Vue.use(ActionSheet);
Vue.use(NoticeBar);
export default {
  name: "Course",
  components: {
    children: Children,
    "switch-lang": SwitchLang,
  },
  data() {
    return {
      childid: "",
      schoolid: "",
      show: false,
      weekList: {},
      currentWeek: null,
      currentDay: "",
      dayList: [
        this.$t("course.Mon"),
        this.$t("course.Tue"),
        this.$t("course.Wed"),
        this.$t("course.Thu"),
        this.$t("course.Fri"),
        this.$t("course.Sat"),
      ],
      courseData: {},
      clickDay: null,
      weekLen: null,
      copyCurrentWeek: '',
      copyCurrentDay: '',
      showCourse: true
    };
  },
  created() { },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#fff;");
  },
  mounted() {
    if (this.$route.params.id) {
      this.childid = this.$route.params.id;
      this.schoolid = this.$route.params.school_id;
      this.getWeekList();
    }
  },
  methods: {
    holidays() {
      if (this.courseData.holidays[this.clickDay]) {
        return true;
      } else {
        return false;
      }
    },
    splitDay(day) {
      return day.substr(6, 8);
    },
    childChange(val) {
      this.childid = val.id;
      this.schoolid = val.schoolid;
      this.getWeekList();
    },
    getWeekList() {
      getWeek({ school_id: this.schoolid }).then((res) => {
        this.weekList = res.data.item;
        this.currentWeek = res.data.current;
        this.copyCurrentWeek = res.data.current;
        this.weekLen = Object.keys(this.weekList).length;
        const currentDays = this.weekList[this.currentWeek].days
        if (currentDays.indexOf(res.data.today) != -1) {
          this.copyCurrentDay = currentDays.indexOf(res.data.today) + 1
          this.CourseList(currentDays.indexOf(res.data.today) + 1);
        } else {
          this.CourseList(1);
        }
      });
    },
    prev() {
      if (this.currentWeek != 1) {
        this.currentWeek = parseInt(this.currentWeek) - 1;
        this.CourseList(this.currentDay);
        if (this.currentWeek == this.copyCurrentWeek) {
          this.CourseList(this.copyCurrentDay);
        } else {
          this.CourseList(1);
        }
      }
    },
    next() {
      if (this.currentWeek < this.weekLen) {
        this.currentWeek = parseInt(this.currentWeek) + 1;
        if (this.currentWeek == this.copyCurrentWeek) {
          this.CourseList(this.copyCurrentDay);
        } else {
          this.CourseList(1);
        }
      }
    },
    CourseList(week_day, week) {
      if (week) {
        this.currentWeek = week;
      }
      this.currentDay = week_day;
      this.clickDay = parseInt(
        this.weekList[this.currentWeek].days[week_day - 1]
      );
      if (this.$route.params.id) {
        preCourse(this.childid, this.schoolid, this.$route.params.sign, {
          week_number: this.currentWeek,
          week_day: week_day,
          child_id: this.childid,
          day: this.clickDay,
        }).then((res) => {
          this.courseData = res.data;
          if (week) {
            this.show = false;
          }
        });
      } else {
        getCourse({
          week_number: this.currentWeek,
          week_day: week_day,
          child_id: this.childid,
          day: this.clickDay,
        }).then((res) => {
          this.courseData = res.data;
          if (week) {
            this.show = false;
          }
        });
      }
    },
    weekListShow() {
      this.show = true;
      this.$nextTick(() => {
        var scroll = this.$refs.scroll;
        scroll.scrollTop = (this.currentWeek - 3) * 70;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ml-2 {
  margin-left: 2px;
}

.borderf {
  border: 1px solid #fff !important;
}

.van-notice-bar__wrap {
  text-align: center;
}

.van-cell::after {
  border-bottom: none;
}

.weekData {
  border: 1px solid #dcdee0;
  border-radius: 8px;
  padding: 5px 0;
}

.p15 {
  padding: 0 15px;
}

.plr15 {
  padding: 0 15px;
}

.plr10 {
  padding: 0 10px;

}

.pb-10 {
  padding-bottom: 10px;
}

.magintop-2 {
  margin-top: -2px;
}

.img {
  width: 28px;
  height: 28px;
}

.font13 {
  font-size: 13px;
}

.font12 {
  font-size: 12px;
}

.font16 {
  font-size: 16px;
}

.font15 {
  font-size: 15px;
}

.relative {
  position: relative;

  .point {
    width: 12px;
    height: 12px;

    border-radius: 50%;
    float: right;
    position: absolute;
    right: -6px;
    top: 0;

    span {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      left: 3px;
      top: 3px;
    }
  }
}

.blue {
  background: #4d88d2;
}

.blueBg {
  background: #e5edfc;
}

.noCourse {
  background: #f7f8fa;
}

.infoBg {
  background: rgba(255, 255, 255, 0.5);
  padding: 3px 10px;
  border-radius: inherit;
  display: inline-block;
  position: relative;
  // height: 20px;
  border-radius: 999em;
  padding-left: 23px;

  .icons {
    // width: 16px;
    // height: 16px;
    position: absolute;
    // top: 3px;
    left: 8px;
  }
}

.text-center {
  text-align: center !important;
}

.font14 {
  font-size: 14px;
}

.stepLeft {
  border-left: 2px solid #f7f8fa;
}

.colorf {
  color: #fff;
}

.color {
  color: #323233;
}

.color6 {
  color: #646566;
}

.color9 {
  color: #969799;
}

.color4 {
  color: #4d88d2 !important;
}

.disabled {
  color: #c8c9cc;
}

.replace {
  background: #f9edd9;
}

.replaceBg {
  background: #f5a429;
}

.bg {
  background: #fff;

  .week {
    font-size: 16px;
    color: #323233;
  }

  .leftRight {
    width: 25px;
    height: 25;
    background: #edeeef;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
  }

  .course {
    padding: 10px;
    border-radius: 5px;
    min-height: 70px;
  }
}
</style>
