import Vue from 'vue';

export function yearList (id) {
    return Vue.axios({
      url: '/api/parent/assessments/overview/' + id,
      method: 'get'
    })
}

export function commentList (id,yearId) {
    return Vue.axios({
      url: '/api/parent/assessments/' + id +'/'+yearId,
      method: 'get'
    })
}