import Vue from 'vue';

export function noticeList (params) {
  return Vue.axios({
    url: '/api/parent/notice/showList',
    method: 'get',
    params: params
  })
}

export function noticeItem (id) {
  return Vue.axios({
    url: '/api/parent/notice/item/' + id,
    method: 'get'
  })
}

export function noticeListPreview (params) {
  return Vue.axios({
    url: '/api/preview/notice/showList',
    method: 'get',
    params: params
  })
}

export function noticeItemPreview (id) {
  return Vue.axios({
    url: '/api/preview/notice/item/' + id,
    method: 'get'
  })
}

export function confirmNotice (id) {
  return Vue.axios({
    url: '/api/parent/notice/confirm/' + id,
    method: 'post'
  })
}

export function saveConfirm (data) {
  return Vue.axios({
    url: '/api/parent/notice/save/confirmation',
    method: 'post',
    data: data
  })
}

export function yearMonthCount (params) {
  return Vue.axios({
    url: '/api/parent/notice/year/month/count',
    method: 'get',
    params: params
  })
}

export function yearMonthItems (data) {
  return Vue.axios({
    url: '/api/parent/notice/monthArchive',
    method: 'post',
    data: data
  })
}

export function saveSurvey (data) {
  return Vue.axios({
    url: '/api/parent/notice/survey/save',
    method: 'post',
    data: data
  })
}

export function saveViewSurvey (data) {
  return Vue.axios({
    url: '/api/preview/notice/survey/preview',
    method: 'post',
    data: data
  })
}

export function noticeTeacherList (params) {
  return Vue.axios({
    url: '/api/parent/notice/teacherList',
    method: 'get',
    params: params
  })
}
