<template>
  <div>
    <div ref="signPage" id="signPage" v-if="showTab">
      <vue-esign
        v-if="showSign"
        :height="canvasHeight"
        :width="canvasWidth"
        class="vue-esign"
        ref="esign"
        :isCrop="isCrop"
        :lineWidth="lineWidth"
        :lineColor="lineColor"
        :bgColor.sync="bgColor"
      />
      <div class="opa-part" v-if="!horizontal">
        <div class="flex">
          <van-button
            type="default"
            class="flexWiddth cancel-btn"
            size="small"
            @click="handleGenerate('cancel')"
            > {{$t('global.cancel')}}</van-button
          >
          <span @click="handleReset" class="flexWiddth rewrite-btn"
            ><van-icon name="replay" /> {{$t('coll.clear')}}</span
          >
          <van-button
            type="primary"
            class="confirm-btn"
            color="#4D88D2"
            size="small"
            @click="handleGenerate('confirm')"
          >
            {{$t('global.save')}}
          </van-button>
        </div>
      </div>
      <div class="horizontal" v-else>
        <van-row type="flex" justify="space-between">
          <van-col span="6">
            <van-button
              type="default"
              class="ml-20"
              size="mini"
              @click="handleGenerate('cancel')"
              >{{$t('global.cancel')}}</van-button
            >
          </van-col>
          <van-col span="6">
            <span @click="handleReset" class="replay font12 color6">
              <van-icon name="replay" /> {{$t('coll.clear')}} </span
            >
            <van-button
              type="primary"
              color="#4D88D2"
              class="replay ml-20"
              size="mini"
              @click="handleGenerate('confirm')"
            >
              {{$t('global.save')}}
            </van-button>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showTip: false,
      lineWidth: 3,
      lineColor: "#000000",
      bgColor: "#fff",
      isCrop: false,
      canvasHeight: 0,
      showSign: false,
      showTab: true,
      canvasWidth: 0,
      horizontal: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.renderResize, false);
    this.getSign();
    this.renderResize();
  },
  methods: {
    //获取历史签名
    getSign() {
      this.showSign = true;
    },
    //重置
    handleReset() {
      this.signUrl = "";
      this.$nextTick(() => {
        this.$refs.esign.reset();
      });
    },
    //保存
    handleGenerate(type) {
      if (type == "cancel") {
        this.$emit("click", "cancel");
        return;
      }
      if (this.showLoading) return;
      this.showLoading = true;
      var _this = this;
      _this.$refs.esign
        .generate()
        .then(async (res) => {
          if (!_this.horizontal) {
            _this.rotateBase64Img(res, 90); //进行旋转
          } else {
            _this.imgToBlob(res);
          }
        })
        .catch(() => {
          _this.$toast(this.$t("coll.please_sign"));
          _this.showLoading = false;
        });
    },
    imgToBlob(base64) {
      this.$emit("click", base64);
    },
    rotateBase64Img(src, edg) {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var imgW; //图片宽度
      var imgH; //图片高度
      var size; //canvas初始大小
      if (edg % 90 != 0) {
        throw "旋转角度必须是90的倍数!";
      }
      edg < 0 && (edg = (edg % 360) + 360);
      const quadrant = (edg / 90) % 4; //旋转象限
      const cutCoor = {
        sx: 0,
        sy: 0,
        ex: 0,
        ey: 0,
      }; //裁剪坐标
      var image = new Image();
      image.crossOrigin = "anonymous";
      image.src = src;
      let _this = this;
      image.onload = function () {
        imgW = image.width;
        imgH = image.height;
        size = imgW > imgH ? imgW : imgH;
        canvas.width = size * 2;
        canvas.height = size * 2;
        switch (quadrant) {
          case 0:
            cutCoor.sx = size;
            cutCoor.sy = size;
            cutCoor.ex = size + imgW;
            cutCoor.ey = size + imgH;
            break;
          case 1:
            cutCoor.sx = size - imgH;
            cutCoor.sy = size;
            cutCoor.ex = size;
            cutCoor.ey = size + imgW;
            break;
          case 2:
            cutCoor.sx = size - imgW;
            cutCoor.sy = size - imgH;
            cutCoor.ex = size;
            cutCoor.ey = size;
            break;
          case 3:
            cutCoor.sx = size;
            cutCoor.sy = size - imgW;
            cutCoor.ex = size + imgH;
            cutCoor.ey = size + imgW;
            break;
        }
        ctx.translate(size, size);
        ctx.rotate((edg * Math.PI) / 180);
        ctx.drawImage(image, 0, 0);
        var imgData = ctx.getImageData(
          cutCoor.sx,
          cutCoor.sy,
          cutCoor.ex,
          cutCoor.ey
        );
        if (quadrant % 2 == 0) {
          canvas.width = imgW;
          canvas.height = imgH;
        } else {
          canvas.width = imgH;
          canvas.height = imgW;
        }
        ctx.putImageData(imgData, 0, 0);
        _this.imgToBlob(canvas.toDataURL());
      };
    },
    renderResize() {
      let clientWidth = document.documentElement.clientWidth;
      let clientHeight = document.documentElement.clientHeight;
      this.showTab = false;
      if (clientWidth > clientHeight) {
        this.horizontal = true;
        this.canvasHeight = this.$refs.signPage.offsetHeight;
        this.canvasWidth = this.$refs.signPage.offsetWidth;
        this.$nextTick(() => {
          this.showTab = true;
        });
      } else {
        this.horizontal = false;
        this.canvasHeight = this.$refs.signPage.offsetHeight;
        this.canvasWidth = this.$refs.signPage.offsetWidth;
        this.$nextTick(() => {
          this.showTab = true;
        });
      }
    },
  },
};
</script>

<style lang="less"  scoped>
#signPage {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #fff;
}
.history-sign {
  position: fixed;
  left: -120px;
  top: 50%;
  transform: translateY(-50%) rotate(270deg);
}
.sign-tips {
  font-size: 100px;
  color: rgba(0, 0, 0, 0.05);
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
}
.tip-text {
  transform: rotate(-90deg);
}
.vue-esign {
  border: 1px solid rgba(214, 219, 226, 0.5);
  box-sizing: border-box;
  position: relative;
  z-index: 99;
}
.horizontal {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 30px;
  width: 100%;
  z-index: 999;
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}
.opa-part {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 60px;
  z-index: 999;
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  display: flex;
  .flex {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    .cancel-btn {
      position: absolute;
      bottom: 20px;
      right: 7px;
      transform: rotate(-90deg);
    }
    .rewrite-btn {
      position: absolute;
      top: 100px;
      right: -18px;
      width: 100px;
      transform: rotate(-90deg);
    }
    .confirm-btn {
      position: absolute;
      top: 20px;
      right: 7px;
      transform: rotate(-90deg);
    }
  }
}
</style>