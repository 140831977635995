export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

export function getimgsrc(htmlstr) {
  let ret = []
	let imgStrs = htmlstr.match(/<img.*?>/g)
  if (imgStrs) {
    imgStrs.map(url => {
      ret.push(url.match(/\ssrc=['"](.*?)['"]/)[1])
    })
  }
  return ret;
}

export function prefixZero(num, n) {
  return (Array(n).join(0) + num).slice(-n);
}
