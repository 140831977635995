<template>
    <van-row type="flex" justify="center">
        <div class="image-text_1 flex-col">
          <img class="image_2" referrerpolicy="no-referrer" src="@/assets/successFeedback@2x.png"/>
          <div class="text-group_1 flex-col">
            <p v-if="title" class="text_3 mb-10">{{title}}</p>
            <div v-if="desc" class="text_4 mb-35">{{desc}}</div>
          </div>
        </div>
      <van-col span="20">
        <van-button v-if="details_url!=''" block round type="info" :url="details_url" class="mb-15">{{ $t('global.view_details') }}</van-button>
        <van-button block round  @click="onClose" >{{$t('global.close')}}</van-button>
      </van-col>
    </van-row>
</template>
<script>
import {Row,Button,Image,Skeleton} from 'vant'
import Vue from "vue";
import {getSuccessFeedback} from "@/api/campus";
import wx from 'weixin-js-sdk'
Vue.use(Row,Button,Image,Skeleton);
export default {
  data() {
    return {
      title:'',
      desc:'',
      details_url:'',
    }
  },
  created() {
    this.getData()
  },
  methods:{
    getData(){
      let params = {
        type:this.$route.params.type,
        id:this.$route.params.id
      }
      getSuccessFeedback(params).then(res=>{
        if(res.code==0){
          this.title = res.data.title
          this.desc = res.data.desc
          this.details_url = res.data.detail_url
        }
      })
    },
    onClose(){
      wx.closeWindow()
    }
  }
}
</script>
<style lang="less" scoped>

.image-text_1 {
  width: 246px;
  margin-top: 16px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.image_2 {
  width: 168px;
  height: 168px;
}
.text-group_1 {
  margin-top: 8px;
}
.text_3 {
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  line-height: 24px;
  align-self: center;
}
.text_4 {
  width: 246px;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  margin-top: 8px;
}
</style>