"use strict";

import SlsWebLogger from 'js-sls-logger'
import store from '@/store'

const logger = {
  obj: null,
  init(config) {
    this.obj = new SlsWebLogger({
      ...{
        host: 'cn-beijing.log.aliyuncs.com',
        project: 'dso01',
        logstore: 'logstore01',
        time: 10,
        count: 10
      },
      ...config
    })
  },
  send(data) {
    this.obj.send({
      ...{
        __topic__: 'Wechat Client',
        openid: store.getters.userInfo.openid,
        parentid: store.getters.userInfo.userid
      },
      ...data
    })
  }
}

logger.init()

export default logger;
