<template>
  <div :class="stepStatus == 4 && dataDetails.isCommon==1? 'clickNone' : ''">
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{ $t("coll.Fapiao") }}</div>
      </div>

      <div class="fr" @click="wxFaPiao">
        <span class="addText">{{$t("coll.useWxFapiao")}}</span>
      </div>

    </div>
    <div class="mt-20">
      <div class="label mb-5 font14">{{ $t("coll.FapiaoTitle") }}</div>
      <van-field
          class="bgGray"
          v-model="faPiaoData.title"
          :placeholder="$t('global.please_input')"
      />
    </div>
    <div class="mt-15">
      <div class="label mb-5 font14">{{ $t("coll.FapiaoNumber") }}</div>
      <van-field
          class="bgGray"
          v-model="faPiaoData.taxID"
          :placeholder="$t('global.please_input')"
      />
    </div>
    <div v-if="dataDetails.protocol.length != 0">
      <protocol-sign
          @click="baseImg"
          :busProtocol="dataDetails.protocol"
          :base64="base64"
      ></protocol-sign>
    </div>
    <div class="mt-20">
      <van-button
          v-if="stepStatus == 4 && dataDetails.isCommon==1"
          disabled
          type="info"
          block
          round
          color="#4d88d2"
          class="saveSub"
      >{{ $t("coll.step_reviewed") }}</van-button>
      <a
          href="javascript:;"
          v-else
          @click="submitData()"
          class="saveBtnStep"
          :class="btnDisabled ? 'disabledSubmit' : ''"
      >{{ $t("global.submit") }}</a>
    </div>
  </div>
</template>

<script>
import ProtocolSign from "@/components/steps/ProtocolSign";
import { Toast } from "vant";
import Vue from "vue";
import wx from 'weixin-js-sdk';
import {faPiao} from "@/api/collection";
export default {
  components: {
    "protocol-sign": ProtocolSign,
  },
  props: {
    dataDetails: {
      type: Object,
    },
    status: {
      type: Number,
    },
    disabled: {
      type: Number,
    },
    deviceType: {
      type: String,
    }
  },
  name: "faPiao",
  data() {
    return {
      faPiaoData: {
        'title':'',
        'taxID':'',
        // 'companyAddress':'',
        // 'telephone':'',
        // 'bankName':'',
        // 'bankAccount':'',
      },
      stepStatus: "",
      btnDisabled: false,
      base64: "",
      newSign: false,
      wxFaPiaoBtn:true
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
  },
  created() {
    this.stepStatus = this.status;
    this.faPiaoData = this.dataDetails;
    if (this.dataDetails.sign_url != "") {
      this.base64 = this.dataDetails.sign_url;
    }
  },
  methods: {
    baseImg(data) {
      if (data != "cancel") {
        this.base64 = data;
        this.newSign = true;
      }
    },
    submitData() {
      let that = this;
      if (that.faPiaoData.title=='') {
        Toast(this.$t('global.please_input')+this.$t('coll.FapiaoTitle'));
        return;
      }
      if (that.dataDetails.protocol.length != 0 && this.base64 == "") {
        Toast(that.$t("coll.please_sign"));
        return;
      }
      this.$nextTick(function () {
        this.btnDisabled = true;
      });
      if (that.dataDetails.protocol.length == 0) {
        that.saveData();
        return;
      }
      if (!that.newSign) {
        that.saveData();
        return;
      }
      var pic = this.base64.substring(this.base64.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // toast.clear()
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that.faPiaoData, "sign_url", resJson.data.url);
            Vue.set(that.faPiaoData, "sign", resJson.data.key);
            that.saveData();
          } else {
            Toast(resJson.msg);
            that.btnDisabled = false;
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.dataDetails.signQiniuToken);
      xhr.send(pic);
    },
    saveData() {
      this.$emit("change", this.faPiaoData);
    },
    wxFaPiao() {
      var pageUrl = '';
      if(this.deviceType === 'ios'){
         pageUrl = sessionStorage.getItem('LandingPage')
      }else{
         pageUrl = window.location.href
      }
      if(this.wxFaPiaoBtn == true){
        this.wxFaPiaoBtn = false;
        faPiao(pageUrl).then((res) => {
          let that = this;
          wx.config({
            beta: true,
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appId,
            timestamp:res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature,// 必填，签名
            jsApiList: ["chooseInvoiceTitle"], // 必填，需要使用的JS接口列表
          });
          wx.ready(function () {
            wx.invoke(
                'chooseInvoiceTitle',
                {"scene":"1"},
                function (res){
                  var info = res.choose_invoice_title_info
                  if(info != null && info != undefined){
                    var infoJson = JSON.parse(info)
                    that.faPiaoData.title = infoJson.title
                    that.faPiaoData.taxID = infoJson.taxNumber
                    // that.faPiaoData.companyAddress = res.choose_invoice_title_info.companyAddress
                    // that.faPiaoData.telephone = res.choose_invoice_title_info.telephone
                    // that.faPiaoData.bankName = res.choose_invoice_title_info.bankName
                    // that.faPiaoData.bankAccount = res.choose_invoice_title_info.bankAccount
                  }
                }
            );
          });
          that.wxFaPiaoBtn = true;
        })
      }

    }
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  .flexWidth {
    width: 16px;
    padding-top: 3px;
    .icon {
      display: inline-block;
      width: 6px;
      height: 14px;
      background: #4d88d2;
      border-radius: 3px;
    }
  }
  .flex1 {
    flex: 1;
    .text {
      font-size: 12px;
      font-weight: 400;
      color: #646566;
      line-height: 20px;
    }
    .title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 26px;
    }
  }
}
.addText {
  color: #4d88d2;
  line-height: 26px;
}
</style>