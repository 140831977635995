<template>
  <div>
    <div v-if="commentData.length > 0" class="comment" :class="isBtn && bottomBar ? 'pb-100' : isBtn && !bottomBar ? 'pb-50':'pb-15'">
      <div class="sub-title">{{ $t("global.comment") }}</div>
      <van-row v-for="(item, i) in commentData" :key="i" class="mb-15">
        <div class="flex">
          <van-image round width="32px" height="32px" :src="item.avatar" />
          <div class="flex1 content-1 ml-10" >
            <div class="flex">
              <div class="name-1 flex1">{{ item.name }}</div>
              <div span="7" class="time-1">{{ item.updated_at }}</div>
            </div>
            <div v-html="item.content"></div>
            <div>
              <div class="mt-10" v-if="item.imgUrl.length > 0">
                <van-uploader v-model="item.imgUrl" :deletable="false" :show-upload="false" preview-size="80"></van-uploader>
              </div>
              <div class="pdfList flex" v-for="(list, index) in item.pdfUrl" :key="index">
                <!-- <img src="@/assets/PDF.png" alt="" class="pdfImg"  /> -->
                <img src="@/assets/PDF.png" alt="" class="pdfImg" v-if="list.mimetype == 'application/pdf'" />
                <img src="@/assets/excel.png" alt="" class="pdfImg" v-else-if="list.mimetype == 'application/vnd.ms-excel' || list.mimetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" />
                <img src="@/assets/word.png" alt="" class="pdfImg" v-else-if="list.mimetype == 'application/msword' || list.mimetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" />
                <img src="@/assets/zip.png" alt="" class="pdfImg" v-else-if="list.mimetype == 'application/x-zip-compressed'" />
                <img src="@/assets/link.png" alt="" class="pdfImg" v-else />
                <a class="ml-10 color3 flex1" :href="list.url">{{ list.title }}</a>
              </div>
            </div>
            <div style="font-size: 12px; color: #646566" v-if="item.from">{{ item.from }}</div>
          </div>
        </div>
      </van-row>
    </div>
    <van-button type="default" round icon="chat-o" size="small" class="comment-btn" :class="bottomBar ? 'bottom-2' : 'bottom-1'" @click="scrllToBottom" v-if="isBtn">{{ $t("global.comment") }}</van-button>
    <div v-else :class="commentData.length ==0?'borderTop':''">
      <!-- v-if="type == 'notice'" -->
      <div style="padding:15px;background: #fff;">
        <div class="uploadList">
          <van-uploader v-model="qiniuFile" :show-upload="false" :before-delete="delImg" preview-size="60" v-if="qiniuFile.length > 0" class="imgList"></van-uploader>
          <div v-if="qiniuDataPdf.length > 0">
            <div class="color6 flex font13 items-center filePdf" v-for="(list, ix) in qiniuDataPdf" :key="ix">
              <span class="iconfont iconfont-link iconColor"></span>
              <a class="flex1 ml-4 color6" :href="list.file_key">{{ list.title }}</a>
              <van-icon name="edit" size="18" class="mr-10" v-if="list.file_key != ''" @click="editTitle(list, ix)" />
              <van-icon name="close" size="18" v-if="list.file_key != ''" @click="delPdf(list, ix)" />
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="comment-input flex1">
            <van-field v-model="content" rows="2" autosize label="" type="textarea" ref="textareaInput" maxlength="5000" :placeholder="$t('global.please_input')" show-word-limit />
          </div>
          <div class="sendText">
            <van-uploader :after-read="afterRead" multiple :max-size="1024 * 1024 * 50" @oversize="onOversize" accept="image/*,.pdf,.doc,.docx,.zip,.xls,.xlsx">
              <span class="iconfont iconfont-link"></span>
            </van-uploader>
            <div class="btn">
              <van-button round type="info" size="small" v-if="content != '' || uploadList.length != 0 || qiniuDataPdf.length != 0" @click="commit" :loading="disabledSend">{{ $t("global.submit") }}</van-button>
              <van-button round type="info" size="small" v-else disabled>{{ $t("global.submit") }}</van-button>
            </div>
          </div>
        </div>
        <div :class="bottomBar ? 'mb-2' : 'mb-1'">
          <span class="flex1 color9 bottomText"><van-icon name="info-o" /> {{ $t("global.comments_teacher") }}</span>
        </div>
      </div>
      <!-- <div v-else>
        <div class="comment-input">
          <van-field v-model="content" rows="2" autosize label="" type="textarea" ref="textareaInput" maxlength="5000" :placeholder="$t('global.please_input')" show-word-limit />
        </div>
        <div style="text-align: right" class="bottomFlex mt-10" :class="bottomBar ? 'mb-2' : 'mb-1'">
          <span class="flex1 color9"><van-icon name="info-o" /> {{ $t("global.comments_teacher") }}</span>
          <van-button round type="info" size="small" class="flexWidth" style="padding: 0 20px" :loading="loading" @click="commit">{{ $t("global.submit") }}</van-button>
        </div>
      </div> -->
    </div>
    <van-dialog v-model="titleShow" title="重命名" show-cancel-button :before-close="beforeClose">
      <div class="rename">
        <van-field v-model="title" rows="2" autosize type="textarea" class="textarea" />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { Field, Toast, Uploader, Dialog } from "vant";
import { commentList, commentSave } from "@/api/comment";
import * as qiniu from "qiniu-js";
import { getQiniuToken, deleteFile, updateFileTitle } from "@/api/contactCampus";

Vue.use(Field);
Vue.use(Toast);
Vue.use(Uploader);
Vue.use(Dialog);

export default {
  name: "Comment",
  props: {
    id: String,
    type: String,
    childid: Number,
    bottomBar: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  watch: {
    childid(val) {
      this.child_id = val;
    },
  },
  data() {
    return {
      loading: false,
      isBtn: true,
      content: "",
      commentData: [],
      child_id: "",
      Token: "",
      qiniuFile: [],
      qiniuDataUrl: [],
      qiniuDataPdf: [],
      uploadList: [],
      attachmentList: {},
      titleShow: false,
      editList: {},
      editIndex: null,
      title: "",
      confirmBtn: false,
      files: [],
      countIndex: 0,
      showFiles: [],
      uploadStatus: true,
      disabledSend: false,
    };
  },
  created() {
    this.fetchData();
    this.getQiniu();
  },
  methods: {
    scrllToBottom() {
      
      this.isBtn = false;
      this.$nextTick(() => {
        var container = document.querySelector("#app");
        scrollTo(0, container.scrollHeight);
        this.$refs.textareaInput.focus();
      });
    },
    fetchData() {
      this.child_id = this.childid;
      commentList(this.type, this.id).then((res) => {
        res.data.item.forEach((list) => {
          list.imgUrl = [];
          list.pdfUrl = [];
          if (list.attachments.length != 0) {
            list.attachments.forEach((item) => {
              let type = res.data.attachmentList[item].mimetype.split("/")[0];
              if (type == "image") {
                list.imgUrl.push({ url: res.data.attachmentList[item].url });
              } else {
                list.pdfUrl.push(res.data.attachmentList[item]);
              }
            });
          }
        });
        this.commentData = res.data.item;
        this.$emit("change", res.data.item);
      });
    },
    commit() {
      if (!this.uploadStatus) {
        Toast(this.$t("contact.Uploading"));
        return;
      }
      this.disabledSend = true;
      var ids = [];
      this.uploadList.forEach((item) => {
        ids.push(item._id);
        this.attachmentList[item._id] = {
          url: item.file_key,
          id: item._id,
          mimetype: item.mimetype,
          title: item.title,
        };
      });
      this.qiniuDataPdf.forEach((item) => {
        ids.push(item._id);
        this.attachmentList[item._id] = {
          url: item.file_key,
          id: item._id,
          mimetype: item.mimetype,
          title: item.title,
        };
      });
      this.loading = true;
      commentSave({
        id: this.id,
        type: this.type,
        child_id: this.child_id,
        content: this.content,
        attachments: ids,
      }).then(() => {
        this.fetchData();
        this.qiniuDataPdf=[]
        this.uploadList=[]
        this.qiniuFile=[]
        this.content = "";
        this.isBtn = true;
        this.loading = false;
        this.disabledSend = false;
      });
    },
    getQiniu() {
      return new Promise((resolve, reject) => {
        getQiniuToken({ linkId: this.id, linkType: this.type })
          .then((res) => {
            this.Token = res.data;
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    sendQiniu() {
      if (this.countIndex >= this.files.length) {
        this.uploadStatus = true;
        return;
      }
      this.uploadStatus = false;
      let that = this;
      let type = that.files[that.countIndex].file.type.split("/");
      let config = { useCdnDomain: true, region: qiniu.region.z1 };
      let putExtra = { fname: that.files[that.countIndex].file.name, params: {}, mimeType: null };
      let headers = qiniu.getHeadersForMkFile(that.Token);
      let key = that.files[that.countIndex].file.name;
      var lens = "";
      if (type[0] == "image") {
        if (this.files.length == that.qiniuFile.length) {
          lens = that.countIndex;
        } else {
          lens = that.qiniuFile.length - that.files.length + that.countIndex;
        }
        that.qiniuFile[lens].message = this.$t("contact.Uploading");
      } else {
        if (this.files.length == that.qiniuDataPdf.length) {
          lens = that.countIndex;
        } else {
          lens = that.qiniuDataPdf.length - this.files.length + that.countIndex;
        }
        that.qiniuDataPdf[lens].title = this.$t("contact.Uploading");
        that.qiniuDataPdf[lens].file_key = "";
      }
      that.$nextTick(function () {
        setTimeout(() => {
          that.scrllToBottom();
        }, 100);
      });
      let observe = {
        next() {
        },
        error() {
          that.getQiniu().then(() => {
            that.sendQiniu();
          });
        },
        complete(res) {
          let fileType = that.files[that.countIndex].file.type.split("/");
          if (fileType[0] == "image") {
            that.uploadList.push(res.data);
            Vue.set(that.qiniuFile[lens], "url", res.data.file_key);
            Vue.set(that.qiniuFile[lens], "status", "done");
            Vue.set(that.qiniuFile[lens], "name", res.data.title);
            that.countIndex++;
            that.sendQiniu();
          } else {
            Vue.set(that.qiniuDataPdf, lens, res.data);

            that.countIndex++;
            that.sendQiniu();
          }
        },
      };
      let observable = qiniu.upload(that.files[that.countIndex].file, key, that.Token, headers, putExtra, config);
      that.subscription = observable.subscribe(observe);
    },
    onOversize() {
      Toast(this.$t("contact.keep_below"));
    },
    afterRead(file) {
      if (Array.isArray(file)) {
        this.files = file;
      } else {
        this.files = [file];
      }
      let type = this.files[0].file.type.split("/");
      // this.files.some(ele=>(alert(ele.file.name)))
      if (type[0] == "image") {
        let arr = this.qiniuFile.filter((item) => this.files.some((ele) => ele.file.name === item.name));
        if (arr.length > 0) {
          Toast(this.$t("contact.Data_duplication"));
          return;
        }
      }
      this.files.forEach((item) => {
        let type = item.file.type.split("/");
        if (type[0] == "image") {
          this.qiniuFile.push({ url: "", status: "uploading", message: this.$t("contact.Upload_queue") });
        } else {
          this.qiniuDataPdf.push({
            title: this.$t("contact.Upload_queue"),
            file_key: "",
          });
        }
      });
      this.countIndex = 0;
      this.sendQiniu();
    },
    delImg(file, detail) {
      deleteFile(this.uploadList[detail.index]._id).then(() => {
        this.uploadList.splice(detail.index, 1);
        this.qiniuFile.splice(detail.index, 1);
      });
    },
    delPdf(list, index) {
      deleteFile(list._id).then(() => {
        this.qiniuDataPdf.splice(index, 1);
      });
    },
    editTitle(list, index) {
      this.editList = list;
      this.editIndex = index;
      this.titleShow = true;
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        if (this.title == "") {
          Toast(this.$t("global.please_input"));
          done(false);
          return;
        }
        this.confirmBtn = true;
        updateFileTitle({ id: this.editList._id, title: this.title }).then((res) => {
          if (res.code == 0) {
            this.qiniuDataPdf[this.editIndex].title = res.data.title;
            this.qiniuDataPdf[this.editIndex].file_key = res.data.url;
            this.titleShow = false;
            this.confirmBtn = false;
            done();
          } else {
            this.confirmBtn = false;
            done();
          }
        });
      } else {
        this.titleShow = false;
        done();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.flex1{
  min-width: 0
}
.pb-100{
  padding-bottom: 100px;
}
.borderTop{
  border-top: 15px solid #F7F8FA;
}
.comment{
  background: #fff;
  padding-top: 18px  ;
  padding-left:  15px ;
  padding-right:  15px ;
  border-top: 15px solid #F7F8FA;
}
.sub-title {
  font-size: 15px;
  font-weight: bold;
  color: #323233;
  margin-bottom: 15px;
}
.name-1 {
  line-height: 30px;
  font-size: 14px;
  font-weight: 400;
  color: #646566;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.time-1 {
  line-height: 30px;
  font-size: 12px;
  font-weight: 400;
  color: #646566;
  text-align: right;
}
.content-1 {
  font-size: 14px;
  font-weight: 400;
  color: #323233;
  line-height: 20px;
}
.comment-btn {
  position: fixed;
  right: 16px;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 20%);
}
.bottom-1 {
  bottom:20px;
}
.bottom-2 {
  bottom: 66px;
}
.mb-2 {
  padding-bottom: 60px;
}
.comment-input {
  background-color: #f2f3f5;
  .van-cell {
    background-color: transparent;
  }
}
.bottomFlex {
  display: flex;
  .flex1 {
    flex: 1;
    text-align: left;
    font-size: 12px;
    line-height: 32px;
    color: #888;
  }
}
.bottomText {
  text-align: left;
  font-size: 12px;
  line-height: 32px;
  color: #888;
}
.sendText {
  margin-left: 10px;
  position: relative;
  min-width: 55px;
  text-align: center;
  .iconfont {
    margin-top: 12px;
    font-size: 17px;
  }
  .btn {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.uploadList {
  // margin:16px;
  .imgList {
  }
  .filePdf {
    margin: 8px 0;
    background: #f2f3f5;
    padding: 2px 8px;
    border-radius: 4px;
    align-items: center;
    color: #646566;
    .iconfont {
      font-size: 14px;
    }
    a {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 7px 10px;
    }
  }
}
.rename {
  margin: 8px 24px;
  .textarea {
    background: #f7f8fa;
    border-radius: 8px;
  }
}
.pdfList {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f2f3f5;
  font-size: 14px;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 0;
  img {
    height: 22px;
    width: 22px;
  }
  a {
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>

<style>
.van-image--round img {
  object-fit: cover;
}
</style>
