import Vue from 'vue';

export function infoShow(params) {
  return Vue.axios({
    url: '/api/bank/account/show',
    method: 'get',
    params: params
  })
}

export function infoSave(params) {
  return Vue.axios({
    url: '/api/bank/account/save',
    method: 'post',
    data: params
  })
}

export function infoStatus() {
  return Vue.axios({
    url: '/api/bank/account/status',
    method: 'get'
  })
}