<template>
  <div  :class="stepStatus == 4 && dataDetails.isCommon==1? 'clickNone' : ''">
    <!--    母亲-->
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{ $t('coll.Mother') }}</div>
      </div>
    </div>
    <!--    法定名-->
    <div class="flex mt-20">
      <div class="flex1">
        <div class="title">{{ $t('coll.LegalName') }}</div>
      </div>
    </div>
    <van-field
        :label="$t('coll.LastName')"
        :placeholder="$t('global.please_input')"
        v-model="matherData.last_name"
        class="bgGray mt-10"
    />
    <van-field
        :label="$t('coll.MiddleName')"
        :placeholder="$t('coll.MiddleNameOptional')"
        v-model="matherData.middle_name"
        label-class="van-field-label"
        class="bgGray mt-10"
    />
    <van-field
        :label="$t('coll.FirstName')"
        :placeholder="$t('global.please_input')"
        v-model="matherData.first_name"
        class="bgGray mt-10"
    />
    <van-field
        :label="$t('coll.EnglishName/NickName')"
        :placeholder="$t('global.please_input')"
        v-model="matherData.english_name"
        class="bgGray mt-10"
    />
    <!--    联系方式-->
    <div class="flex mt-20">
      <div class="flex1">
        <div class="title">{{ $t('coll.ContactInformation') }}</div>
      </div>
    </div>
    <van-field
        type="tel"
        :label="$t('coll.Mobile')"
        :placeholder="$t('global.please_input')"
        :rules="[{ pattern, message: $t('pickupcard.invalid_email') }]"
        v-model="matherData.mobile"
        class="bgGray mt-10"
    />
    <van-field
        :label="$t('coll.Email')"
        :placeholder="$t('global.please_input')"
        :rules="[{ email_rule, message: $t('pickupcard.invalid_phone') }]"
        v-model="matherData.email"
        class="bgGray mt-10"
    />

    <!--父亲-->
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{ $t('coll.Father') }}</div>
      </div>
    </div>
    <!--    法定名-->
    <div class="flex mt-20">
      <div class="flex1">
        <div class="title">{{ $t('coll.LegalName') }}</div>
      </div>
    </div>
    <van-field
        :label="$t('coll.LastName')"
        :placeholder="$t('global.please_input')"
        v-model="fatherData.last_name"
        class="bgGray mt-10"
    />
    <van-field
        :label="$t('coll.MiddleName')"
        :placeholder="$t('coll.MiddleNameOptional')"
        v-model="fatherData.middle_name"
        class="bgGray mt-10"
    />
    <van-field
        :label="$t('coll.FirstName')"
        :placeholder="$t('global.please_input')"
        v-model="fatherData.first_name"
        class="bgGray mt-10"
    />
    <van-field
        :label="$t('coll.EnglishName/NickName')"
        :placeholder="$t('global.please_input')"
        v-model="fatherData.english_name"
        class="bgGray mt-10"
    />
    <!--    联系方式-->
    <div class="flex mt-20">
      <div class="flex1">
        <div class="title">{{ $t('coll.ContactInformation') }}</div>
      </div>
    </div>
    <van-field
        type="tel"
        :label="$t('coll.Mobile')"
        :placeholder="$t('global.please_input')"
        :rules="[{ pattern, message: $t('pickupcard.invalid_email') }]"
        v-model="fatherData.mobile"
        class="bgGray mt-10"
    />
    <van-field
        :label="$t('coll.Email')"
        :placeholder="$t('global.please_input')"
        :rules="[{ email_rule, message: $t('pickupcard.invalid_phone') }]"
        v-model="fatherData.email"
        class="bgGray mt-10"
    />
    <!--    第一联系人-->
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{$t("coll.please_designate_contact")}}</div>
      </div>
    </div>
    <van-row gutter="20" class="mt-20">
      <van-col span="8">
            <span
                class="ride font14 color3"
                :class="primary_contact.type == 1 ? 'rideActive' : ''"
                @click="setPrimaryContact(1)"
            >{{ $t("coll.Mother") }}
            </span>
      </van-col>
      <van-col span="8">
            <span
                class="ride font14 color3"
                :class="primary_contact.type== 2 ? 'rideActive' : ''"
                @click="setPrimaryContact(2)"
            >{{ $t("coll.Father") }}
            </span>
      </van-col>
      <van-col span="8">
            <span
                class="ride font14 color3"
                :class="primary_contact.type == 3 ? 'rideActive' : ''"
                @click="setPrimaryContact(3)"
            >{{ $t("coll.otherFile") }}
            </span>
      </van-col>
    </van-row>
    <div v-if="primary_contact.type == 3">
      <div class="flex mt-20">
        <div class="flex1">
          <div class="addText">{{$t("coll.choose_other")}}</div>
        </div>
      </div>
      <van-field
          :label="$t('global.name')"
          :placeholder="$t('global.please_input')"
          v-model="primary_contact.name"
          class="bgGray mt-10"
      />
      <van-field
          :label="$t('coll.relationship')"
          :placeholder="$t('global.please_input')"
          v-model="primary_contact.relation"
          class="bgGray mt-10"
      />
      <van-field
          type="tel"
          :label="$t('coll.Mobile')"
          :placeholder="$t('global.please_input')"
          :rules="[{ pattern, message: $t('pickupcard.invalid_email') }]"
          v-model="primary_contact.mobile"
          class="bgGray mt-10"
      />
      <van-field
          :label="$t('coll.Email')"
          :placeholder="$t('global.please_input')"
          :rules="[{ email_rule, message: $t('pickupcard.invalid_phone') }]"
          v-model="primary_contact.email"
          class="bgGray mt-10"
      />
    </div>

    <!--    紧急联系人-->
    <!-- <div>
      <div class="flex mt-20">
        <div class="flexWidth"><span class="icon"></span></div>
        <div class="flex1">
          <div class="title">{{ $t('coll.Emergency') }}</div>
        </div>
      </div>
      <div class="fr relative mt-20 flexRemove" @click="addEmergency">
        <van-icon name="add-o"  class="addIcon font22 emergency-icon" />
        <span class="addText emergency">{{ $t('coll.please_add_contact') }} </span>
      </div>
      <div class="clearfix"></div>
      <div v-for="(list, index) in EmergencyNum" :key="index">
        <div class="flex mt-20">
          <div class="flex1">
            <div class="title">{{ $t('coll.contact_person') }}{{ index + 1 }}</div>
          </div>
        </div>
        <van-field
            :label="$t('global.name')"
            :placeholder="$t('global.please_input')"
            v-model="EmergencyNumCopy[index]['name']"
            class="bgGray mt-10"
        />
        <van-field
            type="tel"
            :label="$t('coll.Mobile')"
            :placeholder="$t('global.please_input')"
            :rules="[{ pattern, message: $t('pickupcard.invalid_email') }]"
            v-model="EmergencyNumCopy[index]['mobile']"
            class="bgGray mt-10"
        />
        <van-field
            :label="$t('coll.Email')"
            :placeholder="$t('global.please_input')"
            :rules="[{ email_rule, message: $t('pickupcard.invalid_phone') }]"
            v-model="EmergencyNumCopy[index]['email']"
            class="bgGray mt-10"
        />
        <div class="color6 font12 mt-10 fr" v-if="index > 0">
        <span @click='delEmergency(index)' class="flex">
          <van-icon name="clear" color="#cccccc"  class='mr-5 font19'/>
          <span style="color:#646566;" class="font14">{{$t('global.delete')}}</span>
        </span>
        </div>
        <div class="clearfix"></div>
      </div>
    </div> -->


    <!--    协议和提交按钮等-->
    <div v-if="dataDetails.protocol.length != 0">
      <protocol-sign
          @click="baseImg"
          :busProtocol="dataDetails.protocol"
          :base64="base64"
      ></protocol-sign>
    </div>
    <div class="mt-20">
      <van-button
          v-if="stepStatus == 4 && dataDetails.isCommon==1"
          disabled
          type="info"
          block
          round
          color="#4d88d2"
          class="saveSub"
      >{{ $t("coll.step_reviewed") }}
      </van-button
      >
      <a
          href="javascript:;"
          v-else
          @click="submitData()"
          class="saveBtnStep"
          :class="btnDisabled ? 'disabledSubmit' : ''"
      >{{ $t("global.submit") }}</a
      >
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {Toast} from "vant";
import ProtocolSign from "@/components/steps/ProtocolSign";

Vue.use(Toast);

export default {
  components: {
    "protocol-sign": ProtocolSign,
  },
  props: {
    dataDetails: {
      type: Object,
    },
    status: {
      type: Number,
    },
    disabled: {
      type: Number,
    },
    schoolId: {
      type: String,
    }
  },
  name: "homeAddress",
  data() {
    return {
      parentsData: "",
      matherData: {},
      fatherData: {},
      parent_contact_details: [],//家长的所有联系方式
      primary_contact: {
        type: 0,
        name: '',
        relation: '',
        mobile: '',
        email: '',
      },//第一联系人信息
      // EmergencyNum: [{
      //   name: "",
      //   mobile: "",
      //   email: ""
      // }],//紧急联系人
      // EmergencyNumCopy: [{
      //   name: "",
      //   mobile: "",
      //   email: ""
      // }],
      // in_einfo: [],
      uptoken: "",
      childid: "",
      isShow: false,
      childrenList: [],
      childIds: [],
      saveCurrent: false,
      childrenStatus: {},
      loading: true,
      emptyChild: false,
      fileinfo: null,
      pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
      email_rule: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,
      stepStatus: "",
      btnDisabled: false,
      base64: "",
      newSign: false,
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
  },
  created() {
    this.stepStatus = this.status;
    this.parentsData = this.dataDetails.data;
    if(this.parentsData.minfo.length!=0){
      this.matherData = this.parentsData.minfo;
    }
    if(this.parentsData.finfo.length!=0){
      this.fatherData = this.parentsData.finfo;
    }
    if (this.parentsData.primary_contact.type > 0) {
      this.primary_contact.type = this.parentsData.primary_contact.type;
      this.primary_contact.mobile = this.parentsData.primary_contact.mobile;
      this.primary_contact.name = this.parentsData.primary_contact.name;
      this.primary_contact.relation = this.parentsData.primary_contact.relation;
      this.primary_contact.email = this.parentsData.primary_contact.email;
    }
    // if (this.parentsData.einfo.length > 0) {
    //   this.EmergencyNum = this.parentsData.einfo;
    // }
    // this.EmergencyNumCopy = this.EmergencyNum;
    if (this.dataDetails.sign_url != "") {
      this.base64 = this.dataDetails.sign_url;
    }
  },
  methods: {
    // addEmergency() {
    //   if (this.EmergencyNum.length < 5) {
    //     let push_data = {
    //       name: "",
    //       mobile: "",
    //       email: ""
    //     };
    //     this.EmergencyNum.push(push_data);
    //     this.EmergencyNumCopy = this.EmergencyNum;
    //   } else {
    //     Toast('最大数');
    //   }
    // },
    // delEmergency(index) {
    //   if (this.EmergencyNum.length == 1) {
    //     return;
    //   }
    //   this.EmergencyNum.splice(index, 1);
    // },
    setPrimaryContact(primary_contact_type) {
      if (primary_contact_type == 1 && !this.matherData.mobile && !this.matherData.email) {
        Toast(this.$t('coll.please_required'));
        return;
      }
      if (primary_contact_type == 2 && !this.fatherData.mobile && !this.fatherData.email) {
        Toast(this.$t('coll.please_required'));
        return;
      }

      this.primary_contact.type = primary_contact_type;
    },
    uploadToQiniu(data, i) {
      this.uptoken = this.cardData.qiniuToken;
      this.putb64(data, i);
    },
    putb64(data, i) {
      const that = this;
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: that.$t("pickupcard.uploading"),
      });
      var pic = data.substring(data.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // toast.clear()
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that.contact[i], "head", resJson.data.key);
            Vue.set(that.contact[i], "head_url", resJson.data.url);
            Toast(that.$t("pickupcard.Uploaded"));
          } else {
            Toast(resJson.msg);
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + this.uptoken);
      xhr.send(pic);
    },
    //电话验证
    phoneValidate(params) {
      if (this.pattern.test(params.trim())) {
        return true;
      } else {
        return false;
      }
    },
    //邮箱验证
    emailValidate(params) {
      if (this.email_rule.test(params.trim())) {
        return true;
      } else {
        return false;
      }
    },
    baseImg(data) {
      if (data != "cancel") {
        this.base64 = data;
        this.newSign = true;
      }
    },
    submitData() {
      let that = this;
      if (this.btnDisabled) {
        return;
      }
      if(Object.keys(this.matherData).length==0 && Object.keys(this.fatherData).length==0){
        Toast(this.$t('coll.please_required'));
        return;
      }
      let m = {
        "id": that.matherData.id,
        "last_name": that.matherData.last_name?that.matherData.last_name.trim():'',
        "first_name": that.matherData.first_name?that.matherData.first_name.trim():'',
        "email": that.matherData.email?that.matherData.email.trim():'',
        "mobile": that.matherData.mobile?that.matherData.mobile.trim():'',
      }
      let f = {
        "id": that.fatherData.id,
        "last_name":that.fatherData.last_name?that.fatherData.last_name.trim():'',
        "first_name": that.fatherData.first_name?that.fatherData.first_name.trim():'',
        "email":that.fatherData.email? that.fatherData.email.trim():'',
        "mobile": that.fatherData.mobile?that.fatherData.mobile.trim():''
      }
      //父母信息不能同时为空
      if (m.last_name == '' && m.first_name == '' && m.email == '' && m.mobile == '' && f.last_name == '' && f.first_name == '' && f.email == '' && f.mobile == '') {
        Toast(this.$t('coll.please_required'));
        return;
      }
      let m_f = [m, f];
      for (let index in m_f){
        let item = m_f[index]
        let parent = index == 0 ? this.$t('coll.Mother')  :this.$t('coll.Father');
        if (item.mobile) {
          that.parent_contact_details.push(item.mobile);
        }
        if (item.email) {
          that.parent_contact_details.push(item.email);
        }
        //无账号  填写了任意信息 就必须填写上 联系方式和姓名
        if (!item.id && (item.email || item.mobile || item.last_name || item.first_name || item.middle_name || item.english_name)) {
          if (!item.last_name || item.last_name=='') {
            Toast(parent + 'last_name null');
            return ;
          }
          if (!item.first_name || item.first_name=='') {
            Toast(parent + 'first_name null');
            return ;
          }
          if (!item.mobile || item.mobile=='' || !this.phoneValidate(item.mobile)) {
              Toast(parent + this.$t("pickupcard.invalid_phone"));
              return ;
          }
          // if (!item.email || item.email=='' || !this.emailValidate(item.email)) {
          //     Toast(parent + this.$t("pickupcard.invalid_email"));
          //     return ;
          // }
          // if ((!item.email || item.email=='') && (!item.mobile || item.mobile=='')) {
          //   Toast(parent + this.$t('coll.please_required'));
          //   return ;
          // }
         
        }
        //有帐号  可以填写 姓名和联系方式 如果填写了联系方式要验证
        if (item.id && (item.email || !item.mobile)) {
          if (item.mobile) {
            if (!this.phoneValidate(item.mobile)) {
              Toast(parent + this.$t("pickupcard.invalid_phone"));
              return ;
            }
          }
          // if (item.email) {
          //   if (!this.emailValidate(item.email)) {
          //     Toast(parent + this.$t("pickupcard.invalid_email"));
          //     return ;
          //   }
          // }
        }
      }
      if(this.fatherData.email =='' && this.matherData.email==''){
          Toast(this.$t("pickupcard.invalid_email"));
          return ;
      }
      if( this.fatherData.email && this.fatherData.email !='' && !this.emailValidate(this.fatherData.email) ){
          Toast(this.$t("pickupcard.invalid_email"));
          return ;
      }
      if(this.matherData.email && this.matherData.email !='' && !this.emailValidate(this.matherData.email) ){
          Toast(this.$t("pickupcard.invalid_email"));
          return ;
      }
      if(this.fatherData.email && this.matherData.email && this.fatherData.email.trim() == this.matherData.email.trim()){
          Toast(this.$t('coll.not_duplicate'));
          return ;
      }
      if(this.fatherData.mobile && this.matherData.mobile && this.fatherData.mobile.trim() == this.matherData.mobile.trim()){
          Toast(this.$t('coll.not_duplicate'));
          return ;
      }
      // 第一联系人 选择父母其他  联系方式都不能为空
      if (that.primary_contact.type == 1 && !this.matherData.mobile && !this.matherData.email) {
        this.primary_contact.type = 0;
        Toast(this.$t('coll.please_required'));
        return;
      }
      if (that.primary_contact.type == 2 && !this.fatherData.mobile && !this.fatherData.email) {
        this.primary_contact.type = 0;
        Toast(this.$t('coll.please_required'));
        return;
      }
      if (that.primary_contact.type == 3) {
        if (that.primary_contact.name.trim() == '' || that.primary_contact.relation=='') {
          Toast(this.$t('coll.please_required'));
          return;
        }
        if (that.primary_contact.mobile=='' || !this.phoneValidate(that.primary_contact.mobile)) {
            Toast(this.$t('pickupcard.invalid_phone'));
            return;
        }
        if (that.primary_contact.email=='' || !this.emailValidate(that.primary_contact.email)) {
            Toast(this.$t('pickupcard.invalid_email'));
            return;
        }
      }
      //紧急联系人  不能是父母的联系方式
      // that.in_einfo = [];
      // for (let item of  that.EmergencyNum){
      //   if (item.name.trim().length == 0) {
      //     Toast(this.$t('coll.contact_person') + this.$t("pickupcard.information"));
      //     return false;
      //   }
      //   if (!item.mobile && !item.email) {
      //     Toast(this.$t('coll.contact_person') + this.$t("pickupcard.information"));
      //     return false;
      //   }
      //   if (item.name.trim() && (item.mobile || item.email)) {
      //     if (item.mobile=='' || !this.phoneValidate(item.mobile)) {
      //       Toast(this.$t('coll.contact_person') + this.$t("pickupcard.invalid_phone"));
      //       return false;
      //     }
      //     if (that.parent_contact_details.includes(item.mobile)) {
      //       Toast(this.$t('coll.Emergency'));
      //       return false;
      //     }
      //     if (item.email=='' || !this.emailValidate(item.email)) {
      //       Toast(this.$t('coll.contact_person') + this.$t("pickupcard.invalid_email"));
      //       return false;
      //     }
      //     if (that.parent_contact_details.includes(item.email)) {
      //       Toast(this.$t('coll.Emergency') );
      //       return false;
      //     }
      //     that.in_einfo.push({
      //       name: item.name,
      //       mobile: item.mobile,
      //       email: item.email
      //     });

      //   }
      // }
      // if (that.in_einfo.length == 0) {
      //   Toast(this.$t('coll.contact_person') + this.$t("pickupcard.needed"));
      //   return;
      // }
      if (that.dataDetails.protocol.length != 0 && this.base64 == "") {
        Toast(that.$t("coll.please_sign"));
        return;
      }
      if (that.dataDetails.protocol.length == 0) {
        that.saveData();
        return;
      }
      if (!that.newSign) {
        that.saveData();
        return;
      }
      this.$nextTick(function () {
        this.btnDisabled = true
      })

      var pic = this.base64.substring(this.base64.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // toast.clear()
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that.parentsData, "sign_url", resJson.data.url);
            Vue.set(that.parentsData, "sign", resJson.data.key);
            that.saveData();
          } else {
            Toast(resJson.msg);
            that.btnDisabled = false;
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.dataDetails.qiniuToken);
      xhr.send(pic);
    },
    saveData() {
      let dats = {
        // einfo: this.in_einfo,
        finfo: this.fatherData,
        minfo: this.matherData,
        primary_contact: this.primary_contact,
        sign: this.parentsData.sign,
        sign_url: this.parentsData.sign_url,
      };
      this.btnDisabled = true;
      this.$emit("change", dats);
    },
  },
};
</script>

<style lang="less" scoped>
.relative {
  position: relative;
}
.addIcon {
  color: #4d88d2;
}
.emergency,.emergency-icon{
  line-height: 20px;
  vertical-align: middle;
  text-align: center;
}


</style>