import Vue from 'vue';

export function getCalendar (params) {
    return Vue.axios({
      url: '/api/calendar/getCalendarDataByMonth',
      method: 'get',
    params: params

    })
}

export function viewCalendar (schoolId,yid) {
  return Vue.axios({
    url: '/api/preview/calendar/'+schoolId+'/'+yid,
    method: 'get',

  })
}