<template>
  <div :class="stepStatus == 4 && dataDetails.isCommon==1? 'clickNone' : ''">
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{ $t("coll.bank_info") }}</div>
      </div>
    </div>
    <div class="mt-20">
      <div class="label mb-5 font14">{{ $t("bankInfo.name") }}</div>
      <van-field
        class="bgGray"
        v-model="bankData.bank_user"
        :placeholder="$t('global.please_input')"
      />
    </div>
    <div class="mt-15">
      <div class="label mb-5 font14">{{ $t("bankInfo.bankName") }}</div>
      <van-field
        class="bgGray"
        v-model="bankData.bank_name"
        :placeholder="$t('global.please_input')"
      />
    </div>
    <div class="mt-15">
      <div class="label mb-5 font14">{{ $t("bankInfo.bankAddress") }}</div>
      <van-field
        class="bgGray"
        v-model="bankData.bank_city"
        :placeholder="$t('global.please_input')"
      />
    </div>
    <div class="mt-15">
      <div class="label mb-5 font14">{{ $t("bankInfo.number") }}</div>
      <van-field
        class="bgGray"
        type="digit"
        v-model="bankData.bank_account"
        :placeholder="$t('global.please_input')"
      />
    </div>
    <div v-if="dataDetails.protocol.length != 0">
      <protocol-sign
        @click="baseImg"
        :busProtocol="dataDetails.protocol"
        :base64="base64"
      ></protocol-sign>
    </div>
    <div class="mt-20">
      <van-button
        v-if="stepStatus == 4 && dataDetails.isCommon==1"
        disabled
        type="info"
        block
        round
        color="#4d88d2"
        class="saveSub"
        >{{ $t("coll.step_reviewed") }}</van-button
      >
      <a
        href="javascript:;"
        v-else
        @click="submitData()"
        class="saveBtnStep"
        :class="btnDisabled ? 'disabledSubmit' : ''"
        >{{ $t("global.submit") }}</a
      >
    </div>
  </div>
</template>

<script>
import ProtocolSign from "@/components/steps/ProtocolSign";
import { Toast } from "vant";
import Vue from "vue";
export default {
  components: {
    "protocol-sign": ProtocolSign,
  },
  props: {
    dataDetails: {
      type: Object,
    },
    status: {
      type: Number,
    },
    disabled: {
      type: Number,
    },
  },
  name: "homeAddress",
  data() {
    return {
      bankData: "",
      stepStatus: "",
      btnDisabled: false,
      base64: "",
      newSign: false,
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
  },
  created() {
    this.stepStatus = this.status;
    this.bankData = this.dataDetails;
    if (this.dataDetails.sign_url != "") {
      this.base64 = this.dataDetails.sign_url;
    }
  },
  methods: {
    baseImg(data) {
      if (data != "cancel") {
        this.base64 = data;
        this.newSign = true;
      }
    },
    submitData() {
      let that = this;
      if (that.bankData.bank_user=='') {
        Toast(this.$t('global.please_input')+this.$t('bankInfo.name'));
        return;
      }
      if (that.bankData.bank_name=='') {
        Toast(this.$t('global.please_input')+this.$t('bankInfo.bankName'));
        return;
      }
      if (that.bankData.bank_city=='') {
        Toast(this.$t('global.please_input')+this.$t('bankInfo.bankAddress'));
        return;
      }
      if (that.bankData.bank_account=='') {
        Toast(this.$t('global.please_input')+this.$t('bankInfo.number'));

        return;
      }
      if (that.dataDetails.protocol.length != 0 && this.base64 == "") {
        Toast(that.$t("coll.please_sign"));
        return;
      }
      this.$nextTick(function () {
        this.btnDisabled = true;
      });
      if (that.dataDetails.protocol.length == 0) {
        that.saveData();
        return;
      }
      if (!that.newSign) {
        that.saveData();
        return;
      }
      var pic = this.base64.substring(this.base64.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // toast.clear()
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that.bankData, "sign_url", resJson.data.url);
            Vue.set(that.bankData, "sign", resJson.data.key);
            that.saveData();
          } else {
            Toast(resJson.msg);
            that.btnDisabled = false;
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.dataDetails.signQiniuToken);
      xhr.send(pic);
    },
    saveData() {
      this.$emit("change", this.bankData);
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  .flexWidth {
    width: 16px;
    padding-top: 3px;
    .icon {
      display: inline-block;
      width: 6px;
      height: 14px;
      background: #4d88d2;
      border-radius: 3px;
    }
  }
  .flex1 {
    flex: 1;
    .text {
      font-size: 12px;
      font-weight: 400;
      color: #646566;
      line-height: 20px;
    }
    .title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 26px;
    }
  }
}
</style>