<template>
  <div  :class="stepStatus == 4 && dataDetails.isCommon==1?'clickNone':''">
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{$t('coll.student_passport')}}</div>
      </div>
    </div>
    <van-row class="mt-20">
      <van-uploader
        :before-read="beforeRead"
        v-model="step_data.student_identity_url"
        @delete="deleteimg"
        :after-read="afterRead"
        name="student_identity"
      />
    </van-row>
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{$t('coll.mother_passport')}}</div>
      </div>
    </div>
    <van-row class="mt-20">
      <van-uploader
       :before-read="beforeRead"
        v-model="step_data.mother_identity_url"
        @delete="deleteimg"
        :after-read="afterRead"
        name="mother_identity"
      />
    </van-row>
    <!-- <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{$t('coll.vaccination')}}</div>
        <div class="color6 font14 pt-5">{{$t('coll.first_file')}}</div>
      </div>
    </div>
    <van-row class="mt-20">
      <van-uploader
        :before-read="beforeRead"
        v-model="step_data.vaccine_record_url"
        @delete="deleteimg"
        :after-read="afterRead"
        name="vaccine_record"
      />
    </van-row>
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{$t('coll.medical_insurance')}}</div>
        <div class="color6 font14 pt-5">{{$t('coll.optional')}}</div>
      </div>
    </div>
    <van-row class="mt-20">
      <van-uploader
        :before-read="beforeRead"
        v-model="step_data.medical_insurance_url"
        @delete="deleteimg"
        :after-read="afterRead"
        name="medical_insurance"
      />
    </van-row> -->
    <div v-if="dataDetails.protocol.length != 0">
      <protocol-sign
        @click="baseImg"
        :busProtocol="dataDetails.protocol"
        :base64="base64"
      ></protocol-sign>
    </div>
    <div class="mt-20">
      <van-button
        v-if="stepStatus == 4 && dataDetails.isCommon==1"
        disabled
        type="info"
        block
        round
        color="#4d88d2"
        class="saveSub"
        >{{ $t("coll.step_reviewed") }}</van-button
      >
      <a
        href="javascript:;"
        v-else
        @click="submitData()"
        class="saveBtnStep"
        :class="btnDisabled ? 'disabledSubmit' : ''"
        >{{ $t("global.submit") }}</a
      >
    </div>
  </div>
</template>

<script>
import ProtocolSign from "@/components/steps/ProtocolSign";
import Vue from "vue";
import { Toast } from "vant";
import Compressor from 'compressorjs';

export default {
   components: {
    "protocol-sign": ProtocolSign,
  },
  props: {
    dataDetails: {
      type: Object,
    },
    status: {
      type: Number,
    },
    disabled: {
      type: Number,
    },
  },
  name: "homeAddress",
  data() {
    return {
      insurance: [],
      stuPhoto: [],
      monPhoto: [],
      vaccination: [],
      stepStatus: "",
      qiniuToken: "",
      type: "",
      base64:'',
      btnDisabled:false,
      sign: "",
      sign_url: "",
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
  },
  created() {
    this.stepStatus = this.status;
    this.step_data = this.dataDetails.data;
    this.step_data.student_identity_url=this.tojson(this.dataDetails.data.student_identity_url)
    this.step_data.mother_identity_url=this.tojson(this.dataDetails.data.mother_identity_url)
    // this.step_data.vaccine_record_url=this.tojson(this.dataDetails.data.vaccine_record_url)
    // this.step_data.medical_insurance_url=this.tojson(this.dataDetails.data.medical_insurance_url)
    this.qiniuToken = this.dataDetails.qiniuToken;
    if (this.dataDetails.sign_url != "") {
      this.base64 = this.dataDetails.sign_url;
    }
  },
  methods: {
    tojson(rows){
        var ids=[]
          for(var i=0,len=rows.length; i<len; i++){
              var signAgainReq=new Object();
              signAgainReq.url=rows[i];
              ids.push(signAgainReq);
          }
          return ids
    },
    beforeRead(file) {
      return new Promise((resolve) => {
        new Compressor(file, {
          maxWidth:1200,
          quality:0.6,
          success: resolve,
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
    afterRead(file, detail) {
      this.type = detail.name;
      if (file.length > 1) {
        for (let i in file) {
          this.upimg(file[i].content);
        }
      } else {
        this.upimg(file.content);
      }
    },
    upimg(data) {
      const that = this;
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: that.$t("pickupcard.uploading"),
      });
      var pic = data.substring(data.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            that.step_data[that.type].push(resJson.data.key);
            Toast(that.$t("pickupcard.Uploaded"));
          } else {
            Toast(resJson.msg);
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.qiniuToken);
      xhr.send(pic);
    },
    //删除图片
    deleteimg(file, detail) {
      this.step_data[detail.name].splice(detail.index - 1, 1);
    },
    baseImg(data) {
      if (data != "cancel") {
        this.base64 = data;
        this.newSign = true;
      }
    },
    submitData() {
      let that = this;
      if (this.btnDisabled) {
        return;
      }
      if (that.step_data.student_identity.length==0) {
        Toast(this.$t('global.please_upload')+this.$t('coll.student_passport'));
        return;
      }
      if (that.step_data.mother_identity.length==0) {
        Toast(this.$t('global.please_upload')+this.$t('coll.mother_passport'));
        return;
      }
      // if (that.step_data.vaccine_record.length==0) {
      //   Toast(this.$t('global.please_upload')+this.$t('coll.vaccination') );
      //   return;
      // }
      if (that.dataDetails.protocol.length != 0 && this.base64 == "") {
        Toast(that.$t("coll.please_sign"));
        return;
      }
      this.$nextTick(function () {
        this.btnDisabled = true
      })
      if (that.dataDetails.protocol.length == 0) {
        that.saveData();
        return;
      }
      if (!that.newSign) {
        that.saveData();
        return;
      }
      var pic = this.base64.substring(this.base64.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // toast.clear()
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that, "sign_url", resJson.data.url);
            Vue.set(that, "sign", resJson.data.key);
            that.saveData();
          } else {
            Toast(resJson.msg);
            that.btnDisabled = false;
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.dataDetails.signQiniuToken);
      xhr.send(pic);
    },
    saveData() {
      this.btnDisabled = true
      let data = {
        // medical_insurance: this.step_data.medical_insurance,
        mother_identity: this.step_data.mother_identity,
        student_identity: this.step_data.student_identity,
        // vaccine_record: this.step_data.vaccine_record,
        sign: this.sign,
        sign_url: this.sign_url,
      };
      this.$emit("change", data);
    },
  },
};
</script>

<style lang="less" scoped>

</style>