<template>
  <div>
    <div class="text-center pb-20 bgWhite">
      <img class="img" src="../../assets/auditCompleted.png" alt="" />
      <div class="color6 font14  pb-15 pr-45 pl-45">{{success_content}}</div>
    </div>
    <div class="mt-10 bgWhite p16" v-if="stepList.length!=0">
      <div class="color3 font14 mb-15 mt-5 font600">
        以下步骤有反馈
      </div>
      <div class="van-steps van-steps--vertical">
        <div
          class="van-hairline van-step van-step--vertical van-step--process"
          v-for="(item, idx) in stepList"
          :key="idx"
        >
          <div class="van-step__title">
            <div class="flex">
              <span class="flex1 font14 color3 ">{{ item.title }}</span>
            </div>
            <div class="font13 color6 mt-10 lineHeight">
                {{item.auditComments}}
            </div>
          </div>
          <div class="van-step__circle-container">
            <i
              class="
                van-icon van-icon-checked
                van-step__icon van-step__icon--active
                font14
              "
            ></i>
          </div>
          <div class="van-step__line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auditSuccess } from "@/api/collection";

export default {
  name: "homeAddress",
  data() {
    return {
      coll_id: this.$route.params.id,
      childid: this.$route.params.childid,
      stepList:[],
      statusList: [],
      success_content:''
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
  },
  created() {
    this.coll_id = this.$route.params.id;
    auditSuccess(this.coll_id).then((res) => {
      this.stepList = res.data.steps;
      this.statusList = res.data.stepStatusList;
      this.success_content=res.data.success_content;
    });
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.img {
  width: 130px;
  margin-top: 32px;
}
.bgWhite {
  background: #fff;
}
.p16 {
  padding: 16px;
}
.van-step--vertical:not(:last-child)::after {
  border: none;
}
.labelBage {
  padding: 5px 10px;
  background: #f2f4f9;
  border-radius: 12px;
  display: inline-block;
}
.font600{
  font-weight: 600;
}
.lineHeight{
    line-height: 20px;
    text-align: justify;
    word-break:break-all
}
</style>