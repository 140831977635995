import { render, staticRenderFns } from "./Notice.vue?vue&type=template&id=142e271e&scoped=true&"
import script from "./Notice.vue?vue&type=script&lang=js&"
export * from "./Notice.vue?vue&type=script&lang=js&"
import style0 from "./Notice.vue?vue&type=style&index=0&id=142e271e&lang=less&scoped=true&"
import style1 from "./Notice.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "142e271e",
  null
  
)

export default component.exports