<template>
  <div class="login mt-30">
    <div class="mb-35" style="text-align: center;">
      <img :src="logoUrl" alt="" style="height: 60px;">
    </div>
    <van-tabs class="mb-30">
      <van-tab :title="$t('bind.mobile_login')">
        <van-form class="mt-30">
          <van-field
            v-model="phone"
            name="phone"
            label=""
            :placeholder="$t('bind.phone_number')"
          />
          <van-field
            v-model="code"
            type="code"
            name="code"
            label=""
            :placeholder="$t('bind.auth_code')"
          >
            <template #button>
              <van-count-down :time="timeDown" format="ss s" @finish="finish" v-if="timeDown>0" />
              <span class="btn-text" @click.stop="sendCode" v-else>{{ $t('bind.send_code') }}</span>
            </template>
          </van-field>
          <div style="margin: 30px 32px 12px 32px;">
            <van-button round block type="info" @click.stop="bindByPhone">{{ $t('bind.login') }}</van-button>
          </div>
          <div class="tips">
            {{ $t('bind.mobile_login_tips') }}
          </div>
        </van-form>
      </van-tab>
      <van-tab :title="$t('bind.email_login')">
        <van-form class="mt-30" @submit="onSubmit">
          <van-field
            v-model="email"
            name="email"
            label=""
            :placeholder="$t('bind.email')"
          />
          <van-field
            v-model="password"
            type="password"
            name="password"
            label=""
            :placeholder="$t('bind.password')"
            autocomplete
          />
          <div style="margin: 30px 32px 12px 32px;">
            <van-button round block type="info" native-type="submit">{{ $t('bind.login') }}</van-button>
          </div>
          <div class="tips">
            {{ $t('bind.email_login_tips') }}
          </div>
        </van-form>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Vue from 'vue';
import { Col, Row, Form, Field, Button, Tab, Tabs, Toast, CountDown } from 'vant';
import { sendCode, bindByPhone, login } from '@/api/login'
import store from "../store";

Vue.use(Col);
Vue.use(Row);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Toast);
Vue.use(CountDown);

export default {
  name: 'Login',
  data() {
    return {
      phone: '',
      code: '',
      email: '',
      password: '',
      timeDown: 0,
      logoUrl: ''
    };
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color:#fff;')
  },
  created () {
    if (store.getters.wxState == 'ivy') {
      this.logoUrl = 'https://m2.files.ivykids.cn/cloud01-file-5FhbY07mNlvQCD92VfUg7MJbzqFG_.png'
    } else {
      this.logoUrl = 'https://m2.files.ivykids.cn/cloud01-file-5Fpg_yg-35IHhcapbTMXJhwWKTiOA.png'
    }
  },
  methods: {
    sendCode() {
      sendCode({
        phone: this.phone
      }).then(res => {
        if(res.code == 0) {
          this.timeDown = 60000
          Toast.success('发送成功')
        }
      })
    },
    bindByPhone() {
      bindByPhone({
        phone: this.phone,
        code: this.code,
      }).then(res => {
        if(res.code == 0) {
          this.$router.push({ path: '/' })
        }
      })
    },
    finish() {
      this.timeDown = 0
    },
    onSubmit(values) {
      login(values).then((res) => {
        if (res.code == 0) {
          this.$router.push({ path: '/' })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  .van-tabs {
    padding: 0 10px;
  }
  .btn-text {
    color: #4D88D2;
  }
  .van-count-down {
    color: #C8C9CC;
  }
  .tips {
    margin: 0 65px;
    font-size: 12px;
    font-weight: 400;
    color: #969799;
  }
}
</style>
