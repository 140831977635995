<template>
  <div>
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{ $t("coll.Agreement") }}</div>
        <div class="color6 font14 pt-5">{{ $t("coll.agree_sign") }}</div>
      </div>
    </div>
    <div class="mt-20 protocol" v-if="busProtocol.length != 0">
      <img
        v-for="(list, index) in busProtocol"
        :key="index"
        :src="list"
        @click="showImg(index)"
        alt=""
        class="protocolImg"
      />
    </div>
    <div>
      <div
        class="signText color6 font14 mt-20"
        v-if="imgData == '' || imgData==undefined"
        @click="signature"
      >
      {{$t('coll.click_sign')}}
      </div>
      <img
        :src="imgData"
        alt=""
        class="signImg mt-20"
        v-else
        @click="signature"
      />
      <div class="signCon" v-if="signShow">
        <sign @click="baseImg"></sign>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import sign from "@/components/steps/sign";

export default {
  components: {
    sign: sign,
  },
  props: {
    busProtocol: {
      type: Array,
    },
    base64: {
      type: String,
    },
  },
  data() {
    return {
      signShow: false,
      imgData:''
    };
  },
   created() {
    this.imgData=this.base64
  },
  mounted() {},
  methods: {
    signature() {
      this.signShow = true;
    },
    baseImg(data) {
      if (data != "cancel") {
        this.imgData = data;
        this.newSign = true;
        this.$emit("click", this.imgData);
      }
      this.signShow = false;
      
    },
    showImg(index) {
      ImagePreview({
        images: this.busProtocol,
        startPosition: index,
        closeable: true,
      });
    },
  },
};
</script>

<style lang="less"  scoped>
.protocol{
  pointer-events:auto
}
#signPage {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #fff;
}
.history-sign {
  position: fixed;
  left: -120px;
  top: 50%;
  transform: translateY(-50%) rotate(270deg);
}
.sign-tips {
  font-size: 100px;
  color: rgba(0, 0, 0, 0.05);
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
}
.tip-text {
  transform: rotate(-90deg);
}
.vue-esign {
  border: 1px solid rgba(214, 219, 226, 0.5);
  box-sizing: border-box;
  position: relative;
  z-index: 99;
}
.horizontal {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 30px;
  width: 100%;
  z-index: 999;
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}
.opa-part {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 60px;
  z-index: 999;
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  display: flex;
  .flex {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    .cancel-btn {
      position: absolute;
      bottom: 20px;
      right: 7px;
      transform: rotate(-90deg);
    }
    .rewrite-btn {
      position: absolute;
      top: 100px;
      right: -18px;
      width: 100px;
      transform: rotate(-90deg);
    }
    .confirm-btn {
      position: absolute;
      top: 20px;
      right: 7px;
      transform: rotate(-90deg);
    }
  }
}
</style>