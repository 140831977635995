import Vue from 'vue';

export function journalList (params) {
  return Vue.axios({
    url: '/api/parent/journal/showList',
    method: 'get',
    params: params
  })
}

export function journalItem (id, childid) {
  return Vue.axios({
    url: '/api/parent/journal/item/' + id + '/' + childid,
    method: 'get'
  })
}

export function journalItemPreview (id) {
  return Vue.axios({
    url: '/api/preview/journal/item/' + id,
    method: 'get'
  })
}

export function journalTeacherList (params) {
  return Vue.axios({
    url: '/api/parent/journal/teacherList',
    method: 'get',
    params: params
  })
}

export function journalSearchList (params) {
  return Vue.axios({
    url: '/api/parent/journal/searchList',
    method: 'get',
    params: params
  })
}
