<template>
  <div v-if="studentInfo">
    <van-notice-bar wrapable :scrollable="false" :text="studentInfo.tips" @click="openCopy"
      v-if="studentInfo.is_ds && studentInfo.tips != ''" />
    <div class="child-info">
      <div class="avatar">
        <div class="imgCropper">
          <img :src="studentInfo.photo" :alt="studentInfo.name_cn">
          <div class="cropper" v-if="!studentInfo.is_ds">
            <van-icon name="photograph" class="photograph" />
            <h5-cropper :option="option" @getbase64Data="getbase64Data($event)" />
          </div>
        </div>
      </div>
      <div v-if="studentInfo.is_legel_cn_name != 2">
        <van-row type="flex" justify="space-between">
          <van-col>法定中文名字</van-col>
          <van-col>{{ studentInfo.name_cn }}</van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
          <van-col>法定姓（拼音）</van-col>
          <van-col>{{ studentInfo.last_name_en }}</van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
          <van-col>法定名（拼音）</van-col>
          <van-col>{{ studentInfo.first_name_en }}</van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
          <van-col>昵称/英文名</van-col>
          <van-col>{{ studentInfo.nick }}</van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
          <van-col>{{ $t('student.gender') }}</van-col>
          <van-col>{{ studentInfo.gender }}</van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
          <van-col>{{ $t('student.nationality') }}</van-col>
          <van-col>{{ studentInfo.country }}</van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
          <van-col>{{ $t('student.language') }}</van-col>
          <van-col>{{ studentInfo.lang }}</van-col>
        </van-row>
        <van-row type="flex" justify="space-between" v-if="studentInfo.educational_id">
          <van-col>{{ $t('student.educational_id') }}</van-col>
          <van-col>{{ studentInfo.educational_id }}</van-col>
        </van-row>
      </div>
      <div v-else>
        <van-row type="flex" justify="space-between">
          <van-col>Legal Last Name</van-col>
          <van-col>{{ studentInfo.last_name_en }}</van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
          <van-col>Legal First Name</van-col>
          <van-col>{{ studentInfo.first_name_en }}</van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
          <van-col>Legal Middle Name</van-col>
          <van-col>{{ studentInfo.middle_name_en }}</van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
          <van-col>Preferred Name</van-col>
          <van-col>{{ studentInfo.nick }}</van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
          <van-col>Chinese Name</van-col>
          <van-col>{{ studentInfo.name_cn }}</van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
          <van-col>{{ $t('student.gender') }}</van-col>
          <van-col>{{ studentInfo.gender }}</van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
          <van-col>{{ $t('student.nationality') }}</van-col>
          <van-col>{{ studentInfo.country }}</van-col>
        </van-row>
        <van-row type="flex" justify="space-between">
          <van-col>{{ $t('student.language') }}</van-col>
          <van-col>{{ studentInfo.lang }}</van-col>
        </van-row>
        <van-row type="flex" justify="space-between" v-if="studentInfo.educational_id">
          <van-col>{{ $t('student.educational_id') }}</van-col>
          <van-col>{{ studentInfo.educational_id }}</van-col>
        </van-row>
      </div>
    </div>
    <van-action-sheet v-model="show" :actions="actions" :cancel-text="$t('global.cancel')" close-on-click-action
      @select="copyEmail" />
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast } from 'vant';
import { studentInfo, uploadPhoto } from '@/api/student'
import VueClipboard from 'vue-clipboard2'
import H5Cropper from '@/components/vueCropper.vue';
import store from "../store";

Vue.use(VueClipboard)

export default {
  name: 'StudentInfo',
  components: {
    H5Cropper
    // 'van-icon': Icon,
  },
  data() {
    return {
      studentInfo: null,
      show: false,
      actions: [{ name: this.$t('student.copy_email') }],
      option: {
        cancelButtonText: this.$t("global.cancel"),
        confirmButtonText: this.$t("global.ok"),
        info: false, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "png", // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 800, // 默认生成截图框宽度
        autoCropHeight: 800, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMove: false, // 截图能否拖动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      schoolType: ''
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color:#fff;')
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.schoolType = store.getters.wxState || 'ds'
      studentInfo(this.$route.params.id).then(res => {
        this.studentInfo = res.data
      })
    },
    openCopy() {
      this.show = true
    },
    copyEmail() {
      this.$copyText(this.studentInfo.email).then(() => {
        Toast(this.$t('student.copy_success'))
      })
    },
    getbase64Data(data) {
      this.compressBase64Image(data, 800, 800)
        .then((compressedBase64Data) => {
          this.putb64(compressedBase64Data);
        })
    },
    compressBase64Image(base64Image, targetWidth, targetHeight) {
      return new Promise((resolve, reject) => {
        let image = new Image();
        image.src = base64Image;
        image.onload = () => {
          let canvas = document.createElement('canvas');
          let context = canvas.getContext('2d');
          let scaleFactor = Math.min(targetWidth / image.width, targetHeight / image.height);
          canvas.width = image.width * scaleFactor;
          canvas.height = image.height * scaleFactor;
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
          let newBase64Image = canvas.toDataURL('image/jpeg', 1);
          resolve(newBase64Image);
        };
        image.onerror = (error) => {
          reject(error);
        };
      });
    },
    putb64(data,) {
      const that = this;
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: that.$t("pickupcard.uploading"),
      });
      uploadPhoto({ child_id: this.$route.params.id, avatar: data }).then(res => {
        if (res.code == 0) {
          this.studentInfo.photo = res.data
          Toast(that.$t("pickupcard.Uploaded"));
        } else {
          Toast.clear();
        }
      }).catch(() => {
        Toast(that.$t("pickupcard.upload_failed"));
      });
    },
  }
}
</script>

<style scoped lang="less">
.child-info {
  margin: 15px;

  .avatar {
    margin: 24px 0;
    text-align: center;

    .imgCropper {
      position: relative;
      display: inline-block;

      img {
        width: 88px;
        height: 88px;
        border-radius: 44px;
      }

      .cropper {
        width: 24px;
        height: 24px;
        background: #4D88D2;
        border: 1px solid #FFFFFF;
        color: #DCDEE0;
        border-radius: 50%;
        text-align: center;
        line-height: 24px;
        position: absolute;
        bottom: 0;
        right: 0;

        .photograph {
          font-size: 12px;
        }

        .upbtn {
          position: absolute;
          font-size: 24px;
          color: #dcdee0;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .van-row {
    height: 44px;
    line-height: 44px;
    border-bottom: 1px solid #EBEDF0;

    &:last-child {
      border-bottom: 0;
    }

    .van-col {
      font-size: 14px;
      color: #323233;

      &:first-child {
        color: #646566;
      }
    }
  }
}
</style>
