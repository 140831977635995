<template>
  <div>
    <van-empty :description="$t('pickupcard.no_student')" v-if="emptyChild" />
    <van-skeleton title :row="23" :loading="loading" v-else>
      <van-notice-bar
        v-if="cardData.audit_status==2"
        wrapable
        left-icon="volume-o"
        :scrollable="false"
        :text="cardData.audit_comments"
      />
      <van-notice-bar
        v-if="cardData.audit_status==1"
        wrapable
        left-icon="volume-o"
        :scrollable="false"
        :text="$t('pickupcard.information_complete')"
      />
      <div class="mb-10 word relative">
        <div class="bgImg">
          <van-row type="flex" justify="end">
            <switch-lang color='#377ED7' />
          </van-row>
          <div class="mb-10 mt-10" v-html="cardData.tipsText"></div> 
        </div>
      </div>
      <div class="mt-15">
          <children @change="childChange" :children="childrenList" v-if="childrenList.length>0" />
      </div>
      <div class="container">
        <div class="flex" v-if="cardData.enable_plates==1">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">{{$t('pickupcard.car_plates')}}</div>
            <div class="text mt-5">{{cardData.tips_plates}}</div>
            <div class="fr relative mt-20" @click='addCar'>
              <van-icon name="add-o" size="22" class='addIcon' />
              <span class="addText">{{$t('pickupcard.add_plate')}}</span>  
            </div>
            <div class="clearfix"></div>
            <div class="mt-15">
              <div class="flex" v-for='(list,index) in carTel' :key="index">
                <van-field class='bgGray mb-10 flex1' v-model="carTelCopy[index]" :disabled='!cardData.state || cardData.audit_status==1' :placeholder="$t('pickupcard.input_plate')" />
                <span class="flexRemove">
                  <van-icon name="delete-o"  v-if="index>0" @click='delCar(index)' size="22" class='removeIcon'/>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex mt-20">
          <div class="flexWidth"><span class="icon"></span></div>
          <div class="flex1">
            <div class="title">{{ $t('pickupcard.people') }}</div>
            <div class="text mt-5">{{cardData.tips_pickup}}</div>
          </div>
        </div>
        <div v-for='(list,index) in contact' :key="index">
          <div class="title relative mt-20 mb-15 flex">
            <van-icon name="manager" size="18" class='color6 absolute flexWidth' />
            <span class="ml-10 font15 ml-25 line18 flex1">#{{index+1}}</span>
          </div>
          <van-form class="border">
            <van-field name="uploader" :label="$t('pickupcard.photo')">
              <template #input>
                <div class="cropper">
                  <div v-if="list.head_url">
                    <img :src="list.head_url" class="img" />
                    <div class="del" @click.stop="delFile(index)"><van-icon name="cross" class="del-icon" /></div>
                  </div>
                  <div v-else>
                    <van-icon name="photograph" class="graph"/>
                    <h5-cropper :option="option" @getbase64Data="getbase64Data($event, index)" v-if="cardData.audit_status!=1" />
                  </div>
                </div>
              </template>
            </van-field>
            <van-field
              v-model="list.name"
              :name="$t('pickupcard.name')"
              :label="$t('pickupcard.name')"
              :placeholder="$t('pickupcard.name')"
              :disabled='!cardData.state || cardData.audit_status==1' 
            />
            <van-field
              v-model="list.relation"
              :name="$t('pickupcard.relation')"
              :label="$t('pickupcard.relation')"
              :placeholder="$t('pickupcard.relation')"
              :disabled='!cardData.state || cardData.audit_status==1' 
            />
            <van-field
              v-model="list.phone"
              :name="$t('pickupcard.tel')"
              :label="$t('pickupcard.tel')"
              :placeholder="$t('pickupcard.tel')"
              :disabled='!cardData.state || cardData.audit_status==1' 
              :rules="[ { pattern, message: $t('pickupcard.invalid_phone')}]"
            />
          </van-form>
        </div>
        <div class="btnBox">
          <van-button type="info" block class="saveSub" disabled v-if="!cardData.state">{{$t('pickupcard.not_edit')}}</van-button>
          <van-button type="info" block class="saveSub" @click='saveData()' v-else-if='cardData.audit_status!=1' :disabled='saveCurrent' >{{$t('global.submit')}}</van-button>
          <van-button type="info" block class="saveSub" v-else-if="cardData.audit_status==1" disabled>{{$t('pickupcard.approved')}}</van-button>
        </div>
      </div>
      <van-dialog
        v-model="isShow"
        show-cancel-button
        :beforeClose="beforeClose"
      >
        <div class="p15">
          <van-row type="flex" justify="center">
            <div class="mb-10"><strong>{{$t('pickupcard.apply_to')}}</strong></div>
          </van-row>
          <van-checkbox-group v-model="childIds"  class="mb-10">
            <van-checkbox v-for="(list,i) in childrenList" :key='i' class="mt-15" :name='list.id' :disabled="!childrenStatus[list.id]"> {{list.name}}</van-checkbox>
          </van-checkbox-group>
        </div>
      </van-dialog>
    </van-skeleton>
  </div>
</template>

<script>
import Vue from 'vue';
import { Form, Icon, Toast, Dialog, CheckboxGroup, Checkbox, Button, Row, Skeleton, Overlay} from 'vant';
import Children from '@/components/Children'
import {cardList, token, cardSave, childrenStatus } from '@/api/PickupCard'
import storage from 'store'
import { CURRENT_CHILD } from '@/store/mutation-types'
import { childList } from '@/api/child'
import SwitchLang from '@/components/SwitchLang'
import H5Cropper from '@/components/vueCropper.vue';

Vue.use(Form);
Vue.use(Icon);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Button);
Vue.use(Row);
Vue.use(Skeleton);
Vue.use(Overlay);

export default {
  name: 'Pickup',
   components: {
     H5Cropper,
    'children': Children,
    'switch-lang':SwitchLang
  },
  data() {
    return {
      show: false,
      option: {
        cancelButtonText: this.$t('global.cancel'),
        confirmButtonText: this.$t('global.ok'),
        info: false, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // autoCropWidth: 300, // 默认生成截图框宽度
        // autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMove: false, // 截图能否拖动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      childrenStatus: {},
      loading: true,
      emptyChild: false,
      fileinfo: null,
      pattern:/^[1][3,4,5,6,7,8,9][0-9]{9}$/,
      contact:[
        {
            "name": "",
            "relation": "",
            "head": "",
            "phone": "",
            "head_url": ""
        },
        {
            "name": "",
            "relation": "",
            "head": "",
            "phone": "",
            "head_url": ""
        },
        {
            "name": "",
            "relation": "",
            "head": "",
            "phone": "",
            "head_url": ""
        },
        {
            "name": "",
            "relation": "",
            "head": "",
            "phone": "",
            "head_url": ""
        }
      ],
      carTel:[''],
      carTelCopy:[''],
      uptoken:'',
      childid:'',
      isShow:false,
      childrenList:[],
      childIds:[],
      plates:[],
      pickups:[],
      saveCurrent:false,
      cardData:{}
    }
  },
  created () {
    childList().then(res => {
      this.childrenList=res.data
      this.loading = false
      let cIds = [];
      this.childrenList.map(item => {
        this.childrenStatus[item.id] = true
        cIds.push(item.id)
      })
      if (this.childrenList.length < 1) {
        this.emptyChild = true
      }
      let cid = storage.get(CURRENT_CHILD) ? storage.get(CURRENT_CHILD).id : ''
      this.childid = cIds.indexOf(cid) > -1 ? cid : cIds[0]
    })
  },
   watch:{
    'carTelCopy':{
      handler:function(val){
        for(var i=0;i<val.length;i++){
          val[i]=val[i].toUpperCase()
        }
      },   
    },
  },
  methods: {
    getCard(){
      if (this.childid != '') {
        this.childIds=[]
        this.childIds.push(this.childid)
        cardList({childId:this.childid}).then(res => {
          this.cardData=res.data
          if(res.data.plates.length>0){
            this.carTel=res.data.plates
          }else{
            this.carTel=['']
          }
          this.carTelCopy=this.carTel
          // if(res.data.pickups.length!=0){
          //    for(var i=0;i<res.data.pickups.length;i++){
          //      if(res.data.pickups[i].head==null){
          //        res.data.pickups[i].head=''
          //      }
          //   }
          //   this.contact=res.data.pickups
          // }
          this.contact.map((item, index) => {
            if (res.data.pickups[index].head && res.data.pickups[index].head != '') {
              this.contact[index] = res.data.pickups[index]
            }
          })
        })
      }
    },
    getbase64Data(data, i) {
      this.uploadToQiniu(data, i)
    },
    uploadToQiniu(data, i) {
      if(this.uptoken == '') {
        token().then(res => {
          this.uptoken = res.data.token
          this.putb64(data, i)
        })
      } else {
        this.putb64(data, i)
      }
    },
    putb64(data, i) {
      const that = this
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: that.$t('pickupcard.uploading'),
      });
      var pic = data.substring(data.indexOf(',')+1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange=function() {
        // toast.clear()
        if (xhr.readyState==4){
          var resJson = JSON.parse(xhr.responseText)
          if (resJson.code == 0) {
            that.contact[i].head = resJson.data.file_key
            that.contact[i].head_url = resJson.data.file_url
            Toast(that.$t('pickupcard.Uploaded'))
          }
          else {
            Toast(resJson.msg)
          }
        }
      }
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", 'UpToken ' + this.uptoken);
      xhr.send(pic);
    },
    delFile(i){
        this.contact[i].head='';
        this.contact[i].head_url=''
    },
    addCar(){
      if(this.carTel.length<3){
        this.carTel.push('')
        this.carTelCopy=this.carTel
      }else{
        Toast(this.$t('pickupcard.max_number'))
      }
    },
    delCar(index){
      if(this.carTel.length==1){
        return
      }
      this.carTel.splice(index,1)
    },
    childChange(val) {
      console.log(val)
      this.childid = val.id
      this.getCard()
    },
    paramsValidate(params) {
      let flag = true;
      for(var key in params){
        if(params[key].trim() == ''){
          return false;
        }
      }
      return flag;
    },
    phoneValidate(params){
      if (this.pattern.test(params)) {
        return true;
      }else{
        return false;
      }
    },
    saveData(){
      this.plates=[]
      if(this.carTel.length!=0){
        for(var k=0;k<this.carTel.length;k++){
          if(this.carTel[k].trim()!=''){
            this.plates.push(this.carTel[k].trim())
          }
        }
      }
      this.pickups=[]
      var listData=[]
      this.pickups=JSON.parse(JSON.stringify(this.contact))
      for(var i=0;i<this.pickups.length;i++){
        delete this.pickups[i].head_url;
        if(this.pickups[i].head.trim()!='' || this.pickups[i].name.trim()!='' || this.pickups[i].relation.trim()!=''  || this.pickups[i].phone.trim()!='' ){
          listData.push(this.pickups[i])
        }
      }
      if(listData.length==0){
        Toast(this.$t('pickupcard.needed'))
        return 
      }
      for(var j=0;j<listData.length;j++){
        if(!this.paramsValidate(listData[j])) {
          Toast(this.$t('pickupcard.information'))
          return 
        }
        console.log(this.phoneValidate(listData[j].phone))
        if(!this.phoneValidate(listData[j].phone)) {
          Toast(this.$t('pickupcard.invalid_phone'))
          return
        }
      }
      if(this.childrenList.length>1){
        var toast = Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: 'Loading...'
        })
        childrenStatus().then(res => {
          this.isShow = !this.isShow
          this.childrenStatus = res.data
          toast.clear()
        })
      }else{
        this.save()
      }
    },
    beforeClose(action, done) {
      if(action === 'confirm') {
          this.save(done)
      } else if(action === 'cancel') {
         done() //关闭
      }
    },
    save(done){
      if(this.childIds.length==0){
        Toast(this.$t('homeAddress.select_child'))
        done(false)
        this.saveCurrent=false
        return
      }
      this.saveCurrent=true
      let that=this
      cardSave({
        childId: this.childIds,
        plates: this.plates,
        pickups: this.pickups
      }).then(response => {
        if (response.code == 0) {
          that.cardData.audit_status=0
          Toast(response.msg || '提交成功')
          if(done){
            done()
          }
          this.saveCurrent=false
        }else{
          done(false) 
          this.saveCurrent=false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.cropper {
  width: 80px;
  height: 80px;
  line-height: 80px;
  position: relative;
  overflow: hidden;
  text-align: center;
  background-color: #f7f8fa;
  .img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .del {
    position: absolute;
    top: 0;
    right: 0;
    width: 14px;
    height: 14px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0 0 0 12px;
    .del-icon {
      position: absolute;
      top: -2px;
      right: -2px;
      color: #fff;
      font-size: 16px;
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
  }
  .graph {
    position: absolute;
    font-size: 24px;
    color: #dcdee0;
    top: 28px;
    left: 28px;
  }
}
.relative{
  position: relative;
}
.word{
    font-size: 13px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 21px;
    background:#7EA9F6;
    .bgImg{
      background-image:url('../assets/pickupCard.png');
      padding:16px;
      background-repeat:no-repeat;
      background-position:bottom right;
      min-height:110px;
    }
}
.p15{
  padding:20px
}
.container{
  background-color: #fff;
  padding:25px 16px 70px;
  font-size:12px;
  .flex{
    display: flex;
    .flexWidth{
      width: 16px;
      padding-top:5px;
      .icon{
        display: inline-block;
        width: 6px;
        height: 14px;
        background: #4D88D2;
        border-radius: 3px;
      }
    }
    .flex1{
      flex:1;
      .text{
        font-size: 12px;
        font-weight: 400;
        color: #646566;
        line-height:20px;
      }
    }
    .flexRemove{
      width: 30px;
      .removeIcon{
        margin-top: 10px;
        float: right;
      }
    }
  }
  .addIcon{
    color: #4D88D2;
    position: absolute;
    left: -18px;
  }
  .addText{
    font-size: 14px;
    font-weight: 400;
    color: #646566;
    line-height:22px;
    margin-left:8px
  }
  .bgGray{
    background: #F7F8FA;
  }
  
  .title{
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height:26px;
    .font15{
      font-size: 15px;
    }
    .absolute{
      position:absolute;
    }
    .color6{
      color: #666 !important;
    }
  }
  .border{
    border:1px solid #EBEDF0;
    border-radius:0px;
  }
  .btnBox{
    width: calc(100% - 32px);
    height: 55px;
    position: fixed;
    bottom:0px;
    background: #fff;
    .saveSub{
      height: 40px;
      background: #4D88D2;
      border-radius: 100px;
    }
  }
  
}
</style>
