<template>
  <div v-if="noticeData">
    <van-notice-bar left-icon="volume-o" :text="$t('notice.preview_model')"
      v-if="$route.name == 'PreviewNoticeContent'" />

    <div class="content">
      <van-row type="flex" justify="end">
        <switch-lang color="#377ED7" />
      </van-row>
      <van-row v-if="noticeData.level == 4">
        <img :src="expressImg" alt="" height="22" />
      </van-row>
      <van-row v-if="noticeData.level == 2">
        <img :src="important" alt="" height="22" />
      </van-row>
      <van-row v-if="noticeData.level == 1">
        <img :src="urgrnt" alt="" height="22" />
      </van-row>
      <img v-if="noticeData.expired == 1" :src="expiredImg" alt="Expired" class="Expired"
        :class="$route.name == 'PreviewNoticeContent' ? 'ExpiredTop' : ''" />
      <div class="title">{{ noticeData.title }}</div>
      <div class="sub-title mb-10">{{ noticeData.grade_text.join(" | ") }}</div>
      <van-col span="24"
        v-if="noticeData.category != 1 && noticeData.category != 0 && $route.name == 'NoticeContent' && schoolType == 'ds'"><van-tag
          type="primary" class="categoryTag mb-10" color="#E7F4FF" text-color="#4D88D2">{{
    showCategory(noticeData.category) }}</van-tag></van-col>
      <div class="sub-title mb-15">{{ noticeData.publish_at }}</div>
      <blockquote v-if="noticeData.summary">
        {{ noticeData.summary }}
      </blockquote>
      <div class="mt-15 " v-if="noticeData.sign_as">
        <router-link class="color3 flex" :to="{ name: 'NoticeSearch', query: { uid: noticeData.sign_as.uid } }">
          <div>
            <van-image round :src="noticeData.sign_as.photoUrl" class="avatar-1 mr-10 avatarImg" />
          </div>
          <div class="item-1-1 flex1">
            <div class="flex align-items">
              <div class="flex1">
                <div class="name">{{ noticeData.sign_as.name }}</div>
                <div class="sub-name">{{ noticeData.sign_as.title }}</div>
              </div>
              <div><van-icon name="arrow" class="color9" /></div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="rich-text mt-15 mb-50 notice_content" v-html="noticeData.content" @click="textClick"></div>
      <attachment :attachments="noticeData.attachments" v-if="Object.values(noticeData.attachments).length > 0"
        class="mb-50 pb-50" />
    </div>
    <div class="categoryData mt-15"
      :class="noticeData.require_response != 0 && commentList.length == 0 ? 'pb-50' : 'pb-15'"
      v-if="$route.name == 'NoticeContent' && schoolType == 'ds'">
      <div class="categoryText">{{ $t("notice.other_notice") }}</div>
      <div :class="commentList.length == 0 ? 'pb-50' : ''">
        <span class="mr-10 mt-10 tagLabel" v-for="(list, index) in categoryList" :key="index"><router-link
            class="color3" :to="{ name: 'Notice', query: { type: list.key } }">{{ list.value }}</router-link></span>
      </div>
    </div>
    <comment type="notice" :id="noticeData.id" :bottomBar="noticeData.require_response == 0 ? false : true"
      @change="commentData" v-if="noticeData.comment_enabled && $route.name != 'PreviewNoticeContent'" />
    <div v-if="noticeData.require_response == 1" class="bottom-box">
      <van-row type="flex" align="center">
        <van-col span="24">
          <van-button block round type="info" :loading="loading" @click="confirmNotice"
            v-if="noticeData.has_response == 0">{{ $t("global.confirm") }}</van-button>
          <van-button block round type="info" disabled v-if="noticeData.has_response == 1">{{ $t("global.confirmed")
            }}</van-button>
        </van-col>
      </van-row>
    </div>
    <div v-if="noticeData.require_response == 2" class="bottom-box">
      <van-row type="flex" align="center">
        <van-col span="24">
          <div v-if="$route.name == 'PreviewNoticeContent'">
            <van-button block round type="info" @click="confirmShow = true" class="height40">{{ $t("notice.to_confirm")
              }}</van-button>
          </div>
          <div v-else>
            <div class="btn-ext">
              <van-image round class="avatar-2" fit="cover" :src="noticeData.response_items[item].avatar"
                v-for="(item, index) in oChildIds" :key="index" :style="{ left: index * 14 + 'px' }" />
            </div>
            <van-button block round type="info" class="height40" disabled v-if="oChildIds.length == 0">{{
    $t("notice.done_thx") }}</van-button>
            <van-button block round type="info" @click="confirmShow = true" v-else class="height40">{{
    $t("notice.to_confirm") }}</van-button>
          </div>
        </van-col>
      </van-row>
    </div>
    <div v-if="noticeData.require_response == 3" class="bottom-box">
      <van-row type="flex" align="center">
        <van-col span="24">
          <van-button block round type="info" class="height40" disabled
            v-if="surveyIds.length == 1 && surveyData.answer && surveyData.answer[surveyIds[0]] && surveyData.answer[surveyIds[0]].status == 2">{{
    $t("notice.done_thx") }}</van-button>
          <van-button block round type="info" @click="showQuestion" class="height40" v-else>{{ $t("notice.take_survey")
            }}</van-button>
        </van-col>
      </van-row>
    </div>
    <van-action-sheet v-model="confirmShow" title="1/1" class="the-sheet">
      <div style="padding: 0 15px 60px 15px">
        <div class="title-1">{{ noticeData.confirmation.title }}</div>
        <van-radio-group v-model="radio" class="mb-10 ml-15">
          <van-radio :name="radio.id" v-for="(radio, index) in noticeData.confirmation.options" :key="index">{{
    radio.title }}</van-radio>
        </van-radio-group>
        <div class="mb-10">{{ $t("notice.memo") }}</div>
        <div class="comment-input">
          <van-field v-model="memo" rows="2" autosize label="" type="textarea" maxlength="5000"
            :placeholder="$t('notice.please_memo')" show-word-limit />
        </div>
      </div>
      <div class="sheet-bottom">
        <van-button round type="info" v-if="$route.name == 'PreviewNoticeContent'" size="small" disabled
          style="padding: 0 120px; bottom: 5px">{{ $t("global.submit") }}</van-button>
        <van-button round type="info" v-else size="small" @click="submitConfirm"
          style="padding: 0 120px; bottom: 5px">{{ $t("global.submit") }}</van-button>
      </div>
    </van-action-sheet>
    <van-dialog v-model="dialogShow" show-cancel-button :beforeClose="beforeClose">
      <div class="p20">
        <van-row type="flex" justify="center">
          <div class="mb-10">
            <strong>{{ $t("pickupcard.apply_to") }}</strong>
          </div>
        </van-row>
        <van-checkbox-group v-model="childIds" class="mb-10">
          <van-checkbox v-for="(child, i) in oChildIds" :key="i" class="mt-15" :name="child"> {{
    noticeData.response_items[child].name }}</van-checkbox>
        </van-checkbox-group>
      </div>
    </van-dialog>
    <van-action-sheet v-if="noticeData.require_response == 3 && questionChildId != null" v-model="questionShow"
      :title="showContent ? $t('notice.parent_survey') : surveyData.survey && !surveyData.survey.is_jump ? showQuestionsIndex + '/' + surveyData.survey.question_num : $t('notice.some', { index: showQuestionsIndex })"
      class="the-sheet">
      <div v-if="showContent">
        <div class="requireQuestion questionpadding">
          {{ currentQuestion.title }}
        </div>

        <div class="bottom-box">
          <van-row type="flex" align="center">
            <van-col span="24">
              <van-button block round type="info" @click="questionShow = false" class="height40">{{ $t("global.close")
                }}</van-button>
            </van-col>
          </van-row>
        </div>
      </div>
      <div v-else>
        <div class="requireQuestion questionpadding">
          <div class="title-1 pt-20">{{ currentQuestion.title }}</div>
          <van-radio-group v-model="saveAnswer.radioOption_id" class="mb-10 ml-15 font14"
            v-if="currentQuestion.is_multiple == 0">
            <van-radio :name="radio.oid" v-for="(radio, index) in currentQuestion.options" :key="index">{{ radio.title
              }}</van-radio>
          </van-radio-group>
          <van-checkbox-group v-model="saveAnswer.checkOption_id" class="mb-15 ml-15"
            v-if="currentQuestion.is_multiple == 1">
            <van-checkbox :name="radio.oid" v-for="(radio, index) in currentQuestion.options" :key="index"
              class="mt-15 checkColor font14" shape="square">{{ radio.title }}</van-checkbox>
          </van-checkbox-group>
          <div class="mb-10 mt-10" v-if="currentQuestion.is_memo == 1 || currentQuestion.is_memo == 2">
            <!-- <div class="mb-10"  v-if="currentQuestion.options">{{ $t("notice.memo") }}</div> -->
            <div class="comment-input">
              <van-field v-model="saveAnswer.memo" rows="2" autosize label="" type="textarea" maxlength="5000"
                :placeholder="$t('notice.please_memo')" show-word-limit />
            </div>
          </div>
        </div>
        <div class="bottom-box">
          <van-row gutter="20" style="padding: 5px 15px 0; bottom: 5px">
            <van-col span="12">
              <van-button type="default" block round class="height40" @click="prevQuestion"
                :disabled="showQuestionsIndex != 1 ? false : true">{{ $t("notice.prev_question") }}</van-button>
            </van-col>
            <van-col span="12">
              <van-button v-if="!lastQuestion" type="info" block round :disabled="nextBtn" color="#4d88d2"
                class="saveSub height40" @click="nextQuestion">{{ $t("notice.next_question") }}</van-button>
              <van-button v-else type="info" block round color="#4d88d2" class="saveSub height40"
                @click="nextQuestion">{{ $t("global.submit") }}</van-button>
            </van-col>
          </van-row>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="selectChild" :title="$t('notice.select_child')" class="the-sheet"
      v-if="noticeData.require_response == 3">
      <div>
        <div class="requireQuestion">
          <van-row type="flex" align="center" justify="center">
            <van-col>
              <div v-for="(child, i) in surveyIds" :key="i" class="avatar-box fl mr-15" @click="showSwitch(child)">
                <van-image round :src="noticeData.survey_child[child].avatar"
                  :class="{ active: child == questionChildId }" class="avatar-1 height48" />
                <van-icon name="checked" color="#4D88D2" v-if="child == questionChildId" />
              </div>
            </van-col>
          </van-row>
        </div>
        <div class="bottom-box">
          <van-row type="flex" align="center">
            <van-col span="24">
              <van-button block round type="info" class="height40" disabled
                v-if="surveyData.answer && surveyData.answer[questionChildId] && surveyData.answer[questionChildId].status == 2">{{
    $t("notice.done_thx") }}</van-button>
              <van-button block :disabled="questionChildId == null || questionChildId == '' ? true : false" round
                type="info" @click="startQuestion" class="height40" v-else>{{ $t("coll.Start") }}</van-button>
            </van-col>
          </van-row>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-if="noticeData.require_response == 3" v-model="viewQuestionShow"
      :title="showContent ? $t('notice.parent_survey') : surveyData.survey && !surveyData.survey.is_jump ? showQuestionsIndex + '/' + surveyData.survey.question_num : $t('notice.some', { index: showQuestionsIndex })"
      class="the-sheet">
      <div v-if="showContent">
        <div class="requireQuestion questionpadding">
          {{ currentQuestion.title }}
        </div>

        <div class="bottom-box">
          <van-row type="flex" align="center">
            <van-col span="24">
              <van-button block round type="info" @click="viewQuestionShow = false" class="height40">{{
    $t("global.close") }}</van-button>
            </van-col>
          </van-row>
        </div>
      </div>
      <div v-else>
        <div class="requireQuestion questionpadding">
          <div class="title-1 pt-20">{{ currentQuestion.title }}</div>
          <van-radio-group v-model="saveAnswer.radioOption_id" class="mb-10 ml-15 font14"
            v-if="currentQuestion.is_multiple == 0">
            <van-radio :name="radio.oid" v-for="(radio, index) in currentQuestion.options" :key="index">{{ radio.title
              }}</van-radio>
          </van-radio-group>
          <van-checkbox-group v-model="saveAnswer.checkOption_id" class="mb-15 ml-15"
            v-if="currentQuestion.is_multiple == 1">
            <van-checkbox :name="radio.oid" v-for="(radio, index) in currentQuestion.options" :key="index"
              class="mt-15 checkColor font14" shape="square">{{ radio.title }}</van-checkbox>
          </van-checkbox-group>
          <div class="mb-10 mt-10" v-if="currentQuestion.is_memo == 1 || currentQuestion.is_memo == 2">
            <!-- <div class="mb-10"  v-if="currentQuestion.options">{{ $t("notice.memo") }}</div> -->
            <div class="comment-input">
              <van-field v-model="saveAnswer.memo" rows="2" autosize label="" type="textarea" maxlength="5000"
                :placeholder="$t('notice.please_memo')" show-word-limit />
            </div>
          </div>
        </div>
        <div class="bottom-box">
          <van-row gutter="20" style="padding: 5px 15px 0; bottom: 5px">
            <van-col span="12">
              <van-button type="default" block round class="height40" @click="viewPrevQuestion"
                :disabled="showQuestionsIndex != 1 ? false : true">{{ $t("notice.prev_question") }}</van-button>
            </van-col>
            <van-col span="12">
              <van-button v-if="!lastQuestion" type="info" block round :disabled="nextBtn" color="#4d88d2"
                class="saveSub height40" @click="viewNextQuestion">{{ $t("notice.next_question") }}</van-button>
              <van-button v-else type="info" block round color="#4d88d2" class="saveSub height40"
                @click="viewNextQuestion">{{ $t("global.submit") }}</van-button>
            </van-col>
          </van-row>
        </div>
      </div>
    </van-action-sheet>
    <van-dialog v-model="qrcodeShow" :title="$t('notice.Video_Link')" :show-cancel-button="false"
      :show-confirm-button="false">
      <div class="noticeQrcodeLink">
        <div class="color6 font12 text-center mb-15">{{ $t('notice.download_or_QR') }}</div>
        <vue-qr ref="qrCode" :logoSrc="logoUrl" :text="currentLink" :logoScale="0.2" :logoMargin="2"
        :logoCornerRadius="2" :size="144" :margin="0" :correntLevel="1" />
        <div class="noticeCopyLink">{{ currentLink }}</div>
      </div>
      <van-row type="flex" justify="center" class="buttonCopy">
        <van-col span="12" @click="cancelCopy">{{$t('global.close')}}</van-col>
        <van-col span="12" class="btnText" @click="copyLink" v-html="copyText"></van-col>
      </van-row>
    </van-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { NoticeBar, Divider, Toast, ImagePreview, RadioGroup, Radio, Field, Dialog } from "vant";
import Comment from "@/components/Comment";
import Attachment from "@/components/Attachment";
import { noticeItem, noticeItemPreview, confirmNotice, saveConfirm, saveSurvey, saveViewSurvey } from "@/api/notice";
import { getimgsrc } from "@/plugins/util";
import SwitchLang from "@/components/SwitchLang";
import storage from "store";
import store from "../store";
import vueQr from 'vue-qr'
import { APP_LANGUAGE, CURRENT_CAMPUS } from "@/store/mutation-types";
Vue.use(NoticeBar);
Vue.use(Divider);
Vue.use(Toast);
Vue.use(Dialog);
export default {
  name: "NoticeContent",
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    attachment: Attachment,
    comment: Comment,
    "switch-lang": SwitchLang,
    "van-field": Field,
    "van-radio": Radio,
    "van-radio-group": RadioGroup,
    vueQr
  },
  data() {
    return {
      loading: false,
      noticeData: null,
      confirmShow: false,
      radio: "",
      dialogShow: false,
      oChildIds: [],
      childIds: [],
      memo: "",
      expiredImg: "",
      expressImg: "",
      questionShow: false,
      surveyData: {},
      currentQuestion: {},
      questionsIndex: null,
      showQuestionsIndex: null,
      saveAnswer: {
        radioOption_id: "",
        checkOption_id: [],
        memo: "",
      },
      lastQuestion: false,
      lastContent: false,
      questionChildId: null,
      showContent: false,
      surveyIds: [],
      selectChild: false,
      nextBtn: false,
      viewQuestionShow: false,
      viewAnswerData: {},
      viewAnswerLen: [],
      categoryList: [],
      commentList: [],
      schoolType: 'ds',
      qrcodeShow: false,
      currentLink: '',
      copyText: '',
      logoUrl: ''
    };
  },
  created() {
    this.schoolType = store.getters.wxState || 'ds'
    let cuLang = storage.get(APP_LANGUAGE) || "zh";
    this.expressImg = require("../assets/happenings_" + cuLang + "@2x.png");
    this.important = require("../assets/important_" + cuLang + ".png");
    this.urgrnt = require("../assets/urgrnt_" + cuLang + ".png");
    if (cuLang == "en") {
      this.expiredImg = "http://m2.files.ivykids.cn/cloud01-file-8025768FpRD26R7F4PGAoCaE-vnvfTjjbO0.png";
    } else {
      this.expiredImg = "http://m2.files.ivykids.cn/cloud01-file-8025768FhS0-jBWrxm_CpOeYMI9mWOg_hOy.png";
    }
    if (this.$route.name == "PreviewNoticeContent") {
      noticeItemPreview(this.$route.params.id).then((res) => {
        this.noticeData = res.data;
        this.surveyData = res.data.survey_data;
        this.viewLink()
      });
    } else {
      noticeItem(this.$route.params.id).then((res) => {
        this.noticeData = res.data;
        this.viewLink()
        this.categoryList = [
          {
            key: "",
            value: this.$t("global.All"),
          },
        ];
        storage.set(CURRENT_CAMPUS, res.data.school_id, 60);
        this.categoryList = this.categoryList.concat(res.data.categoryList);
        this.surveyData = res.data.survey_data;
        let surveyIds = Object.keys(this.noticeData.survey_child);
        surveyIds.map((res) => {
          this.surveyIds.push(parseInt(res));
        });
        let ids = Object.keys(this.noticeData.response_items);
        ids.map((res) => {
          this.oChildIds.push(parseInt(res));
        });
      });
    }
  },
  watch: {
    saveAnswer: {
      handler: function (newVal) {
        let optionId = "";
        if (this.currentQuestion.is_multiple == 1) {
          optionId = newVal.checkOption_id[0];
        } else {
          optionId = newVal.radioOption_id;
        }
        let option = {};
        if (this.currentQuestion.options) {
          this.currentQuestion.options.map((item) => {
            if (item.oid == optionId) {
              option = item;
            }
          });
          if (option.jump_id == 0) {
            this.lastQuestion = true;
            this.lastContent = false;
          } else {
            let findList = this.findQuestion(option.jump_id).question;
            if (findList.jump_id == 0 && findList.type == "content") {
              this.lastContent = true;
              this.lastQuestion = true;
            } else {
              this.lastContent = false;
              this.lastQuestion = false;
            }
          }
        } else {
          let findList = this.findQuestion(this.currentQuestion.jump_id).question;
          if (findList.jump_id == 0 && findList.type == "content") {
            this.lastContent = true;
            this.lastQuestion = true;
          } else {
            this.lastContent = false;
            this.lastQuestion = false;
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    window.showVideo = this.showVideo
  },
  methods: {
    viewLink() {
      let text = "</video><div class='noticeCopyRight'><span class='noticeCopyVideo'><span class='iconfont iconfont-copy'></span> " + this.$t('notice.Video_Link') + "</span> </div>"
      const regex = /<\/video>/gi;
      const html = this.noticeData.content.replace(regex, text);
      this.noticeData.content = html
      this.$nextTick(() => {
        var newEle = document.getElementsByClassName('noticeCopyVideo')
        for (let i = 0; i < newEle.length; i++) {
          newEle[i].onclick = () => {
            this.showVideo(i)
          };
        }
      });
    },
    getvideoFromRichtext(html) {
      const regex = /<source.*?src=['"](.*?)['"].*?>/g;
      const srcList = [];
      let match;
      while ((match = regex.exec(html)) !== null) {
        srcList.push(match[1]);
      }
      return srcList;
    },
    showVideo(index) {
      if (this.schoolType == 'ds') {
        this.logoUrl = require('@/assets/qr_ds_logo.png');
      } else {
        this.logoUrl = require('@/assets/qr_ivy_logo.png');
      }
      this.copyText = this.$t('notice.Copy_Link')
      this.currentLink = this.getvideoFromRichtext(this.noticeData.content)[index]
      this.qrcodeShow = true
      // this.$nextTick(function () {
      //   if (document.getElementById('noticeQrcode')) {
      //     document.getElementById('noticeQrcode').innerHTML = ''
      //     new QRCode(document.getElementById('noticeQrcode'), {
      //       text: this.currentLink, // 二维码内容
      //       width: 144,
      //       height: 144,
      //       colorDark: '#000000', // 二维码颜色
      //       colorLight: '#ffffff', // 二维码背景色
      //       correctLevel: QRCode.CorrectLevel.L,
      //     })
      //   }
      // })
      // }
    },
    cancelCopy() {
      this.qrcodeShow = false
    },
    copyLink() {
      var input = document.createElement("input"); // 创建input对象
      input.value = this.currentLink; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.copyText = '<span class="iconfont iconfont-success"></span> ' + this.$t("notice.Copied")
      setTimeout(() => {
        this.qrcodeShow = false
      }, 1000);
    },
    handleClick() {
      alert('Button clicked!');
    },
    commentData(item) {
      this.commentList = item;
    },
    showCategory(id) {
      return this.categoryList.find((item2) => item2.key === id).value;
    },
    confirmNotice() {
      this.loading = true;
      confirmNotice(this.$route.params.id).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.$set(this.noticeData, "has_response", 1);
          Toast(res.msg || "确认成功");
        }
      });
    },
    textClick(e) {
      if (e.target.tagName.toUpperCase() === "IMG") {
        this.imgs = getimgsrc(this.noticeData.content);
        let index = this.imgs.indexOf(e.target.src);
        ImagePreview({
          images: this.imgs,
          closeable: true,
          startPosition: index,
        });
      }
    },
    submitConfirm() {
      if (this.radio != "") {
        if (this.oChildIds.length == 1) {
          if (this.childIds.indexOf(this.oChildIds[0]) == -1) {
            this.childIds.push(this.oChildIds[0]);
          }
          this.save();
        } else if (this.oChildIds.length > 1) {
          this.dialogShow = true;
        }
      } else {
        Toast(this.$t("notice.please_option"));
      }
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        if (this.childIds.length > 0) {
          this.save();
        } else {
          Toast(this.$t("notice.please_child"));
          done(false);
        }
      } else if (action === "cancel") {
        done();
      }
    },
    save() {
      saveConfirm({
        id: this.noticeData.id,
        option_id: this.radio,
        memo: this.memo,
        childid: this.childIds,
      }).then((res) => {
        if (res.code == 0) {
          this.childIds.map((item) => {
            this.oChildIds.splice(this.oChildIds.indexOf(item), 1);
          });
          this.radio = "";
          this.memo = "";
          this.childIds = [];
          this.confirmShow = false;
          this.dialogShow = false;
          Toast(this.$t("notice.done_thx"));
        } else if (res.code == 1) {
          res.data.map((item) => {
            this.oChildIds.splice(this.oChildIds.indexOf(item), 1);
          });
          this.childIds = [];
          if (this.oChildIds.length > 0) {
            this.dialogShow = false;
            let childName = "";
            res.data.map((item) => {
              childName += this.noticeData.response_items[item].name + ", ";
            });
            Toast(
              this.$t("notice.error2", {
                name: childName.substring(0, childName.length - 2),
              })
            );
          } else {
            this.radio = "";
            this.memo = "";
            this.confirmShow = false;
            this.dialogShow = false;
            Toast(this.$t("notice.error1"));
          }
        }
      });
    },
    showQuestion() {
      if (this.$route.name == "PreviewNoticeContent") {
        this.showQuestionsIndex = 1;
        this.questionsIndex = 0;
        this.currentQuestion = this.noticeData.survey_data.questions[0];
        this.viewQuestionShow = true;
        this.showContent = false;
        this.viewAnswerLen = [];
        this.viewAnswerData = {};
        return;
      }

      if (this.surveyIds.length == 1) {
        this.questionChildId = this.oChildIds[0];
        this.startQuestion();
      } else {
        this.questionChildId = null;
        this.selectChild = true;
      }
    },
    startQuestion() {
      if (this.questionChildId == null || this.questionChildId == "") {
        return;
      }
      this.showContent = false;
      this.selectChild = false;
      let findQuestion = this.findQuestion(this.surveyData.answer[this.questionChildId].current_question_id);
      this.currentQuestion = findQuestion.question;
      this.questionsIndex = this.surveyData.answer[this.questionChildId].answer_sort.length;
      this.showQuestionsIndex = this.questionsIndex + 1;
      this.questionShow = true;
    },
    showSwitch(child) {
      this.questionChildId = child;
    },
    findQuestion(id) {
      let question = {};
      let questionIndex = "";
      this.surveyData.questions.map((item, index) => {
        if (item.qid == id) {
          question = item;
          questionIndex = index;
        }
      });
      return { question: question, index: questionIndex };
    },
    viewPrevQuestion() {
      let prevId = this.viewAnswerLen[this.questionsIndex - 1];
      // let currentId = this.viewAnswerLen[this.questionsIndex];
      let finQuestion = this.findQuestion(prevId);
      var prevQuestions = finQuestion.question;
      this.currentQuestion = prevQuestions;
      this.saveAnswer.memo = this.viewAnswerData[this.currentQuestion.qid].memo;
      if (this.currentQuestion.is_multiple == 1) {
        this.saveAnswer.checkOption_id = this.viewAnswerData[this.currentQuestion.qid].option_id;
      } else {
        this.saveAnswer.radioOption_id = this.viewAnswerData[this.currentQuestion.qid].option_id[0];
      }
      this.viewAnswerLen.splice(this.questionsIndex, 1);
      this.questionsIndex = Object.keys(this.viewAnswerLen).length - 1;
      this.showQuestionsIndex = this.questionsIndex + 1;
    },
    viewNextQuestion() {
      var dataList = {};
      this.nextBtn = true;
      if (this.currentQuestion.options) {
        let option_id = [];
        if (this.currentQuestion.is_multiple == 1) {
          option_id = this.saveAnswer.checkOption_id;
        } else {
          option_id = this.saveAnswer.radioOption_id != "" ? [this.saveAnswer.radioOption_id] : [];
        }
        dataList = {
          notice_id: this.noticeData.id,
          question_id: this.currentQuestion.qid,
          option_id: option_id,
          emo: this.saveAnswer.memo,
        };
      } else {
        dataList = {
          notice_id: this.noticeData.id,
          question_id: this.currentQuestion.qid,
          emo: this.saveAnswer.memo,
          option_id: [],
        };
      }
      if (this.currentQuestion.is_required == 1 && dataList.option_id.length == 0) {
        Toast(this.$t("notice.please_option"));
        this.nextBtn = false;
        return;
      }
      if (this.currentQuestion.is_memo == 2 && this.saveAnswer.memo == "") {
        Toast(this.$t("notice.please_memo"));
        this.nextBtn = false;
        return;
      }
      saveViewSurvey(dataList).then((res) => {
        if (res.code == 0) {
          this.nextBtn = false;
          this.selectChild = false;
          this.viewAnswerData[this.currentQuestion.qid] = {};
          this.viewAnswerData[this.currentQuestion.qid] = dataList;
          let finQuestion = this.findQuestion(res.data);
          if (this.viewAnswerLen.indexOf(this.currentQuestion.qid) == -1) {
            this.viewAnswerLen.push(this.currentQuestion.qid);
          }
          if (finQuestion.question.is_end) {
            this.lastContent = true;
            this.lastQuestion = true;
          }
          if (!this.lastQuestion && !this.lastContent) {
            this.currentQuestion = finQuestion.question;
            this.questionsIndex = Object.keys(this.viewAnswerLen).length;
            this.showQuestionsIndex = this.questionsIndex + 1;
            this.saveAnswer = {
              radioOption_id: "",
              checkOption_id: [],
              memo: "",
            };
          } else if (this.lastQuestion && this.lastContent) {
            this.showContent = true;
            this.currentQuestion = finQuestion.question;
          } else if (this.lastQuestion && !this.lastContent) {
            Toast(res.msg || "提交成功");
            this.questionShow = false;
          }
        } else {
          this.nextBtn = false;
        }
      });
    },
    prevQuestion() {
      let prevId = this.surveyData.answer[this.questionChildId].answer_sort[this.questionsIndex - 1];
      let finQuestion = this.findQuestion(prevId);
      var prevQuestions = finQuestion.question;
      this.currentQuestion = prevQuestions;
      this.questionsIndex = this.questionsIndex - 1;
      this.showQuestionsIndex = this.questionsIndex + 1;
      this.answerData();
    },
    answerData() {
      this.saveAnswer.memo = this.surveyData.answer[this.questionChildId].answer_detail[this.currentQuestion.qid].memo;
      if (this.currentQuestion.is_multiple == 1) {
        this.saveAnswer.checkOption_id = this.surveyData.answer[this.questionChildId].answer_detail[this.currentQuestion.qid].option;
      } else {
        this.saveAnswer.radioOption_id = this.surveyData.answer[this.questionChildId].answer_detail[this.currentQuestion.qid].option[0];
      }
    },
    nextQuestion() {
      var dataList = {};
      this.nextBtn = true;
      if (this.currentQuestion.options) {
        let option_id = [];
        if (this.currentQuestion.is_multiple == 1) {
          option_id = this.saveAnswer.checkOption_id;
        } else {
          option_id = this.saveAnswer.radioOption_id != "" ? [this.saveAnswer.radioOption_id] : [];
        }
        dataList = {
          child_id: this.questionChildId,
          notice_id: this.noticeData.id,
          question_id: this.currentQuestion.qid,
          option_id: option_id,
          memo: this.saveAnswer.memo,
        };
      } else {
        dataList = {
          child_id: this.questionChildId,
          notice_id: this.noticeData.id,
          question_id: this.currentQuestion.qid,
          memo: this.saveAnswer.memo,
          option_id: [],
        };
      }
      if (this.currentQuestion.is_required == 1 && dataList.option_id.length == 0) {
        Toast(this.$t("notice.please_option"));
        this.nextBtn = false;
        return;
      }
      if (this.currentQuestion.is_memo == 2 && this.saveAnswer.memo == "") {
        Toast(this.$t("notice.please_memo"));
        this.nextBtn = false;
        return;
      }
      saveSurvey(dataList).then((res) => {
        if (res.code == 0) {
          this.nextBtn = false;
          this.selectChild = false;
          this.surveyData.answer[this.questionChildId] = res.data.answerData[this.questionChildId];
          let finQuestion = this.findQuestion(this.surveyData.answer[this.questionChildId].current_question_id);
          if (finQuestion.question.is_end) {
            this.lastContent = true;
            this.lastQuestion = true;
          }
          if (!this.lastQuestion && !this.lastContent) {
            this.currentQuestion = finQuestion.question;
            this.questionsIndex = this.surveyData.answer[this.questionChildId].answer_sort.length;
            this.showQuestionsIndex = this.questionsIndex + 1;
            this.saveAnswer = {
              radioOption_id: "",
              checkOption_id: [],
              memo: "",
            };
          } else if (this.lastQuestion && this.lastContent) {
            this.showContent = true;
            this.currentQuestion = finQuestion.question;
          } else if (this.lastQuestion && !this.lastContent) {
            Toast(res.msg || "提交成功");
            this.questionShow = false;
          }
        } else {
          this.nextBtn = false;
        }
      });
    },
  },
};
</script>
<style lang="less">
.align-items {
  align-items: center;
}

.p16 {
  padding: 16px;
}

.notice_content ol,
.notice_content ul {
  margin: auto !important;
  list-style: revert;
  padding: revert !important;
}
</style>
<style lang="less" scoped>
.categoryData {
  padding-top: 15px;
  padding-left: 18px;
  padding-right: 18px;
  background-color: #fff;

  .categoryText {
    font-size: 15px;
    font-weight: bold;
    color: #323233;
    margin-bottom: 5px;
  }

  .tagLabel {
    display: inline-block;
    padding: 6px 12px;
    background: #f7f8fa;
    border-radius: 16px;
    margin-right: 8px;
    font-size: 13px;
    color: #323233;
  }
}

.content {
  background-color: #fff;
  padding: 15px;

  .Expired {
    position: absolute;
    right: 16px;
    top: 66px;
    width: 48px;
    height: 48px;
  }

  .ExpiredTop {
    top: 106px;
  }

  .title {
    padding: 0 0 10px 0;
    font-size: 17px;
    font-weight: bold;
    color: #323233;
    line-height: 26px;
    position: relative;
  }

  .categoryTag {
    padding: 1px 5px;
  }

  .sub-title {
    font-size: 12px;
    font-weight: 400;
    color: #969799;
    line-height: 12px;
  }

  blockquote {
    margin: 16px 0 0;
    padding: 16px;
    background-color: #f7f8fa;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
    color: #646566;
    line-height: 22px;
  }

  .avatarImg {
    width: 44px;
    height: 44px;
  }
}

.p20 {
  padding: 20px;
}

.bottom-box {
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ebedf0;

  .van-row {
    padding: 0 16pt;

    .van-button {
      margin: 5pt 0;
    }

    .van-icon {
      font-size: 24px;
      color: #646566;
    }
  }

  .btn-ext {
    position: absolute;
    top: 16px;
    left: 36px;
    z-index: 9;
  }
}

.the-sheet {
  .van-radio {
    margin-bottom: 16px;
  }

  .sheet-bottom {
    position: absolute;
    bottom: 0;
    height: 43px;
    line-height: 43px;
    text-align: center;
    width: 100%;
    border-top: 1px solid #ebedf0;
  }

  .title-1 {
    font-size: 15px;
    font-weight: 600;
    color: #323233;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .comment-input {
    background-color: #fafafa;

    .van-cell {
      background-color: transparent;
    }
  }
}

.height40 {
  height: 40px;
}

.height48 {
  height: 48px;
  width: 48px;
}

.requireQuestion {
  min-height: 200px;
  padding: 15px;
  line-height: 200px;
}

.questionpadding {
  padding: 0 15px 60px 15px !important;
}
</style>

<style lang="less">
.checkColor .van-checkbox__label {
  color: #323233 !important;
}

.content {
  .item-1-1 {
    .name {
      font-size: 14px;
      font-weight: bold;
      color: #323233;
      margin-bottom: 3px;
      margin-top: 3px;
    }

    .sub-name {
      font-size: 13px;
      font-weight: 400;
      color: #646566;
    }
  }

  .rich-text {
    font-size: 14px;
    font-weight: 400;
    color: #323233;
    line-height: 28px;
    overflow: hidden;

    & img {
      max-width: 100%;
      margin-bottom: 1em;
    }

    & video {
      width: 100%;
      max-width: 100%;
    }
  }
}

.buttonCopy {
  text-align: center;
  height: 55px;
  line-height: 55px;
  border-top: 1px solid #ebedf0;

  .btnText {
    border-left: 1px solid #ebedf0;
    color: #4D88D2
  }
}
</style>

<style lang="less">
.content {
  .avatar-1 {
    img {
      object-fit: cover;
    }
  }
}

.bottom-box {
  .avatar-2 {
    position: absolute;

    img {
      border: 1px solid #fff;
      width: 18px;
      height: 18px;
    }
  }
}

.noticeCopyRight {
  text-align: right;
  margin-top: -15px;

  .noticeCopyVideo {
    margin: 0;
    color: #4D88D2;
    font-size: 14px;

  }
}

.noticeQrcodeLink {
  padding: 4px 24px 24px;
  text-align: center;

  .noticeCopyLink {
    font-size: 14px;
    color: #323233;
    line-height: 20px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #EBEDF0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px 12px;
    margin-top: 12px;
  }

  #noticeQrcode {
    margin: 0 auto;
    text-align: center;
    width: 144px
  }
}
</style>
