<template>
  <div :class="stepStatus == 4 && dataDetails.isCommon==1? 'clickNone' : ''">
    <div class="color6 font14 mt-20">{{ $t("coll.first_lang") }}</div>
    <van-field
      v-model="first_lang_title"
      is-link
      readonly
      class="bgGray mt-10"
      :placeholder="$t('coll.choose')"
      @click="showPicker('first_lang')"
    />
    <div class="color6 font14 mt-20">{{ $t("coll.home_lang") }}</div>
    <van-field
      v-model="family_lang_title"
      is-link
      readonly
      class="bgGray mt-10"
      :placeholder="$t('coll.choose')"
      @click="showPicker('family_lang')"
    />
    <div class="color6 font14 mt-20">{{ $t("coll.speak_lang") }}</div>
    <van-field
      v-model="child_lang_title"
      is-link
      readonly
      class="bgGray mt-10"
      :placeholder="$t('coll.choose')"
      :default-index="index"
      @click="showPicker('child_lang')"
    />

    <van-popup v-model="showLan" round position="bottom">
      <van-picker
        :title="$t('coll.choose')"
        :columns="columns"
        @cancel="showLan = false"
        @confirm="onConfirm"
        value-key="title"
        ref="picker1"
        id="picker1"
        show-toolbar
      />
    </van-popup>
    <div v-if="dataDetails.protocol.length != 0">
      <protocol-sign
        @click="baseImg"
        :busProtocol="dataDetails.protocol"
        :base64="base64"
      ></protocol-sign>
    </div>
    <div class="mt-20">
      <van-button
        v-if="stepStatus == 4 && dataDetails.isCommon==1"
        disabled
        type="info"
        block
        round
        color="#4d88d2"
        class="saveSub"
        >{{ $t("coll.step_reviewed") }}</van-button
      >
      <a
        href="javascript:;"
        v-else
        @click="submitData()"
        class="saveBtnStep"
        :class="btnDisabled ? 'disabledSubmit' : ''"
        >{{ $t("global.submit") }}</a
      >
    </div>
  </div>
</template>

<script>
import ProtocolSign from "@/components/steps/ProtocolSign";
import Vue from "vue";
import { Toast } from "vant";
export default {
  props: {
    dataDetails: {
      type: Object,
    },
    status: {
      type: Number,
    },
    disabled: {
      type: Number,
    },
  },
  components: {
    "protocol-sign": ProtocolSign,
  },
  name: "homeAddress",
  data() {
    return {
      lanData: "",
      stepStatus: "",
      show: false,
      showLan: false,
      columns: [],
      value: "",
      type: "",
      index: "",
      child_lang_title: "",
      first_lang_title: "",
      family_lang_title: "",
      btnDisabled: false,
      base64: "",
      newSign: false,
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
  },
  created() {
    this.stepStatus = this.status;
    this.lanData = this.dataDetails.data;
    for (var key in this.dataDetails.langList) {
      this.columns.push({
        id: key,
        title: this.dataDetails.langList[key],
      });
    }
    this.child_lang_title = this.dataDetails.langList[this.lanData.child_lang];
    this.first_lang_title = this.dataDetails.langList[this.lanData.first_lang];
    this.family_lang_title =
      this.dataDetails.langList[this.lanData.family_lang];
    if (this.dataDetails.sign_url != "") {
      this.base64 = this.dataDetails.sign_url;
    }
  },
  methods: {
    showPicker(type) {
      this.type = type;
      this.showLan = true;
      let that = this;
      this.$nextTick(() => {
        that.index = this.lanIndex(that.lanData[that.type]);
        that.$refs.picker1.setIndexes([that.index]);
      });
    },
    lanIndex(id) {
      for (var i = 0; i < this.columns.length; i++) {
        if (this.columns[i].id == id) {
          return i;
        }
      }
    },
    onConfirm(value) {
      this.lanData[this.type] = value.id;
      this[this.type + "_title"] = value.title;
      this.showLan = false;
    },
    baseImg(data) {
      if (data != "cancel") {
        this.base64 = data;
        this.newSign = true;
      }
    },
    submitData() {
      let that = this;
      if (this.btnDisabled) {
        return;
      }
      if(that.lanData.first_lang==''){
        Toast(this.$t('coll.choose')+this.$t("coll.first_lang"));
        return;
      }
      if(that.lanData.family_lang==''){
        Toast(this.$t('coll.choose')+this.$t("coll.home_lang"));
        return;
      }
      if(that.lanData.child_lang==''){
        Toast(this.$t('coll.choose')+this.$t("coll.speak_lang"));
        return;
      }
      if (that.dataDetails.protocol.length != 0 && this.base64 == "") {
        Toast(that.$t("coll.please_sign"));
        return;
      }
      this.$nextTick(function () {
        this.btnDisabled = true
      })
      if (that.dataDetails.protocol.length == 0) {
        that.saveData();
        return;
      }
      if (!that.newSign) {
        that.saveData();
        return;
      }
      var pic = this.base64.substring(this.base64.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // toast.clear()
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that.lanData, "sign_url", resJson.data.url);
            Vue.set(that.lanData, "sign", resJson.data.key);
            that.saveData();
          } else {
            Toast(resJson.msg);
            that.btnDisabled = false;
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.dataDetails.signQiniuToken);
      xhr.send(pic);
    },
    saveData() {
      this.$emit("change", this.lanData);
    },
  },
};
</script>

<style lang="less" scoped>
</style>