import Vue from 'vue';

export function classmates (childid) {
  return Vue.axios({
    url: '/api/child/classmates/' + childid,
    method: 'get'
  })
}

export function studentInfo (childid) {
  return Vue.axios({
    url: '/api/child/detail/' + childid,
    method: 'get'
  })
}

export function uploadPhoto(data) {
  return Vue.axios({
    url: '/api/child/uploadPhoto',
    method: 'post',
    data: data
  })
}