<template>
  <div>
    <div class="text-center pb-20 bgWhite">
      <img class="img" src="../../assets/completed.png" alt="" />
      <div class="font17 color3 mt-10 font600"> 填写完成</div>
      <div class="color6 font14  pb-15 pr-45 pl-45">{{success_content}}</div>
      <router-link
        :to="`/collection/index/${coll_id}`"
        class="flex1"
      >
        <van-button round type="info" size="small" class="mb-10">返回首页</van-button>
      </router-link>
    </div>
    <div class="mt-10 bgWhite p16">
      <div class="color3 font14 mb-20 mt-5 font600">
        共{{ stepList.length }}步，已全部提交
      </div>
      <div class="van-steps van-steps--vertical">
        <div
          class="van-hairline van-step van-step--vertical van-step--process"
          v-for="(item, idx) in stepList"
          :key="idx"
        >
          <div class="van-step__title">
            <div class="flex">
              <span class="flex1 font14 color3">{{ item.title }}</span>
              <van-tag
                color="#F2F4F9"
                text-color="#666666"
                round
                type="primary"
                >{{ statusList[item.status] }}</van-tag
              >
            </div>
          </div>
          <div class="van-step__circle-container">
            <i
              class="
                van-icon van-icon-checked
                van-step__icon van-step__icon--active
                font14
              "
            ></i>
          </div>
          <div class="van-step__line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { childrenStatus } from "@/api/collection";

export default {
  name: "homeAddress",
  data() {
    return {
      coll_id: this.$route.params.id,
      childid: this.$route.params.childid,
      stepList: {},
      statusList: [],
      success_content:'',
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
  },
  created() {
    this.coll_id = this.$route.params.id;
    childrenStatus(this.coll_id).then((res) => {
      this.stepList = res.data.childrenStatus[this.childid].steps;
      this.statusList = res.data.stepStatusList;
      this.success_content=res.data.success_content
    });
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.img {
  width: 130px;
  margin-top: 32px;
}
.bgWhite {
  background: #fff;
}
.p16 {
  padding: 16px;
}
.van-step--vertical:not(:last-child)::after {
  border: none;
}
.labelBage {
  padding: 5px 10px;
  background: #f2f4f9;
  border-radius: 12px;
  display: inline-block;
}
.font600{
  font-weight: 600;
}
</style>