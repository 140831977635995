<template>
  <div class="list">
    <van-row v-if="pageData">
      <van-row class="item-1">
        <van-row class="title">
          <div>
            <img src="@/assets/logo.png" alt="Logo" class="logo" />
          </div>
          <div>
            <strong class="font16 color3">启明星学校（北皋校区）</strong>
          </div>
        </van-row>
        <a class="files" :href="item.url" v-for="(item, id) in pageData" :key="id">
          <van-row class="flex">
            <img :src="`http://mega.ivymik.cn/file-icon/223017b546a29cfe7aa7f1efcefc0b88.png`" />
            <div class="flex flexCenter flex1">
              <div class="ml-8  flex1">
              <div class="font15 fontWeight">{{ item.title }}</div> 
            </div>
            <van-icon name="arrow" color="#969799" style="vertical-align: middle" />
            </div>
            
          </van-row>
        </a>
      </van-row>
    </van-row>
    <van-row v-else>
      <van-empty :description="$t('global.empty')" />
    </van-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Cell, Icon, Picker, Col, Row, ActionSheet, Empty, Toast, Sticky } from "vant";
import i18n from '../i18n'

Vue.use(Cell);
Vue.use(Icon);
Vue.use(Picker);
Vue.use(Toast);
Vue.use(Col);
Vue.use(Row);
Vue.use(ActionSheet);
Vue.use(Empty);
Vue.use(Sticky);

export default {
  name: "SPAC",
  components: {},
  data() {
    return {
      pageData: null
    };
  },
  created() {
    const title1 = i18n.locale == 'zh' ? '关于我们' : 'About Us';
    const url1 = i18n.locale == 'zh' ? 'https://m2.files.ivykids.cn/cloud01-file-5FpuIa7hmednyzlwJ61BfdUFFB8d5.pdf'
                : 'https://m2.files.ivykids.cn/cloud01-file-5Fmby4rnoXxM3A6LBT0YYMyM6lg_e.pdf';
    const title2 = i18n.locale == 'zh' ? '委员会架构' : 'Structures';
    const url2 = i18n.locale == 'zh' ? 'https://m2.files.ivykids.cn/cloud01-file-5FrktgzimHGJJ9uUFpK6MEdSJUhUP.pdf'
                : 'https://m2.files.ivykids.cn/cloud01-file-5FgtuB-Vhs1HfP8vMprS7eMo-kBOF.pdf';
    const title3 = i18n.locale == 'zh' ? '志愿者招募' : 'Volunteers Call Up';
    const url3 = i18n.locale == 'zh' ? 'https://m2.files.ivykids.cn/cloud01-file-5FtzQNEzdmji3gjUlN2E01rWVcY2v.pdf'
                : 'https://m2.files.ivykids.cn/cloud01-file-5FnJNGnv_-ep-6Kq3v-OCpKYqGtaY.pdf';

    this.pageData = [
        {
          'id': 1,
          'title': title1,
          'url': url1
        },
        {
          'id': 2,
          'title': title2,
          'url': url2
        },
        {
          'id': 3,
          'title': title3,
          'url': url3
        }
      ];
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.schoolYear {
  line-height: 52px;
  padding-right: 16px;
  margin-right: 16px;
  position: relative;
  .open {
    position: absolute;
    right: 0;
    top: 18px;
  }
}
.list {
  min-height: 100vh;
  background: #fff;
  .item-1 {
    margin: 0 16px;
    .title {
      font-size: 15px;
      margin: 0 auto;
      text-align: center;
      padding-top: 32px;
      padding-bottom: 8px;
      img {
        width: 50px;
      }
    }
    .files {
      color: #323233;
      font-size: 14px;
      background: #f7f8fa;
      margin-top: 16px;
      padding: 12px;
      display: block;
      border-radius: 5px;
      .flex{
        img {
          height: 16px;
          margin-top: 2px;
        }
      }
      .flexCenter {
        align-items: center;
        .flex1 {
          margin-left: 8px;
        }
      }
      .fontWeight{
        font-weight: 500;
      }
    }
  }
}
</style>
