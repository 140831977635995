<template>
  <div class="list">
    <van-row v-if="listData && schoolList.length != 0">
      <van-row class="item-1" v-for="(_item, index) in schoolList" :key="index">
        <van-row class="title">
          <div>
            <img src="@/assets/logo.png" alt="Logo" class="logo" />
          </div>
          <div>
            <strong class="font16 color3">{{ _item.title }}</strong>
          </div>
        </van-row>
        <a class="files" :href="item.url" v-for="(item, id) in _item.list" :key="id">
          <van-row class="flex">
            <img :src="`http://mega.ivymik.cn/file-icon/223017b546a29cfe7aa7f1efcefc0b88.png`" />
            <div class="flex flexCenter flex1">
              <div class="ml-8  flex1">
              <div class="font15 fontWeight">{{ item.title }}</div> 
              <div class="font12 color6 mt-5">{{ item.subTitle }}</div> 
            </div>
            <van-icon name="arrow" color="#969799" style="vertical-align: middle" />
            </div>
            
          </van-row>
        </a>
      </van-row>
    </van-row>
    <van-row v-else-if="listData">
      <van-empty :description="$t('global.empty')" />
    </van-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Cell, Icon, Picker, Col, Row, ActionSheet, Empty, Toast, Sticky } from "vant";
import { studentHandBook } from "@/api/hankBook";

Vue.use(Cell);
Vue.use(Icon);
Vue.use(Picker);
Vue.use(Toast);
Vue.use(Col);
Vue.use(Row);
Vue.use(ActionSheet);
Vue.use(Empty);
Vue.use(Sticky);
export default {
  name: "HankBook",
  components: {},
  data() {
    return {
      schoolList: [],
      listData: false,
    };
  },
  created() {
    studentHandBook().then((res) => {
      this.schoolList = res.data;
      this.listData = true;
    });
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.schoolYear {
  line-height: 52px;
  padding-right: 16px;
  margin-right: 16px;
  position: relative;
  .open {
    position: absolute;
    right: 0;
    top: 18px;
  }
}
.list {
  min-height: 100vh;
  background: #fff;
  .item-1 {
    margin: 0 16px;
    .title {
      font-size: 15px;
      margin: 0 auto;
      text-align: center;
      padding-top: 32px;
      padding-bottom: 8px;
      img {
        width: 50px;
      }
    }
    .files {
      color: #323233;
      font-size: 14px;
      background: #f7f8fa;
      margin-top: 16px;
      padding: 12px;
      display: block;
      border-radius: 5px;
      .flex{
        img {
          height: 16px;
          margin-top: 2px;
        }
      }
      .flexCenter {
        align-items: center;
        .flex1 {
          margin-left: 8px;
        }
      }
      .fontWeight{
        font-weight: 500;
      }
    }
  }
}
</style>
