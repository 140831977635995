<template>
  <div :class="stepStatus == 4 && dataDetails.isCommon == 1 ? 'clickNone' : ''">
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{ $t("全家户口本") }}</div>
      </div>
    </div>
    <van-row class="mt-15">
      <div class="color3 font14 mb-5">学生户口本首页</div>
      <van-uploader v-model="step_data.home_page_url" @delete="deleteimg" :after-read="afterRead" :before-read="beforeRead" name="home_page" />
    </van-row>
    <van-row class="mt-15">
      <div class="color3 font14 mb-5">学生页</div>
      <van-uploader v-model="step_data.child_page_url" @delete="deleteimg" :after-read="afterRead" :before-read="beforeRead" name="child_page" />
    </van-row>
    <van-row class="mt-15">
      <div class="color3 font14 mb-5">父亲页</div>
      <van-uploader v-model="step_data.father_page_url" @delete="deleteimg" :after-read="afterRead" :before-read="beforeRead" name="father_page" />
    </van-row>
    <van-row class="mt-15">
      <div class="color3 font14 mb-5">母亲页</div>
      <van-uploader v-model="step_data.mother_page_url" @delete="deleteimg" :after-read="afterRead" :before-read="beforeRead" name="mother_page" />
    </van-row>
    <van-row class="mt-15">
      <div class="color3 font14 mb-5">户主页</div>
      <div class="color6 font12 mb-10">学生父母是户主的，可以不用上传</div>
      <van-uploader v-model="step_data.master_page_url" @delete="deleteimg" :after-read="afterRead" :before-read="beforeRead" name="master_page" />
    </van-row>
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{ $t("出生医学证明") }}</div>
      </div>
    </div>
    <van-row class="mt-20">
      <van-uploader v-model="step_data.medical_certificate_birth_url" @delete="deleteimg" :after-read="afterRead" :before-read="beforeRead" name="medical_certificate_birth" />
    </van-row>
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{ $t("房产证明") }}</div>
        <div class="color6 font14 pt-5">{{ $t("小升初准备去外区登记入学的，上传外区的房产证明；租房不用上传") }}</div>
      </div>
    </div>
    <van-row class="mt-15">
      <div class="color3 font14 mb-5">房产地址页</div>
      <van-uploader v-model="step_data.address_page_url" @delete="deleteimg" :after-read="afterRead" :before-read="beforeRead" name="address_page" />
    </van-row>
    <van-row class="mt-15">
      <div class="color3 font14 mb-5">房产编号页</div>
      <div class="color6 font12 mb-10">若房产地址、房产编号、登记日期在一页，可不用上传</div>
      <van-uploader v-model="step_data.number_page_url" @delete="deleteimg" :after-read="afterRead" :before-read="beforeRead" name="number_page" />
    </van-row>
    <van-row class="mt-15">
      <div class="color3 font14 mb-5">房产登记日期页</div>
      <div class="color6 font12 mb-10">若房产地址、房产编号、登记日期在一页，可不用上传</div>
      <van-uploader v-model="step_data.date_page_url" @delete="deleteimg" :after-read="afterRead" :before-read="beforeRead" name="date_page" />
    </van-row>
    <div class="flex mt-20">
      <div class="flexWidth"><span class="icon"></span></div>
      <div class="flex1">
        <div class="title">{{ $t("按本市户籍对待的学生须提交按本市户籍对待相关材料") }}</div>
        <div class="color6 font14 pt-5">{{ $t("不是京户对待的学生不用上传") }}</div>
      </div>
    </div>
    <van-row class="mt-15">
      <div class="color3 font14 mb-5">工作居住证确认单</div>
      <van-uploader v-model="step_data.voucher_url" @delete="deleteimg" :after-read="afterRead" :before-read="beforeRead" name="voucher" />
    </van-row>
    <van-row class="mt-15">
      <div class="color3 font14 mb-5">其他</div>
      <van-uploader v-model="step_data.other_url" @delete="deleteimg" :after-read="afterRead" :before-read="beforeRead" name="other" />
    </van-row>
    <div v-if="dataDetails.protocol.length != 0">
      <protocol-sign @click="baseImg" :busProtocol="dataDetails.protocol" :base64="base64"></protocol-sign>
    </div>
    <div class="mt-20">
      <van-button v-if="stepStatus == 4 && dataDetails.isCommon == 1" disabled type="info" block round color="#4d88d2" class="saveSub">{{ $t("coll.step_reviewed") }}</van-button>
      <a href="javascript:;" v-else @click="submitData()" class="saveBtnStep" :class="btnDisabled ? 'disabledSubmit' : ''">{{ $t("global.submit") }}</a>
    </div>
  </div>
</template>

<script>
import ProtocolSign from "@/components/steps/ProtocolSign";
import Vue from "vue";
import { Toast } from "vant";
import Compressor from "compressorjs";

export default {
  components: {
    "protocol-sign": ProtocolSign,
  },
  props: {
    dataDetails: {
      type: Object,
    },
    status: {
      type: Number,
    },
    disabled: {
      type: Number,
    },
  },
  name: "homeAddress",
  data() {
    return {
      insurance: [],
      stuPhoto: [],
      monPhoto: [],
      vaccination: [],
      stepStatus: "",
      qiniuToken: "",
      type: "",
      base64: "",
      btnDisabled: false,
      sign: "",
      sign_url: "",
    };
  },
  watch: {
    disabled() {
      this.btnDisabled = false;
    },
  },
  created() {
    this.stepStatus = this.status;
    this.step_data = this.dataDetails.step_data;
    this.step_data.home_page_url = this.tojson(this.dataDetails.step_data.home_page_url);
    this.step_data.child_page_url = this.tojson(this.dataDetails.step_data.child_page_url);
    this.step_data.father_page_url = this.tojson(this.dataDetails.step_data.father_page_url);
    this.step_data.mother_page_url = this.tojson(this.dataDetails.step_data.mother_page_url);
    this.step_data.master_page_url = this.tojson(this.dataDetails.step_data.master_page_url);
    this.step_data.medical_certificate_birth_url = this.tojson(this.dataDetails.step_data.medical_certificate_birth_url);
    this.step_data.address_page_url = this.tojson(this.dataDetails.step_data.address_page_url);
    this.step_data.number_page_url = this.tojson(this.dataDetails.step_data.number_page_url);
    this.step_data.date_page_url = this.tojson(this.dataDetails.step_data.date_page_url);
    this.step_data.voucher_url = this.tojson(this.dataDetails.step_data.voucher_url);
    this.step_data.other_url = this.tojson(this.dataDetails.step_data.other_url);
    this.qiniuToken = this.dataDetails.qiniuToken;
    if (this.dataDetails.sign_url != "") {
      this.base64 = this.dataDetails.sign_url;
    }
  },
  methods: {
    tojson(rows) {
      var ids = [];
      for (var i = 0, len = rows.length; i < len; i++) {
        var signAgainReq = new Object();
        signAgainReq.url = rows[i];
        ids.push(signAgainReq);
      }
      return ids;
    },
    beforeRead(file) {
      return new Promise((resolve) => {
        new Compressor(file, {
          maxWidth: 1200,
          quality: 0.6,
          success: resolve,
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
    afterRead(file, detail) {
      this.type = detail.name;
      if (file.length > 1) {
        for (let i in file) {
          this.upimg(file[i].content);
        }
      } else {
        this.upimg(file.content);
      }
    },
    upimg(data) {
      const that = this;
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: that.$t("pickupcard.uploading"),
      });
      var pic = data.substring(data.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            that.step_data[that.type].push(resJson.data.key);
            Toast(that.$t("pickupcard.Uploaded"));
          } else {
            Toast(resJson.msg);
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.qiniuToken);
      xhr.send(pic);
    },
    //删除图片
    deleteimg(file, detail) {
      this.step_data[detail.name].splice(detail.index - 1, 1);
    },
    baseImg(data) {
      if (data != "cancel") {
        this.base64 = data;
        this.newSign = true;
      }
    },
    submitData() {
      let that = this;
      if (this.btnDisabled) {
        return;
      }
      if (that.dataDetails.protocol.length != 0 && this.base64 == "") {
        Toast(that.$t("coll.please_sign"));
        return;
      }
      this.$nextTick(function () {
        this.btnDisabled = true;
      });
      if (that.dataDetails.protocol.length == 0) {
        that.saveData();
        return;
      }
      if (!that.newSign) {
        that.saveData();
        return;
      }
      var pic = this.base64.substring(this.base64.indexOf(",") + 1);
      var url = "https://upload-z1.qiniup.com/putb64/-1";
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // toast.clear()
        if (xhr.readyState == 4) {
          var resJson = JSON.parse(xhr.responseText);
          if (resJson.code == 0) {
            Vue.set(that, "sign_url", resJson.data.url);
            Vue.set(that, "sign", resJson.data.key);
            that.saveData();
          } else {
            Toast(resJson.msg);
            that.btnDisabled = false;
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.dataDetails.signQiniuToken);
      xhr.send(pic);
    },
    saveData() {
      this.btnDisabled = true;
      let data = {
        home_page: this.step_data.home_page,
        child_page: this.step_data.child_page,
        father_page: this.step_data.father_page,
        mother_page: this.step_data.mother_page,
        master_page: this.step_data.master_page,
        medical_certificate_birth: this.step_data.medical_certificate_birth,
        address_page: this.step_data.address_page,
        number_page: this.step_data.number_page,
        date_page: this.step_data.date_page,
        voucher: this.step_data.voucher,
        other: this.step_data.other,
        sign: this.sign,
        sign_url: this.sign_url,
      };
      this.$emit("change", data);
    },
  },
};
</script>

<style lang="less" scoped></style>
