<template>
  <van-row class="p16">
    <van-row type="flex" justify="end">
      <van-col>
        <switch-lang color="#377ED7" />
        <!-- <span class="lan"><van-icon name="cross" /> <span>English</span></span> -->
      </van-col>
    </van-row>
    <div v-if="loading && surveyData.length > 0">
      <div class="surveyList" v-for="(item, index) in surveyData" :key="index" :class="(item.survey_type == 1 && item.survey_status == 1) || (item.survey_type == 2 && item.survey_status == 1 && item.survey_status_teacher == 1) ? 'completedBg' : ''">
        <div class="flex item_center">
          <img :src="childName(item.childid, 'avatar')" alt="" class="avatar" />
          <div class="flex1 ml-12">
            <div class="font16 color3 font500 mb-4">{{ childName(item.childid, "name") }}</div>
            <div class="font12 color6">{{ childName(item.childid, "className") }}</div>
          </div>
          <van-tag round type="primary" color="#45A367" class="font12" v-if="(item.survey_type == 1 && item.survey_status == 1) || (item.survey_type == 2 && item.survey_status == 1 && item.survey_status_teacher == 1)">{{ $t("survey.Completed") }}</van-tag>
        </div>
        <div class="surveyInfo">
          <div class="font14 color3 font500 mb-8">{{ item.survey_title }}</div>
          <div class="font14 color6 font12 flex align_baseline">
            <van-icon name="clock-o" size="16" />
            <span class="ml-4  flex1">
              <div class="font14">{{ item.start_time }} ~ {{ item.end_time }}</div>
              <div class="time font12 mt-5" v-if="(item.survey_type == 1 && item.survey_status == 0) || (item.survey_type == 2 && (item.survey_status == 0 || item.survey_status_teacher == 0))">{{$t("survey.Remaining")}} {{item.left_time}}</div>
            </span>
          </div>
          <div class="step" >
            <div class="font14  flex item_center">
              <van-icon name="checked" color="#45A367" size="18" v-if="item.survey_status == 1" />
              <span class="stepNum" v-else>1</span>
              <span class="ml-4 flex1" :class="item.survey_status == 1 ? 'colorSuccess' : 'colorText'">{{ $t("survey.Regular_Survey") }}</span>
              <img src="../assets/default.png" alt=""  class="avatar24"  @click="showParent(item, 'teacher')" v-if="item.survey_status == 1 && item.fb_wx_user_avatar==''" >
              <van-image :src="item.fb_wx_user_avatar" alt="" class="avatar24" round cover @click="showParent(item, 'common')" v-if="item.survey_status == 1 && item.fb_wx_user_avatar!=''" />
              <van-icon name="arrow" class="color9 ml-4" size="16" @click="showParent(item, 'common')" v-if="item.survey_status == 1" />
            </div>
            <div class="font14 mt-10 flex item_center" v-if="item.survey_type == 2">
              <van-icon name="checked" color="#45A367" size="18" v-if="item.survey_status_teacher == 1" />
              <span class="stepNum" v-else>2</span>
              <span class="ml-4 flex1" :class="item.survey_status_teacher == 1 ? 'colorSuccess' : 'colorText'">{{ $t("survey.Teacher_Evaluation") }}</span>
              <img src="../assets/default.png" alt=""  class="avatar24"  @click="showParent(item, 'teacher')" v-if="item.survey_status_teacher == 1 && item.teacher_fb_wx_user_avatar==''" >
              <van-image :src="item.teacher_fb_wx_user_avatar" alt="" class="avatar24" round cover @click="showParent(item, 'teacher')" v-if="item.survey_status_teacher == 1 && item.teacher_fb_wx_user_avatar!=''" />
              <van-icon name="arrow" class="color9 ml-4" size="16" @click="showParent(item, 'teacher')" v-if="item.survey_status_teacher == 1" />
            </div>
          </div>
          <div class="startBtn" v-if="(item.survey_type == 1 && item.survey_status == 0) || (item.survey_type == 2 && (item.survey_status == 0 || item.survey_status_teacher == 0))">
            <router-link :to="{ name: 'SurveyStep', query: { feedback_ids: item.feedback_id, childid: item.childid, question_type: 'common' } }" v-if="(item.survey_type == 1 || item.survey_type == 2) && item.survey_status == 0">
              <van-button type="info" block round class="btnHeight">{{ $t("survey.Start") }}</van-button>
            </router-link>
            <router-link :to="{ name: 'SurveyStep', query: { feedback_ids: item.feedback_id, childid: item.childid, question_type: 'teacher' } }" v-else-if="item.survey_type == 2 && item.survey_status == 1">
              <van-button type="info" block round class="btnHeight">{{ $t("survey.Continue") }}</van-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="loading">
      <van-empty :description="$t('calendar.no_data')" />
    </div>
    <van-dialog v-model="detailShow" :title='$t("survey.Detail")' :show-confirm-button="false" show-cancel-button :cancel-button-text="$t('global.close')" cancel-button-color="#4D88D2" class="setTitle">
      <div class="detail">
        <div class="flex item_center font14 mb-16">
          <div class="color6 text"  :class="locale=='zh'?'width60':'width105'">{{ $t("survey.Survey") }}</div>
          <div class="color3 flex1" v-if="detailType == 'common'">{{ $t("survey.Regular_Survey") }}</div>
          <div class="color3 flex1" v-if="detailType == 'teacher'">{{ $t("survey.Teacher_Evaluation") }}</div>
        </div>
        <div class="flex item_center font14 mb-16">
          <div class="color6 text" :class="locale=='zh'?'width60':'width105'">{{ $t("survey.Submitted_by") }}</div>
          <div class="color3 flex1 item_center flex" v-if="detailType == 'common'">
            <img src="../assets/default.png" alt=""  class="avatar24"  v-if="detailItem.fb_wx_user_avatar==''" >
            <van-image :src="detailItem.fb_wx_user_avatar" alt="" class="avatar24" round cover  v-else/>
            <span class="ml-8">{{ detailItem.fb_wx_user_nickname }}</span>
          </div>
          <div class="color3 flex1 item_center flex" v-if="detailType == 'teacher'">
            <img src="../assets/default.png" alt=""  class="avatar24"  v-if="detailItem.teacher_fb_wx_user_avatar==''" >
            <van-image :src="detailItem.teacher_fb_wx_user_avatar" alt="" class="avatar24" round cover v-else />
            <span class="ml-8">{{ detailItem.teacher_fb_wx_user_nickname }}</span>
          </div>
        </div>
        <div class="flex item_center font14">
          <div class="color6 text"  :class="locale=='zh'?'width60':'width105'">{{ $t("survey.Time") }}</div>
          <div class="color3 flex1">{{ detailType == "common" ? detailItem.fb_date : detailItem.teacher_fb_date }}</div>
        </div>
      </div>
    </van-dialog>
  </van-row>
</template>
<script>
import { Row, Button, Image, Skeleton } from "vant";
import Vue from "vue";
import { childList } from "@/api/child";
import { surveyList } from "@/api/Survey";
Vue.use(Row, Button, Image, Skeleton);
import SwitchLang from "@/components/SwitchLang";
import i18n from "../i18n";

export default {
  data() {
    return {
      detailShow: false,
      childData: [],
      surveyData: [],
      loading: false,
      detailItem: {},
      detailType: "",
      locale:i18n.locale
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background-color:#fff;");
  },
  components: {
    "switch-lang": SwitchLang,
  },
  created() {
    childList().then((res) => {
      if (res.code == 0) {
        this.childData = res.data;
      }
    });
    this.getData();
  },
  methods: {
    childName(id, type) {
      var title = "";
      this.childData
        .filter((item) => item.id == id)
        .map((_item) => {
          title = _item[type];
        });
      return title;
    },
    getData() {
      //  survey_type 状态 1  普通
      surveyList().then((res) => {
        if (res.code == 0) {
          this.loading = true;
          this.surveyData = res.data;
        } else {
          this.loading = true;
        }
      });
    },
    showParent(item, type) {
      this.detailItem = item;
      this.detailType = type;
      this.detailShow = true;
    },
  },
};
</script>
<style lang="less" scoped>
.p16 {
  padding: 16px;
}

.font500 {
  font-weight: 500;
}

.item_center {
  align-items: center;
}
.avatar24 {
  width: 24px;
  height: 24px;
}

.lan {
  font-size: 14px;
  color: #4d88d2;
  border: 1px solid #4d88d2;
  padding: 0 12px;
  border-radius: 16px;
  display: inline-block;
  height: 32px;
  line-height: 31px;
}

.completedBg {
  background: #f2f3f5 !important;
}

.surveyList {
  background: #e9f3ff;
  border-radius: 16px;
  padding: 20px 16px;
  margin-top: 16px;

  .avatar {
    width: 48px;
    height: 48px;
    border-radius: 8px;
  }

  .completed {
    padding: 2px 6px;
    background: #45a367;
    border-radius: 10px;
    font-size: 12px;
    color: #fff;
  }
}

.surveyInfo {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  padding: 16px;
  margin-top: 16px;

  .step {
    border-top: 1px solid #dcdee0;
    padding-top: 12px;
    margin-top: 12px;

    .stepNum {
      width: 16px;
      height: 16px;
      display: inline-block;
      border-radius: 50%;
      color: #fff;
      background: #c8c9cc;
      text-align: center;
      line-height: 16px;
      font-size: 12px;
    }
    .colorText {
      color: #969799;
    }
    .colorSuccess {
      color: #45a367;
    }
  }
  .align_baseline{
    align-items: baseline;
  }
  .time {
    color: #f5a429;
  }
  .startBtn {
    padding: 0 24px;
    margin-top: 16px;
    .btnHeight {
      height: 40px;
    }
  }
}
.detail {
  padding: 24px;
  .text {
    margin-right: 16px;
  }
  .width60{
    width: 60px;
  }
  .width105{
    width: 105px;
  }
}
</style>
<style lang="less">
.setTitle {
  .van-dialog__header {
    color: #333333;
  }
}
</style>
