"use strict";

import Vue from 'vue';
import axios from "axios";
import store from '../store'
import storage from 'store'
import { Notify, Dialog } from 'vant';
import { APP_LANGUAGE, ACCESS_TOKEN } from '@/store/mutation-types'
import wx from 'weixin-js-sdk'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: process.env.VUE_APP_apiUrl || "",
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    config.headers['client'] = btoa('parent-wx')
    // Do something before request is sent
    const token = store.getters.token || sessionStorage.getItem(ACCESS_TOKEN)
    // const token = store.getters.token
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    const pos = config.url.indexOf('?') > -1 ? '&' : '?';
    let lang = storage.get(APP_LANGUAGE) || 'zh'
    if (lang == 'zh') {
      lang = 'zh_CN'
    }
    config.url += pos + 'lang=' + lang
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    if (response.data.code != 0 && response.data.msg) {
      // Notify({ type: 'warning', message: response.data.msg })
      Dialog.alert({
        message: response.data.msg,
      });
    }
    return response.data;
  },
  function(error) {
    // Do something with response error
    if (error.response.status === 403) {
      Notify({ type: 'warning', message: '403' })
    }
    else if (error.response.status === 401) {
      Dialog.alert({
        message: '页面过期，请重新进入',
      }).then(() => {
        sessionStorage.removeItem(ACCESS_TOKEN)
        wx.closeWindow()
      });
    }
    else {
      Notify({ type: 'danger', message: error.response.status })
    }
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
