<template>
  <div class="uniform">
    <div class="itemList" v-if="dataList.cat">
      <div>
        <div class="flex classify">
          <img :src="dataList.cat.img" alt="" class="img88" />
          <div class="flex1 ml-10">
            <div class="color3 font14 fontWeight word-break">{{ dataList.cat.title }}</div>
            <div class="font12 color6 mt-5 word-break2">{{ dataList.cat.desc }}</div>
            <div class="color9 font12 mt-15">{{ $t("uniform.Subtotal", { x: Object.keys(dataList.goods).length }) }}</div>
          </div>
        </div>
      </div>
      <div v-if="Object.keys(dataList.goods).length != 0">
        <div v-for="(item, key, index) in dataList.goods" :key="index">
          <div class="flex classify uniformList" @click="router(item)">
            <img v-if="item.img.all" :src="item.img.all[0]" alt="" class="img72" />
            <img v-else src="https://m2.files.ivykids.cn/cloud01-file-8025768Frlg5G8ESlkiwyVrQ50DbxZebVHT.png" alt=""
              class="img72" />
            <div class="flex1 ml-10">
              <div class="color3 font14 fontWeight word-break2 mt-2">{{ item.title }}</div>
              <div class="font12 color6 mt-5 word-break height16">{{ item.intro }}</div>

              <div class="flex align-items mt-5">
                <div class="flex1 font14"><span class=" colorRed fontWeight">¥ {{ item.unit_price }}</span> <span
                    class="van-card__origin-price" v-if="item.scribing_price != ''">¥ {{ item.scribing_price }}</span></div>
                <van-button round type="info" size="mini" @click.stop="buyUniform(item)" class="btnWidth">{{
                  $t("uniform.Purchase") }}</van-button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <van-empty :description="$t('calendar.no_data')" class="mt-30" />
      </div>
    </div>
    <shopping :actionShow="actionShow" :shoppingData="shoppingData" @click="hideAction" />
  </div>
</template>

<script>
import { uniformCats } from "@/api/uniform";
import Vue from "vue";
import Shopping from "@/components/shopping";
import { NoticeBar, ActionSheet, Empty, Toast, Icon, GoodsAction, GoodsActionIcon, GoodsActionButton, Sku, Card } from "vant";
import storage from "store";
import i18n from "../i18n";
import { CURRENT_CHILD } from "@/store/mutation-types";
Vue.use(ActionSheet);
Vue.use(NoticeBar);
Vue.use(Empty);
Vue.use(Icon);
Vue.use(Toast);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Sku);
Vue.use(Card);
export default {
  name: "lunch",
  components: {
    shopping: Shopping,
  },
  data() {
    return {
      childrenList: [],
      childrenStatus: {},
      childid: "",
      show: false,
      quantityNum: 1,
      dataList: {},
      actionShow: false,
      shoppingData: {},
    };
  },
  beforeCreate() {
    // document.querySelector("body").setAttribute("style", "background-color:#fff;");
  },
  mounted() { },
  created() {
    let childData = storage.get(CURRENT_CHILD + i18n.locale);
    this.childid = childData.id;
    this.getList();
  },
  methods: {
    getList() {
      uniformCats({ cid: this.$route.params.id, child_id: this.childid }).then((res) => {
        this.dataList = res.data;
      });
    },
    buyUniform(item) {
      this.shoppingData = item;
      this.actionShow = true;
    },
    hideAction() {
      this.actionShow = false;
    },
    router(item) {
      this.$router.push({
        path: "/uniformDetail/" + item.pid,
      });
    },
  },
};
</script>

<style scoped lang="less">
.blue {
  color: #4d88d2;
}

.classify {
  padding: 16px;
  background: #fff;

  .flex1 {
    width: 0;
  }
}

.img88 {
  width: 88px;
  height: 88px;
  border-radius: 8px;
  object-fit: cover;
}

.img72 {
  width: 72px;
  height: 72px;
  border-radius: 8px;
  object-fit: cover;
}

.uniformList {
  background: #fff;
  padding: 12px;
  border-radius: 8px;
  margin: 12px 16px;
}

.align-items {
  align-items: center;
}

.colorRed {
  color: #ee0a24;
}

.fontWeight {
  font-weight: 500;
}

.word-break {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shopping {
  width: 40px;
  height: 40px;
  background: #4d88d2;
  border-radius: 50%;
  position: fixed;
  right: 16px;
  bottom: 66px;
  line-height: 44px;
  text-align: center;

  .num {
    width: 16px;
    height: 16px;
    background: #ee0a24;
    display: inline-block;
    line-height: 15px;
    position: absolute;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    top: -3px;
  }
}

.word-break2 {
  overflow: hidden; //超出文本隐藏
  text-overflow: ellipsis; ///超出部分省略号显示
  display: -webkit-box; //弹性盒模型
  -webkit-box-orient: vertical; //上下垂直
  -webkit-line-clamp: 2; //自定义行数
}

.content {
  padding: 16px;
  margin-bottom: 50px;

  .categoryTitle {
    color: #323233;
    font-size: 14px;
    font-weight: 500;
    margin: 20px 0 12px 0;
  }

  .tagList {
    color: #323233;
    font-size: 13px;
    padding: 7px 16px;
    background: #f7f8fa;
    border-radius: 4px;
    margin-bottom: 8px;
    display: inline-block;
  }
}

.btnWidth {
  padding: 4px 10px;
}

.uniform {
  overflow-y: scroll;
  height: 100vh;
}
.height16{
  height: 16px;
}
.mt-2{
  margin-top: 2px;
}
.uniform::-webkit-scrollbar {
  display: none;
}</style>
