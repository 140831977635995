<template>
  <div>
    <div class="journal" v-if="journalData">
      <van-tabs v-model="active" color="#4D88D2">
        <van-tab :title="`${tab.name} (${tab.count})`" v-for="(tab, i) in tabData" :key="i">
          <div v-if="typeof journalData[tab.flag] != 'undefined' && journalData[tab.flag].length > 0">
            <router-link class="item-1" v-for="(item, i) in journalData[tab.flag]" :key="i" :to="`/journal/${item.id}`">
              <van-row>
                <div class="flex item_center">
                  <div >
                    <van-image round width="44px" height="44px" :src="item.sign_as.avatar" class="avatar-1 mr-10" />
                  </div>
                  <div  class="item-1-1 flex1">
                    <div class="name">{{ item.sign_as.name }}</div>
                    <div class="sub-name">{{ item.sign_as.title }}</div>
                    <van-icon name="arrow" color="#969799" />
                  </div>
                </div>
                <van-col span="24" class="title">{{ item.title }}</van-col>
                <van-col span="24">
                  <van-row type="flex" justify="space-between" class="sub-title">
                    <van-col>{{ item.updated_at }}</van-col>
                    <van-col v-if="item.has_reply" class="tips"><i class="dot-red"></i>{{ $t("journal.new_reply") }}</van-col>
                  </van-row>
                </van-col>
              </van-row>
            </router-link>
          </div>
          <van-empty :description="$t('global.empty')" v-else />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import storage from "store";
import { CURRENT_CHILD } from "@/store/mutation-types";
import { Col, Row, Icon, Empty, Image as VanImage } from "vant";
import { journalList } from "@/api/journal";

Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(Empty);
Vue.use(VanImage);

export default {
  name: "Journal",
  components: {},
  data() {
    return {
      active: 0,
      journalData: null,
      tabData: [
        { name: this.$t("journal.direct_message"), flag: 2, count: 0 },
        { name: this.$t("journal.normal_journal"), flag: 1, count: 0 },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      journalList({
        childid: this.$route.params.childid,
        school_id: this.$route.params.school_id,
        start_year: this.$route.params.start_year,
        week_num: this.$route.params.week_num,
      }).then((res) => {
        storage.set(CURRENT_CHILD, { id: parseInt(this.$route.params.childid) });
        this.journalData = res.data;
        this.tabData.map((val) => {
          if (typeof this.journalData[val.flag] != "undefined") {
            val.count = this.journalData[val.flag].length;
          }
        });
        if (this.tabData[0].count == 0 && this.tabData[1].count > 0) {
          this.active = 1;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.item_center{
  align-items: center;
}
.journal {
  background-color: #fff;
  .item-1 {
    display: block;
    margin: 0 15px;
    padding: 15px 0;
    border-bottom: 1px solid #ebedf0;
    &:last-child {
      border-bottom: 0;
    }
    .item-1-1 {
      position: relative;
      .van-icon {
        position: absolute;
        right: 0;
        top: 10px;
      }
    }
    .name {
      font-size: 14px;
      font-weight: bold;
      color: #323233;
      margin-bottom: 3px;
    }
    .sub-name {
      font-size: 13px;
      font-weight: 400;
      color: #646566;
    }
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #323233;
      line-height: 20px;
      margin: 8px 0 10px 0;
    }
    .sub-title {
      height: 16px;
      font-size: 12px;
      font-weight: 400;
      color: #969799;
      line-height: 16px;
      .tips {
        color: #ee0a24;
        line-height: 16px;
        height: 16px;
        .dot-red {
          margin-right: 4px;
          display: inline-block;
          width: 6px;
          height: 6px;
          background-color: #ee0a24;
          border-radius: 3px;
        }
      }
    }
  }
}
</style>

<style lang="less">
.journal {
  .avatar-1 {
    img {
      object-fit: cover;
    }
  }
}
</style>
