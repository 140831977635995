<template>
  <div>
    <children @change="childChange" :children="childrenList" v-if="childrenList.length > 0" />
    <div class="child-info" v-if="currentStudent">
      <router-link :to="`/studentInfo/${currentStudent.id}`">
        <div style="padding:12px;">
          <img :src="currentStudent.avatar" alt="" class="img-1">
          <div class="text-1">
            <span>{{ currentStudent.name }}</span>
            <van-icon name="arrow" />
          </div>
        </div>
      </router-link>
    </div>
    <div class="mate-info">
      <div class="mate-title">{{$t('student.my_class')}}</div>
      <div class="mate-items" v-if="classmates && classmates.length>0">
        <van-row type="flex" class="mate-item" v-for="(item, index) in classmates" :key="index">
          <van-col class="col-1">
            <img :src="item.avatar" :alt="item.name" class="avatar">
          </van-col>
          <van-col class="col-2">
            <div class="mate-name">{{ item.name }}</div>
            <div class="mate-gender">{{ item.gender }}</div>
          </van-col>
        </van-row>
      </div>
      <div v-else-if="classmates && classmates.length == 0">
        <van-empty :description="$t('calendar.no_data')" />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from 'vant';
import Children from '@/components/Children'
import { classmates } from '@/api/student'
import { childList } from "@/api/child";
import storage from 'store'
import { CURRENT_CHILD } from '@/store/mutation-types'
import i18n from '../i18n'

export default {
  name: 'Student',
  components: {
    'van-icon': Icon,
    'children': Children
  },
  data() {
    return {
      currentStudent: null,
      childrenList: [],
      classmates: null
    }
  },
  activated() {
    document.querySelector('body').setAttribute('style', 'background-color:#fff;')
  },
  deactivated() {
    document.querySelector('body').removeAttribute('style')
  },
  created() {
    childList().then((res) => {
      let localStudent = storage.get(CURRENT_CHILD + i18n.locale) || {};
      if (localStudent.id) {
        let currentStudent =res.data.filter((item) => item.id == localStudent.id)
        if (currentStudent.length != 0) {
          this.currentStudent = currentStudent[0]
          storage.set(CURRENT_CHILD + i18n.locale, this.currentStudent)
        }
        this.childrenList = res.data;
      }else{
        this.childrenList = res.data;
      }
      
    });
  },
  methods: {
    fetchData() {
      classmates(this.currentStudent.id).then(res => {
        this.classmates = res.data
      })
    },
    childChange(val) {
      this.currentStudent = val
      this.fetchData()
    }
  }
}
</script>

<style scoped lang="less">
.child-info {
  margin: 15px;
  height: 120px;
  background-image: url('../assets/student_bg.png');
  background-size: cover;
  text-align: center;

  .img-1 {
    width: 64px;
    height: 64px;
    border: 1px solid #fff;
    border-radius: 32px;
  }

  a {
    color: #fff !important;

    i {
      vertical-align: middle;
      font-size: 18px;
      margin-left: 4px;
      padding: 6px 0 8px 0;
    }
  }

  .text-1 {
    font-size: 16px;
    font-weight: bold;
  }
}

.mate-info {
  margin: 15px;

  .mate-title {
    margin-top: 20px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: bold;
  }

  .mate-items {
    .mate-item {
      height: 68px;

      .col-1 {
        line-height: 68px;

        .avatar {
          vertical-align: middle;
          width: 52px;
          height: 52px;
          border-radius: 26px;
        }
      }

      .col-2 {
        margin-left: 12px;

        .mate-name {
          font-size: 15px;
          margin-top: 15px;
          margin-bottom: 12px;
          font-weight: 400;
          line-height: 15px;
        }

        .mate-gender {
          font-size: 12px;
          color: #646566;
        }
      }
    }
  }
}
</style>
