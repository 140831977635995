import Vue from 'vue';

export function getBillHistory(params) {
    return Vue.axios({
        url: '/api/parent/invoice/payRecords',
        method: 'get',
        params: params
    })
}

export function getBillingDetails(params){
    return Vue.axios({
        url: '/api/parent/invoice/billingDetails',
        method: 'get',
        params: params
    })
}

export function getMyAccountBalance(params){
    return Vue.axios({
        url: '/api/parent/invoice/myAccount',
        method: 'get',
        params: params
    })
}