<template>
  <van-action-sheet v-model="newShow" :title="$t('bill.Detail')">
    <van-loading v-if="loadingDetail" vertical class="mt-50 mb-50"></van-loading>
    <div v-else class="pb-5">
      <div class="flex flexCenter van-row--justify-center mt-10 mb-20">
        <span class="fee">{{ billingDetails.amount_string }}</span>
      </div>
      <div>
        <van-cell class="cell-custom" center :title="$t('bill.InvoiceTitle')"
                  value-class="cell-value flexAuto"
                  title-class="cell-title"
                  :border="false"
                  :value="billingDetails.title"/>
        <van-cell v-if="billingDetails.fee_type==='lunch'" class="cell-custom" :border="false">
          <div slot="title" class="flex van-row--justify-space-between">
            <span class="cell-title">{{$t('bill.FeeType')}}</span>
            <div class="flex van-row--justify-end">
              <span class="mr-5">{{ billingDetails.fee_type_title }}</span>
              <router-link to="/Lunch" class="meal-details-link">
                {{$t('bill.ViewLunchDetail')}}
                <van-icon name="arrow"/>
              </router-link>
            </div>
          </div>
        </van-cell>
        <van-cell v-else class="cell-custom" center :title="$t('bill.FeeType')" value-class="cell-value flexAuto"
                  title-class="cell-title"
                  :border="false"
                  :value="billingDetails.fee_type_title"/>
        <van-cell v-if="billingDetails.deposit_amount>0" class="cell-custom" center :title="$t('bill.UseTuitionDeposit')"
                  value-class="cell-value flexAuto" title-class="cell-title"
                  :border="false"
                  :value="billingDetails.deposit_amount_string"/>
        <van-cell class="cell-custom" center :title="$t('bill.Status')" value-class="cell-value flexAuto" title-class="cell-title"
                  :border="false"
                  :value="billingDetails.invoice_status_title"/>
        <van-cell class="cell-custom" center :title="$t('bill.InvoicePeroid')" value-class="cell-value flexAuto" title-class="cell-title"
                  :border="false"
                  :value="billingDetails.interval"/>
        <van-cell class="cell-custom" center :title="$t('bill.DueDate')" value-class="cell-value flexAuto" title-class="cell-title"
                  :border="false"
                  :value="billingDetails.due_time"/>
        <van-cell class="cell-custom" center :title="$t('bill.AddTimestamp')"  value-class="cell-value flexAuto" title-class="cell-title"
                  :border="false"
                  :value="billingDetails.timestamp"/>
      </div>
      <div>
        <van-divider/>
        <van-cell center :border="false" :title="$t('bill.PaymentList')" title-class="record-title" class="cell-custom"/>
        <div v-for="(item,index) in billingPayment" :key="index">
          <van-cell center :border="false" :title="$t('bill.InvoiceAmount')" value-class="cell-value flexAuto" title-class="cell-title"
                    class="cell-custom"
                    :value="item.amount_string"/>
          <van-cell center :border="false" :title="$t('bill.Datereceived')" value-class="cell-value flexAuto" title-class="cell-title"
                    class="cell-custom"
                    :value="item.timestampe"/>
          <van-cell center :border="false" :title="$t('bill.PaymentType')" value-class="cell-value flexAuto" title-class="cell-title"
                    class="cell-custom"
                    :value="item.type"/>
          <van-divider v-if="index < billingPayment.length-1" dashed/>
        </div>
      </div>
      <div v-if="billingRefund.length>0">
        <van-divider/>
        <van-cell center  :border="false" :title="$t('bill.RefundList')" title-class="record-title" class="cell-custom" />
        <div v-for="(item,index) in billingRefund" :key="index">
          <van-cell center :border="false" :title="$t('bill.Title')" value-class="cell-value flexAuto" title-class="cell-title"
                    class="cell-custom"
                    :value="item.title"/>
          <van-cell center :border="false" :title="$t('bill.Amount')" value-class="cell-value flexAuto" title-class="cell-title"
                    class="cell-custom"
                    :value="item.refund_amount_string"/>
          <van-cell center :border="false" :title="$t('bill.RefundPeroid')" value-class="cell-value flexAuto" title-class="cell-title"
                    class="cell-custom"
                    :value="item.refund_period"/>
          <van-cell center :border="false" :title="$t('bill.RefundDate')" value-class="cell-value flexAuto" title-class="cell-title"
                    class="cell-custom"
                    :value="item.timestamp"/>
          <van-divider v-if="index < billingRefund.length-1" dashed/>
        </div>
      </div>
    </div>
  </van-action-sheet>
</template>

<script>
export default {
  name: "BillDetail",
  props: [
    "inoutSymbol",
    "billingDetails",
    "billingPayment",
    "billingRefund",
    "show",
    "loadingDetail"
  ],
  data() {
    return {
      newShow:this.show,
      newLoadingDetail:this.loadingDetail
    }
  },
  watch:{
    show(value){
      this.newShow = value
    },
    newShow(value){
      this.$emit('show', value);
    },
    loadingDetail(value){
      this.newLoadingDetail = value
    },
    newLoadingDetail(value){
      this.$emit('loadingDetail', value);
    }

  }
}
</script>

<style scoped  lang="less">
.flexCenter {
  align-items: center;

  .flex1 {
    margin-left: 8px;
  }
}

.cell-value {
  color: #323233;
}

.fee {
  height: 28px;
  font-size: 20px;
  font-weight: 600;
  color: #323233;
  line-height: 28px;
}

.cell-title {
  flex: unset;
  width: 25%;
  color: #646566;
}

.flexAuto {
  flex: auto;
}

.cell-custom {
  padding-top: 0;
}

.record-title {
  font-weight: 600;
  color: #323233;
  font-size: 16px;
}

.meal-details-link {
  color: #4D88D2;
}
</style>