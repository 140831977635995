import Vue from 'vue';

export function uniformHome(params) {
  return Vue.axios({
    url: '/api/uniform/home',
    method: 'get',
    params: params
  })
}

export function uniformCats(params) {
  return Vue.axios({
    url: '/api/uniform/produceCatGoods',
    method: 'get',
    params: params
  })
}

export function productDetail(params) {
  return Vue.axios({
    url: '/api/uniform/productDetail',
    method: 'get',
    params: params
  })
}

export function recordWant(data) {
  return Vue.axios({
    url: '/api/uniform/recordWant',
    method: 'post',
    data: data
  })
}

export function orderList(params) {
  return Vue.axios({
    url: '/api/uniform/orderList',
    method: 'get',
    params: params
  })
}

export function clearOrderGoods(data) {
  return Vue.axios({
    url: '/api/uniform/clearOrderGoods',
    method: 'post',
    data: data
  })
}

export function creatOrder(data) {
  return Vue.axios({
    url: '/api/uniform/creatOrder',
    method: 'post',
    data: data
  })
}

export function JSAPIPa(data) {
  return Vue.axios({
    url: '/api/uniform/JSAPIPay',
    method: 'post',
    data: data
  })
}

export function getStock(params) {
  return Vue.axios({
    url: '/api/uniform/cartInfo',
    method: 'get',
    params: params
  })
}

export function cancelPay(data) {
  return Vue.axios({
    url: '/api/uniform/cancelUnpaidOrders',
    method: 'post',
    data: data
  })
}

export function submitOrderCheck(data) {
  return Vue.axios({
    url: '/api/uniform/submitOrderCheck',
    method: 'post',
    data: data
  })
}

export function childList(data) {
  return Vue.axios({
    url: '/api/uniform/childList',
    method: 'post',
    data: data
  })
}




